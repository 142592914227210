export const COUNTRIES_DATA = [{
  code: 10,
  iso: 'ATA',
  coordinates: {
    lat: '-79.3344',
    lng: '20.313',
  },
  name: {
    en: 'Antarctica',
    es: 'Antártida',
    it: 'Antartide',
  },
},
{
  code: 16,
  iso: 'ASM',
  coordinates: {
    lat: '-14.316',
    lng: '-170.76',
  },
  name: {
    en: 'American Samoa',
    es: 'Samoa Americana',
    it: 'Samoa americane',
  },
},
{
  code: 74,
  iso: 'BVT',
  coordinates: {
    lat: '-54.417',
    lng: '3.419',
  },
  name: {
    en: 'Bouvet Island',
    es: 'Isla Bouvet',
    it: 'Isola di Bouvet',
  },
},
{
  code: 80,
  iso: '',
  coordinates: {
    lat: '',
    lng: '',
  },
  name: {
    en: 'Br. Antarctic Terr.',
    es: 'Territorios Antárticos Británicos',
    it: 'British Antartic Terr.',
  },
},
{
  code: 86,
  iso: 'IOT',
  coordinates: {
    lat: '',
    lng: '',
  },
  name: {
    en: 'Br. Indian Ocean Terr.',
    es: 'Territorio Británico del Océano Índico',
    it: "Territorio Britannico dell'Oceano Indiano",
  },
},
{
  code: 92,
  iso: 'VGB',
  coordinates: {
    lat: '',
    lng: '',
  },
  name: {
    en: 'Br. Virgin Isds',
    es: 'Islas Vírgenes Británicas',
    it: 'Isole Vergini Britanniche',
  },
},
{
  code: 129,
  iso: '',
  coordinates: {
    lat: '',
    lng: '',
  },
  name: {
    en: 'Caribbean, nes',
    es: 'Caribe, nes',
    it: 'Caraibi, nes',
  },
},
{
  code: 162,
  iso: 'CXR',
  coordinates: {
    lat: '-10.442',
    lng: '105.69',
  },
  name: {
    en: 'Christmas Isds',
    es: 'Isla de Navidad',
    it: 'Isd di Natale',
  },
},
{
  code: 166,
  iso: 'CCK',
  coordinates: {
    lat: '-12.171',
    lng: '96.855',
  },
  name: {
    en: 'Cocos Isds',
    es: 'Islas Cocos (Keeling)',
    it: 'Cocos Isds',
  },
},
{
  code: 238,
  iso: 'FLK',
  coordinates: {
    lat: '-51.663',
    lng: '-58.685',
  },
  name: {
    en: 'Falkland Isds (Malvinas)',
    es: 'Islas Malvinas',
    it: 'Isole Falkland (Malvinas)',
  },
},
{
  code: 239,
  iso: 'SGS',
  coordinates: {
    lat: '-54.261',
    lng: '-36.784',
  },
  name: {
    en: 'South Georgia and the South Sandwich Islands',
    es: 'Islas Georgias del Sur y Sandwich del Sur',
    it: 'Georgia meridionale e le isole Sandwich meridionali',
  },
},
{
  code: 260,
  iso: 'ATF',
  coordinates: {
    lat: '',
    lng: '',
  },
  name: {
    en: 'Fr. South Antarctic Terr.',
    es: 'Territorios Australes y Antárticas Franceses',
    it: 'Territori francesi meridionali e antartici',
  },
},
{
  code: 290,
  iso: '',
  coordinates: {
    lat: '',
    lng: '',
  },
  name: {
    en: 'Northern Africa, nes',
    es: 'Norte de África, nes',
    it: 'Africa settentrionale, nes',
  },
},
{
  code: 292,
  iso: 'GIB',
  coordinates: {
    lat: '36.1323',
    lng: '-5.3478',
  },
  name: {
    en: 'Gibraltar',
    es: 'Gibraltar',
    it: 'Gibilterra',
  },
},
{
  code: 334,
  iso: 'HMD',
  coordinates: {
    lat: '-53.101',
    lng: '73.526',
  },
  name: {
    en: 'Heard Island and McDonald Islands',
    es: 'Islas Heard y McDonald',
    it: 'Heard Island e McDonald Islands',
  },
},
{
  code: 336,
  iso: 'VAT',
  coordinates: {
    lat: '41.9034',
    lng: '12.4524',
  },
  name: {
    en: 'Holy See (Vatican City State)',
    es: 'Ciudad del Vaticano',
    it: 'Santa Sede (Cittá del Vaticano)',
  },
},
{
  code: 473,
  iso: '',
  coordinates: {
    lat: '',
    lng: '',
  },
  name: {
    en: 'LAIA, nes',
    es: 'LAIA, nes',
    it: 'LAIA, nes',
  },
},
{
  code: 490,
  iso: 'TWN',
  coordinates: {
    lat: '23.4747318',
    lng: '117.8402288',
  },
  name: {
    en: 'Taiwan',
    es: 'Taiwan',
    it: 'Taiwan',
  },
},
{
  code: 492,
  iso: '',
  coordinates: {
    lat: '',
    lng: '',
  },
  name: {
    en: 'Europe EU, nes',
    es: 'Europa EU, nes',
    it: 'Europa Unione europea, nes',
  },
},
{
  code: 527,
  iso: '',
  coordinates: {
    lat: '',
    lng: '',
  },
  name: {
    en: 'Oceania, nes',
    es: 'Oceanía, nes',
    it: 'Oceania, nes',
  },
},
{
  code: 531,
  iso: 'CUW',
  coordinates: {
    lat: '12.166',
    lng: '-69',
  },
  name: {
    en: 'Curaçao',
    es: 'Curaçao',
    it: 'Curacao',
  },
},
{
  code: 534,
  iso: 'SXM',
  coordinates: {
    lat: '18.041',
    lng: '-63.066',
  },
  name: {
    en: 'Saint Maarten',
    es: 'Saint Maarten',
    it: 'Saint Maarten',
  },
},
{
  code: 535,
  iso: 'BES',
  coordinates: {
    lat: '12.212',
    lng: '-68.294',
  },
  name: {
    en: 'Bonaire',
    es: 'Bonaire',
    it: 'Bonaire',
  },
},
{
  code: 536,
  iso: '',
  coordinates: {
    lat: '',
    lng: '',
  },
  name: {
    en: 'Neutral Zone',
    es: 'Zona Neutral',
    it: 'Zona Neutrale',
  },
},
{
  code: 568,
  iso: '',
  coordinates: {
    lat: '',
    lng: '',
  },
  name: {
    en: 'Other Europe, nes',
    es: 'Otros Europa, nes',
    it: 'Altro Europa, nes',
  },
},
{
  code: 570,
  iso: 'NIU',
  coordinates: {
    lat: '-19.033',
    lng: '-169.86',
  },
  name: {
    en: 'Niue',
    es: 'Niue',
    it: 'Niue',
  },
},
{
  code: 574,
  iso: 'NFK',
  coordinates: {
    lat: '-29.035',
    lng: '167.954',
  },
  name: {
    en: 'Norfolk Isds',
    es: 'Isla Norfolk',
    it: 'Norfolk Isds',
  },
},
{
  code: 577,
  iso: '',
  coordinates: {
    lat: '',
    lng: '',
  },
  name: {
    en: 'Other Africa, nes',
    es: 'Otros África, nes',
    it: 'Altro Africa, nes',
  },
},
{
  code: 580,
  iso: 'MNP',
  coordinates: {
    lat: '15.18',
    lng: '145.72',
  },
  name: {
    en: 'N. Mariana Isds',
    es: 'Islas Marianas del Norte',
    it: 'Isole Marianne settentrionali',
  },
},
{
  code: 581,
  iso: 'UMI',
  coordinates: {
    lat: '5.875',
    lng: '-162.05',
  },
  name: {
    en: 'United States Minor Outlying Islands',
    es: 'Islas Ultramarinas Menores de EstadosUnidos',
    it: 'Isole Minori Esterne degli Stati Uniti',
  },
},
{
  code: 584,
  iso: 'MHL',
  coordinates: {
    lat: '5.961',
    lng: '169.651',
  },
  name: {
    en: 'Marshall Isds',
    es: 'Islas Marshall',
    it: 'Marshall Isds',
  },
},
{
  code: 612,
  iso: 'PCN',
  coordinates: {
    lat: '-24.332',
    lng: '-128.34',
  },
  name: {
    en: 'Pitcairn',
    es: 'Islas Pitcairn',
    it: 'Pitcairn',
  },
},
{
  code: 636,
  iso: '',
  coordinates: {
    lat: '',
    lng: '',
  },
  name: {
    en: 'Rest of America, nes',
    es: 'Resto de América, nes',
    it: "Resto d'America, nomini",
  },
},
{
  code: 637,
  iso: '',
  coordinates: {
    lat: '',
    lng: '',
  },
  name: {
    en: 'North America and Central America, nes',
    es: 'Norte América y Centroamérica, nes',
    it: 'Nord America e America Centrale, nes',
  },
},
{
  code: 652,
  iso: 'BLM',
  coordinates: {
    lat: '17.903',
    lng: '-62.832',
  },
  name: {
    en: 'Saint Bartholemy',
    es: 'San Bartolomé',
    it: 'San Bartolomeo',
  },
},
{
  code: 654,
  iso: 'SHN',
  coordinates: {
    lat: '-40.289',
    lng: '-10.033',
  },
  name: {
    en: 'Saint Helena',
    es: 'Santa Elena',
    it: 'Saint Helena',
  },
},
{
  code: 659,
  iso: 'KNA',
  coordinates: {
    lat: '17.147',
    lng: '-62.58',
  },
  name: {
    en: 'Saint Kitts and Nevis',
    es: 'San Cristóbal y Nieves',
    it: 'Saint Kitts e Nevis',
  },
},
{
  code: 666,
  iso: 'SPM',
  coordinates: {
    lat: '47.036',
    lng: '-56.321',
  },
  name: {
    en: 'Saint Pierre and Miquelon',
    es: 'San Pedro y Miquelón',
    it: 'Saint Pierre e Miquelon',
  },
},
{
  code: 674,
  iso: 'SMR',
  coordinates: {
    lat: '43.934',
    lng: '12.454',
  },
  name: {
    en: 'San Marino',
    es: 'San Marino',
    it: 'San Marino',
  },
},
{
  code: 732,
  iso: 'ESH',
  coordinates: {
    lat: '24.4813',
    lng: '-13.6266',
  },
  name: {
    en: 'Western Sahara',
    es: 'Sahara Occidental',
    it: 'Sahara occidentale',
  },
},
{
  code: 772,
  iso: 'TKL',
  coordinates: {
    lat: '-8.571',
    lng: '-172.48',
  },
  name: {
    en: 'Tokelau',
    es: 'Tokelau',
    it: 'Tokelau',
  },
},
{
  code: 837,
  iso: '',
  coordinates: {
    lat: '',
    lng: '',
  },
  name: {
    en: 'Bunkers',
    es: 'Bunkers',
    it: 'bunkers',
  },
},
{
  code: 838,
  iso: '',
  coordinates: {
    lat: '',
    lng: '',
  },
  name: {
    en: 'Free Zones',
    es: 'Free Zones',
    it: 'Zone libere',
  },
},
{
  code: 839,
  iso: '',
  coordinates: {
    lat: '',
    lng: '',
  },
  name: {
    en: 'Special Categories',
    es: 'Categorías Especiales',
    it: 'Categorie speciali',
  },
},
{
  code: 849,
  iso: '',
  coordinates: {
    lat: '',
    lng: '',
  },
  name: {
    en: 'US Misc. Pacific Isds',
    es: 'Islas Pacíficas Misc. EE.UU.',
    it: 'Isole del Pacifico Misc.',
  },
},
{
  code: 876,
  iso: 'WLF',
  coordinates: {
    lat: '-13.262',
    lng: '-176.15',
  },
  name: {
    en: 'Wallis and Futuna Isds',
    es: 'Wallis y Futuna',
    it: 'Wallis e Futuna',
  },
},
{
  code: 879,
  iso: '',
  coordinates: {
    lat: '',
    lng: '',
  },
  name: {
    en: 'Western Asia, nes',
    es: 'Asia Occidental, nes',
    it: 'Asia Occidentale, nes',
  },
},
{
  code: 899,
  iso: '',
  coordinates: {
    lat: '',
    lng: '',
  },
  name: {
    en: 'Areas, nes',
    es: 'Areas, nes',
    it: 'Aree, n',
  },
},
{
  code: 4,
  iso: 'AFG',
  coordinates: {
    lat: '34.023',
    lng: '65.5267',
  },
  name: {
    en: 'Afghanistan',
    es: 'Afganistán',
    it: 'Afghanistan',
  },
  publicUrl: {
    en: 'afghanistan',
    es: 'afganistan',
    it: 'afghanistan',
  },
},
{
  code: 8,
  iso: 'ALB',
  coordinates: {
    lat: '40.654',
    lng: '20.076',
  },
  name: {
    en: 'Albania',
    es: 'Albania',
    it: 'Albania',
  },
  publicUrl: {
    en: 'albania',
    es: 'albania',
    it: 'albania',
  },
},
{
  code: 276,
  iso: 'DEU',
  coordinates: {
    lat: '51.3819',
    lng: '9.9877',
  },
  name: {
    en: 'Germany',
    es: 'Alemania',
    it: 'Germania',
  },
  publicUrl: {
    en: 'germany',
    es: 'alemania',
    it: 'germania',
  },
},
{
  code: 12,
  iso: 'DZA',
  coordinates: {
    lat: '28.6045',
    lng: '2.64',
  },
  name: {
    en: 'Algeria',
    es: 'Algeria',
    it: 'algeria',
  },
  publicUrl: {
    en: 'algeria',
    es: 'algeria',
    it: 'algeria',
  },
},
{
  code: 20,
  iso: 'AND',
  coordinates: {
    lat: '42.541',
    lng: '1.546',
  },
  name: {
    en: 'Andorra',
    es: 'Andorra',
    it: 'Andorra',
  },
  publicUrl: {
    en: 'andorra',
    es: 'andorra',
    it: 'andorra',
  },
},
{
  code: 24,
  iso: 'AGO',
  coordinates: {
    lat: '-12.836',
    lng: '17.808',
  },
  name: {
    en: 'Angola',
    es: 'Angola',
    it: 'angola',
  },
  publicUrl: {
    en: 'angola',
    es: 'angola',
    it: 'angola',
  },
},
{
  code: 660,
  iso: 'AIA',
  coordinates: {
    lat: '18.245',
    lng: '-63.023',
  },
  name: {
    en: 'Anguilla',
    es: 'Anguila',
    it: 'Anguilla',
  },
  publicUrl: {
    en: 'anguilla',
    es: 'anguila',
    it: 'anguilla',
  },
},
{
  code: 28,
  iso: 'ATG',
  coordinates: {
    lat: '17.625',
    lng: '-61.786',
  },
  name: {
    en: 'Antigua and Barbuda',
    es: 'Antigua y Barbuda',
    it: 'Antigua e Barbuda',
  },
  publicUrl: {
    en: 'antigua-and-barbuda',
    es: 'antigua-y-barbuda',
    it: 'antigua-e-barbuda',
  },
},
{
  code: 530,
  iso: 'ANT',
  coordinates: {
    lat: '12.165',
    lng: '-68.937',
  },
  name: {
    en: 'Neth. Antilles',
    es: 'Antillas Neerlandesas',
    it: 'Neth. Antilles',
  },
  publicUrl: {
    en: 'neth-antilles',
    es: 'antillas-neerlandesas',
    it: 'neth-antilles',
  },
},
{
  code: 682,
  iso: 'SAU',
  coordinates: {
    lat: '24.191',
    lng: '44.32',
  },
  name: {
    en: 'Saudi Arabia',
    es: 'Arabia Saudita',
    it: 'Arabia Saudita',
  },
  publicUrl: {
    en: 'saudi-arabia',
    es: 'arabia-saudita',
    it: 'arabia-saudita',
  },
},
{
  code: 32,
  iso: 'ARG',
  coordinates: {
    lat: '-33.166',
    lng: '-64.31',
  },
  name: {
    en: 'Argentina',
    es: 'Argentina',
    it: 'Argentina',
  },
  publicUrl: {
    en: 'argentina',
    es: 'argentina',
    it: 'argentina',
  },
},
{
  code: 51,
  iso: 'ARM',
  coordinates: {
    lat: '40.56',
    lng: '44.449',
  },
  name: {
    en: 'Armenia',
    es: 'Armenia',
    it: 'Armenia',
  },
  publicUrl: {
    en: 'armenia',
    es: 'armenia',
    it: 'armenia',
  },
},
{
  code: 533,
  iso: 'ABW',
  coordinates: {
    lat: '12.556',
    lng: '-70.024',
  },
  name: {
    en: 'Aruba',
    es: 'Aruba',
    it: 'aruba',
  },
  publicUrl: {
    en: 'aruba',
    es: 'aruba',
    it: 'aruba',
  },
},
{
  code: 36,
  iso: 'AUS',
  coordinates: {
    lat: '-24.578',
    lng: '133.582',
  },
  name: {
    en: 'Australia',
    es: 'Australia',
    it: 'Australia',
  },
  publicUrl: {
    en: 'australia',
    es: 'australia',
    it: 'australia',
  },
},
{
  code: 40,
  iso: 'AUT',
  coordinates: {
    lat: '47.65',
    lng: '14.705',
  },
  name: {
    en: 'Austria',
    es: 'Austria',
    it: 'Austria',
  },
  publicUrl: {
    en: 'austria',
    es: 'austria',
    it: 'austria',
  },
},
{
  code: 31,
  iso: 'AZE',
  coordinates: {
    lat: '40.46',
    lng: '47.883',
  },
  name: {
    en: 'Azerbaijan',
    es: 'Azerbayán',
    it: 'Azerbaijan',
  },
  publicUrl: {
    en: 'azerbaijan',
    es: 'azerbayan',
    it: 'azerbaijan',
  },
},
{
  code: 56,
  iso: 'BEL',
  coordinates: {
    lat: '50.6745',
    lng: '4.4588',
  },
  name: {
    en: 'Belgium',
    es: 'Bélgica',
    it: 'Belgio',
  },
  publicUrl: {
    en: 'belgium',
    es: 'belgica',
    it: 'belgio',
  },
},
{
  code: 44,
  iso: 'BHS',
  coordinates: {
    lat: '24.616',
    lng: '-77.989',
  },
  name: {
    en: 'Bahamas',
    es: 'Bahamas',
    it: 'Bahamas',
  },
  publicUrl: {
    en: 'bahamas',
    es: 'bahamas',
    it: 'bahamas',
  },
},
{
  code: 48,
  iso: 'BHR',
  coordinates: {
    lat: '26.028',
    lng: '50.55',
  },
  name: {
    en: 'Bahrain',
    es: 'Bahrein',
    it: 'Bahrain',
  },
  publicUrl: {
    en: 'bahrain',
    es: 'bahrein',
    it: 'bahrain',
  },
},
{
  code: 50,
  iso: 'BGD',
  coordinates: {
    lat: '24.598',
    lng: '90.1963',
  },
  name: {
    en: 'Bangladesh',
    es: 'Bangladesh',
    it: 'Bangladesh',
  },
  publicUrl: {
    en: 'bangladesh',
    es: 'bangladesh',
    it: 'bangladesh',
  },
},
{
  code: 52,
  iso: 'BRB',
  coordinates: {
    lat: '13.147',
    lng: '-59.557',
  },
  name: {
    en: 'Barbados',
    es: 'Barbados',
    it: 'Barbados',
  },
  publicUrl: {
    en: 'barbados',
    es: 'barbados',
    it: 'barbados',
  },
},
{
  code: 84,
  iso: 'BLZ',
  coordinates: {
    lat: '17.108',
    lng: '-88.744',
  },
  name: {
    en: 'Belize',
    es: 'Belice',
    it: 'Belize',
  },
  publicUrl: {
    en: 'belize',
    es: 'belice',
    it: 'belize',
  },
},
{
  code: 204,
  iso: 'BEN',
  coordinates: {
    lat: '10.478',
    lng: '2.274',
  },
  name: {
    en: 'Benin',
    es: 'Benín',
    it: 'Benin',
  },
  publicUrl: {
    en: 'benin',
    es: 'benin',
    it: 'benin',
  },
},
{
  code: 64,
  iso: 'BTN',
  coordinates: {
    lat: '27.4902',
    lng: '90.2151',
  },
  name: {
    en: 'Bhutan',
    es: 'Bhután',
    it: 'Bhutan',
  },
  publicUrl: {
    en: 'bhutan',
    es: 'bhutan',
    it: 'bhutan',
  },
},
{
  code: 112,
  iso: 'BLR',
  coordinates: {
    lat: '53.3292',
    lng: '27.9492',
  },
  name: {
    en: 'Belarus',
    es: 'Bielorrusia',
    it: 'Bielorussia',
  },
  publicUrl: {
    en: 'belarus',
    es: 'bielorrusia',
    it: 'bielorussia',
  },
},
{
  code: 104,
  iso: 'MMR',
  coordinates: {
    lat: '21.841',
    lng: '96.05',
  },
  name: {
    en: 'Myanmar',
    es: 'Birmania',
    it: 'Myanmar',
  },
  publicUrl: {
    en: 'myanmar',
    es: 'birmania',
    it: 'myanmar',
  },
},
{
  code: 68,
  iso: 'BOL',
  coordinates: {
    lat: '-16.71',
    lng: '-65.183',
  },
  name: {
    en: 'Bolivia',
    es: 'Bolivia',
    it: 'Bolivia',
  },
  publicUrl: {
    en: 'bolivia',
    es: 'bolivia',
    it: 'bolivia',
  },
},
{
  code: 70,
  iso: 'BIH',
  coordinates: {
    lat: '44.342',
    lng: '17.804',
  },
  name: {
    en: 'Bosnia Herzegovina',
    es: 'Bosnia y Herzegovina',
    it: 'Bosnia Erzegovina',
  },
  publicUrl: {
    en: 'bosnia-herzegovina',
    es: 'bosnia-y-herzegovina',
    it: 'bosnia-erzegovina',
  },
},
{
  code: 72,
  iso: 'BWA',
  coordinates: {
    lat: '-22.126',
    lng: '23.662',
  },
  name: {
    en: 'Botswana',
    es: 'Botsuana',
    it: 'Botswana',
  },
  publicUrl: {
    en: 'botswana',
    es: 'botsuana',
    it: 'botswana',
  },
},
{
  code: 76,
  iso: 'BRA',
  coordinates: {
    lat: '-11.694',
    lng: '-48.978',
  },
  name: {
    en: 'Brazil',
    es: 'Brasil',
    it: 'Brasile',
  },
  publicUrl: {
    en: 'brazil',
    es: 'brasil',
    it: 'brasile',
  },
},
{
  code: 96,
  iso: 'BRN',
  coordinates: {
    lat: '4.452',
    lng: '114.557',
  },
  name: {
    en: 'Brunei Darussalam',
    es: 'Brunéi',
    it: 'Brunei Darussalam',
  },
  publicUrl: {
    en: 'brunei-darussalam',
    es: 'brunei',
    it: 'brunei-darussalam',
  },
},
{
  code: 100,
  iso: 'BGR',
  coordinates: {
    lat: '42.625',
    lng: '24.521',
  },
  name: {
    en: 'Bulgaria',
    es: 'Bulgaria',
    it: 'Bulgaria',
  },
  publicUrl: {
    en: 'bulgaria',
    es: 'bulgaria',
    it: 'bulgaria',
  },
},
{
  code: 854,
  iso: 'BFA',
  coordinates: {
    lat: '12.673',
    lng: '-1.226',
  },
  name: {
    en: 'Burkina Faso',
    es: 'Burkina Faso',
    it: 'Burkina Faso',
  },
  publicUrl: {
    en: 'burkina-faso',
    es: 'burkina-faso',
    it: 'burkina-faso',
  },
},
{
  code: 108,
  iso: 'BDI',
  coordinates: {
    lat: '-3.333',
    lng: '29.749',
  },
  name: {
    en: 'Burundi',
    es: 'Burundi',
    it: 'Burundi',
  },
  publicUrl: {
    en: 'burundi',
    es: 'burundi',
    it: 'burundi',
  },
},
{
  code: 132,
  iso: 'CPV',
  coordinates: {
    lat: '15.416',
    lng: '-23.36',
  },
  name: {
    en: 'Cabo Verde',
    es: 'Cabo Verde',
    it: 'Cabo Verde',
  },
  publicUrl: {
    en: 'cabo-verde',
    es: 'cabo-verde',
    it: 'cabo-verde',
  },
},
{
  code: 116,
  iso: 'KHM',
  coordinates: {
    lat: '12.69',
    lng: '104.444',
  },
  name: {
    en: 'Cambodia',
    es: 'Camboya',
    it: 'Cambogia',
  },
  publicUrl: {
    en: 'cambodia',
    es: 'camboya',
    it: 'cambogia',
  },
},
{
  code: 120,
  iso: 'CMR',
  coordinates: {
    lat: '4.4',
    lng: '12.131',
  },
  name: {
    en: 'Cameroon',
    es: 'Camerún',
    it: 'Camerun',
  },
  publicUrl: {
    en: 'cameroon',
    es: 'camerun',
    it: 'camerun',
  },
},
{
  code: 124,
  iso: 'CAN',
  coordinates: {
    lat: '59.2067',
    lng: '-111.1937',
  },
  name: {
    en: 'Canada',
    es: 'Canadá',
    it: 'Canada',
  },
  publicUrl: {
    en: 'canada',
    es: 'canada',
    it: 'canada',
  },
},
{
  code: 148,
  iso: 'TCD',
  coordinates: {
    lat: '15.981',
    lng: '18.781',
  },
  name: {
    en: 'Chad',
    es: 'Chad',
    it: 'Chad',
  },
  publicUrl: {
    en: 'chad',
    es: 'chad',
    it: 'chad',
  },
},
{
  code: 152,
  iso: 'CHL',
  coordinates: {
    lat: '-23.176',
    lng: '-69.201',
  },
  name: {
    en: 'Chile',
    es: 'Chile',
    it: 'Chile',
  },
  publicUrl: {
    en: 'chile',
    es: 'chile',
    it: 'chile',
  },
},
{
  code: 156,
  iso: 'CHN',
  coordinates: {
    lat: '35.1753',
    lng: '99.3877',
  },
  name: {
    en: 'China',
    es: 'China',
    it: 'Cina',
  },
  publicUrl: {
    en: 'china',
    es: 'china',
    it: 'cina',
  },
},
{
  code: 196,
  iso: 'CYP',
  coordinates: {
    lat: '34.894',
    lng: '32.928',
  },
  name: {
    en: 'Cyprus',
    es: 'Chipre',
    it: 'Cipro',
  },
  publicUrl: {
    en: 'cyprus',
    es: 'chipre',
    it: 'cipro',
  },
},
{
  code: 170,
  iso: 'COL',
  coordinates: {
    lat: '3.794',
    lng: '-73.606',
  },
  name: {
    en: 'Colombia',
    es: 'Colombia',
    it: 'Colombia',
  },
  publicUrl: {
    en: 'colombia',
    es: 'colombia',
    it: 'colombia',
  },
},
{
  code: 174,
  iso: 'COM',
  coordinates: {
    lat: '-11.755',
    lng: '43.342',
  },
  name: {
    en: 'Comoros',
    es: 'Comoras',
    it: 'Comoros',
  },
  publicUrl: {
    en: 'comoros',
    es: 'comoras',
    it: 'comoros',
  },
},
{
  code: 178,
  iso: 'COG',
  coordinates: {
    lat: '0.509',
    lng: '16.08',
  },
  name: {
    en: 'Congo',
    es: 'Congo',
    it: 'Congo',
  },
  publicUrl: {
    en: 'congo',
    es: 'congo',
    it: 'congo',
  },
},
{
  code: 180,
  iso: 'COD',
  coordinates: {
    lat: '-1.418',
    lng: '23.607',
  },
  name: {
    en: 'Dem. Rep. of the Congo',
    es: 'Rep. Dem. del Congo',
    it: 'Congo-Kinshasa',
  },
  publicUrl: {
    en: 'dem-rep-of-the-congo',
    es: 'congo',
    it: 'congo-kinshasa',
  },
},
{
  code: 408,
  iso: 'PRK',
  coordinates: {
    lat: '39.767',
    lng: '126.389',
  },
  name: {
    en: 'North Korea',
    es: 'Corea del Norte',
    it: 'Corea del Nord',
  },
  publicUrl: {
    en: 'dem-peoples-rep-of-korea',
    es: 'corea-del-norte',
    it: 'corea-del-nord',
  },
},
{
  code: 410,
  iso: 'KOR',
  coordinates: {
    lat: '36.338',
    lng: '128.16',
  },
  name: {
    en: 'South Korea',
    es: 'Corea del Sur',
    it: 'Corea del Sud',
  },
  publicUrl: {
    en: 'rep-of-korea',
    es: 'corea-del-sur',
    it: 'Corea-del-sud',
  },
},
{
  code: 384,
  iso: 'CIV',
  coordinates: {
    lat: '7.497',
    lng: '-5.336',
  },
  name: {
    en: "\"Côte d'Ivoire\"",
    es: 'Costa de Marfil',
    it: "\"Costa d'Avorio\"",
  },
  publicUrl: {
    en: 'cote-divoire',
    es: 'costa-de-marfil',
    it: "\"costa-d'avorio\"",
  },
},
{
  code: 188,
  iso: 'CRI',
  coordinates: {
    lat: '10.126',
    lng: '-84.075',
  },
  name: {
    en: 'Costa Rica',
    es: 'Costa Rica',
    it: 'Costa Rica',
  },
  publicUrl: {
    en: 'costa-rica',
    es: 'costa-rica',
    it: 'costa-rica',
  },
},
{
  code: 191,
  iso: 'HRV',
  coordinates: {
    lat: '45.754',
    lng: '16.385',
  },
  name: {
    en: 'Croatia',
    es: 'Croacia',
    it: 'Croazia',
  },
  publicUrl: {
    en: 'croatia',
    es: 'croacia',
    it: 'croazia',
  },
},
{
  code: 192,
  iso: 'CUB',
  coordinates: {
    lat: '20.571',
    lng: '-76.456',
  },
  name: {
    en: 'Cuba',
    es: 'Cuba',
    it: 'Cuba',
  },
  publicUrl: {
    en: 'cuba',
    es: 'cuba',
    it: 'cuba',
  },
},
{
  code: 208,
  iso: 'DNK',
  coordinates: {
    lat: '56.113',
    lng: '9.096',
  },
  name: {
    en: 'Denmark',
    es: 'Dinamarca',
    it: 'Danimarca',
  },
  publicUrl: {
    en: 'denmark',
    es: 'dinamarca',
    it: 'danimarca',
  },
},
{
  code: 212,
  iso: 'DMA',
  coordinates: {
    lat: '15.458',
    lng: '-61.35',
  },
  name: {
    en: 'Dominica',
    es: 'Dominica',
    it: 'Dominica',
  },
  publicUrl: {
    en: 'dominica',
    es: 'dominica',
    it: 'dominica',
  },
},
{
  code: 218,
  iso: 'ECU',
  coordinates: {
    lat: '-1.151',
    lng: '-78.308',
  },
  name: {
    en: 'Ecuador',
    es: 'Ecuador',
    it: 'Ecuador',
  },
  publicUrl: {
    en: 'ecuador',
    es: 'ecuador',
    it: 'ecuador',
  },
},
{
  code: 818,
  iso: 'EGY',
  coordinates: {
    lat: '26.16',
    lng: '28.742',
  },
  name: {
    en: 'Egypt',
    es: 'Egipto',
    it: 'Egitto',
  },
  publicUrl: {
    en: 'egypt',
    es: 'egipto',
    it: 'egitto',
  },
},
{
  code: 222,
  iso: 'SLV',
  coordinates: {
    lat: '13.848',
    lng: '-89.229',
  },
  name: {
    en: 'El Salvador',
    es: 'El Salvador',
    it: 'El Salvador',
  },
  publicUrl: {
    en: 'el-salvador',
    es: 'el-salvador',
    it: 'el-salvador',
  },
},
{
  code: 784,
  iso: 'ARE',
  coordinates: {
    lat: '23.9299',
    lng: '53.9031',
  },
  name: {
    en: 'United Arab Emirates',
    es: 'Emiratos Árabes Unidos',
    it: 'Emirati Arabi Uniti',
  },
  publicUrl: {
    en: 'united-arab-emirates',
    es: 'emiratos-arabes-unidos',
    it: 'emirati-arabi-uniti',
  },
},
{
  code: 232,
  iso: 'ERI',
  coordinates: {
    lat: '15.982',
    lng: '38.02',
  },
  name: {
    en: 'Eritrea',
    es: 'Eritrea',
    it: "\"l'Eritrea\"",
  },
  publicUrl: {
    en: 'eritrea',
    es: 'eritrea',
    it: "\"l'eritrea\"",
  },
},
{
  code: 703,
  iso: 'SVK',
  coordinates: {
    lat: '48.8595',
    lng: '19.4434',
  },
  name: {
    en: 'Slovakia',
    es: 'Eslovaquia',
    it: 'Slovacchia',
  },
  publicUrl: {
    en: 'slovakia',
    es: 'eslovaquia',
    it: 'slovacchia',
  },
},
{
  code: 705,
  iso: 'SVN',
  coordinates: {
    lat: '46.037',
    lng: '14.534',
  },
  name: {
    en: 'Slovenia',
    es: 'Eslovenia',
    it: 'Slovenia',
  },
  publicUrl: {
    en: 'slovenia',
    es: 'eslovenia',
    it: 'slovenia',
  },
},
{
  code: 724,
  iso: 'ESP',
  coordinates: {
    lat: '40.617',
    lng: '-3.388',
  },
  name: {
    en: 'Spain',
    es: 'España',
    it: 'Spagna',
  },
  publicUrl: {
    en: 'spain',
    es: 'espana',
    it: 'spagna',
  },
},
{
  code: 842,
  iso: 'USA',
  coordinates: {
    lat: '40.0792',
    lng: '-98.8164',
  },
  name: {
    en: 'USA',
    es: 'Estados Unidos',
    it: 'Stati Uniti',
  },
  publicUrl: {
    en: 'usa',
    es: 'estados-unidos',
    it: 'stati-uniti',
  },
},
{
  code: 233,
  iso: 'EST',
  coordinates: {
    lat: '58.777',
    lng: '25.977',
  },
  name: {
    en: 'Estonia',
    es: 'Estonia',
    it: 'Estonia',
  },
  publicUrl: {
    en: 'estonia',
    es: 'estonia',
    it: 'estonia',
  },
},
{
  code: 231,
  iso: 'ETH',
  coordinates: {
    lat: '8.435',
    lng: '38.786',
  },
  name: {
    en: 'Ethiopia',
    es: 'Etiopía',
    it: 'Etiopia',
  },
  publicUrl: {
    en: 'ethiopia',
    es: 'etiopia',
    it: 'etiopia',
  },
},
{
  code: 608,
  iso: 'PHL',
  coordinates: {
    lat: '17.181',
    lng: '121.256',
  },
  name: {
    en: 'Philippines',
    es: 'Filipinas',
    it: 'Filippine',
  },
  publicUrl: {
    en: 'philippines',
    es: 'filipinas',
    it: 'filippine',
  },
},
{
  code: 246,
  iso: 'FIN',
  coordinates: {
    lat: '62.716',
    lng: '26.192',
  },
  name: {
    en: 'Finland',
    es: 'Finlandia',
    it: 'Finlandia',
  },
  publicUrl: {
    en: 'finland',
    es: 'finlandia',
    it: 'finlandia',
  },
},
{
  code: 242,
  iso: 'FJI',
  coordinates: {
    lat: '-17.15',
    lng: '177.186',
  },
  name: {
    en: 'Fiji',
    es: 'Fiyi',
    it: 'Fiji',
  },
  publicUrl: {
    en: 'fiji',
    es: 'fiyi',
    it: 'fiji',
  },
},
{
  code: 251,
  iso: 'FRA',
  coordinates: {
    lat: '46.656',
    lng: '2.543',
  },
  name: {
    en: 'France',
    es: 'Francia',
    it: 'Francia',
  },
  publicUrl: {
    en: 'france',
    es: 'francia',
    it: 'francia',
  },
},
{
  code: 266,
  iso: 'GAB',
  coordinates: {
    lat: '-0.406',
    lng: '11.914',
  },
  name: {
    en: 'Gabon',
    es: 'Gabón',
    it: 'Gabon',
  },
  publicUrl: {
    en: 'gabon',
    es: 'gabon',
    it: 'gabon',
  },
},
{
  code: 270,
  iso: 'GMB',
  coordinates: {
    lat: '13.499',
    lng: '-14.641',
  },
  name: {
    en: 'Gambia',
    es: 'Gambia',
    it: 'Gambia',
  },
  publicUrl: {
    en: 'gambia',
    es: 'gambia',
    it: 'gambia',
  },
},
{
  code: 268,
  iso: 'GEO',
  coordinates: {
    lat: '42.05',
    lng: '43.576',
  },
  name: {
    en: 'Georgia',
    es: 'Georgia',
    it: 'Georgia',
  },
  publicUrl: {
    en: 'georgia',
    es: 'georgia',
    it: 'georgia',
  },
},
{
  code: 288,
  iso: 'GHA',
  coordinates: {
    lat: '7.258',
    lng: '-1.062',
  },
  name: {
    en: 'Ghana',
    es: 'Ghana',
    it: 'Ghana',
  },
  publicUrl: {
    en: 'ghana',
    es: 'ghana',
    it: 'ghana',
  },
},
{
  code: 308,
  iso: 'GRD',
  coordinates: {
    lat: '12.107',
    lng: '-61.681',
  },
  name: {
    en: 'Grenada',
    es: 'Granada',
    it: 'Grenada',
  },
  publicUrl: {
    en: 'grenada',
    es: 'granada',
    it: 'grenada',
  },
},
{
  code: 300,
  iso: 'GRC',
  coordinates: {
    lat: '39.787',
    lng: '21.617',
  },
  name: {
    en: 'Greece',
    es: 'Grecia',
    it: 'Grecia',
  },
  publicUrl: {
    en: 'greece',
    es: 'grecia',
    it: 'grecia',
  },
},
{
  code: 304,
  iso: 'GRL',
  coordinates: {
    lat: '71',
    lng: '-39',
  },
  name: {
    en: 'Greenland',
    es: 'Groenlandia',
    it: 'Groenlandia',
  },
  publicUrl: {
    en: 'greenland',
    es: 'groenlandia',
    it: 'groenlandia',
  },
},
{
  code: 316,
  iso: 'GUM',
  coordinates: {
    lat: '13.37',
    lng: '144.7',
  },
  name: {
    en: 'Guam',
    es: 'Guam',
    it: 'Guam',
  },
  publicUrl: {
    en: 'guam',
    es: 'guam',
    it: 'guam',
  },
},
{
  code: 320,
  iso: 'GTM',
  coordinates: {
    lat: '15.124',
    lng: '-90.711',
  },
  name: {
    en: 'Guatemala',
    es: 'Guatemala',
    it: 'Guatemala',
  },
  publicUrl: {
    en: 'guatemala',
    es: 'guatemala',
    it: 'guatemala',
  },
},
{
  code: 324,
  iso: 'GIN',
  coordinates: {
    lat: '10.599',
    lng: '-10.907',
  },
  name: {
    en: 'Guinea',
    es: 'Guinea',
    it: 'Guinea',
  },
  publicUrl: {
    en: 'guinea',
    es: 'guinea',
    it: 'guinea',
  },
},
{
  code: 226,
  iso: 'GNQ',
  coordinates: {
    lat: '1.596',
    lng: '10.553',
  },
  name: {
    en: 'Equatorial Guinea',
    es: 'Guinea Ecuatorial',
    it: 'Guinea Equatoriale',
  },
  publicUrl: {
    en: 'equatorial-guinea',
    es: 'guinea-ecuatorial',
    it: 'guinea-equatoriale',
  },
},
{
  code: 624,
  iso: 'GNB',
  coordinates: {
    lat: '12.191',
    lng: '-14.475',
  },
  name: {
    en: 'Guinea Bissau',
    es: 'Guinea Bissau',
    it: 'Guinea Bissau',
  },
  publicUrl: {
    en: 'guinea-bissau',
    es: 'guinea-bissau',
    it: 'guinea-bissau',
  },
},
{
  code: 328,
  iso: 'GUY',
  coordinates: {
    lat: '5.17',
    lng: '-58.939',
  },
  name: {
    en: 'Guyana',
    es: 'Guyana',
    it: 'Guyana',
  },
  publicUrl: {
    en: 'guyana',
    es: 'guyana',
    it: 'guyana',
  },
},
{
  code: 332,
  iso: 'HTI',
  coordinates: {
    lat: '19.3175',
    lng: '-72.4205',
  },
  name: {
    en: 'Haiti',
    es: 'Haití',
    it: 'Haiti',
  },
  publicUrl: {
    en: 'haiti',
    es: 'haiti',
    it: 'haiti',
  },
},
{
  code: 340,
  iso: 'HND',
  coordinates: {
    lat: '14.881',
    lng: '-87.123',
  },
  name: {
    en: 'Honduras',
    es: 'Honduras',
    it: 'Honduras',
  },
  publicUrl: {
    en: 'honduras',
    es: 'honduras',
    it: 'honduras',
  },
},
{
  code: 344,
  iso: 'HKG',
  coordinates: {
    lat: '22.436',
    lng: '114.099',
  },
  name: {
    en: 'Hong Kong',
    es: 'Hong kong',
    it: 'Hong Kong',
  },
  publicUrl: {
    en: 'hong-kong',
    es: 'hong-kong',
    it: 'hong-kong',
  },
},
{
  code: 348,
  iso: 'HUN',
  coordinates: {
    lat: '47.174',
    lng: '19.618',
  },
  name: {
    en: 'Hungary',
    es: 'Hungría',
    it: 'Ungheria',
  },
  publicUrl: {
    en: 'hungary',
    es: 'hungria',
    it: 'ungheria',
  },
},
{
  code: 699,
  iso: 'IND',
  coordinates: {
    lat: '22.741',
    lng: '78.236',
  },
  name: {
    en: 'India',
    es: 'India',
    it: 'India',
  },
  publicUrl: {
    en: 'india',
    es: 'india',
    it: 'india',
  },
},
{
  code: 360,
  iso: 'IDN',
  coordinates: {
    lat: '-0.739',
    lng: '114.045',
  },
  name: {
    en: 'Indonesia',
    es: 'Indonesia',
    it: 'Indonesia',
  },
  publicUrl: {
    en: 'indonesia',
    es: 'indonesia',
    it: 'indonesia',
  },
},
{
  code: 364,
  iso: 'IRN',
  coordinates: {
    lat: '33.408',
    lng: '54.0418',
  },
  name: {
    en: 'Iran',
    es: 'Irán',
    it: 'Ho corso',
  },
  publicUrl: {
    en: 'iran',
    es: 'iran',
    it: 'ho-corso',
  },
},
{
  code: 368,
  iso: 'IRQ',
  coordinates: {
    lat: '33.545',
    lng: '43.278',
  },
  name: {
    en: 'Iraq',
    es: 'Irak',
    it: 'Iraq',
  },
  publicUrl: {
    en: 'iraq',
    es: 'irak',
    it: 'iraq',
  },
},
{
  code: 372,
  iso: 'IRL',
  coordinates: {
    lat: '53.214',
    lng: '-7.588',
  },
  name: {
    en: 'Ireland',
    es: 'Irlanda',
    it: 'Irlanda',
  },
  publicUrl: {
    en: 'ireland',
    es: 'irlanda',
    it: 'irlanda',
  },
},
{
  code: 352,
  iso: 'ISL',
  coordinates: {
    lat: '64.826',
    lng: '-18.417',
  },
  name: {
    en: 'Iceland',
    es: 'Islandia',
    it: 'Islanda',
  },
  publicUrl: {
    en: 'iceland',
    es: 'islandia',
    it: 'islanda',
  },
},
{
  code: 60,
  iso: 'BMU',
  coordinates: {
    lat: '32.3',
    lng: '-64.761',
  },
  name: {
    en: 'Bermuda',
    es: 'Islas Bermudas',
    it: 'Bermuda',
  },
  publicUrl: {
    en: 'bermuda',
    es: 'islas-bermudas',
    it: 'bermuda',
  },
},
{
  code: 136,
  iso: 'CYM',
  coordinates: {
    lat: '19.331',
    lng: '-81.25',
  },
  name: {
    en: 'Cayman Isds',
    es: 'Islas Caimán',
    it: 'Cayman Isds',
  },
  publicUrl: {
    en: 'cayman-isds',
    es: 'islas-caiman',
    it: 'cayman-isds',
  },
},
{
  code: 184,
  iso: 'COK',
  coordinates: {
    lat: '-21.202',
    lng: '-159.82',
  },
  name: {
    en: 'Cook Isds',
    es: 'Islas Cook',
    it: 'Cook Isds',
  },
  publicUrl: {
    en: 'cook-isds',
    es: 'islas-cook',
    it: 'cook-isds',
  },
},
{
  code: 234,
  iso: 'FRO',
  coordinates: {
    lat: '62.053',
    lng: '-6.863',
  },
  name: {
    en: 'Faeroe Isds',
    es: 'Islas Feroe',
    it: 'Isole Faeroe',
  },
  publicUrl: {
    en: 'faeroe-isds',
    es: 'islas-feroe',
    it: 'isole-faeroe',
  },
},
{
  code: 462,
  iso: 'MDV',
  coordinates: {
    lat: '4.221',
    lng: '73.463',
  },
  name: {
    en: 'Maldives',
    es: 'Islas Maldivas',
    it: 'Maldive',
  },
  publicUrl: {
    en: 'maldives',
    es: 'islas-maldivas',
    it: 'maldive',
  },
},
{
  code: 90,
  iso: 'SLB',
  coordinates: {
    lat: '-9.611',
    lng: '160.19',
  },
  name: {
    en: 'Solomon Isds',
    es: 'Islas Salomón',
    it: 'Isole di Salomone',
  },
  publicUrl: {
    en: 'solomon-isds',
    es: 'islas-salomon',
    it: 'isole-di-salomone',
  },
},
{
  code: 796,
  iso: 'TCA',
  coordinates: {
    lat: '21.816',
    lng: '-71.799',
  },
  name: {
    en: 'Turks and Caicos Isds',
    es: 'Islas Turcas y Caicos',
    it: 'Isole Turche e Caicos',
  },
  publicUrl: {
    en: 'turks-and-caicos-isds',
    es: 'islas-turcas-y-caicos',
    it: 'isole-turche-e-caicos',
  },
},
{
  code: 376,
  iso: 'ISR',
  coordinates: {
    lat: '30.992',
    lng: '34.834',
  },
  name: {
    en: 'Israel',
    es: 'Israel',
    it: 'Israele',
  },
  publicUrl: {
    en: 'israel',
    es: 'israel',
    it: 'israele',
  },
},
{
  code: 381,
  iso: 'ITA',
  coordinates: {
    lat: '44.3713',
    lng: '11.1084',
  },
  name: {
    en: 'Italy',
    es: 'Italia',
    it: 'Italia',
  },
  publicUrl: {
    en: 'italy',
    es: 'italia',
    it: 'italia',
  },
},
{
  code: 388,
  iso: 'JAM',
  coordinates: {
    lat: '18.163',
    lng: '-77.477',
  },
  name: {
    en: 'Jamaica',
    es: 'Jamaica',
    it: 'Giamaica',
  },
  publicUrl: {
    en: 'jamaica',
    es: 'jamaica',
    it: 'giamaica',
  },
},
{
  code: 392,
  iso: 'JPN',
  coordinates: {
    lat: '35.962',
    lng: '137.855',
  },
  name: {
    en: 'Japan',
    es: 'Japón',
    it: 'Giappone',
  },
  publicUrl: {
    en: 'japan',
    es: 'japon',
    it: 'giappone',
  },
},
{
  code: 400,
  iso: 'JOR',
  coordinates: {
    lat: '30.681',
    lng: '36.279',
  },
  name: {
    en: 'Jordan',
    es: 'Jordania',
    it: 'Giordania',
  },
  publicUrl: {
    en: 'jordan',
    es: 'jordania',
    it: 'giordania',
  },
},
{
  code: 398,
  iso: 'KAZ',
  coordinates: {
    lat: '48.866',
    lng: '69.772',
  },
  name: {
    en: 'Kazakhstan',
    es: 'Kazajistán',
    it: 'Kazakistan',
  },
  publicUrl: {
    en: 'kazakhstan',
    es: 'kazajistan',
    it: 'kazakistan',
  },
},
{
  code: 404,
  iso: 'KEN',
  coordinates: {
    lat: '0.806',
    lng: '38.166',
  },
  name: {
    en: 'Kenya',
    es: 'Kenia',
    it: 'Kenia',
  },
  publicUrl: {
    en: 'kenya',
    es: 'kenia',
    it: 'kenia',
  },
},
{
  code: 417,
  iso: 'KGZ',
  coordinates: {
    lat: '41.741',
    lng: '75.043',
  },
  name: {
    en: 'Kyrgyzstan',
    es: 'Kirgizstán',
    it: 'Kyrgyzstan',
  },
  publicUrl: {
    en: 'kyrgyzstan',
    es: 'kirgizstan',
    it: 'kyrgyzstan',
  },
},
{
  code: 296,
  iso: 'KIR',
  coordinates: {
    lat: '2.048',
    lng: '-158.59',
  },
  name: {
    en: 'Kiribati',
    es: 'Kiribati',
    it: 'Kiribati',
  },
  publicUrl: {
    en: 'kiribati',
    es: 'kiribati',
    it: 'kiribati',
  },
},
{
  code: 414,
  iso: 'KWT',
  coordinates: {
    lat: '29.396',
    lng: '47.326',
  },
  name: {
    en: 'Kuwait',
    es: 'Kuwait',
    it: 'Kuwait',
  },
  publicUrl: {
    en: 'kuwait',
    es: 'kuwait',
    it: 'kuwait',
  },
},
{
  code: 422,
  iso: 'LBN',
  coordinates: {
    lat: '34.161',
    lng: '36.021',
  },
  name: {
    en: 'Lebanon',
    es: 'Líbano',
    it: 'Libano',
  },
  publicUrl: {
    en: 'lebanon',
    es: 'libano',
    it: 'libano',
  },
},
{
  code: 418,
  iso: 'LAO',
  coordinates: {
    lat: '19.595',
    lng: '102.538',
  },
  name: {
    en: "\"Lao People's Dem. Rep.\"",
    es: 'Laos',
    it: 'Democrazia popolare di Lao',
  },
  publicUrl: {
    en: 'lao-peoples-dem-rep',
    es: 'laos',
    it: 'democrazia-popolare-di-lao',
  },
},
{
  code: 426,
  iso: 'LSO',
  coordinates: {
    lat: '-29.446',
    lng: '28.371',
  },
  name: {
    en: 'Lesotho',
    es: 'Lesoto',
    it: 'Lesoto',
  },
  publicUrl: {
    en: 'lesotho',
    es: 'lesoto',
    it: 'lesoto',
  },
},
{
  code: 428,
  iso: 'LVA',
  coordinates: {
    lat: '57.017',
    lng: '25.0173',
  },
  name: {
    en: 'Latvia',
    es: 'Letonia',
    it: 'Lettonia',
  },
  publicUrl: {
    en: 'latvia',
    es: 'letonia',
    it: 'lettonia',
  },
},
{
  code: 430,
  iso: 'LBR',
  coordinates: {
    lat: '6.51',
    lng: '-9.33',
  },
  name: {
    en: 'Liberia',
    es: 'Liberia',
    it: 'Liberia',
  },
  publicUrl: {
    en: 'liberia',
    es: 'liberia',
    it: 'liberia',
  },
},
{
  code: 434,
  iso: 'LBY',
  coordinates: {
    lat: '27.24',
    lng: '17.571',
  },
  name: {
    en: 'Libya',
    es: 'Libia',
    it: 'Libia',
  },
  publicUrl: {
    en: 'libya',
    es: 'libia',
    it: 'libia',
  },
},
{
  code: 440,
  iso: 'LTU',
  coordinates: {
    lat: '55.342',
    lng: '24.23',
  },
  name: {
    en: 'Lithuania',
    es: 'Lituania',
    it: 'Lituania',
  },
  publicUrl: {
    en: 'lithuania',
    es: 'lituania',
    it: 'lituania',
  },
},
{
  code: 442,
  iso: 'LUX',
  coordinates: {
    lat: '49.671',
    lng: '6.113',
  },
  name: {
    en: 'Luxembourg',
    es: 'Luxemburgo',
    it: 'Lussemburgo',
  },
  publicUrl: {
    en: 'luxembourg',
    es: 'luxemburgo',
    it: 'lussemburgo',
  },
},
{
  code: 484,
  iso: 'MEX',
  coordinates: {
    lat: '22.925',
    lng: '-101.68',
  },
  name: {
    en: 'Mexico',
    es: 'México',
    it: 'Messico',
  },
  publicUrl: {
    en: 'mexico',
    es: 'mexico',
    it: 'messico',
  },
},
{
  code: 446,
  iso: 'MAC',
  coordinates: {
    lat: '22.132',
    lng: '113.558',
  },
  name: {
    en: 'Macao',
    es: 'Macao',
    it: 'Macao',
  },
  publicUrl: {
    en: 'macao',
    es: 'macao',
    it: 'macao',
  },
},
{
  code: 807,
  iso: 'MKD',
  coordinates: {
    lat: '41.636',
    lng: '21.676',
  },
  name: {
    en: 'TFYR of Macedonia',
    es: 'Macedonia',
    it: 'TFYR di Macedonia',
  },
  publicUrl: {
    en: 'tfyr-of-macedonia',
    es: 'macedonia',
    it: 'tfyr-di-macedonia',
  },
},
{
  code: 450,
  iso: 'MDG',
  coordinates: {
    lat: '-19.615',
    lng: '46.645',
  },
  name: {
    en: 'Madagascar',
    es: 'Madagascar',
    it: 'Madagascar',
  },
  publicUrl: {
    en: 'madagascar',
    es: 'madagascar',
    it: 'madagascar',
  },
},
{
  code: 458,
  iso: 'MYS',
  coordinates: {
    lat: '4.416',
    lng: '102.099',
  },
  name: {
    en: 'Malaysia',
    es: 'Malasia',
    it: 'Malaysia',
  },
  publicUrl: {
    en: 'malaysia',
    es: 'malasia',
    it: 'malaysia',
  },
},
{
  code: 454,
  iso: 'MWI',
  coordinates: {
    lat: '-13.258',
    lng: '33.723',
  },
  name: {
    en: 'Malawi',
    es: 'Malawi',
    it: 'Malawi',
  },
  publicUrl: {
    en: 'malawi',
    es: 'malawi',
    it: 'malawi',
  },
},
{
  code: 466,
  iso: 'MLI',
  coordinates: {
    lat: '18.211',
    lng: '-2.209',
  },
  name: {
    en: 'Mali',
    es: 'Mali',
    it: 'Mali',
  },
  publicUrl: {
    en: 'mali',
    es: 'mali',
    it: 'mali',
  },
},
{
  code: 470,
  iso: 'MLT',
  coordinates: {
    lat: '35.884',
    lng: '14.446',
  },
  name: {
    en: 'Malta',
    es: 'Malta',
    it: 'Malta',
  },
  publicUrl: {
    en: 'malta',
    es: 'malta',
    it: 'malta',
  },
},
{
  code: 504,
  iso: 'MAR',
  coordinates: {
    lat: '31.129',
    lng: '-7.355',
  },
  name: {
    en: 'Morocco',
    es: 'Marruecos',
    it: 'Marocco',
  },
  publicUrl: {
    en: 'morocco',
    es: 'marruecos',
    it: 'marocco',
  },
},
{
  code: 480,
  iso: 'MUS',
  coordinates: {
    lat: '-20.323',
    lng: '57.518',
  },
  name: {
    en: 'Mauritius',
    es: 'Mauricio',
    it: 'Mauritius',
  },
  publicUrl: {
    en: 'mauritius',
    es: 'mauricio',
    it: 'mauritius',
  },
},
{
  code: 478,
  iso: 'MRT',
  coordinates: {
    lat: '19.413',
    lng: '-10.6528',
  },
  name: {
    en: 'Mauritania',
    es: 'Mauritania',
    it: 'Mauritania',
  },
  publicUrl: {
    en: 'mauritania',
    es: 'mauritania',
    it: 'mauritania',
  },
},
{
  code: 175,
  iso: 'MYT',
  coordinates: {
    lat: '-12.833',
    lng: '45.166',
  },
  name: {
    en: 'Mayotte',
    es: 'Mayotte',
    it: 'Mayotte',
  },
  publicUrl: {
    en: 'mayotte',
    es: 'mayotte',
    it: 'mayotte',
  },
},
{
  code: 583,
  iso: 'FSM',
  coordinates: {
    lat: '7.531',
    lng: '150.457',
  },
  name: {
    en: 'FS Micronesia',
    es: 'Micronesia',
    it: 'FS Micronesia',
  },
  publicUrl: {
    en: 'fs-micronesia',
    es: 'micronesia',
    it: 'fs-micronesia',
  },
},
{
  code: 498,
  iso: 'MDA',
  coordinates: {
    lat: '47.524',
    lng: '28.444',
  },
  name: {
    en: 'Rep. of Moldova',
    es: 'Moldavia',
    it: 'Repubblica moldova',
  },
  publicUrl: {
    en: 'rep-of-moldova',
    es: 'moldavia',
    it: 'repubblica-moldova',
  },
},
{
  code: 496,
  iso: 'MNG',
  coordinates: {
    lat: '46.516',
    lng: '102.564',
  },
  name: {
    en: 'Mongolia',
    es: 'Mongolia',
    it: 'Mongolia',
  },
  publicUrl: {
    en: 'mongolia',
    es: 'mongolia',
    it: 'mongolia',
  },
},
{
  code: 499,
  iso: 'MNE',
  coordinates: {
    lat: '42.857',
    lng: '19.096',
  },
  name: {
    en: 'Montenegro',
    es: 'Montenegro',
    it: 'Montenegro',
  },
  publicUrl: {
    en: 'montenegro',
    es: 'montenegro',
    it: 'montenegro',
  },
},
{
  code: 500,
  iso: 'MSR',
  coordinates: {
    lat: '16.721',
    lng: '-62.185',
  },
  name: {
    en: 'Montserrat',
    es: 'Montserrat',
    it: 'Montserrat',
  },
  publicUrl: {
    en: 'montserrat',
    es: 'montserrat',
    it: 'montserrat',
  },
},
{
  code: 508,
  iso: 'MOZ',
  coordinates: {
    lat: '-13.852',
    lng: '38.042',
  },
  name: {
    en: 'Mozambique',
    es: 'Mozambique',
    it: 'Mozambico',
  },
  publicUrl: {
    en: 'mozambique',
    es: 'mozambique',
    it: 'mozambico',
  },
},
{
  code: 516,
  iso: 'NAM',
  coordinates: {
    lat: '-21.276',
    lng: '17.219',
  },
  name: {
    en: 'Namibia',
    es: 'Namibia',
    it: 'Namibia',
  },
  publicUrl: {
    en: 'namibia',
    es: 'namibia',
    it: 'namibia',
  },
},
{
  code: 520,
  iso: 'NRU',
  coordinates: {
    lat: '-0.519',
    lng: '166.932',
  },
  name: {
    en: 'Nauru',
    es: 'Nauru',
    it: 'Nauru',
  },
  publicUrl: {
    en: 'nauru',
    es: 'nauru',
    it: 'nauru',
  },
},
{
  code: 524,
  iso: 'NPL',
  coordinates: {
    lat: '28.2114',
    lng: '83.5608',
  },
  name: {
    en: 'Nepal',
    es: 'Nepal',
    it: 'Nepal',
  },
  publicUrl: {
    en: 'nepal',
    es: 'nepal',
    it: 'nepal',
  },
},
{
  code: 558,
  iso: 'NIC',
  coordinates: {
    lat: '12.692',
    lng: '-85.03',
  },
  name: {
    en: 'Nicaragua',
    es: 'Nicaragua',
    it: 'Nicaragua',
  },
  publicUrl: {
    en: 'nicaragua',
    es: 'nicaragua',
    it: 'nicaragua',
  },
},
{
  code: 562,
  iso: 'NER',
  coordinates: {
    lat: '16.996',
    lng: '9.915',
  },
  name: {
    en: 'Niger',
    es: 'Niger',
    it: 'Niger',
  },
  publicUrl: {
    en: 'niger',
    es: 'niger',
    it: 'niger',
  },
},
{
  code: 566,
  iso: 'NGA',
  coordinates: {
    lat: '9.3417',
    lng: '7.678',
  },
  name: {
    en: 'Nigeria',
    es: 'Nigeria',
    it: 'Nigeria',
  },
  publicUrl: {
    en: 'nigeria',
    es: 'nigeria',
    it: 'nigeria',
  },
},
{
  code: 579,
  iso: 'NOR',
  coordinates: {
    lat: '61.3701',
    lng: '9.3031',
  },
  name: {
    en: 'Norway',
    es: 'Noruega',
    it: 'Norvegia',
  },
  publicUrl: {
    en: 'norway',
    es: 'noruega',
    it: 'norvegia',
  },
},
{
  code: 540,
  iso: 'NCL',
  coordinates: {
    lat: '-21.02',
    lng: '165.062',
  },
  name: {
    en: 'New Caledonia',
    es: 'Nueva Caledonia',
    it: 'Nuova Caledonia',
  },
  publicUrl: {
    en: 'new-caledonia',
    es: 'nueva-caledonia',
    it: 'nuova-caledonia',
  },
},
{
  code: 554,
  iso: 'NZL',
  coordinates: {
    lat: '-42.558',
    lng: '172.377',
  },
  name: {
    en: 'New Zealand',
    es: 'Nueva Zelanda',
    it: 'Nuova Zelanda',
  },
  publicUrl: {
    en: 'new-zealand',
    es: 'nueva-zelanda',
    it: 'nuova-zelanda',
  },
},
{
  code: 512,
  iso: 'OMN',
  coordinates: {
    lat: '19.85',
    lng: '56.25',
  },
  name: {
    en: 'Oman',
    es: 'Omán',
    it: 'Oman',
  },
  publicUrl: {
    en: 'oman',
    es: 'oman',
    it: 'oman',
  },
},
{
  code: 528,
  iso: 'NLD',
  coordinates: {
    lat: '52.1704',
    lng: '5.0102',
  },
  name: {
    en: 'Netherlands',
    es: 'Países Bajos',
    it: 'Olanda',
  },
  publicUrl: {
    en: 'netherlands',
    es: 'paises-bajos',
    it: 'olanda',
  },
},
{
  code: 586,
  iso: 'PAK',
  coordinates: {
    lat: '28.1854',
    lng: '66.6429',
  },
  name: {
    en: 'Pakistan',
    es: 'Pakistán',
    it: 'Pakistan',
  },
  publicUrl: {
    en: 'pakistan',
    es: 'pakistan',
    it: 'pakistan',
  },
},
{
  code: 585,
  iso: 'PLW',
  coordinates: {
    lat: '7.473',
    lng: '134.549',
  },
  name: {
    en: 'Palau',
    es: 'Palau',
    it: 'Palau',
  },
  publicUrl: {
    en: 'palau',
    es: 'palau',
    it: 'palau',
  },
},
{
  code: 275,
  iso: 'PSE',
  coordinates: {
    lat: '32.097',
    lng: '35.26',
  },
  name: {
    en: 'State of Palestine',
    es: 'Palestina',
    it: 'Stato di Palestina',
  },
  publicUrl: {
    en: 'state-of-palestine',
    es: 'palestina',
    it: 'stato-di-palestina',
  },
},
{
  code: 591,
  iso: 'PAN',
  coordinates: {
    lat: '8.348',
    lng: '-81.005',
  },
  name: {
    en: 'Panama',
    es: 'Panamá',
    it: 'Panama',
  },
  publicUrl: {
    en: 'panama',
    es: 'panama',
    it: 'panama',
  },
},
{
  code: 598,
  iso: 'PNG',
  coordinates: {
    lat: '-5.636',
    lng: '142.798',
  },
  name: {
    en: 'Papua New Guinea',
    es: 'Papúa Nueva Guinea',
    it: 'Papua Nuova Guinea',
  },
  publicUrl: {
    en: 'papua-new-guinea',
    es: 'papua-nueva-guinea',
    it: 'papua-nuova-guinea',
  },
},
{
  code: 600,
  iso: 'PRY',
  coordinates: {
    lat: '-23.266',
    lng: '-58.409',
  },
  name: {
    en: 'Paraguay',
    es: 'Paraguay',
    it: 'Paraguay',
  },
  publicUrl: {
    en: 'paraguay',
    es: 'paraguay',
    it: 'paraguay',
  },
},
{
  code: 604,
  iso: 'PER',
  coordinates: {
    lat: '-12.664',
    lng: '-72.98',
  },
  name: {
    en: 'Peru',
    es: 'Perú',
    it: 'Perù',
  },
  publicUrl: {
    en: 'peru',
    es: 'peru',
    it: 'peru',
  },
},
{
  code: 258,
  iso: 'PYF',
  coordinates: {
    lat: '-17.529',
    lng: '-149.53',
  },
  name: {
    en: 'French Polynesia',
    es: 'Polinesia Francesa',
    it: 'Polinesia francese',
  },
  publicUrl: {
    en: 'french-polynesia',
    es: 'polinesia-francesa',
    it: 'polinesia-francese',
  },
},
{
  code: 616,
  iso: 'POL',
  coordinates: {
    lat: '52.442',
    lng: '19.72',
  },
  name: {
    en: 'Poland',
    es: 'Polonia',
    it: 'Polonia',
  },
  publicUrl: {
    en: 'poland',
    es: 'polonia',
    it: 'polonia',
  },
},
{
  code: 620,
  iso: 'PRT',
  coordinates: {
    lat: '40.957',
    lng: '-7.9373',
  },
  name: {
    en: 'Portugal',
    es: 'Portugal',
    it: 'Portogallo',
  },
  publicUrl: {
    en: 'portugal',
    es: 'portugal',
    it: 'portogallo',
  },
},
{
  code: 634,
  iso: 'QAT',
  coordinates: {
    lat: '25.168',
    lng: '51.17',
  },
  name: {
    en: 'Qatar',
    es: 'Qatar',
    it: 'Qatar',
  },
  publicUrl: {
    en: 'qatar',
    es: 'qatar',
    it: 'qatar',
  },
},
{
  code: 826,
  iso: 'GBR',
  coordinates: {
    lat: '52.289',
    lng: '-1.259',
  },
  name: {
    en: 'United Kingdom',
    es: 'Reino Unido',
    it: 'Regno Unito',
  },
  publicUrl: {
    en: 'united-kingdom',
    es: 'reino-unido',
    it: 'regno-unito',
  },
},
{
  code: 140,
  iso: 'CAF',
  coordinates: {
    lat: '7.043',
    lng: '21.424',
  },
  name: {
    en: 'Central African Rep.',
    es: 'República Centroafricana',
    it: 'Repubblica Centrafricana',
  },
  publicUrl: {
    en: 'central-african-rep',
    es: 'republica-centroafricana',
    it: 'repubblica-centrafricana',
  },
},
{
  code: 203,
  iso: 'CZE',
  coordinates: {
    lat: '49.848',
    lng: '15.2812',
  },
  name: {
    en: 'Czech Rep.',
    es: 'República Checa',
    it: 'Repubblica Ceca',
  },
  publicUrl: {
    en: 'czech-rep',
    es: 'republica-checa',
    it: 'repubblica-ceca',
  },
},
{
  code: 214,
  iso: 'DOM',
  coordinates: {
    lat: '19.01',
    lng: '-70.1685',
  },
  name: {
    en: 'Dominican Rep.',
    es: 'República Dominicana',
    it: 'Dominican Rep.',
  },
  publicUrl: {
    en: 'dominican-rep',
    es: 'republica-dominicana',
    it: 'dominican-rep',
  },
},
{
  code: 646,
  iso: 'RWA',
  coordinates: {
    lat: '-1.887',
    lng: '29.904',
  },
  name: {
    en: 'Rwanda',
    es: 'Ruanda',
    it: 'Ruanda',
  },
  publicUrl: {
    en: 'rwanda',
    es: 'ruanda',
    it: 'ruanda',
  },
},
{
  code: 642,
  iso: 'ROU',
  coordinates: {
    lat: '45.933',
    lng: '25.119',
  },
  name: {
    en: 'Romania',
    es: 'Rumanía',
    it: 'Romania',
  },
  publicUrl: {
    en: 'romania',
    es: 'rumania',
    it: 'romania',
  },
},
{
  code: 643,
  iso: 'RUS',
  coordinates: {
    lat: '64.736',
    lng: '104.062',
  },
  name: {
    en: 'Russian Federation',
    es: 'Rusia',
    it: 'Federazione Russa',
  },
  publicUrl: {
    en: 'russian-federation',
    es: 'rusia',
    it: 'federazione-russa',
  },
},
{
  code: 882,
  iso: 'WSM',
  coordinates: {
    lat: '-13.625',
    lng: '-172.42',
  },
  name: {
    en: 'Samoa',
    es: 'Samoa',
    it: 'Samoa',
  },
  publicUrl: {
    en: 'samoa',
    es: 'samoa',
    it: 'samoa',
  },
},
{
  code: 670,
  iso: 'VCT',
  coordinates: {
    lat: '13.234',
    lng: '-61.202',
  },
  name: {
    en: 'Saint Vincent and the Grenadines',
    es: 'San Vicente y las Granadinas',
    it: 'Saint Vincent e Grenadine',
  },
  publicUrl: {
    en: 'saint-vincent-and-the-grenadines',
    es: 'san-vicente-y-las-granadinas',
    it: 'saint-vincent-e-grenadine',
  },
},
{
  code: 662,
  iso: 'LCA',
  coordinates: {
    lat: '13.865',
    lng: '-60.98',
  },
  name: {
    en: 'Saint Lucia',
    es: 'Santa Lucía',
    it: 'Santa Lucia',
  },
  publicUrl: {
    en: 'saint-lucia',
    es: 'santa-lucia',
    it: 'santa-lucia',
  },
},
{
  code: 678,
  iso: 'STP',
  coordinates: {
    lat: '0.233',
    lng: '6.589',
  },
  name: {
    en: 'Sao Tome and Principe',
    es: 'Santo Tomé y Príncipe',
    it: 'Sao Tome e Principe',
  },
  publicUrl: {
    en: 'sao-tome-and-principe',
    es: 'santo-tome-y-principe',
    it: 'sao-tome-e-principe',
  },
},
{
  code: 686,
  iso: 'SEN',
  coordinates: {
    lat: '15.044',
    lng: '-15.081',
  },
  name: {
    en: 'Senegal',
    es: 'Senegal',
    it: 'Senegal',
  },
  publicUrl: {
    en: 'senegal',
    es: 'senegal',
    it: 'senegal',
  },
},
{
  code: 688,
  iso: 'SRB',
  coordinates: {
    lat: '44.021',
    lng: '20.699',
  },
  name: {
    en: 'Serbia',
    es: 'Serbia',
    it: 'Serbia',
  },
  publicUrl: {
    en: 'serbia',
    es: 'serbia',
    it: 'serbia',
  },
},
{
  code: 891,
  iso: 'SCG',
  coordinates: {
    lat: '44.021',
    lng: '20.699',
  },
  name: {
    en: 'Serbia and Montenegro',
    es: 'Serbia y Montenegro',
    it: 'Serbia e Montenegro',
  },
  publicUrl: {
    en: 'serbia-and-montenegro',
    es: 'serbia-y-montenegro',
    it: 'serbia-e-montenegro',
  },
},
{
  code: 690,
  iso: 'SYC',
  coordinates: {
    lat: '-4.627',
    lng: '55.461',
  },
  name: {
    en: 'Seychelles',
    es: 'Seychelles',
    it: 'Seychelles',
  },
  publicUrl: {
    en: 'seychelles',
    es: 'seychelles',
    it: 'seychelles',
  },
},
{
  code: 694,
  iso: 'SLE',
  coordinates: {
    lat: '8.677',
    lng: '-11.779',
  },
  name: {
    en: 'Sierra Leone',
    es: 'Sierra Leona',
    it: 'Sierra Leone',
  },
  publicUrl: {
    en: 'sierra-leone',
    es: 'sierra-leona',
    it: 'sierra-leone',
  },
},
{
  code: 702,
  iso: 'SGP',
  coordinates: {
    lat: '1.364',
    lng: '103.81',
  },
  name: {
    en: 'Singapore',
    es: 'Singapur',
    it: 'Singapore',
  },
  publicUrl: {
    en: 'singapore',
    es: 'singapur',
    it: 'singapore',
  },
},
{
  code: 760,
  iso: 'SYR',
  coordinates: {
    lat: '35.303',
    lng: '38.322',
  },
  name: {
    en: 'Syria',
    es: 'Siria',
    it: 'Siria',
  },
  publicUrl: {
    en: 'syria',
    es: 'siria',
    it: 'siria',
  },
},
{
  code: 706,
  iso: 'SOM',
  coordinates: {
    lat: '2.875',
    lng: '43.446',
  },
  name: {
    en: 'Somalia',
    es: 'Somalia',
    it: 'Somalia',
  },
  publicUrl: {
    en: 'somalia',
    es: 'somalia',
    it: 'somalia',
  },
},
{
  code: 144,
  iso: 'LKA',
  coordinates: {
    lat: '7.3',
    lng: '80.727',
  },
  name: {
    en: 'Sri Lanka',
    es: 'Sri lanka',
    it: 'Sri Lanka',
  },
  publicUrl: {
    en: 'sri-lanka',
    es: 'sri-lanka',
    it: 'sri-lanka',
  },
},
{
  code: 710,
  iso: 'ZAF',
  coordinates: {
    lat: '-31.7',
    lng: '23.441',
  },
  name: {
    en: 'South Africa',
    es: 'Sudáfrica',
    it: 'Sud Africa',
  },
  publicUrl: {
    en: 'south-africa',
    es: 'sudafrica',
    it: 'sud-africa',
  },
},
{
  code: 729,
  iso: 'SDN',
  coordinates: {
    lat: '15.739',
    lng: '29.533',
  },
  name: {
    en: 'Sudan',
    es: 'Sudán',
    it: 'Sudan',
  },
  publicUrl: {
    en: 'sudan',
    es: 'sudan',
    it: 'sudan',
  },
},
{
  code: 736,
  iso: 'SDN',
  coordinates: {
    lat: '7.1',
    lng: '30.48',
  },
  name: {
    en: 'Fmr Sudan',
    es: 'Sudán',
    it: 'Fmr Sudan',
  },
  publicUrl: {
    en: 'fmr-sudan',
    es: 'sudan',
    it: 'fmr-sudan',
  },
},
{
  code: 728,
  iso: 'SSD',
  coordinates: {
    lat: '7.1',
    lng: '30.48',
  },
  name: {
    en: 'South Sudan',
    es: 'Sudán del Sur',
    it: 'Sudan del Sud',
  },
  publicUrl: {
    en: 'south-sudan',
    es: 'sudan-del-sur',
    it: 'sudan-del-sud',
  },
},
{
  code: 752,
  iso: 'SWE',
  coordinates: {
    lat: '66.2188',
    lng: '19',
  },
  name: {
    en: 'Sweden',
    es: 'Suecia',
    it: 'Svezia',
  },
  publicUrl: {
    en: 'sweden',
    es: 'suecia',
    it: 'svezia',
  },
},
{
  code: 757,
  iso: 'CHE',
  coordinates: {
    lat: '46.7794',
    lng: '8.0329',
  },
  name: {
    en: 'Switzerland',
    es: 'Suiza',
    it: 'Svizzera',
  },
  publicUrl: {
    en: 'switzerland',
    es: 'suiza',
    it: 'svizzera',
  },
},
{
  code: 740,
  iso: 'SUR',
  coordinates: {
    lat: '4.248',
    lng: '-55.848',
  },
  name: {
    en: 'Suriname',
    es: 'Surinám',
    it: 'Suriname',
  },
  publicUrl: {
    en: 'suriname',
    es: 'surinam',
    it: 'suriname',
  },
},
{
  code: 748,
  iso: 'SWZ',
  coordinates: {
    lat: '-26.596',
    lng: '31.47',
  },
  name: {
    en: 'Swaziland',
    es: 'Swazilandia',
    it: 'Swaziland',
  },
  publicUrl: {
    en: 'swaziland',
    es: 'swazilandia',
    it: 'swaziland',
  },
},
{
  code: 762,
  iso: 'TJK',
  coordinates: {
    lat: '38.367',
    lng: '72.597',
  },
  name: {
    en: 'Tajikistan',
    es: 'Tadjikistán',
    it: 'Tajikistan',
  },
  publicUrl: {
    en: 'tajikistan',
    es: 'tadjikistan',
    it: 'tajikistan',
  },
},
{
  code: 764,
  iso: 'THA',
  coordinates: {
    lat: '15.688',
    lng: '100.845',
  },
  name: {
    en: 'Thailand',
    es: 'Tailandia',
    it: 'Tailandia',
  },
  publicUrl: {
    en: 'thailand',
    es: 'tailandia',
    it: 'tailandia',
  },
},
{
  code: 834,
  iso: 'TZA',
  coordinates: {
    lat: '-5.651',
    lng: '34.146',
  },
  name: {
    en: 'United Rep. of Tanzania',
    es: 'Tanzania',
    it: 'Tanzania',
  },
  publicUrl: {
    en: 'united-rep-of-tanzania',
    es: 'tanzania',
    it: 'tanzania',
  },
},
{
  code: 626,
  iso: 'TLS',
  coordinates: {
    lat: '-8.856',
    lng: '125.556',
  },
  name: {
    en: 'Timor Leste',
    es: 'Timor Oriental',
    it: 'Timor Est',
  },
  publicUrl: {
    en: 'timor-leste',
    es: 'timor-oriental',
    it: 'timor-est',
  },
},
{
  code: 768,
  iso: 'TGO',
  coordinates: {
    lat: '7.7',
    lng: '1.106',
  },
  name: {
    en: 'Togo',
    es: 'Togo',
    it: 'Andare',
  },
  publicUrl: {
    en: 'togo',
    es: 'togo',
    it: 'andare',
  },
},
{
  code: 776,
  iso: 'TON',
  coordinates: {
    lat: '-21.167',
    lng: '-175.19',
  },
  name: {
    en: 'Tonga',
    es: 'Tonga',
    it: 'tonga',
  },
  publicUrl: {
    en: 'tonga',
    es: 'tonga',
    it: 'tonga',
  },
},
{
  code: 780,
  iso: 'TTO',
  coordinates: {
    lat: '10.448',
    lng: '-61.257',
  },
  name: {
    en: 'Trinidad and Tobago',
    es: 'Trinidad y Tobago',
    it: 'Trinidad e Tobago',
  },
  publicUrl: {
    en: 'trinidad-and-tobago',
    es: 'trinidad-y-tobago',
    it: 'trinidad-e-tobago',
  },
},
{
  code: 788,
  iso: 'TUN',
  coordinates: {
    lat: '35.594',
    lng: '9.421',
  },
  name: {
    en: 'Tunisia',
    es: 'Tunez',
    it: 'Tunisia',
  },
  publicUrl: {
    en: 'tunisia',
    es: 'tunez',
    it: 'tunisia',
  },
},
{
  code: 795,
  iso: 'TKM',
  coordinates: {
    lat: '39.584',
    lng: '59.597',
  },
  name: {
    en: 'Turkmenistan',
    es: 'Turkmenistán',
    it: 'Turkmenistan',
  },
  publicUrl: {
    en: 'turkmenistan',
    es: 'turkmenistan',
    it: 'turkmenistan',
  },
},
{
  code: 792,
  iso: 'TUR',
  coordinates: {
    lat: '39.144',
    lng: '34.188',
  },
  name: {
    en: 'Turkey',
    es: 'Turquía',
    it: 'Turchia',
  },
  publicUrl: {
    en: 'turkey',
    es: 'turquia',
    it: 'turchia',
  },
},
{
  code: 798,
  iso: 'TUV',
  coordinates: {
    lat: '-7.48',
    lng: '178.68',
  },
  name: {
    en: 'Tuvalu',
    es: 'Tuvalu',
    it: 'Tuvalu',
  },
  publicUrl: {
    en: 'tuvalu',
    es: 'tuvalu',
    it: 'tuvalu',
  },
},
{
  code: 804,
  iso: 'UKR',
  coordinates: {
    lat: '49.364',
    lng: '32.132',
  },
  name: {
    en: 'Ukraine',
    es: 'Ucrania',
    it: 'Ucraina',
  },
  publicUrl: {
    en: 'ukraine',
    es: 'ucrania',
    it: 'ucraina',
  },
},
{
  code: 800,
  iso: 'UGA',
  coordinates: {
    lat: '1.9392',
    lng: '32.7415',
  },
  name: {
    en: 'Uganda',
    es: 'Uganda',
    it: 'Uganda',
  },
  publicUrl: {
    en: 'uganda',
    es: 'uganda',
    it: 'uganda',
  },
},
{
  code: 858,
  iso: 'URY',
  coordinates: {
    lat: '-32.948',
    lng: '-56.249',
  },
  name: {
    en: 'Uruguay',
    es: 'Uruguay',
    it: 'Uruguay',
  },
  publicUrl: {
    en: 'uruguay',
    es: 'uruguay',
    it: 'uruguay',
  },
},
{
  code: 860,
  iso: 'UZB',
  coordinates: {
    lat: '41.757',
    lng: '63.958',
  },
  name: {
    en: 'Uzbekistan',
    es: 'Uzbekistán',
    it: 'Uzbekistan',
  },
  publicUrl: {
    en: 'uzbekistan',
    es: 'uzbekistan',
    it: 'uzbekistan',
  },
},
{
  code: 548,
  iso: 'VUT',
  coordinates: {
    lat: '-15.339',
    lng: '166.89',
  },
  name: {
    en: 'Vanuatu',
    es: 'Vanuatu',
    it: 'Vanuatu',
  },
  publicUrl: {
    en: 'vanuatu',
    es: 'vanuatu',
    it: 'vanuatu',
  },
},
{
  code: 862,
  iso: 'VEN',
  coordinates: {
    lat: '6.983',
    lng: '-64.588',
  },
  name: {
    en: 'Venezuela',
    es: 'Venezuela',
    it: 'Venezuela',
  },
  publicUrl: {
    en: 'venezuela',
    es: 'venezuela',
    it: 'venezuela',
  },
},
{
  code: 704,
  iso: 'VNM',
  coordinates: {
    lat: '21.75',
    lng: '105.373',
  },
  name: {
    en: 'Viet Nam',
    es: 'Vietnam',
    it: 'Viet Nam',
  },
  publicUrl: {
    en: 'viet-nam',
    es: 'vietnam',
    it: 'viet-nam',
  },
},
{
  code: 887,
  iso: 'YEM',
  coordinates: {
    lat: '15.569',
    lng: '47.793',
  },
  name: {
    en: 'Yemen',
    es: 'Yemen',
    it: 'yemen',
  },
  publicUrl: {
    en: 'yemen',
    es: 'yemen',
    it: 'yemen',
  },
},
{
  code: 262,
  iso: 'DJI',
  coordinates: {
    lat: '11.41',
    lng: '42.165',
  },
  name: {
    en: 'Djibouti',
    es: 'Yibuti',
    it: 'Gibuti',
  },
  publicUrl: {
    en: 'djibouti',
    es: 'yibuti',
    it: 'gibuti',
  },
},
{
  code: 894,
  iso: 'ZMB',
  coordinates: {
    lat: '-15.13',
    lng: '25.268',
  },
  name: {
    en: 'Zambia',
    es: 'Zambia',
    it: 'Zambia',
  },
  publicUrl: {
    en: 'zambia',
    es: 'zambia',
    it: 'zambia',
  },
},
{
  code: 716,
  iso: 'ZWE',
  coordinates: {
    lat: '-19.098',
    lng: '30.047',
  },
  name: {
    en: 'Zimbabwe',
    es: 'Zimbabue',
    it: 'Zimbabwe',
  },
  publicUrl: {
    en: 'zimbabwe',
    es: 'zimbabue',
    it: 'zimbabwe',
  },
},
{
  code: 280,
  iso: 'DEU',
  coordinates: {
    lat: '51.3819',
    lng: '9.9877',
  },
  name: {
    en: 'Germany',
    es: 'Alemania',
    it: 'Germania',
  },
  publicUrl: {
    en: 'germany',
    es: 'alemania',
    it: 'germania',
  },
}];
