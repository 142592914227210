import React from 'react';

import {
  FullFlexCont,
  ModalMenuP,
} from '../../formStyledComponents';


const ToAddList = ({ setSelectedCvAdd }) => {
  const toAddTitles = [
    'Estudios superiores',
    'Experiencia laboral',
    'Idiomas',
    'Competencias digitales',
    'Otros cursos',
    'Otros documentos',
  ];

  const onSelectField = (field) => {
    setSelectedCvAdd(field);
  };

  return (
    <FullFlexCont padding="0" between alignEnd mobilePadding="0" alignCenter>
      {toAddTitles.map(title => {
        return <ModalMenuP key={title} onClick={() => onSelectField(title)}>{title}</ModalMenuP>;
      })}
    </FullFlexCont>
  );
};

export default ToAddList;

