import React, { useState } from 'react';
import _get from 'lodash/get';
import { useDispatch } from 'react-redux';
import SingleRow from '../../../common/SingleRow';
import StudyToEdit from './StudyToEdit';
import ButtonPrimary from '../../../common/Buttons/ButtonPrimary';
import ButtonSecondary from '../../../common/Buttons/ButtonSecondary';
import NewStudy from '../prevStudies/NewStudy';
import { studentActions } from '../../../../redux/actions';

import {
  FullFlexCont,
  ModalMenuP,
  NoIconTitle,
  WhiteSpace,
  Column,
} from '../../formStyledComponents';


const PrevStudiesList = ({
  profile,
  setSelectedCvEdit,
  littleDevice,
}) => {
  const dispatch = useDispatch();

  const [selectedStudy, setSelectedStudy] = useState(null);

  const onSelectField = (field) => {
    setSelectedStudy(field);
  };

  /* add-prev-studies button */
  const addPrevStudy = (studyData) => {
    const studies = [];
    const prevStudies = _get(profile, 'prevStudies', []);

    prevStudies.forEach((study) => {
      studies.push({
        ended: study.ended,
        gradDate: study.gradDate,
        iniDate: study.iniDate,
        institutionName: study.institutionName,
        name: study.name,
      });
    });

    studies.push(studyData);
    dispatch(studentActions.save({ prevStudies: studies }));
    setSelectedStudy(null);
  };

  /* Select options */
  const finishSelect = ['Sí', 'No'];

  const prevStudies = _get(profile, 'prevStudies', []);

  return (
    <FullFlexCont padding="0" between alignEnd mobilePadding="0">

      <NoIconTitle width="100%" margin=" 2rem 0" center>ESTUDIOS PREVIOS</NoIconTitle>

      <WhiteSpace mobileHeight="5rem" />

      {
        prevStudies.map((study, i) => {
          const name = _get(study, 'name', '');
          const institution = _get(study, 'institutionName', '');
          const init = _get(study, 'iniDate', '');
          const grad = _get(study, 'gradDate', '');
          const finish = _get(study, 'ended', true);

          return (
            selectedStudy === name && (
              <StudyToEdit
                key={name + i}
                name={name}
                institution={institution}
                init={init}
                grad={grad}
                finish={finish}
                profile={profile}
                setSelectedStudy={setSelectedStudy}
              />
            )
          );
        })
      }

      {!selectedStudy && (
        <>
          {prevStudies.map((field) => <ModalMenuP key={field.name} onClick={() => onSelectField(field.name)}>{field.name}</ModalMenuP>)}

          <SingleRow mobileMargin="0" margin="0" />
        </>
      )}

      {!selectedStudy
        && (
          <FullFlexCont justify="space-between">
            <Column width="46" padding="0">
              <ButtonSecondary
                text="Atrás"
                onClick={() => setSelectedCvEdit(null)}
                margin="auto"
                fullWidth
              />
            </Column>

            <Column width="46" padding="0">
              <ButtonPrimary
                type={2}
                text="Añadir"
                margin="auto"
                fullWidth
                onClick={() => setSelectedStudy('addPrevStudy')}
              />
            </Column>
          </FullFlexCont>
        )}

      {selectedStudy === 'addPrevStudy' && (
        <NewStudy
          addPrevStudy={addPrevStudy}
          finishSelect={finishSelect}
          profile={profile}
          littleDevice={littleDevice}
          setSelectedStudy={setSelectedStudy}
          isEditModal
        />
      )}

    </FullFlexCont>
  );
};

export default PrevStudiesList;

