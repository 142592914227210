import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import _get from 'lodash/get';
import { useDispatch } from 'react-redux';

import { studentActions } from '../../redux/actions';

import {
  CardPrioridad, ButtonBig, Box, Icons,
  DatesOpen, CardRecomendado, FixedFooterButton,
  Modal, ButtonPrimary, ButtonSecondary,
} from '../common';
import HSpacer from '../layout/HSpacer';

const AddButtonContainer = styled.div`
  margin-bottom: 6rem;
`;

const ButtonContent = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: bold;
  margin-right: 1.2rem;

  span {
    font-weight: normal;
    font-size: ${({ theme }) => theme.fontSizes.small};
    color: ${({ theme }) => theme.colors.themePrimary};
  }
`;

const ButtonBigContent = styled(Box)`
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 90%;
    justify-content: space-between;

    svg {
      width: 4rem;
    }
  }
`;

const CardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 4rem;
  flex-wrap: wrap;
  text-align: center;
  line-height: 2;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    text-align: center;
    padding: 2rem;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    margin: 1rem;
  }
`;

const Priorities = (props) => {
  const {
    message, user, onChange, isLocked,
    setVacancyId,
  } = props;
  const { recommended } = user;
  const dispatch = useDispatch();
  const [orderedList, setOrderedList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idToRemove, setIdToRemove] = useState('');

  useEffect(() => {
    if (!user?.priorities?.length) return;
    const newList = user.priorities.sort((a, b) => a.order - b.order);
    setOrderedList(newList);
  }, [user.priorities]);

  const onReorder = (oldPosition, reorder) => {
    const newPosition = reorder === 'up' ? oldPosition - 1 : oldPosition + 1;
    const newList = orderedList.map((item) => {
      const newItem = { order: item.order, priorityVacancyId: item.vacancy.id };
      if (item.order === oldPosition) newItem.order = newPosition;
      if (item.order === newPosition) newItem.order = oldPosition;
      return newItem;
    });
    onChange('priorities', newList);
  };

  const onOpenModal = (id) => {
    setIdToRemove(id);
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    setIdToRemove('');
    setIsModalOpen(false);
  };

  const onRemove = () => {
    const newList = orderedList
      .filter((item) => item.vacancy.id !== idToRemove)
      .map((item, index) => ({ order: index + 1, priorityVacancyId: item.vacancy.id }));
    onChange('priorities', newList);
    onCloseModal();
  };

  const onAccept = () => {
    onChange('recommendedStatus', 'accepted');
  };

  const onReject = () => {
    onChange('recommendedStatus', 'rejected');
  };

  const lockPriorities = () => {
    const studenHistory = user?.statusHistory || [];
    const today = new Date();
    const statusHistory = [...studenHistory, { date: today, status: 'prioritiesLocked' }];
    dispatch(studentActions.save({ status: 'prioritiesLocked', statusHistory }));
    const newUserData = { vacancyPrioritiesLocked: true, statusHistory };
    const confirmText = 'Tras bloquear tus prioridades, no podrás efectuar ningún cambio. ¿Estás seguro de que deseas bloquearlas ahora?';
    if (window.confirm(confirmText)) {
      onChange('', newUserData, true);
    }
  };

  const renderRecomendedCard = () => {
    if (!recommended) return null;
    const id = _get(recommended, 'id', '');
    let { datesOpen } = recommended;
    datesOpen = datesOpen === null ? [] : datesOpen;

    return (
      <CardRecomendado
        department={_get(recommended, 'department', '')}
        company={_get(recommended, 'company', '')}
        benefitEconomy={_get(recommended, 'benefitEconomy', '')}
        datesOpen={<DatesOpen datesOpen={datesOpen} />}
        city={_get(recommended, 'city', '')}
        onAccept={() => onAccept(id)}
        onReject={() => onReject(id)}
        onClick={() => setVacancyId(id)}
        recommendedStatus={user?.recommendedStatus}
      />
    );
  };


  return (
    <>
      {message && message}
      {recommended && renderRecomendedCard()}
      {
        orderedList.length > 0 && orderedList.map((item, index) => {
          const { vacancy, order } = item;
          const {
            id, department, company, benefitEconomy, city,
          } = vacancy;
          let { datesOpen } = vacancy;
          datesOpen = datesOpen === null ? [] : datesOpen;
          return (
            <CardPrioridad
              key={id}
              position={order}
              first={index === 0}
              last={index === orderedList.length - 1}
              department={department}
              company={company}
              benefitEconomy={benefitEconomy}
              datesOpen={<DatesOpen datesOpen={datesOpen} />}
              city={city}
              onUp={(position) => onReorder(position, 'up')}
              onDown={(position) => onReorder(position, 'down')}
              onClick={() => setVacancyId(id)}
              onRemove={() => onOpenModal(id)}
              isLocked={isLocked}
            />
          );
        })
      }
      {
        !isLocked && orderedList.length < 4 && (
          <AddButtonContainer>
            <Link to="/vacancy-finder">
              <ButtonBig onClick={() => { }}>
                <ButtonBigContent container>
                  <ButtonContent>
                    Añadir práctica
                    <br />
                    <span>
                      (
                      {orderedList.length}
                      {' '}
                      de 4 posibles)
                    </span>
                  </ButtonContent>
                  <Icons icon="add" iconColor="themePrimary" width={2.5} />
                </ButtonBigContent>
              </ButtonBig>
            </Link>
          </AddButtonContainer>
        )
      }
      {
        !isLocked && (
          <FixedFooterButton
            text="Bloquear Prioridades"
            icon="bloqueo"
            onClick={lockPriorities}
            width="auto"
            margin="0"
            rightPositioned
            blockedButton
          />
        )
      }
      {
        isModalOpen && (
          <Modal onClose={onCloseModal}>
            <CardContentWrapper>
              <p>¿Estás seguro de que quieres quitar la vacante de las prioridades?</p>

              <HSpacer height="1.2rem" />

              <ButtonsContainer>
                <ButtonSecondary
                  iconColor="#105285"
                  type={2}
                  text="Cancelar"
                  fontSize="medium"
                  onClick={onCloseModal}
                />

                <ButtonPrimary
                  iconColor="#105285"
                  type={2}
                  text="Confirmar"
                  fontSize="medium"
                  onClick={onRemove}
                />
              </ButtonsContainer>
            </CardContentWrapper>
          </Modal>
        )
      }
    </>
  );
};

Priorities.propTypes = {
  isLocked: PropTypes.bool.isRequired,
  message: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
  setVacancyId: PropTypes.func.isRequired,
};

Priorities.defaultProps = {
  message: '',
};

export default Priorities;
