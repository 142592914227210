import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import _get from 'lodash/get';
import { useForm } from 'react-hook-form';

import ButtonPrimary from '../../common/Buttons/ButtonPrimary';
import { EditIcon } from '../../common/Icons/IconsList';
import { studentActions } from '../../../redux/actions';

import {
  Column,
  Input,
  Label,
  DobleInputDiv,
  InputColumn,
  Error,
  FlexForm,
} from '../formStyledComponents';


const UsersContactDataForm = (props) => {
  const { contactData, formActive, setFormActive } = props;
  const dispatch = useDispatch();

  const { register, handleSubmit, errors } = useForm();

  /* get data from profileData for the initial input values */
  const initialEmail = _get(contactData, 'email', '');
  const initialSkype = _get(contactData, 'skypeUsername', '');
  const initialMobile = _get(contactData, 'mobilePhone', '');
  const initialFacebook = _get(contactData, 'facebookProfileURL', '');

  /* handle errors  */
  const emailError = errors.email?.type === 'required' && <Error>Por favor, indica tu email de contacto</Error>;
  const emailError2 = errors.email?.type === 'pattern' && <Error>Formato de email incorrecto</Error>;
  const mobileError = errors.mobilePhone?.type === 'required' && <Error>Debes indicar un teléfono móvil de contacto</Error>;
  const phoneError2 = errors.mobilePhone?.type === 'pattern' && <Error>Número de teléfono no válido</Error>;

  /* edit / save data button */
  const onSubmit = (data, e) => {
    e.preventDefault();
    const contact = {
      email: data.email,
      skypeUsername: data.skypeUsername,
      mobilePhone: data.mobilePhone,
      facebookProfileURL: data.facebookProfileURL,
    };
    dispatch(studentActions.save({ ...contact }));
    setFormActive(!formActive);
  };


  return (
    <>
      <FlexForm key={initialEmail} onSubmit={handleSubmit(onSubmit)}>

        <Column width={60} padding="0 0 0 2rem" mobileFull>
          <DobleInputDiv>
            <InputColumn>
              <Label>Email*:</Label>
              <Input
                disabled={!formActive}
                name="email"
                placeholder="email"
                ref={register({
                  required: true,
                  pattern: {
                    value: /[\w\-\._]+@[\w\-\._]+\.\w{2,10}/,
                  },
                })}
                defaultValue={initialEmail}
                mobileWidth="80%"
              />
              {emailError}
              {emailError2}
            </InputColumn>

            <InputColumn>
              <Label>SkypeID:</Label>
              <Input
                disabled={!formActive}
                name="skypeUsername"
                placeholder="skype"
                ref={register()}
                defaultValue={initialSkype}
                mobileWidth="80%"
              />
            </InputColumn>
          </DobleInputDiv>

          <DobleInputDiv>
            <InputColumn>
              <Label>Teléfono celular*:</Label>
              <Input
                disabled={!formActive}
                name="mobilePhone"
                placeholder="mobile"
                ref={register({
                  required: true,
                  pattern: {
                    value: /^\+[0-9][0-9]{6,20}$/,
                  },
                })}
                defaultValue={initialMobile}
                mobileWidth="80%"
              />
              {mobileError}
              {phoneError2}
            </InputColumn>
            <InputColumn>
              <Label>Facebook:</Label>
              <Input
                disabled={!formActive}
                name="facebookProfileURL"
                placeholder="facebook"
                ref={register({ required: false })}
                defaultValue={initialFacebook}
                mobileWidth="80%"
              />
            </InputColumn>
          </DobleInputDiv>
        </Column>

        <Column width={40} center mobileFull>
          {!formActive
            && (
              <ButtonPrimary
                iconComponent={<EditIcon height="3rem" />}
                fontSize="medium"
                type={2}
                text="Editar datos"
                onClick={() => setFormActive(!formActive)}
                margin="0 0 0 auto"
                mobileFullWidth
              />
            )}

          {formActive
            && (
              <ButtonPrimary
                iconComponent={<EditIcon height="3rem" />}
                iconColor="#105285"
                type={2}
                text="Guardar datos"
                htmlType="submit"
                margin="0 0 0 auto"
                mobileFullWidth
              />
            )}

        </Column>

      </FlexForm>
    </>
  );
};

UsersContactDataForm.propTypes = {
  contactData: PropTypes.shape({}).isRequired,
  formActive: PropTypes.bool.isRequired,
  setFormActive: PropTypes.func.isRequired,
};

export default UsersContactDataForm;
