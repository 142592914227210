import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import EditDeleteIcons from '../../EditDeleteIcons';
import showOptions from '../../helpers/showOptions';
import { SingleRow } from '../../../common';
import SeeMoreCard from '../../SeeMoreCard';
import { studentActions } from '../../../../redux/actions';

import {
  Label,
  InputColumn,
  FullFlexCont,
  ContactForm,
  EditDeleteIconsCont,
  Select,
  Column,
  DisabledBlueInput,
  MobilePadding,
} from '../../formStyledComponents';
import { levelsTransEn } from '../../helpers/constants';

const Language = ({
  languageName,
  speak,
  write,
  understand,
  deleteLang,
  levels,
  profile,
  littleDevice,
  langExperience,
}) => {
  const { register, handleSubmit, reset } = useForm();

  const dispatch = useDispatch();

  /* set the form to active/inactive to be edited */
  const initialFormState = langExperience !== [] && false;
  const [langFormActive, setLangFormActive] = useState(initialFormState);

  // Asign a ref to the card, to open it with the "edit" button
  const cardRefLang = useRef(`lang-${languageName}`);

  const [selectedCardLang, setSelectedCardLang] = useState(null);

  const edit = (e) => {
    e.preventDefault();
    setLangFormActive(!langFormActive);
    setSelectedCardLang(cardRefLang.current);
  };

  const openByRef = selectedCardLang === `lang-${languageName}`;

  const translateLanguage = (language) => ({
    ...Object.entries(language)
      .filter(([key]) => key !== 'languageName')
      .reduce((obj, [key, value]) => {
        obj[key] = (levelsTransEn[value.toLowerCase()] || value).toLowerCase();
        return obj;
      }, {}),
    languageName: language.languageName,
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    const languages = langExperience;

    languages.forEach((lang, index) => {
      if (data.languageName === lang.languageName) {
        const transData = translateLanguage(data);
        languages.splice(index, 1, transData);
        dispatch(studentActions.save({ languageExperience: languages }));
      }
    });

    setLangFormActive(!langFormActive);
  };

  useEffect(() => {
    const fetchData = async () => {
      reset({
        speak,
        write,
        understand,
      });
    };
    fetchData();
  }, [reset, profile]);


  return (
    <>
      <ContactForm onSubmit={handleSubmit(onSubmit)}>

        {!littleDevice && (
          <FullFlexCont justify="flex-end" padding="0">

            <InputColumn width="17%" margin="0 auto 0 2rem">
              <DisabledBlueInput
                bold
                width="100%"
                mBottom="0"
                disabled={!langFormActive}
                name="languageName"
                placeholder="Idioma"
                ref={register({ required: true })}
                value={languageName}
              />
            </InputColumn>

            <InputColumn width="17%">
              <Label>Hablado:</Label>
              <Select
                width="100%"
                mBottom="0"
                disabled={!langFormActive}
                name="speak"
                ref={register({ required: true })}
              >
                {showOptions(levels, speak, 'nivel hablado')}
              </Select>
            </InputColumn>

            <InputColumn width="17%">
              <Label>Escrito:</Label>
              <Select
                width="100%"
                mBottom="0"
                disabled={!langFormActive}
                name="write"
                ref={register({ required: true })}
              >
                {showOptions(levels, write, 'nivel escrito')}
              </Select>
            </InputColumn>

            <InputColumn width="17%">
              <Label>Comprensión:</Label>
              <Select
                width="100%"
                mBottom="0"
                disabled={!langFormActive}
                name="understand"
                ref={register({ required: true })}
              >
                {showOptions(levels, understand, 'comprensión')}
              </Select>
            </InputColumn>

            <InputColumn width="17%">
              <EditDeleteIconsCont>
                <EditDeleteIcons
                  edit={edit}
                  deleteField={deleteLang}
                  isActive={langFormActive}
                  onSubmit={onSubmit}
                  confirmText="¿Estás seguro de que quieres eliminar este idioma?"
                  value1={languageName}
                  value2={null}
                />
              </EditDeleteIconsCont>
            </InputColumn>

          </FullFlexCont>
        )}

        {littleDevice && (
          <>
            <MobilePadding>
              <FullFlexCont mobilePadding="2rem 0" mobileAlignCenter>
                <DisabledBlueInput
                  bold
                  width="100%"
                  mBottom="0"
                  disabled={!langFormActive}
                  name="languageName"
                  placeholder="Idioma"
                  ref={register({ required: true })}
                  value={languageName}
                  mobileWidth="50%"
                />

                <EditDeleteIconsCont>
                  <EditDeleteIcons
                    edit={edit}
                    deleteField={deleteLang}
                    isActive={langFormActive}
                    onSubmit={onSubmit}
                    confirmText="¿Estás seguro de que quieres eliminar este idioma?"
                    value1={languageName}
                    value2={null}
                  />
                </EditDeleteIconsCont>
              </FullFlexCont>
            </MobilePadding>

            <SeeMoreCard
              ref={cardRefLang}
              openByRef={openByRef}
              selectedCard={selectedCardLang}
              profile={profile}
              setSelectedCard={setSelectedCardLang}
            >

              <Column width={30} center mobileFull mobileMargin="0">
                <InputColumn>
                  <Label>Hablado:</Label>
                  <Select
                    width="100%"
                    mBottom="0"
                    disabled={!langFormActive}
                    name="speak"
                    ref={register({ required: true })}
                    defaultValue={speak}
                  >
                    {showOptions(levels, speak, 'nivel hablado')}
                  </Select>
                </InputColumn>
              </Column>
              <Column width={30} center mobileFull mobileMargin="0">
                <InputColumn>
                  <Label>Escrito:</Label>
                  <Select
                    width="100%"
                    mBottom="0"
                    disabled={!langFormActive}
                    name="write"
                    ref={register({ required: true })}
                    defaultValue={write}
                  >
                    {showOptions(levels, write, 'nivel escrito')}
                  </Select>
                </InputColumn>
              </Column>
              <Column width={30} center mobileFull mobileMargin="0">
                <InputColumn>
                  <Label>Comprensión:</Label>
                  <Select
                    width="100%"
                    mBottom="0"
                    disabled={!langFormActive}
                    name="understand"
                    ref={register({ required: true })}
                    defaultValue={understand}
                  >
                    {showOptions(levels, understand, 'comprensión')}
                  </Select>
                </InputColumn>
              </Column>

            </SeeMoreCard>
          </>
        )}

      </ContactForm>

      {!littleDevice && <SingleRow />}
    </>
  );
};

export default Language;
