import React from 'react';
import styled from 'styled-components/macro';

const CardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 4rem;
  flex-wrap: wrap;
  text-align: center;
  line-height: 2;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    text-align: center;
    padding: 2rem;
  }
`;

const P = styled.p`
  font-size: 1.5rem;
  margin: 2rem 0;
`;


const ConfirmAccountModal = () => {
  return (
    <CardContentWrapper>
      <h2>¡Confirma tu cuenta!</h2>
      <P>Gracias por realizar el registro. Para acceder a todo el contenido, confirma tu cuenta mediante el enlace que hemos enviado a tu correo electrónico.</P>
    </CardContentWrapper>
  )
};

export default ConfirmAccountModal;