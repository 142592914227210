import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import Theme from '../../../CustomTheme/Theme';


const CircleBg = styled.button`
  cursor: pointer;
  border: none;
  border-radius: ${({ square }) => (square ? '5rem' : '50%')};
  padding: ${({ padding }) => padding};
  width: ${({ width }) => width}rem;
  height: ${({ width }) => width}rem;
  background-color: ${({ bgColor }) => bgColor};
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease-in-out;
  color: ${({ color }) => color};
  border: ${({ border }) => border};

  svg {
    width: ${({ width }) => width}rem;
    fill: ${({ color }) => color};
    stroke: ${({ color }) => color};
  }

  &:hover {
    background-color: ${({ bgHover }) => bgHover};

    svg {
      fill: ${({ hover }) => hover};
      stroke: ${({ hover }) => hover};
    }
    p {
      color: ${({ hover }) => hover};
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    width: 4rem;
  }

  ${(props) => props.selected && css`
    background-color: ${({ bgHover }) => bgHover};
  `};
`;

const Text = styled.p`
  color: ${({ color }) => color};
  font-size: ${(props) => props.theme.fontSizes.xsmall};
  font-weight: 500;
`;

const IconButton = (props) => {
  const {
    icon, square, text, padding,
    width, onClick, type, selected,
  } = props;

  let color;
  let bgColor;
  let hover;
  let bgHover;
  let border = 'none';

  switch (type) {
    case 2:
      color = Theme.colors.themeSecondary;
      bgColor = Theme.colors.themeTertiary;
      hover = Theme.colors.textInverse;
      bgHover = Theme.colors.themeSecondary;
      break;

    case 5:
      border = `2px solid ${Theme.colors.themeTertiary}`;
      color = Theme.colors.themeSecondary;
      bgColor = Theme.colors.themeInverse;
      hover = Theme.colors.themeSecondary;
      bgHover = Theme.colors.themeTertiary;
      break;

    default:
      color = Theme.colors.textInverse;
      bgColor = Theme.colors.themePrimary;
      hover = Theme.colors.themeSecondary;
      bgHover = Theme.colors.themeSecondary;
      break;
  }

  return (
    <CircleBg
      onClick={onClick}
      width={width}
      padding={padding}
      color={color}
      bgColor={bgColor}
      hover={hover}
      bgHover={bgHover}
      square={square}
      border={border}
      selected={selected}
    >
      {icon}
      {!!text && <Text>{text}</Text>}
    </CircleBg>
  );
};

IconButton.propTypes = {
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  padding: PropTypes.string,
  square: PropTypes.bool,
  text: PropTypes.string,
  type: PropTypes.number,
  width: PropTypes.number,
};

IconButton.defaultProps = {
  padding: '1rem',
  square: false,
  text: '',
  type: 3,
  width: 2,
};

export default IconButton;
