const monthText = {
  '01': 'enero',
  '02': 'febrero',
  '03': 'marzo',
  '04': 'abril',
  '05': 'mayo',
  '06': 'junio',
  '07': 'julio',
  '08': 'agosto',
  '09': 'septiembre',
  10: 'octubre',
  11: 'noviembre',
  12: 'diciembre',
};

/**
 * @param {string} date or yyyy-mm-dd
 * @return {string} dd de month
 */
const getDateText = (date = '') => {
  // date = mm-dd;
  const safeDate = !date ? '' : date;
  const day = parseInt(safeDate.slice(3), 10);
  const month = safeDate.slice(0, 2);
  return `${day} de ${monthText[month]}`;
};

/**
 * @param {string} date
 * @return {object} { year, month, day }
 */
const getYearMonthDay = (date) => {
  if (!date) return null;
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (month < 10) month = `0${month}`;
  if (day < 10) day = `0${day}`;
  return { year, month, day };
};

/**
 * @param {string} date or yyyy-mm-dd
 * @return {string} yyyy-mm-dd
 */
const getAWSStringDate = (date) => {
  if (!date) return '';
  const newDate = new Date(date);
  const { year, month, day } = getYearMonthDay(newDate);
  return `${year}-${month}-${day}`;
};

/**
 * @param {string} date or yyyy-mm-dd
 * @return {string} dd / mm / yyyy
 */
const getStringDate = (date) => {
  if (!date) return '';
  const newDate = new Date(date);
  const { year, month, day } = getYearMonthDay(newDate);
  return `${day}/${month}/${year}`;
};

export default {
  getDateText,
  getYearMonthDay,
  getAWSStringDate,
  getStringDate,
};
