import React, { useState } from 'react';
import SingleRow from '../../../common/SingleRow';
import EditUserContact from './EditUserContact';
import EditEmergencyContact from './EditEmergencyContact';

import {
  CardContentWrapper,
  WhiteSpace,
  FullFlexCont,
  ModalsLogo,
  NoIconTitle,
  ModalMenuP,
} from '../../formStyledComponents';

const EditContactModal = ({
  contactData,
  formActive,
  setFormActive,
  emergencyContactData,
  openModal,
  profile,
}) => {

  const [selectedEdit, setSelectedEdit] = useState(null);

  return (
    <CardContentWrapper mobileCenter alignCenter>
      <ModalsLogo src="/images/logo.jpg" alt="Nexus logo" />

      <NoIconTitle margin="0 0 2rem 0" mobileMargin="0 0 2rem 0">
        ¿QUÉ DATOS DE CONTACTO QUIERES EDITAR?
      </NoIconTitle>

      <WhiteSpace
        height= {selectedEdit === 'userContact' || selectedEdit === 'emergencyContact' ? '0' : '2rem'}
        mobileHeight={selectedEdit === 'userContact' || selectedEdit === 'emergencyContact' ? '0' : '5rem'}
      />

      {!selectedEdit &&
        (
          <>
            <FullFlexCont padding="0" between alignEnd mobilePadding="0" >
              <ModalMenuP onClick={() => setSelectedEdit('userContact')}>Datos de contacto</ModalMenuP>
              <ModalMenuP onClick={() => setSelectedEdit('emergencyContact')}>Contacto de emergencia</ModalMenuP>
            </FullFlexCont>

            <SingleRow mobileMargin="0" margin="0" />

            <WhiteSpace mobileHeight="10rem" />
          </>
        )}

      {selectedEdit === 'userContact' &&
        (
          <EditUserContact
            setSelectedEdit={setSelectedEdit}
            contactData={contactData}
            formActive={formActive}
            setFormActive={setFormActive}
            profile={profile}
          />
        )}

      {selectedEdit === 'emergencyContact' &&
        (
          <EditEmergencyContact
            setSelectedEdit={setSelectedEdit}
            emergencyContactData={emergencyContactData}
            openModal={openModal}
            profile={profile}
          />
        )}

    </CardContentWrapper>
  );
};

export default EditContactModal;