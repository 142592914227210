import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import { NotificationProvider } from '../../common';
import Header from '../../Header';
import DesktopAsideMenu from '../../menu/DesktopAsideMenu';
import MobileAsideMenu from '../../menu/MobileAsideMenu';
// import DesktopFooter from '../../footer/DesktopFooter';

import { getFileURL } from '../../../services/storage';
import { getNotificationsList, putNotificationRead } from '../../../services/api/notifications';
import { notificationSent, notificationUpdate } from '../../../graphql/subscriptions';
import NotificationIcon from '../../common/Icons/NotificationIcon';
import { useNotifications } from '../../../contexts/NotificationsContext';


const LayoutContainer = styled.div`
  padding-top: ${(props) => props.theme.sizes.headerHeight};
  display: flex;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-direction: row;
  }
`;

const LoggedLayout = ({ children }) => {
  const user = useSelector((state) => state.student);
  const { id: userId, name: userName, photo: userPhoto } = user;
  const screen = useSelector((state) => state.windowResize.screen);
  const isHamburguerMenu = screen === 'phone' || screen === 'tab-port';

  const [isLogged, setLogged] = useState(false);
  const [showNotification, setShowNotification] = useState([]);

  const { unreadNotifications, unseenNotifications, setNotifications, getList } = useNotifications();

  const notificationsNumber = unreadNotifications?.length >= 1 && <NotificationIcon number={unreadNotifications.length} />;

  useEffect(() => {
    if (!userName) setLogged(false);
    if (userName) setLogged(true);
  }, [userName]);

  const onReadNotification = async (timestamp) => {
    await putNotificationRead(timestamp);
    getList();
  };

  const subscribeNotification = async () => API.graphql(graphqlOperation(notificationSent, { studentId: userId })).subscribe({
    next: (response) => {
      const newNotification = response.value.data.notificationSent;
      setNotifications((oldNotifications) => {
        const index = oldNotifications.findIndex(
          (notification) => notification.timestamp === newNotification.timestamp,
        );
        if (index === -1) {
          return [...oldNotifications, newNotification];
        }
        return oldNotifications;
      });
    },
  });

  const subscribeUpdateNotification = async () => {
    API.graphql(graphqlOperation(notificationUpdate, { studentId: userId }))
      .subscribe({
        next: (response) => {
          const updatedNotification = response.value.data.notificationUpdate;
          setNotifications((oldNotifications) => {
            const index = oldNotifications.findIndex(
              (notification) => notification.timestamp === updatedNotification.timestamp,
            );
            if (index !== -1) {
              const newNotifications = [...oldNotifications];
              newNotifications[index] = updatedNotification;
              return newNotifications;
            }
            return [...oldNotifications, updatedNotification];
          });
        },
      });
  };

  useEffect(() => {
    if (!userId) return;
    subscribeNotification();
    subscribeUpdateNotification();
  }, [userId]);

  const getImgSrc = () => {
    if (!userPhoto) return '';
    const url = getFileURL(userPhoto);
    return url;
  };

  if (!userId) return null;

  return (
    <>
      <NotificationProvider
        onReadNotification={onReadNotification}
        unreadNotifications={unreadNotifications}
        unseenNotifications={unseenNotifications}
        showNotification={showNotification}
        setShowNotification={setShowNotification}
      />
      <div>
        <Header userName={userName} userImg={getImgSrc()} noMenuLink={!userName} notificationsNumber={notificationsNumber} />
        <LayoutContainer>
          {
            isHamburguerMenu
              ? <MobileAsideMenu isLogged={isLogged} notificationsNumber={notificationsNumber} />
              : <DesktopAsideMenu notificationsNumber={notificationsNumber} />
          }
          {children}
        </LayoutContainer>
        {/* { !isHamburguerMenu && <DesktopFooter /> } */}
      </div>
    </>
  );
};

LoggedLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoggedLayout;
