import React from 'react';
import styled from 'styled-components/macro';
import { Icons } from '../../common';

const Container = styled.div`
  color: ${({ theme }) => theme.colors.textInverse};
  padding: 0.8rem 2.5rem 0.8rem 0.5rem ;
  max-height: 3.5rem;
  margin: 0.5rem;
  border-radius: 2rem;
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: 1.8rem;
  font-weight: 700;
  text-align: center;
  background-color: ${({ active, theme }) => (active ? theme.colors.themePrimary : theme.colors.grey)};
  display: flex;
  align-items: center;
  transition: all 0.1s ease-in-out;
  width: fit-content;

  &:hover {
    cursor: ${({ active }) => (active && 'pointer')};
    background-color: ${({ active, theme }) => active && theme.colors.themePrimary};
  }
`;

const RemoveIcon = styled.div`
  background-color: ${({ active, theme }) => (active ? theme.colors.themeTertiary : theme.colors.white)};
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  margin-right: 1rem;

  &:hover {
    cursor: ${({ active }) => (active && 'pointer')};
  }
`;

const NationalityTag = ({ title, onRemove, active }) => {
  return (
    <Container active={active}>
      <RemoveIcon onClick={active ? () => onRemove(title) : () => {}}>
        <Icons
          icon="close"
          width="1.5"
          iconColor="#105285"
          onClick={() => onRemove(title)}
        />
      </RemoveIcon>
      {title}
    </Container>
  );
};

export default NationalityTag;
