/* eslint-disable no-console */
import AWS from 'aws-sdk';
import { API, graphqlOperation } from 'aws-amplify';
import capitalize from '@bit/iamtechnologies.iamtech-js.capitalize';

import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import awsConfig from '../aws-config';
import { getCredentials } from './auth';

const response = (success, msg, data) => ({
  success,
  message: !success && !msg ? 'UnexpectedError' : msg,
  data,
});

export async function getStudent() {
  try {
    const { data } = await API.graphql(graphqlOperation(queries.getStudentInfo));
    const studentData = data.getStudentInfo;
    return response(true, null, studentData);
  } catch (error) {
    const { data, errors } = error;
    console.log('getStudent error', error);
    return response(false, '', data?.getStudentInfo);
  }
}

const studentRelatedEntitiesArray = [
  'nationalities',
  'favourites',
  'payments',
];

const studentRelatedEntities = [
  'country',
  'residence',
  'university',
  'recommended',
  'partner',
  'service',
];

const studentDiscardedField = [
  'loading',
  'code',
  'number',
];


export async function saveStudent(values) {
  const savingStudentInfo = { ...values };
  Object.keys(values).forEach((studentProperty) => {
    if (studentProperty === 'createdAt'
      || studentProperty === 'updatedAt'
      || values[studentProperty] === undefined
      || (values[studentProperty] === null && studentProperty !== 'studentFavouritesId')
      || studentDiscardedField.includes(studentProperty)
    ) {
      delete savingStudentInfo[studentProperty];
    }

    if (studentRelatedEntities.includes(studentProperty)) {
      const relatedFieldName = `student${capitalize(studentProperty)}Id`;
      savingStudentInfo[relatedFieldName] = values[studentProperty] ? values[studentProperty].id : null;
      delete savingStudentInfo[studentProperty];
    }

    if (studentRelatedEntitiesArray.includes(studentProperty)) {
      const relatedFieldName = `student${capitalize(studentProperty)}Id`;
      savingStudentInfo[relatedFieldName] = values[studentProperty] && values[studentProperty].length
        ? values[studentProperty].map((realtedEntity) => realtedEntity.id)
        : null;
      delete savingStudentInfo[studentProperty];
    }

    if (savingStudentInfo?.internship) {
      if (savingStudentInfo?.internship?.service?.id) {
        savingStudentInfo.internship.internshipServiceId = savingStudentInfo?.internship?.service?.id;
      }
      if (savingStudentInfo?.internship?.vacancy?.id) {
        savingStudentInfo.internship.internshipVacancyId = savingStudentInfo?.internship?.vacancy?.id;
      }
      delete savingStudentInfo.internship.service;
      delete savingStudentInfo.internship.vacancy;
    }

    if (savingStudentInfo?.pastInternships?.length > 0) {
      savingStudentInfo.pastInternships.forEach((item, index) => {
        if (item?.service?.id) {
          savingStudentInfo.pastInternships[index].internshipServiceId = item.service?.id;
        }
        if (item?.vacancy?.id) {
          savingStudentInfo.pastInternships[index].internshipVacancyId = item.vacancy?.id;
        }
        delete savingStudentInfo.pastInternships[index].service;
        delete savingStudentInfo.pastInternships[index].vacancy;
      });
    }

    if (savingStudentInfo?.currentStudy) {
      if (savingStudentInfo.currentStudy?.country?.id) {
        savingStudentInfo.currentStudy.studyCountryId = savingStudentInfo.currentStudy.country.id;
      }
      delete savingStudentInfo.currentStudy.country;
    }

    if (savingStudentInfo?.passport) {
      if (savingStudentInfo.passport?.country?.id) {
        savingStudentInfo.passport.passportCountryId = savingStudentInfo.passport.country.id;
      }
      delete savingStudentInfo.passport.country;
    }
  });
  try {
    const { data } = await API.graphql(graphqlOperation(
      mutations.updateStudentInfo, { input: savingStudentInfo },
    ));
    return response(true, 'success', data.updateStudentInfo);
  } catch (error) {
    console.log('saveStudent -> error', error);
    console.log('saveStudent -> savingStudentInfo', savingStudentInfo);
    return response(false, error.code);
  }
}

export async function uploadFile(file, path) {
  const { Storage } = awsConfig;
  const { region, bucket } = Storage;

  try {
    const credentials = await getCredentials();
    AWS.config.update({ credentials, region });

    await new AWS.S3.ManagedUpload({
      params: {
        Bucket: bucket,
        Key: path,
        Body: file,
        ACL: 'public-read',
      },
    }).promise();
    return response(true);
  } catch (error) {
    console.log('uploadFile -> error', error);
    return response(false, error);
  }
}
