import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components/macro';

import { Box, Modal } from '../components/common';
import {
  UserIcon, FindVacancyIcon, ProcessesIcon,
  DocumentsIcon, PracticesIcon,
} from '../components/common/Icons/IconsList';
import LoggedLayout from '../components/layout/LoggedLayout';
import ConfirmAccountModal from '../components/menu/ConfirmAccountModal';
import FirstStepsModal from '../components/menu/FirstStepsModal';


const Container = styled.div`
  width: 60rem;
  margin: 0 auto 10rem;
  padding: 8rem 1rem;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    padding: 2.5rem 3rem;
    width: 38rem;
  }
`;

export const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 6rem;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: ${({ theme }) => theme.fontSizes.titleMedium};
`;

const Span = styled.span`
  padding: 1.5rem;
`;

const MenuItem = styled.div`
  color: ${({ theme, active }) => (active ? theme.colors.textPrimary : theme.colors.themePrimary)};
  cursor: ${({ active }) => (active ? 'pointer' : 'not-allowed')};
  width: 16rem;
  height: 16rem;
  border-radius: 100%;
  background-color: ${({ theme, active }) => (active ? theme.colors.themeTertiary : theme.colors.greyLight)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  transition: all 0.1s ease-in-out;

  &:hover {
     background-color: ${({ theme, active }) => (active ? theme.colors.themeSecondary : theme.colors.greyLight)};
     svg {
       fill: ${({ theme, active }) => (active ? theme.colors.textInverse : theme.colors.themePrimary)};
     }
     span {
       color: ${({ theme, active }) => (active ? theme.colors.textInverse : theme.colors.themePrimary)};
     }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    width: 8rem;
    height: 8rem;
    margin-bottom: 0;

    svg {
      height: 4rem;
    }
  }

  ${(props) => props.documentsIcon && css`
    &:hover {
      svg {
        stroke: ${({ theme, active }) => (active ? theme.colors.textInverse : theme.colors.themePrimary)};
      }
    }
  `}
`;

const MenuText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.titleXSmall};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 0.5rem;
    color: ${({ theme, active }) => (active ? theme.colors.textPrimary : theme.colors.themeTertiary)}
  }
`;

const MenuSquareItem = styled.div`
  cursor: ${({ active }) => (active ? 'pointer' : 'not-allowed')};
  width: 12rem;
  height: 12rem;
  border-radius: 1.2rem;
  background-color: ${({ theme, active }) => (active ? theme.colors.themeSecondary : '#d9fcff')};
  color: ${({ theme, active }) => (active ? theme.colors.textInverse : theme.colors.themePrimary)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  margin-left: 2rem;
  text-align: center;
  transition: all 0.1s ease-in-out;

  &:hover {
    background-color: ${({ theme, active }) => (active ? theme.colors.themePrimary : '#d9fcff')};
  }

  span {
    font-size: ${({ theme }) => theme.fontSizes.titleXSmall};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    width: 8rem;
    height: 8rem;
  }
`;


const Menu = () => {
  const screen = useSelector((state) => state.windowResize.screen);
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  let w = 1 / 3;
  let itemFlex = false;
  let itemFlexDirection = 'row';

  if (screen === 'phone') {
    w = 1 / 2;
    itemFlex = true;
    itemFlexDirection = 'column';
  }

  const activeColor = 'themeSecondary';
  const blockedColor = 'themePrimary';

  const student = useSelector((state) => state.student);
  const { statusHistory, studentStatus, internship, pastInternships } = student;
  const statusList = statusHistory.map((item) => item.status);
  const firstPayment = student?.payments?.[0]?.status;
  const firstPaymentDone = firstPayment === 'done';
  const firstPaymentInReview = firstPayment === 'inReview';
  const hasInternship = !!internship?.vacancy;
  const profileCompleted = statusList.includes('profileCompleted');
  const hasAppliedProrroga = pastInternships?.length > 0;

  // Show / hide ConfirmAccountModal
  const accountConfirmed = studentStatus !== 'pendingAccountConfirmation';
  const [confirmAccountModalVisible, setConfirmModalVisible] = useState(!accountConfirmed);
  // Show / hide FirstPaymentModal
  const showFirstModal = accountConfirmed && studentStatus === 'profileCompleted';
  const [firstModalVisible, setFirstModalVisible] = useState(!showFirstModal);

  // Control the access to all the content, depending on the profile status
  const hasAccess = profileCompleted;

  // Redirect user to Payment page if first payment is pending
  useEffect(() => {
    if (!firstPaymentDone && !firstPaymentInReview) history.push('/payment');
    setLoading(false);
  }, [student]);

  if (loading) return 'Loading...';

  return (
    <LoggedLayout>
      <Container>
        <TitleContainer>
          <Title>
            Hola {student.name}
          </Title>
        </TitleContainer>
        <Box container wrap="wrap">
          <Box
            w={w}
            spacing={5}
            container={itemFlex}
            direction={itemFlexDirection}
          >
            <Link to="/profile">
              <MenuItem active>
                <UserIcon width={5} height={5} iconColor={activeColor} />
                {screen !== 'phone' && <MenuText>Tu perfil</MenuText>}
              </MenuItem>
            </Link>
            {screen === 'phone' && <MenuText active>Tu perfil</MenuText>}
          </Box>

          <Box
            w={w}
            spacing={5}
            container={itemFlex}
            direction={itemFlexDirection}
          >
            {firstPaymentDone
              ? (
                <Link to="/vacancy-finder">
                <MenuItem active>
                  <FindVacancyIcon width={7} height={5} iconColor={activeColor} />
                  {screen !== 'phone' && <MenuText>Vacantes</MenuText>}
                </MenuItem>
              </Link>
              )
              : (
              <MenuItem active={false}>
                <FindVacancyIcon width={7} height={5} iconColor={blockedColor} />
                {screen !== 'phone' && <MenuText>Vacantes</MenuText>}
              </MenuItem>

              )}
            
            {screen === 'phone' && <MenuText active>Vacantes</MenuText>}
          </Box>

          <Box
            w={w}
            spacing={5}
            container={itemFlex}
            direction={itemFlexDirection}
          >
            {hasAccess
              ? (
                <Link to="/processes">
                  <MenuItem active>
                    <ProcessesIcon width={7.2} height={5} iconColor={activeColor} />
                    {screen !== 'phone' && <MenuText>Procesos</MenuText>}
                  </MenuItem>
                </Link>
              )
              : (
                <MenuItem active={false}>
                  <ProcessesIcon width={7.2} height={5} iconColor={blockedColor} />
                  {screen !== 'phone' && <MenuText>Procesos</MenuText>}
                </MenuItem>
              )}
            {screen === 'phone' && <MenuText active={hasAccess}>Procesos</MenuText>}
          </Box>

          <Box
            w={w}
            spacing={5}
            container={itemFlex}
            direction={itemFlexDirection}
          >
            {hasAccess
              ? (
                <Link to="/documents">
                  <MenuItem active documentsIcon>
                    <DocumentsIcon width={5} height={5} iconColor={activeColor} />
                    {screen !== 'phone' && <MenuText>Documentos</MenuText>}
                  </MenuItem>
                </Link>
              )
              : (
                <MenuItem active={false}>
                  <DocumentsIcon width={5} height={5} iconColor={blockedColor} />
                  {screen !== 'phone' && <MenuText>Documentos</MenuText>}
                </MenuItem>
              )}
            {screen === 'phone' && <MenuText active={hasAccess}>Documentos</MenuText>}
          </Box>

          <Box
            w={w}
            spacing={5}
            container={itemFlex}
            direction={itemFlexDirection}
          >
            {hasAccess && hasInternship
              ? (
                <Link to="/my-practices">
                  <MenuItem active>
                    <PracticesIcon width={7} height={5} iconColor={activeColor} />
                    {screen !== 'phone' && <MenuText>Mi Práctica</MenuText>}
                  </MenuItem>
                </Link>
              )
              : (
                <MenuItem active={false}>
                  <PracticesIcon width={7} height={5} iconColor={blockedColor} />
                  {screen !== 'phone' && <MenuText>Mi Práctica</MenuText>}
                </MenuItem>
              )}
            {screen === 'phone' && <MenuText active={hasAccess}>Mi Práctica</MenuText>}
          </Box>

          <Box
            w={w}
            spacing={5}
            container={itemFlex}
            direction={itemFlexDirection}
          >
            {hasAccess && hasInternship && !hasAppliedProrroga
              ? (
                <Link to="/prorroga">
                  <MenuSquareItem active>
                    <Span>Pedir prórroga</Span>
                  </MenuSquareItem>
                </Link>
              )
              : (
                <MenuSquareItem active={false}>
                  <Span>Pedir prórroga</Span>
                </MenuSquareItem>
              )}
          </Box>
        </Box>
      </Container>
      {
        confirmAccountModalVisible && (
          <Modal onClose={() => setConfirmModalVisible(false)}>
            <ConfirmAccountModal />
          </Modal>
        )
      }
      {
        firstModalVisible && firstPaymentInReview && (
          <Modal onClose={() => setFirstModalVisible(false)}>
            <FirstStepsModal messageType="inReview" />
          </Modal>
        )
      }
      {
        firstModalVisible && studentStatus === 'paidRegistration' && (
          <Modal onClose={() => setFirstModalVisible(false)}>
            <FirstStepsModal messageType="completeProfile" />
          </Modal>
        )
      }
    </LoggedLayout>
  );
};

export default Menu;
