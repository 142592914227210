// eslint-disable-next-line import/prefer-default-export
export const GEOGRAPHICZONE = [
  'Norte',
  'Sur',
  'Costa Mediterranea',
  'Ibiza e islas Baleares',
  'Islas Canarias',
  'Centro',
  'Barcelona',
  'Madrid',
];
