import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import {
  SingleRow, BackArrowTitle, PageContainer, PageSubMenuPill,
} from '../components/common';
import Summary from '../components/myPractices/Summary';
import Documents from '../components/myPractices/Documents';
import UsefulInformation from '../components/myPractices/UsefulInformation';
import MobileFooter from '../components/myPractices/MobileFooter';
import LoggedLayout from '../components/layout/LoggedLayout';


const HSpacer = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    height: 8rem;
  }
`;

const MyPractices = () => {
  const history = useHistory();
  const [isActive, setIsActive] = useState('Resumen');
  const [loading, setLoading] = useState(true);

  const student = useSelector((state) => state.student || null);
  const { internship, passport } = student;
  const documents = internship?.documents;
  const hasInternship = !!student?.internship?.vacancy;

  useEffect(() => {
    if (!student) return;
    if (!hasInternship) history.push('/menu');
    setLoading(false);
  }, [student]);

  if (loading) return <LoggedLayout><PageContainer>Loading...</PageContainer></LoggedLayout>;

  return (
    <LoggedLayout>
      <PageContainer>
        <BackArrowTitle title="Mi Práctica" />
        <SingleRow />
        <PageSubMenuPill title1="Resumen" title2="Documentos" title3="Información útil" active={isActive} setActive={setIsActive} />
        {isActive === 'Resumen' && <Summary internship={internship} />}
        {isActive === 'Documentos' && <Documents documents={documents} />}
        {isActive === 'Información útil' && <UsefulInformation />}
        <HSpacer />
        <MobileFooter />
      </PageContainer>
    </LoggedLayout>
  );
};

export default MyPractices;
