import awsConfig from '../services/aws-config';

/**
 * Get image url
 * @param {string} path - The S3 folder where is the image without slashes (/)
 * @param {string} image file - The name of the file
 */
const getUrl = (path, attachment) => {
  if (!path || !attachment) return '';

  const url = `https://${awsConfig.Storage.bucket}.s3.${awsConfig.Storage.region}.amazonaws.com/${path}/${attachment}`;
  return url;
};

export default { getUrl };
