import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';


const VerticalContainer = styled.div`
  width: 2px;
  background-color: ${(props) => props.theme.colors.themeTertiary};
  margin: ${({ margin }) => margin};
`;


const VerticalLine = (props) => {
  const { margin } = props;

  return (
    <VerticalContainer margin={margin} />
  );
};

VerticalLine.propTypes = {
  margin: PropTypes.string,
};

VerticalLine.defaultProps = {
  margin: '0 2rem',
};

export default VerticalLine;
