const languages = ['Español', 'Inglés', 'Francés', 'Alemán', 'Italiano', 'Otros'];

const levels = [
  { label: 'Ninguno', value: 'none' },
  { label: 'Básico', value: 'limited' },
  { label: 'Intermedio', value: 'intermediate' },
  { label: 'Avanzado', value: 'professional' },
  { label: 'Nativo', value: 'native' },
];

const digitalLevels = [
  { label: 'Ninguno', value: 'none' },
  { label: 'Usuario', value: 'user' },
  { label: 'Avanzado', value: 'advanced' },
  { label: 'Profesional', value: 'professional' },
  { label: 'Experto', value: 'expert' },
];

const levelsTrans = {
  none: 'Ninguno',
  limited: 'Básico',
  intermediate: 'Intermedio',
  professional: 'Avanzado',
  native: 'Nativo',
};

const levelsTransEn = {
  none: 'none',
  limited: 'limited',
  intermediate: 'intermediate',
  professional: 'professional',
  native: 'native',
  ninguno: 'none',
  básico: 'limited',
  intermedio: 'intermediate',
  avanzado: 'professional',
  nativo: 'native',
};

const digitalLevelsTrans = {
  none: 'Ninguno',
  user: 'Usuario',
  advanced: 'Avanzado',
  professional: 'Profesional',
  expert: 'Experto',
};

const translateLevel = (level) => levelsTrans[level];
const translateDigitalLevel = (level) => digitalLevelsTrans[level];

export {
  digitalLevels,
  digitalLevelsTrans,
  languages,
  levels,
  levelsTrans,
  levelsTransEn,
  translateDigitalLevel,
  translateLevel,
};
