import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import {
  SingleRow, Icons, DatesOpen,
} from '../common';
import { images, text } from '../../utils';
import theme from '../CustomTheme/Theme';
import ContactPill from '../common/ContactPill';


const EnterpriseInfoWrapper = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
`;

const Img = styled.img`
height: 7.1rem;
`;

const Tag = styled.div`
font-size: ${theme.fontSizes.titleXSmall};
font-weight: bold;
`;

const EnterpriseName = styled.div`
font-size: ${theme.fontSizes.titleXSmall};
font-weight: bold;
line-height: 1.17;
margin-top: 0.8rem;
`;

const LocationWrapper = styled.div`
  display:flex;
  align-items:center;
  margin-top: 0.8rem;
  cursor:pointer;
`;

const Location = styled.div`
  font-size: ${theme.fontSizes.medium};
  line-height: 1.33;
  text-align: center;
  color: ${theme.colors.themePrimary};
`;

const VacancyInfoWrapper = styled.div`
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  padding:0 1rem;
`;

const DetailsWrapper = styled.div`
  margin: 2rem 0;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    margin:0;
  }
`;

const GeneralDetails = styled.div`
  width:30%;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    width:100%;
    margin-top:2rem;
  }
`;

const DatesDetails = styled.div`
  width:30%;
  margin: 0 auto;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    width:100%;
  }
`;

const BoldTitle = styled.div`
font-size: ${theme.fontSizes.medium};
font-weight: bold;
line-height: 1.47;
`;

const Info = styled.div`
font-size: ${theme.fontSizes.medium};
line-height: 1.47;
margin-top:10px;
margin-bottom:20px;

@media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    margin-top:0.5rem;
  }
`;

const LinkStyles = styled.span`
  font-size: ${(props) => props.theme.fontSizes.medium};
  color: ${(props) => props.theme.colors.themeSecondary};
  cursor: pointer;
  text-decoration: underline;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: ${(props) => props.theme.fontSizes.small};
    line-height: 3rem;
    margin-top: 3rem;
  }
`;

const Summary = (props) => {
  const { internship } = props;

  if (internship === null) {
    return (
      <>
        <SingleRow />
        <EnterpriseName>Práctica no asignada</EnterpriseName>
      </>
    );
  }

  const {
    internshipHours, departmentsLooking, initDate, endDate,
    service, whatToBring, documents, visaEndDate,
  } = internship;
  const vacancy = internship?.vacancy || {};

  const {
    logoImage, department, company, city,
    descriptionLang, datesOpen,
  } = vacancy;

  const initInternshipDate = initDate ? new Intl.DateTimeFormat('default', { dateStyle: 'long' }).format(new Date(initDate)) : '';
  const endInternshipDate = endDate ? new Intl.DateTimeFormat('default', { dateStyle: 'long' }).format(new Date(endDate)) : '';
  const endVisaDate = visaEndDate ? new Intl.DateTimeFormat('en-GB').format(new Date(visaEndDate)) : '';

  const benefitEconomy = text.getTrullyText(vacancy.benefitEconomy);
  const benefitMealLang = text.getTrullyText(vacancy?.benefitMealLang?.es);
  const benefitHouse = text.getTrullyText(vacancy?.benefitHouse);

  const vacancyLogo = images.getUrl('vacancy/logoImage', logoImage);
  const locationLink = `https://www.google.com/maps/search/?api=1&query=${city}`;

  return (
    <>
      <SingleRow />

      {
        internship?.vacancy ? (
          <EnterpriseInfoWrapper>
            <Img src={vacancyLogo} />
            <Tag>{department}</Tag>
            <EnterpriseName>{company}</EnterpriseName>
            <LocationWrapper>
              <Icons icon="location-red" width={2} />
              <Location>
                <a href={`${locationLink}`} target="_blank" rel="noopener noreferrer">
                  {city}
                </a>
              </Location>
            </LocationWrapper>
          </EnterpriseInfoWrapper>
        ) : null
      }

      <SingleRow />

      <VacancyInfoWrapper>
        <GeneralDetails>
          {
            benefitEconomy && (
              <DetailsWrapper>
                <BoldTitle>Ayuda Económica:</BoldTitle>
                <Info>{benefitEconomy}</Info>
              </DetailsWrapper>
            )
          }

          {
            benefitMealLang && (
              <DetailsWrapper>
                <BoldTitle>Comidas:</BoldTitle>
                <Info>{benefitMealLang}</Info>
              </DetailsWrapper>
            )
          }

          {
            benefitHouse && (
              <DetailsWrapper>
                <BoldTitle>Alojamiento:</BoldTitle>
                <Info>{benefitHouse}</Info>
              </DetailsWrapper>
            )
          }

          <DetailsWrapper>
            <BoldTitle>Fechas de apertura:</BoldTitle>
            <Info><DatesOpen datesOpen={datesOpen} /></Info>
          </DetailsWrapper>
        </GeneralDetails>

        <DatesDetails>
          <DetailsWrapper>
            <BoldTitle>Fechas de práctica:</BoldTitle>
            {
              (initDate && endDate) ? (
                <Info>Del {initInternshipDate} al {endInternshipDate}</Info>
              ) : <Info>Fechas sin confirmar</Info>
            }
          </DetailsWrapper>

          <DetailsWrapper>
            <BoldTitle>Fecha de vencimiento del visado:</BoldTitle>
            <Info>{endVisaDate}</Info>
          </DetailsWrapper>

          {
            descriptionLang && (
              <DetailsWrapper>
                <BoldTitle>¿Qué debo traer?</BoldTitle>
                <Info>{whatToBring}</Info>
              </DetailsWrapper>
            )
          }

          {/* <DetailsWrapper>
            <BoldTitle>Documentación necesaria:</BoldTitle>
            {
              documents?.length && (
                documents.map((doc, index) => <Info>{index + 1}. {doc.name}</Info>)
              )
            }
            <LinkStyles>
              <Link to="/documents">Ir a mis documentos</Link>
            </LinkStyles>
          </DetailsWrapper> */}
        </DatesDetails>
        <ContactPill />
      </VacancyInfoWrapper>
    </>
  );
};

export default Summary;
