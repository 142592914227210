import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { system } from 'styled-system';


const Icon = styled('svg')(
  system({
    fill: {
      property: 'fill',
      scale: 'colors',
    },
    stroke: {
      property: 'stroke',
      scale: 'colors',
    },
  }),
);

const IconComponent = (props) => {
  const {
    iconCode, height, width,
    iconColor, viewBox,
  } = props;

  const finalHeight = height !== '100%' ? `${height}rem` : '100%';
  const finalWidth = width !== '100%' ? `${width}rem` : '100%';

  return (
    <Icon
      fill={iconColor}
      stroke={iconColor}
      height={finalHeight}
      width={finalWidth}
      viewBox={viewBox}
    >
      {iconCode}
    </Icon>
  );
};

IconComponent.propTypes = {
  iconCode: PropTypes.node.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  iconColor: PropTypes.string,
  viewBox: PropTypes.string,
};

IconComponent.defaultProps = {
  height: '100%',
  width: '100%',
  viewBox: '0 0 40 40',
  iconColor: '',
};

export default IconComponent;
