const show = () => (dispatch) => dispatch({
  type: 'CHANGE_SHOW_BURGER_MENU',
  show: true,
});

const hidde = () => (dispatch) => dispatch({
  type: 'CHANGE_SHOW_BURGER_MENU',
  show: false,
});


export default {
  show,
  hidde,
};
