import React from 'react';
import PropTypes from 'prop-types';

import {
  PageSubMenu,
  PageSubMenuItem,
  VSeparator,
} from './styledComponents';

import { Icons } from '..';


const PageSubMenuPill = ({
  active,
  setActive,
  title1,
  title2,
  title3,
  isVacancies,
  hasFullProfile,
  setFullProfileModalVisible,
  isLocked,
}) => (
  <PageSubMenu>
    <PageSubMenuItem active={active === title1} onClick={() => setActive(title1)}>
      {isLocked && <Icons icon="bloqueo" iconColor={active === 1 ? 'textInverse' : 'textPrimary'} width={2.4} />}
      {!isLocked && title1}
    </PageSubMenuItem>
    <VSeparator />
    <PageSubMenuItem active={active === title2} onClick={() => setActive(title2)}>{title2}</PageSubMenuItem>
    <VSeparator />
    {isVacancies
      ? (
        <PageSubMenuItem
          active={active === title3}
          onClick={hasFullProfile ? () => setActive(title3) : () => setFullProfileModalVisible(true)}
        >
          {title3}
        </PageSubMenuItem>
      )
      : (
        <PageSubMenuItem active={active === title3} onClick={() => setActive(title3)}>
          {title3}
        </PageSubMenuItem>
      )}
  </PageSubMenu>
);

PageSubMenuPill.defaultProps = {
  isVacancies: false,
  hasFullProfile: true,
  setFullProfileModalVisible: () => { },
  isLocked: false,
};

PageSubMenuPill.propTypes = {
  active: PropTypes.string.isRequired,
  setActive: PropTypes.func.isRequired,
  title1: PropTypes.string.isRequired,
  title2: PropTypes.string.isRequired,
  title3: PropTypes.string.isRequired,
  isVacancies: PropTypes.bool,
  hasFullProfile: PropTypes.bool,
  setFullProfileModalVisible: PropTypes.func,
  isLocked: PropTypes.bool,
};

export default PageSubMenuPill;
