import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _get from 'lodash/get';
import _remove from 'lodash/remove';
import ButtonSecondary from '../../../common/Buttons/ButtonSecondary';
import { Modal, SingleRow } from '../../../common';
import NewLang from './NewLang';
import Language from './Language';
import { studentActions } from '../../../../redux/actions';
import { levels } from '../../helpers';

import {
  FullFlexCont,
  WhiteSpace,
  BeforeTitle,
  NoIconTitle,
  MobilePadding,
} from '../../formStyledComponents';
import { translateLevel } from '../../helpers/constants';

const LangExperience = ({ profile, littleDevice }) => {
  const dispatch = useDispatch();

  const [langExperience, setLangExperience] = useState([]);

  useEffect(() => {
    const langs = _get(profile, 'languageExperience', []);
    setLangExperience(langs);
  }, [profile]);

  /* delete language button */
  const deleteLang = (languageName) => {
    const languages = [];
    langExperience.forEach((lang) => languages.push(lang));
    const findLangToDelete = (lang) => lang.languageName === languageName;
    const deleted = _remove(languages, findLangToDelete);
    dispatch(studentActions.save({ languageExperience: languages }));
  };

  /* open / close add-language-modal */
  const [langModalActive, setLangModalActive] = useState(false);

  const onClose = () => {
    setLangModalActive(false);
  };

  const openModal = () => {
    setLangModalActive(true);
  };

  /* add-language button */
  const addLang = (newLang) => {
    const languages = [];
    langExperience.forEach((lang) => languages.push(lang));
    languages.push(newLang);
    dispatch(studentActions.save({ languageExperience: languages }));
    onClose();
  };

  return (
    <>
      <MobilePadding>
        <FullFlexCont mobilePadding="0">
          {!littleDevice && <BeforeTitle>(5 max)</BeforeTitle>}
          <NoIconTitle>IDIOMAS*:</NoIconTitle>
        </FullFlexCont>
      </MobilePadding>

      {!littleDevice && <SingleRow />}

      {langExperience && langExperience.map((lang) => {
        const languageName = _get(lang, 'languageName', '');
        const speak = _get(lang, 'speak', '');
        const write = _get(lang, 'write', '');
        const understand = _get(lang, 'understand', '');

        return (
          <Language
            key={languageName}
            languageName={languageName}
            speak={translateLevel(speak)}
            write={translateLevel(write)}
            understand={translateLevel(understand)}
            deleteLang={deleteLang}
            onClose={onClose}
            levels={levels}
            profile={profile}
            littleDevice={littleDevice}
            langExperience={langExperience}
          />
        );
      })}

      {langExperience && !littleDevice && langExperience.length < 5 && (
        <>
          <WhiteSpace height="4rem" />
          <ButtonSecondary text="Añadir idioma" fullWidth onClick={openModal} />
        </>
      )}

      {langModalActive
        && (
          <Modal onClose={onClose}>
            <NewLang
              addLang={addLang}
              levels={levels}
              littleDevice={littleDevice}
              profile={profile}
              langExperience={langExperience}
            />
          </Modal>
        )}
    </>
  );
};

export default LangExperience;
