/* eslint-disable no-console */
import AWS from 'aws-sdk';

// import SchemaManager from './schema';
import { response } from './api/response';
import { getCredentials } from './api/auth';

import awsconfig from './aws-config';

const { Storage } = awsconfig;

export function getFileURL(fileLocation) {
  const baseURL = `https://${Storage.bucket}.s3.${Storage.region}.amazonaws.com`;
  return `${baseURL}/${fileLocation}`;
}

export async function uploadFile(file, path, retry = false) {
  const { region, bucket, identityPoolId } = Storage;

  try {
    const credentials = await getCredentials();
    AWS.config.update({ credentials, region });
    const s3 = new AWS.S3();

    const data = await s3.upload({
      Body: file,
      ContentType: file.type,
      Key: path,
      Bucket: bucket,
      ACL: 'public-read',
      IdentityPoolId: identityPoolId,
    }).promise();
    const newKey = data.Key || data.key;
    return response(true, '', newKey);
  } catch (error) {
    console.error('upload file error -> ', error);
    return response(false, error, 'Error de guardado de imagen');
  }
}
