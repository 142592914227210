import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { uploadFile, getFileURL } from '../../services/storage';
import { ButtonPrimary, ErrorMessage, IconButton, Modal } from '../common';
import { CardPaymentIcon, TransferPaymentIcon, BankPaymentIcon } from '../common/Icons/IconsList';
import Box from '../common/Flex/Box';
import InputText from '../common/formFields/Inputs/InputText';
import ButtonSecondary from '../common/Buttons/ButtonSecondary';
import HSpacer from '../layout/HSpacer';
// import PaymentMessage from '../OnBoarding/PaymentMessage';
import StripeUI from './Stripe';
import { payment } from '../../utils';


const InputLabel = styled.label`
  display: block;
  text-align: center;
  color: ${(props) => props.theme.colors.greyDark};
  font-size: ${(props) => props.theme.fontSizes.large};
  margin-bottom: 3px;
`;

const FileLink = styled.span`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const AccountInfo = styled.div`
  width: 100%;
  padding: 2rem 0;
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: ${(props) => props.theme.fontSizes.medium};
  line-height: 1.5;

  span {
    font-size: ${(props) => props.theme.fontSizes.small};
    color: ${(props) => props.theme.colors.greyDark};
  }
`;

const CardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4.5rem;
  padding: 2rem 12rem;
  flex-wrap: wrap;
  text-align: center;
  line-height: 2;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    gap: 3.5rem;
    padding: 2rem 8rem;
  }
`;


const PaymentMethods = (props) => {
  const { error, method, onChange, onPaymentSuccess, amount, paymentId,
    firstPaymentDone, showPaymentSuccessModal, onStepCompleted, setShowPaymentModal } = props;

  const screen = useSelector((state) => state.windowResize.screen);
  const student = useSelector((state) => state.student);
  const accountTitleHolder = student?.partner?.titleHolder || 'Solicita la información';
  const accountCountry = student?.partner?.accountCountry || 'Solicita la información';
  const accountAddress = student?.partner?.address || 'Solicita la información';
  const accountNumber = student?.partner?.accountNumber || 'Solicita la información';
  const accountBankBIC = student?.partner?.bankBIC || 'Solicita la información';
  const currencyCountryCode = payment.currencyCountriesCode[student?.residence?.code] || payment.currencyCountriesCode.DEFAULT;

  const [uploadedFile, setUploadedFile] = useState({ filename: '', fileLocation: '' });
  const [showUploadFile, setShowUploadFile] = useState(true);

  const handleFileUpload = async (files) => {
    const file = files[0];
    if (file) {
      const timestamp = +new Date();
      const filename = `${timestamp}-${file.name}`.replace(/\s/g, '-').replace(/á|à|â|é|è|ê|í|ì|î|ó|ò|ô|ú|ù|û|ç|ñ`/gi, '-');
      const fileLocation = `payment/receiptFile/${filename}`;
      let { success } = await uploadFile(file, fileLocation);

      if (!success) {
        const secondResult = await uploadFile(file, fileLocation);
        success = secondResult.success;
      }

      if (success) {
        setUploadedFile({ filename, fileLocation });
        setShowUploadFile(false);
        onPaymentSuccess(filename);
      }
    }
  };

  const handleModalAction = () => {
    if (onStepCompleted) return onStepCompleted();

    return setShowPaymentModal(false);
  };


  const renderUploadFileComponent = () => (
    <>
      <HSpacer />
      {
          showUploadFile ? (
            <InputText
              name="voucher"
              type="file"
              label="Comprobante de pago"
              value=""
              placeholder="Subir archivo"
              onChange={handleFileUpload}
              controlled
            />
          ) : (
            <>
              <HSpacer />
              <ButtonSecondary
                text="Volver a subir archivo"
                onClick={() => setShowUploadFile(true)}
                margin="2rem auto"
                fullWidth
                mobilePadding="1rem 0.5rem"
                fontSize="medium"
                // mobileWidth="10rem"
              />
            </>
          )
        }
      {
          uploadedFile?.fileLocation ? (
            <FileLink>
              <a href={getFileURL(uploadedFile.fileLocation)} target="_blank" rel="noopener noreferrer">
                Ver comprobante de pago subido: {uploadedFile.filename}
              </a>
            </FileLink>
          ) : ''
        }
    </>
  );

  return (
    <>
      <InputLabel>Elige método de pago</InputLabel>
      <HSpacer />
      <Box container>
        <Box w={1 / 3} spacing={3} padding="0.1rem">
          <IconButton
            icon={<CardPaymentIcon />}
            text="Tarjeta"
            type={5}
            width={5.5}
            padding={screen === 'tab-land' ? '0.3rem 2.1rem' : '0.3rem 3.1rem'}
            square
            onClick={() => onChange('payment', 'card')}
            selected={method === 'card'}
          />
        </Box>
        <Box w={1 / 3} spacing={3} padding="0.1rem">
          <IconButton
            icon={<TransferPaymentIcon />}
            text="Transferencia"
            type={5}
            width={6.2}
            padding={screen === 'tab-land' ? '0rem 1.8rem' : '0rem 2.8rem'}
            square
            onClick={() => onChange('payment', 'wireTransfer')}
            selected={method === 'wireTransfer'}
          />
        </Box>
        <Box w={1 / 3} spacing={3} padding="0.1rem">
          <IconButton
            icon={<BankPaymentIcon />}
            text="Subir Comprobante"
            type={5}
            width={6}
            padding={screen === 'tab-land' ? '0.1rem 1.9rem' : '0.1rem 2.9rem'}
            square
            onClick={() => onChange('payment', 'deposit')}
            selected={method === 'deposit'}
          />
        </Box>
      </Box>
      <HSpacer />

      {
        method === 'wireTransfer'
          && (
            <AccountInfo>
              <strong>Titular:</strong> {accountTitleHolder}
              <br />
              <strong>Dirección:</strong> {accountAddress}
              <br />
              <strong>País de la cuenta:</strong> {accountCountry}
              <br />
              <strong>IBAN:</strong> {accountNumber}
              <br />
              <strong>BIC/SWIFT:</strong> {accountBankBIC}
              <br />
              <span>*En el concepto de la transferencia indicar siempre el nombre del estudiante</span>
            </AccountInfo>
          )
      }

      {
        method === 'deposit'
          && (
            <AccountInfo>
              <strong>Titular:</strong> {accountTitleHolder}
              <br />
              <strong>Dirección:</strong> {accountAddress}
              <br />
              <strong>IBAN:</strong> {accountNumber}
              <br />
              <strong>BIC/SWIFT:</strong> {accountBankBIC}
              <br />
              <span>*Indicar el nombre del estudiante</span>
            </AccountInfo>
          )
      }

      {
        method === 'card'
          ? (
            <StripeUI
              amount={amount}
              paymentId={paymentId}
              onPaymentSuccess={onPaymentSuccess}
              firstPaymentDone={firstPaymentDone}
              currency={currencyCountryCode}
            />
          )
          : renderUploadFileComponent()
        }

      {showPaymentSuccessModal && (
        <Modal
          minHeight="30%"
          onClose={handleModalAction}
        >
          <CardContentWrapper>
            <p>Su comprobante de pago ha sido cargado con éxito</p>

            <ButtonPrimary
              iconColor="#105285"
              type={2}
              text="Continuar"
              fontSize="medium"
              onClick={handleModalAction}
            />
          </CardContentWrapper>
        </Modal>
      )}

      {error && (
        <ErrorMessage>{error}</ErrorMessage>
      )}

    </>
  );
};

PaymentMethods.propTypes = {};

PaymentMethods.defaultProps = {};

export default PaymentMethods;
