import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const Rows = styled.div`
    border-top: 2px solid;
    border-bottom: 2px solid;
    border-color: ${({ theme }) => theme.colors.themePrimary};
    height:0.75rem;
    width: 100%;
    margin: ${ ({ margin }) => margin };
`;

const DoubleRow = ({ margin }) => <Rows margin={margin} />;

Rows.propTypes = {
    margin: PropTypes.string,
};

Rows.defaultProps = {
    margin: '0 0 3rem 0',
}

export default DoubleRow;
