import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import { DataForm, PasswordForm } from '../components/myAccount';
import {
  PageContainer, BackArrowTitle, SingleRow,
  ButtonPrimary, Box, VerticalLine,
} from '../components/common';
import HSpacer from '../components/layout/HSpacer';
import { studentActions } from '../redux/actions';
import LoggedLayout from '../components/layout/LoggedLayout';
import FormImageUploadField from '../components/common/formFields/FormImageUploadField';


const ButtonContainer = styled.div`
  width: 100%;
  margin: 2rem auto;

  button {
    max-height: 4.5rem;
  }
`;


const UserAccount = () => {
  const dispatch = useDispatch();
  const student = useSelector((state) => state.student);
  const userId = useSelector((state) => state.student.id);
  const photo = useSelector((state) => state.student.photo);
  const screen = useSelector((state) => state.windowResize.screen);
  const isSmallScreen = screen === 'phone' || screen === 'tab-port';

  const [isFormActive, setIsFormActive] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);

  const onSubmit = (data, event) => {
    event.preventDefault();
    dispatch(studentActions.save({ ...data }));
    setIsFormActive(false);
  };

  const onChangePassword = (data, event) => {
    event.preventDefault();
    setIsChangingPassword(false);
  };

  const onAddImage = (path) => {
    dispatch(studentActions.save({ photo: path }));
  };

  return (
    <LoggedLayout>
      <PageContainer>
        <BackArrowTitle title="Datos de tu cuenta" linkto="/menu" />

        {isSmallScreen ? <HSpacer height="2.5rem" /> : <SingleRow />}

        <HSpacer height="1.2rem" />

        <Box container align="flex-start" direction={isSmallScreen ? 'column' : 'row'}>
          <Box container w={5 / 10}>
            <DataForm
              isFormActive={isFormActive}
              setIsFormActive={setIsFormActive}
              student={student}
              onSubmit={onSubmit}
            />
          </Box>

          <VerticalLine margin="0 1rem" />

          <Box w={3 / 10}>

            <Box w={3 / 10} container align="center" direction="column">
              <FormImageUploadField
                label="Cambiar imagen"
                entity="profile"
                userId={userId}
                onSave={onAddImage}
                photo={photo}
                disabled={isFormActive}
              />
            </Box>

            <HSpacer height="1.5rem" />

            <Box w={3 / 10}>
              {
                !isChangingPassword && (
                  <ButtonContainer>
                    <ButtonPrimary
                      iconColor="#105285"
                      type={2}
                      text="Cambiar contraseña"
                      fontSize="medium"
                      fullWidth
                      onClick={() => setIsChangingPassword(true)}
                      onSubmit={() => { }}
                    />
                  </ButtonContainer>
                )
              }
              {
                isChangingPassword && (
                  <>
                    <HSpacer height="1.2rem" />

                    <PasswordForm
                      onChangePassword={onChangePassword}
                      isChangingPassword={isChangingPassword}
                      setIsChangingPassword={setIsChangingPassword}
                    />
                  </>
                )
              }

            </Box>
          </Box>
        </Box>
      </PageContainer>
    </LoggedLayout>
  );
};

export default UserAccount;
