/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import { API } from 'aws-amplify';

import { listPublicEntity } from './data';
import { createPayment } from '../../graphql/mutations';


const res = ({ success, msg, data }) => ({
  success,
  message: !success && !msg ? 'UnexpectedError' : msg,
  data,
});

async function createUserPayment(payment) {
  try {
    const result = await API.graphql({
      query: createPayment,
      variables: { input: payment },
    });
    const { data } = result;
    return res({ success: true, msg: 'success', data: data.createPayment });
  } catch (error) {
    console.log('🚀 ~ file: getPayments.js ~ line 26 ~ createUserPayment ~ error', error);
    const { data } = error;
    return res({ success: false, msg: error.code, data });
  }
}

const getProrrogaPayment = async (student) => {
  const { euNationality = false } = student;
  const { data } = await listPublicEntity('price');
  if (data.length <= 0) return { error: true };
  const prorrogaPrice = data.filter((price) => (
    price.service.key.includes('prorroga')
    && (!euNationality ? !price.eu : price.eu)
  ));
  return prorrogaPrice[0];
};

const addProrrogaPayment = async (prorrogaPayment, student) => {
  const studentCode = `${student.code}/${student.number}`;
  const newPayment = {
    amount: prorrogaPayment.price,
    code: `${studentCode}/prorroga/1`,
    currency: 'EUR',
    paymentServiceId: prorrogaPayment.service.id,
    quotaName: 'extension',
    status: 'pending',
    paymentStudentId: student.id,
  };
  const result = await createUserPayment(newPayment);
  const { success, data } = result;
  if (success) return data;
  return { error: true };
};

const addProrroga = async (student) => {
  if (!student) {
    return {
      success: false,
      msg: 'No student',
    };
  }

  try {
    const { internship, payments } = student;
    const pastInternships = student.pastInternships || [];
    const statusHistory = student.statusHistory || [];
    const prorrogaPayment = await getProrrogaPayment(student);
    const prorrogaPaymentId = await addProrrogaPayment(prorrogaPayment, student);
    const internshipServiceId = prorrogaPayment.paymentServiceId;
    const newInternship = {
      ...internship,
      internshipHours: null,
      initDate: null,
      endDate: null,
      internshipServiceId,
      documents: [],
      interviews: [],
      isExtension: true,
      // whatToBring (let the same)
      // departmentsLooking (let the same)
    };

    const newStudent = {
      pastInternships: [...pastInternships, internship],
      internship: newInternship,
      payments: [...payments, prorrogaPaymentId],
      // status: 'extension',
      // statusHistory: [
      //   ...statusHistory,
      //   { date: new Date(), status: 'extension' },
      // ],
    };

    return {
      success: true,
      student: newStudent,
    };
  } catch (error) {
    console.log('addProrroga error -> ', error);
    return {
      error: true,
      msg: 'Error',
    };
  }
};

export { addProrroga };
