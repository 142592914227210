import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { PaymentScreen } from '../components/payments';
import Layout from '../components/layout/Layout';
import { BgContainer, OnBoardingStep } from '../components/OnBoarding';
import { payment } from '../utils';
import useListPublicEntity from '../hooks/useListPublicEntity';

const Payment = () => {
  const { data: prices } = useListPublicEntity('price');
  const screen = useSelector((state) => state.windowResize.screen);
  const history = useHistory();
  const student = useSelector((state) => state.student);
  const { payments, partner, residence } = student;
  const [firstPaymentStudent, secondPaymentStudent] = payments;
  const iva = partner?.iva || residence?.iva || 21;
  const insuranceTax = payment.getInsurancePrice(prices, residence.code);

  const paymentDetails = 
    useMemo(() => ({
      firstPayment: firstPaymentStudent?.amount || 0,
      secondPayment: secondPaymentStudent?.amount || 0,
      iva,
    }), [firstPaymentStudent, secondPaymentStudent, iva]);

  const [studentData, setStudentData] = useState({ service: {}, residence: {}, payment: '' });

  useEffect(() => {
    const {
      originalFirstPayment,
      originalSecondPayment,
      totalIvaAmount,
    } = payment.calculateOriginalPayment(paymentDetails, iva);

    setStudentData({
      payment: 'deposit',
      priceSelected: {
        inscriptionPrice: originalSecondPayment ? originalFirstPayment : paymentDetails?.firstPayment,
        firstPayment: originalFirstPayment,
        secondPayment: originalSecondPayment,
        service: student.internship.service,
        residence: student.residence,
        totalIvaAmount,
      },
    });
  }, [student, iva, paymentDetails]);

  const onFinishPayment = () => {
    history.push('/menu');
  };

  const onStepBack = () => {
    history.push('/login');
  };

  const handleStudentDataChange = (name, value) => {
    setStudentData({
      ...studentData,
      [name]: value,
    });
  };

  return (
    <Layout noMenuLink>
      <BgContainer>
        <OnBoardingStep>
          <PaymentScreen
            onStepCompleted={onFinishPayment}
            onStepBack={onStepBack}
            formData={studentData}
            onFormDataChange={handleStudentDataChange}
            screen={screen}
            iva={iva}
            insuranceTax={insuranceTax}
          />
        </OnBoardingStep>
      </BgContainer>
    </Layout>
  );
};

export default Payment;
