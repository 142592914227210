import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Vacancy } from '../components/vacancies';
import { getItemById } from '../services/api/data';


const VacancyPreview = () => {
  const { id } = useParams();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [vacancyId, setVacancyId] = useState('');
  const [vacancy, setVacancy] = useState('');

  useEffect(() => {
    if (!id) return;
    const fetchData = async () => {
      setLoading(true);
      const { success, message, data } = await getItemById('vacancy', id);
      if (success) setVacancy(data);
      else setError(message);
      setLoading(false);
    };
    fetchData();
  }, [id]);

  if (loading) return 'Loading...';

  return (
    <Vacancy
      key={vacancyId}
      identifier={vacancyId}
      item={vacancy}
      setVacancyId={setVacancyId}
      hasFullProfile={false}
      setFullProfileModalVisible={false}
      fullProfileModalVisible={false}
      preview
    />
  );
};

export default VacancyPreview;
