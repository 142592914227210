import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _get from 'lodash/get';
import _remove from 'lodash/remove';
import ButtonSecondary from '../../../common/Buttons/ButtonSecondary';
import { Modal } from '../../../common';
import NewStudy from './NewStudy';
import PrevStudy from './PrevStudy';
import { studentActions } from '../../../../redux/actions';

import {
  FullFlexCont,
  WhiteSpace,
  BeforeTitle,
  NoIconTitle,
  MobilePadding,
} from '../../formStyledComponents';

const PreviousStudies = ({ profile, littleDevice, scrollToTop }) => {
  const dispatch = useDispatch();

  const [prevStudies, setPrevStudies] = useState([]);

  useEffect(() => {
    const studies = _get(profile, 'prevStudies', []);
    setPrevStudies(studies);
  }, [profile]);

  /* delete prev-studies button */
  const deletePrevStudy = (name, institutionName) => {
    const studies = [];

    prevStudies.forEach((study) => {
      studies.push({
        ended: study.ended,
        gradDate: study.gradDate,
        iniDate: study.iniDate,
        institutionName: study.institutionName,
        name: study.name,
      });
    });

    const findStudyToDelete = (study) => study.name === name && study.institutionName === institutionName;
    const deleted = _remove(studies, findStudyToDelete);
    dispatch(studentActions.save({ prevStudies: studies }));
  };

  const [studiesModalActive, setStudiesModalActive] = useState(false);

  /* open / close add-contact-modal */
  const onClose = () => {
    setStudiesModalActive(false);
  };

  const openModal = () => {
    setStudiesModalActive(true);
  };

  /* add-prev-studies button */
  const addPrevStudy = (studyData) => {
    const studies = [];

    prevStudies.forEach((study) => {
      studies.push({
        ended: study.ended,
        gradDate: study.gradDate,
        iniDate: study.iniDate,
        institutionName: study.institutionName,
        name: study.name,
      });
    });

    studies.push(studyData);
    dispatch(studentActions.save({ prevStudies: studies }));
    onClose();
  };

  const finishSelect = ['Sí', 'No'];

  return (
    <>
      <MobilePadding>
        <FullFlexCont mobilePadding="2rem 0">
          {!littleDevice && <BeforeTitle>(2 max)</BeforeTitle>}
          <NoIconTitle>ESTUDIOS PREVIOS:</NoIconTitle>
        </FullFlexCont>
      </MobilePadding>

      {prevStudies && prevStudies.map((study) => {
        const name = _get(study, 'name', '');
        const institution = _get(study, 'institutionName', '');
        const init = _get(study, 'iniDate', '');
        const grad = _get(study, 'gradDate', '');
        const finish = _get(study, 'ended', true);

        return (
          <PrevStudy
            key={name + institution}
            name={name}
            institution={institution}
            deletePrevStudy={deletePrevStudy}
            init={init}
            grad={grad}
            finish={finish}
            finishSelect={finishSelect}
            profile={profile}
            prevStudies={prevStudies}
            scrollToTop={scrollToTop}
          />
        );
      })}

      {prevStudies && !littleDevice && prevStudies.length < 2 && (
        <>
          <WhiteSpace height="4rem" />
          <ButtonSecondary text="Añadir estudios previos" fullWidth onClick={openModal} />
        </>
      )}

      {studiesModalActive
        && (
          <Modal hasDatePicker onClose={onClose}>
            <NewStudy
              profile={profile}
              addPrevStudy={addPrevStudy}
              finishSelect={finishSelect}
            />
          </Modal>
        )}
    </>
  );
};

export default PreviousStudies;
