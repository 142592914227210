import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Icons from '../../Icons';
import TagDepartamento from '../../Tags/TagDepartamento';
import Image from '../../Image';
import { studentActions } from '../../../../redux/actions';
import { text } from '../../../../utils';


const Container = styled.div`
  width: 100%;
  min-width: 20rem;
  min-height: 40rem;
  margin-bottom: 2rem;
  padding: 3rem 3rem 2rem;
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  border-radius: ${({ theme }) => theme.borderRadius.card};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 0rem 1rem;   
    width: auto;
    min-height: initial;
    display:flex;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
`;

const Part = styled.div`
  display: flex;
  margin-bottom: 1rem;

   @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
     margin-bottom: 0;
   }
`;

const PartIcons = styled.div`
  padding: 0rem 1.125rem;
  display: flex;
  justify-content: space-between;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {   
    padding: 0rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
    width: 20%;
  }
`;

const PartFluid = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {   
    width: 78%;
    padding: 2.5rem;
  }
`;

const VerticalSeparator = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.themeTertiary};
  margin-bottom: 1rem;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {  
    width: 2px; 
    height: auto;
    margin-bottom: 0rem;
  }
`;

const Title = styled.p`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  line-height: 1.3;
  font-weight: 700;
  text-align: center;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {    
    text-align: left;
  }
`;

const Text = styled.p`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  line-height: 1.3;
  text-align: center;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {   
    text-align: left;
  }
`;

const Line = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  
  svg {
    vertical-align: middle;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    text-align: left;
  }
`;

const UbicationIcon = styled(Icons)`
  display: inline-block;
  vertical-align: middle;
`;

const TextUbication = styled.p`
  color: ${(props) => props.theme.colors.themePrimary};
  font-size: ${(props) => props.theme.fontSizes.medium};
  line-height: 1.3;
  margin-left: 0.25rem;
  display: inline-block;
`;

const Separator = styled.div`
  height: 1rem;
`;

const IconLikeCover = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  height: 4.5rem;
  width: 4.5rem;
  border: solid 1px ${(props) => props.theme.colors.themePrimary};
  border-radius: 100%;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;

  &.favourite{
    background-color: ${(props) => props.theme.colors.themeSecondary};
    color: ${(props) => props.theme.colors.themePrimary};
    svg {
      fill: ${(props) => props.theme.colors.themeTertiary};
    }
  }

  svg {
    stroke: ${(props) => props.theme.colors.themePrimary};
    fill: none;
  }

    &:hover {
    background-color: ${(props) => props.theme.colors.themeSecondary};
    color: ${(props) => props.theme.colors.themePrimary};

    svg {
      stroke: ${(props) => props.theme.colors.themeTertiary};
      fill: ${(props) => props.theme.colors.themeTertiary};
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    margin-bottom: 2rem;
  }
`;

const IconChevronCover = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  height: 4.5rem;
  width: 4.5rem;
  background-color: ${(props) => props.theme.colors.themeTertiary};
  border-radius: 100%;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;

  &:hover {
    background-color: ${(props) => props.theme.colors.themeSecondary};
    color: ${(props) => props.theme.colors.textInverse};

    svg {
      fill: ${(props) => props.theme.colors.textInverse};
    }
  }
`;

const ImageContainer = styled.div`
  height: 10rem;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const WarningMessage = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.colors.themeTertiary};
  width: auto;
  height: 10vh;
  opacity: 0.8;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  text-align: center;
  line-height: 1.5;
`;

const CardVacante = (props) => {
  const {
    tagName,
    workplaceName,
    ubication,
    file,
    id,
    setVacancyId,
    setFullProfileModalVisible,
    hasFullProfile,
    warned,
  } = props;
  const economicHelp = text.getTrullyText(props?.economicHelp);
  const benefitHouse = text.getTrullyText(props?.benefitHouse);

  const screen = useSelector((state) => state.windowResize.screen);
  const favourites = useSelector((state) => state.student.favourites);
  const ids = useMemo(() => (favourites ? favourites.map((elem) => elem?.id) : []), [favourites]);
  const studentFavourites = ids || [];
  const dispatch = useDispatch();

  const searchFavourites = (id) => {
    let found = false;
    studentFavourites.forEach((elem) => {
      if (elem?.id === id) { found = true; }
    });
    return found;
  };
  const [insideFavourites, setInsideFavourites] = useState(searchFavourites(id));
  const [showWarning, setShowWarning] = useState(false);

  const onShowWarning = () => {
    setShowWarning(true);
    setTimeout(() => setShowWarning(false), 2000);
  };

  useEffect(() => {
    let found = false;
    ids.forEach((elem) => {
      if (elem === id) { found = true; }
    });
    setInsideFavourites(found);
  }, [id, ids]);


  const addFavourites = (identifier) => {
    let isFavourite = false;
    if (studentFavourites && studentFavourites.length > 0) {
      studentFavourites.forEach((fave, index) => {
        if (fave === identifier) {
          isFavourite = true;
          studentFavourites.splice(index, 1);
          const newStudent = { studentFavouritesId: studentFavourites.length > 0 ? studentFavourites.slice() : null };
          setInsideFavourites(false);
          dispatch(studentActions.save(newStudent));
        }
      });
    }
    if (!studentFavourites || !isFavourite) {
      studentFavourites.push(identifier);
      const newStudent = { studentFavouritesId: studentFavourites.slice() };
      setInsideFavourites(true);
      dispatch(studentActions.save(newStudent));
    }
  };

  return (
    <Container>
      {
        showWarning && <WarningMessage>Alguna de las fechas fechas seleccionadas está fuera del rango de vigencia de esta vacante</WarningMessage>
      }
      {warned && (
        <div
          onMouseEnter={onShowWarning}
        >
          <Icons icon="alert" iconColor="themeSecondary" width={2.5} />
        </div>
      )}
      <PartFluid>
        {
          screen !== 'phone' && (
            <ImageContainer>
              <Image url={file} />
            </ImageContainer>
          )
        }
        <TagDepartamento title={tagName} bgColor="themePrimary" full />
        <Separator />
        <Title>{workplaceName}</Title>
        <Separator />
        <Text>Alojamiento <span style={{ fontWeight: 'bold' }}>{benefitHouse}</span></Text>
        <Text>Ayuda económica {economicHelp}</Text>
        <Separator />
        <Line>
          <UbicationIcon icon="location" iconColor="alertError" width={2} />
          <TextUbication>{ubication}</TextUbication>
        </Line>
      </PartFluid>

      <Part><VerticalSeparator /></Part>

      <PartIcons>
        <IconLikeCover
          className={insideFavourites ? 'favourite' : null}
          onClick={hasFullProfile ? () => addFavourites(id) : () => setFullProfileModalVisible(true)}
        >
          <Icons icon="heart-shape" iconColor="themePrimary" width={2.5} />
        </IconLikeCover>
        <IconChevronCover onClick={() => setVacancyId(id)}>
          <Icons icon="chevronRight" iconColor="themeSecondary" width={2.5} />
        </IconChevronCover>
      </PartIcons>
    </Container>
  );
};

CardVacante.propTypes = {
  tagName: PropTypes.string,
  workplaceName: PropTypes.string,
  economicHelp: PropTypes.string,
  ubication: PropTypes.string,
  file: PropTypes.string,
  id: PropTypes.string,
  setVacancyId: PropTypes.func,
};

CardVacante.defaultProps = {
  tagName: '',
  workplaceName: '',
  economicHelp: '',
  ubication: '',
  file: '',
  id: '',
  setVacancyId: () => { },
};

export default CardVacante;
