import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import {
  Box, InputText, ButtonPrimary, Icons,
} from '../common';
import HSpacer from '../layout/HSpacer';
import FadeIn from '../../hooks/fadeIn';


const SearchBar = styled(Box)`
  border-radius: 3rem;
  max-width: 300px;
  margin: 0 auto;

  input {
    padding: 0.75rem 1.5rem;
    border-radius: 3rem 0rem 0rem 3rem;
    border-width: 2px;
      height: 5.5rem;
  font-size: ${({ theme }) => theme.fontSizes.titleXSmall};
  }
  button {
    border-radius: 0rem 3rem 3rem 0rem;
  }
`;

const SearchButtonContainer = styled.div`
  min-width: 8rem;

  button {
    height: 5.5rem;
  }
`;

const SearchContainer = styled.div`
  width: 100%;
  margin: 0 auto 10rem;
  padding: 8rem 2rem;
  position: relative;
  text-align: center;
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: ${({ theme }) => theme.fontSizes.titleSmall};
  text-align: center;
`;

const BlueDivider = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.themeTertiary};
  margin: 2rem 0;
`;

const CloseCross = styled.div`
  position: absolute;
  right: 2rem;
  top: -6rem;

  &:hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
 font-size: ${({ theme }) => theme.fontSizes.titleXSmall};
`;


const MobileSearch = (props) => {
  const {
    search, setSearch, onSearch, setMobileSearch,
  } = props;

  const onSubmit = () => {
    setMobileSearch(false);
    onSearch();
  };

  const onClose = () => {
    setMobileSearch(false);
  };


  return (
    <SearchContainer>
      <FadeIn translate>
        <CloseCross onClick={onClose} role="button" tabIndex={0} onKeyPress={onClose}>
          <Icons icon="close" iconColor="#4d4d4d" width={3} />
        </CloseCross>

        <Title>Buscar vacante</Title>

        <HSpacer height="3rem" />

        <BlueDivider />

        <HSpacer height="6rem" />

        <Text>Escribe tu término de búsqueda:</Text>

        <HSpacer height="4rem" />

        <SearchBar container>
          <InputText
            type="text"
            name="search"
            placeholder="Escribir palabra"
            value={search}
            onChange={(value) => setSearch(value)}
            controlled
          />
          <SearchButtonContainer>
            <ButtonPrimary
              text="ir"
              onClick={onSubmit}
              type={2}
              fontSize="titleXSmall"
              fullWidth
            />
          </SearchButtonContainer>
        </SearchBar>

        <HSpacer height="8rem" />

        <BlueDivider />
      </FadeIn>
    </SearchContainer>
  );
};

MobileSearch.propTypes = {
  search: PropTypes.string,
  setSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  setMobileSearch: PropTypes.func.isRequired,
};

MobileSearch.defaultProps = {
  search: '',
};

export default MobileSearch;
