import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import _get from 'lodash/get';
import EditDeleteIcons from '../EditDeleteIcons';
import SeeMoreCard from '../SeeMoreCard';
import { studentActions } from '../../../redux/actions';

import {
  WhiteSpace,
  Column,
  Input,
  Label,
  InputColumn,
  ContactForm,
  Error,
  EditDeleteIconsCont,
  InputName,
  MobilePadding,
} from '../formStyledComponents';


const EmergencyContact = ({
  name,
  email,
  phone,
  relationship,
  deleteField,
  profile,
  index,
}) => {
  const dispatch = useDispatch();

  const { register, handleSubmit, errors } = useForm();

  // set active / inactive inputs state
  const [contactActive, setContactActive] = useState(false);

  /* handle errors  */
  const emailError = errors.email?.type === 'required' && <Error>Por favor, indica tu email de contacto</Error>;
  const emailError2 = errors.email?.type === 'pattern' && <Error>Formato de email incorrecto</Error>;
  const nameError = errors.name && <Error>El nombre del contacto de emergencia es obligatorio</Error>;
  const phoneError = errors.phone?.type === 'required' && <Error>Debes indicar un teléfono móvil de contacto</Error>;
  const phoneError2 = errors.phone?.type === 'pattern' && <Error>Número de teléfono no válido</Error>;

  /* edit / save data button */
  const onSubmit = (data, e) => {
    e.preventDefault();
    const contacts = _get(profile, 'emergencyContacts', []);
    contacts[index] = data;

    dispatch(studentActions.save({ emergencyContacts: contacts }));
    setContactActive(!contactActive);
  };

  // Asign a ref to the card, to open it with the "edit" button
  const cardRef = useRef(`contact-${name + email}`);

  const [selectedCard, setSelectedCard] = useState(null);

  const edit = (e) => {
    e.preventDefault();
    setContactActive(!contactActive);
    setSelectedCard(cardRef.current);
  };

  const openByRef = selectedCard === `contact-${name + email}`;


  return (
    <ContactForm key={`${name}${email} contactForm`} onSubmit={handleSubmit(onSubmit)}>
      <WhiteSpace height="4rem" />

      <MobilePadding>

        <InputName
          width="20%"
          disabled={!contactActive}
          name="name"
          placeholder="nombre"
          ref={register({ required: true })}
          defaultValue={name}
          mobileWidth="46%"
          mobileMargin="0"
        />
        {nameError}

        <EditDeleteIconsCont>
          <EditDeleteIcons
            edit={edit}
            deleteField={deleteField}
            isActive={contactActive}
            onSubmit={onSubmit}
            value1={name}
            value2={email}
            confirmText="¿Seguro que quieres borrar este contacto?"
          />
        </EditDeleteIconsCont>

      </MobilePadding>

      <SeeMoreCard
        ref={cardRef}
        openByRef={openByRef}
        selectedCard={selectedCard}
        profile={profile}
        setSelectedCard={setSelectedCard}
      >

        <Column width={30} center mobileFull mobileMargin="0">
          <InputColumn>
            <Label>Relación o parentesco:</Label>
            <Input
              disabled={!contactActive}
              name="relationship"
              placeholder="relationship"
              ref={register({ required: false })}
              defaultValue={relationship}
              key={`${relationship} contactForm`}
            />
          </InputColumn>
        </Column>
        <Column width={30} center mobileFull mobileMargin="0">
          <InputColumn>
            <Label>Email de contacto:</Label>
            <Input
              disabled={!contactActive}
              name="email"
              placeholder="email"
              ref={register({
                pattern: {
                  value: /[\w\-\._]+@[\w\-\._]+\.\w{2,10}/,
                },
              })}
              defaultValue={email}
              key={`${email} contactForm`}
            />
            {emailError}
            {emailError2}
          </InputColumn>
        </Column>
        <Column width={30} center mobileFull mobileMargin="0">
          <InputColumn>
            <Label>Teléfono (móvil o celular) de contacto*:</Label>
            <Input
              disabled={!contactActive}
              name="phone"
              placeholder="phone"
              ref={register({
                required: true,
                pattern: {
                  value: /^\+[0-9][0-9]{6,20}$/,
                },
              })}
              defaultValue={phone}
              key={`${phone} contactForm`}
            />
            {phoneError}
            {phoneError2}
          </InputColumn>
        </Column>

      </SeeMoreCard>

    </ContactForm>
  );
};

export default EmergencyContact;

