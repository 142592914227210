import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const Home = () => {
  const student = useSelector(state => state.student);
  const isLoading = student.loading;
  if (isLoading) return 'Loading...';
  if (!isLoading && !student.id) return <Redirect to="/on-boarding" />;

  return <Redirect to="/menu" />;
};
 
export default Home;
