import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  SingleRow, Icons, Modal, Loader,
} from '..';
import AnswersAndQuestions from '../../myPractices/AnswersAndQuestions';
import ButtonPrimary from '../Buttons/ButtonPrimary';
import {
  QuestionsAndAnswersBlock,
  CloseIcon,
  Title,
  TextTermsWrapper,
  BluePoint,
  Text,
  ButtonWrapper,
  Error,
} from './styledComponents';

import { listPublicEntity } from '../../../services/api/data';
import { addProrroga } from '../../../services/api/prorroga';
import { studentActions } from '../../../redux/actions';
import LegalContent from '../Legal/LegalContent';

const ProrrogaStep1 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const student = useSelector((state) => state.student);

  const [loading, setLoading] = useState(true);
  const [addProrrogaloading, setAddProrrogaLoading] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const [bluePointState, setBluePointState] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await listPublicEntity('faq');
      const list = data.filter((faq) => faq?.type === 'extension');
      setFaqs(list);
      setLoading(false);
    };
    fetchData();
  }, []);

  // const termsAndConditionsTitle = 'Condiciones de prórroga';
  // const termsAndConditionsText = 'Las condiciones de prórroga son....';
  // const textInButton = {
  //   undropped: 'Leer Condiciones', dropped: 'Ocultar condiciones', undroppedMobile: 'Ver más', droppedMobile: 'Ver menos',
  // };
  const onClickProrroga = async () => {
    if (!bluePointState) return setTermsError(true);

    setAddProrrogaLoading(true);
    const response = await addProrroga(student);
    setAddProrrogaLoading(false);

    if (response.success) {
      dispatch(studentActions.save(response.student));
      history.push('/menu');
    }
  };

  const onClickTerms = () => {
    setBluePointState(!bluePointState);
    setShowModal(true);
  };

  return (
    <>
      <Link to="/menu">
        <CloseIcon>
          <Icons icon="close" width={2.8} />
        </CloseIcon>
      </Link>
      <Title>PEDIR PRÓRROGA</Title>
      <SingleRow />
      <QuestionsAndAnswersBlock>
        {
          !loading && faqs && faqs.map((faq) => (
            <AnswersAndQuestions faq={faq} />
          ))
        }
      </QuestionsAndAnswersBlock>
      {/* <QuestionsAndAnswersBlock>
        <AnswersAndQuestions
          termsAndConditionsText={termsAndConditionsText}
          termsAndConditionsTitle={termsAndConditionsTitle}
          textInButton={textInButton}
          buttonWidth={25}
        />
      </QuestionsAndAnswersBlock> */}
      <TextTermsWrapper>
        <BluePoint bluePointState={bluePointState} onClick={() => setBluePointState(!bluePointState)} />
        <Text onClick={onClickTerms}>He leído y acepto las condiciones de la Prórroga</Text>
      </TextTermsWrapper>
      <ButtonWrapper>
        <ButtonPrimary
          disabled={addProrrogaloading}
          text={addProrrogaloading ? <Loader /> : 'Solicitar prórroga'}
          mobileFullWidth
          onClick={onClickProrroga}
        />
      </ButtonWrapper>
      {
        termsError && (
          <TextTermsWrapper>
            <Error>Es necesario leer y aceptar las condiciones de la prórroga</Error>
          </TextTermsWrapper>
        )
      }
      {showModal && (
        <Modal onClose={() => setShowModal(false)} isLegal>
          <LegalContent contentKey="prorroga" />
        </Modal>
      )}
    </>
  );
};

export default ProrrogaStep1;
