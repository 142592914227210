import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import _get from 'lodash/get';

import { Link } from 'react-router-dom';
import { setProgress, studentActions } from '../redux/actions';

import LoggedLayout from '../components/layout/LoggedLayout';
import { PersonalDataForm, ContactDataForm, CvDataForm } from '../components/profile';
import {
  BackArrowTitle,
  SingleRow,
  PageSubMenuPill,
  ProgressBar,
  PageContainer,
} from '../components/common';

const BgContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0;
`;

const MiniText = styled.p`
  font-size: 1.3rem;
  width: 100%;
  text-align: center;
`;

export const MobilePadding = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {  
    padding: 0 2rem;
  }
`;

const LinkStyled = styled(Link)`
  color: ${(props) => props.theme.colors.themeSecondary};
  text-decoration: underline;
`;

const Profile = () => {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.student);

  // manage profile settings
  const [profile, setProfile] = useState(profileData);
  // set progress bar %
  const [barNum, setBarNum] = useState(0);

  useEffect(() => {
    dispatch(setProgress(barNum));
    if (barNum === 100) {
      const { statusHistory } = profileData;
      const statusList = statusHistory.map((item) => item.status);
      const profileCompleted = statusList.includes('profileCompleted');
      if (profileCompleted) return;
      const newStatus = 'profileCompleted';
      const newDate = new Date();
      const newStatusHistory = [...statusHistory, { status: newStatus, date: newDate }];
      dispatch(studentActions.save({ status: 'profileCompleted', statusHistory: newStatusHistory }));
    }
  }, [barNum, dispatch]);

  useEffect(() => {
    setProfile({
      ...profileData,
      prevStudies: profileData.prevStudies !== null ? profileData.prevStudies : [],
      emergencyContacts: profileData.emergencyContacts !== null ? profileData.emergencyContacts : [],
      nationalitiesList: profileData.nationalitiesList !== null ? profileData.nationalitiesList : [],
      workExperience: profileData.workExperience !== null ? profileData.workExperience : [],
      languageExperience: profileData.languageExperience !== null ? profileData.languageExperience : [],
      digitalExperience: profileData.digitalExperience !== null ? profileData.digitalExperience : [],
      otherExperience: profileData.otherExperience !== null ? profileData.otherExperience : [],
      otherDocuments: profileData.otherDocuments !== null ? profileData.otherDocuments : [],
    });
  }, [profileData]);

  // calculate % profile data filled
  useEffect(() => {
    const setFilled = () => {
      const currentStudies = _get(profile, 'currentStudy', null);
      const passport = _get(profile, 'passport', null);
      const contactEmail = _get(profile, 'email', null);
      const emergencyContacts = _get(profile, 'emergencyContacts', null);
      const careerName = _get(profile, 'currentStudy.name', null);
      const languageExperience = _get(profile, 'languageExperience', null);
      const skills = _get(profile, 'skills', null);

      const hasTutor = currentStudies?.gradDate && true; // The form forces to complete all the fields, so if we receive this property, it means that we the tutor

      const minimFilled = currentStudies.ended ? {
        passport,
        contactEmail,
        emergencyContacts,
        careerName,
        languageExperience,
        skills,
      } : {
        passport,
        contactEmail,
        emergencyContacts,
        careerName,
        languageExperience,
        skills,
        hasTutor,
      };

      const totalProfile = Object.keys(minimFilled).length;
      const filledCount = Object.values(minimFilled)
        .reduce((acc, value) => acc + (value && value.length !== 0 ? 1 : 0), 0);

      const totalFilled = (filledCount / totalProfile) * 100;
      setBarNum(Math.round(totalFilled));
    };
    setFilled();
  }, [profile]);

  const completeCount = useSelector((state) => state.complete.count);
  const device = useSelector((state) => state.windowResize.screen);
  const littleDevice = device === 'phone' || device === 'tab-port';

  const initialPage = window.sessionStorage.getItem('activeProfile') || 'Sobre mí';
  const [activeSection, setActiveSection] = useState(initialPage);

  useEffect(() => {
    window.sessionStorage.setItem('activePageProfile', activeSection);
  }, [activeSection]);

  const dialogText = completeCount === 100 ? (
    <>
      Perfil completado. <LinkStyled to="/vacancy-finder">Ir a vacantes</LinkStyled>
    </>
  ) : (
    <>
      <strong>*Debes completar tu perfil al 100% </strong>
      para participar en los procesos de selección.
    </>
  );


  return (
    <LoggedLayout>
      <PageContainer noPadding profile>
        <BgContainer>
          <MobilePadding>
            <BackArrowTitle title="Perfil" linkto="/menu" />
            <SingleRow />
            <PageSubMenuPill title1="Sobre mí" title2="Contacto" title3="C.V." active={activeSection} setActive={setActiveSection} />
            <ProgressBar progress={completeCount} mobileMargin="1rem 0" />
            <MiniText>
              {dialogText}
            </MiniText>
          </MobilePadding>

          {activeSection === 'Sobre mí'
            && (
              <PersonalDataForm
                profile={profile}
              />
            )}

          {activeSection === 'Contacto'
            && (
              <ContactDataForm
                profile={profile}
                littleDevice={littleDevice}
              />
            )}

          {activeSection === 'C.V.'
            && (
              <CvDataForm
                profile={profile}
                littleDevice={littleDevice}
                completeCount={completeCount}
              />
            )}

        </BgContainer>
      </PageContainer>
    </LoggedLayout>
  );
};

export default Profile;
