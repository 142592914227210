import React from 'react';

import styled from 'styled-components/macro';

const TextStyled = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify};
  color: ${({ theme }) => theme.colors.alertNotification};
  text-decoration: ${({ textDecoration }) => textDecoration};
`;

const ErrorMessage = ({ children, justify = 'center', textDecoration = 'none', ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <TextStyled justify={justify} textDecoration={textDecoration} {...rest}>{children}</TextStyled>
);

export default ErrorMessage;
