import React from 'react';
import _get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import ButtonPrimary from '../../../common/Buttons/ButtonPrimary';
import ButtonSecondary from '../../../common/Buttons/ButtonSecondary';
import showOptions from '../../helpers/showOptions';
import { studentActions } from '../../../../redux/actions';

import {
  Column,
  Input,
  Label,
  InputColumn,
  FullFlexCont,
  WhiteSpace,
  ContactForm,
  Select,
  DisabledBlueInput,
} from '../../formStyledComponents';

const DigitalToEdit = ({
  profile,
  setSelectedDigital,
  name,
  level,
  certificate,
  levels,
}) => {

  const dispatch = useDispatch();

  const { register, handleSubmit } = useForm();

  const onSubmit = (data, e) => {
    e.preventDefault();
    const digitals = _get(profile, 'digitalExperience', []);
    digitals.forEach((exp, index) => {
      if (data.name === exp.name) {
        digitals.splice(index, 1, data);
      }
      dispatch(studentActions.save({ digitalExperience: digitals }));
    });
    setSelectedDigital(null)
  };


  return (
    <>
      <ContactForm onSubmit={handleSubmit(onSubmit)}>

        <FullFlexCont justify="flex-end" padding="0" mobilePadding="0" column>

          <InputColumn width="100%" margin="0 auto 0 2rem" mobileMargin="0">
            <DisabledBlueInput
              bold
              width="100%"
              mBottom="0"
              name="name"
              placeholder="competencia digital"
              ref={register({ required: true })}
              value={name}
              mobileMargin="0"
              mobileWidth="80%"
            />
          </InputColumn>

          <InputColumn width="100%" mobileMargin="0" mobileAlignCenter>
            <Label>Nivel:</Label>
            <Select
              width="100%"
              mBottom="0"
              name="level"
              ref={register({ required: true })}
              defaultValue={level}
              mobileMargin="0"
              mobileWidth="80%"
            >
              {showOptions(levels, level, "nivel")}
            </Select>
          </InputColumn>

          <InputColumn width="100%" mobileMargin="0" mobileAlignCenter>
            <Label>Certificado:</Label>
            <Input
              name="certificate"
              ref={register({ required: false })}
              defaultValue={certificate}
              mobileMargin="0"
              mobileWidth="80%"
            />
          </InputColumn>

        </FullFlexCont>

        <FullFlexCont justify="space-between">

          <WhiteSpace height="1rem" />

          <Column width="46" padding="0">
            <ButtonSecondary
              text="Atrás"
              onClick={() => setSelectedDigital(null)}
              margin="auto"
              fullWidth
            />
          </Column>

          <Column width="46" padding="0">
            <ButtonPrimary
              type={2}
              text="Guardar"
              htmlType="submit"
              margin="auto"
              fullWidth
            />
          </Column>

        </FullFlexCont>

      </ContactForm>
    </>
  );
};

export default DigitalToEdit;
