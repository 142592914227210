import React, { useState } from 'react';
import styled from 'styled-components/macro';

import {
  ButtonPrimary, Icons,
} from '../common';
import FadeIn from '../../hooks/fadeIn';

const SearchContainer = styled.div`
  margin: 0 auto 3rem;
  padding: 8rem 2rem;
  text-align: center;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: ${({ theme }) => theme.fontSizes.titleSmall};
  text-align: center;
`;

const BlueDivider = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.themeTertiary};
  margin: 2rem 0;
`;

const CloseCross = styled.div`
  position: absolute;
  right: 2rem;
  top: -6rem;

  &:hover {
    cursor: pointer;
  }
`;

const Textdepartments = styled.div`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: ${({ theme }) => theme.fontSizes.titleXsmall};
  text-align: center;
  margin-left:30px;
`;

const DepartmentBlock = styled.div`
  height:6rem;
`;

const PointInfoWrapper = styled.div`
position:relative;
  display: flex;
  justify-content:start;
  margin-left:60px;
`;

const DepartmentsWrapper = styled.div`
  // overflow:scroll;
  overflow: auto;
  height:30rem;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
   flex-grow:1;
  }
`;

const CheckBluePoint = styled.div`
position:absolute;
border-radius: 100%;
height: 1.25rem;
width: 1.25rem;
background-color: ${(props) => props.theme.colors.themePrimary};
`;

const Step3DepartmentMobile = (props) => {
  const {
    setShowDepartments, allDepartments, formData, onFormDataChange
  } = props;
  const { selectedDepartments = [] } = formData;

  const onClose = () => {
    setShowDepartments(false);
  };

  const addDepartment = (department) => {
    const newDepartments = [...selectedDepartments];
    const index = selectedDepartments.indexOf(department);

    if (index !== -1) {
      // Deseleccionar departamento si ya está seleccionado
      newDepartments.splice(index, 1);
    } else if (selectedDepartments.length < 4) {
      // Agregar departamento si no está seleccionado y el límite no ha sido alcanzado
      newDepartments.push(department);
    }

    onFormDataChange('selectedDepartments', newDepartments);
  };

  const onSave = () => {
    setShowDepartments(false);
  };

  return (
    <SearchContainer>
      <FadeIn translate>
        <CloseCross onClick={onClose} role="button" tabIndex={0} onKeyPress={onClose}>
          <Icons icon="close" iconColor="#4d4d4d" width={3} />
        </CloseCross>
        <Title>
          SELECCIONA UN MÁXIMO
          <br />
          DE 3 DEPARTAMENTOS
        </Title>
        <BlueDivider />
        <DepartmentsWrapper>
          {allDepartments.map((department) => (
            <DepartmentBlock key={department}>
              <PointInfoWrapper>
                {selectedDepartments.includes(department) && <CheckBluePoint />}
                <Textdepartments onClick={() => addDepartment(department)}>{department}</Textdepartments>
              </PointInfoWrapper>
              <BlueDivider />
            </DepartmentBlock>
          ))}
        </DepartmentsWrapper>
        <ButtonPrimary text="Guardar" onClick={onSave} fullWidth />
      </FadeIn>
    </SearchContainer>
  );
};

export default Step3DepartmentMobile;
