import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


import {
  AlertErrorIcon, AlertInfoIcon, AlertSuccessIcon, AlertWarningIcon, CloseIcon,
} from './AlertIcons';


const AlertContainer = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  padding: ${({ size }) => (size === 'large' ? '12px 12px 24px' : '12px')};
  border: ${({ borderColor }) => `1px solid ${borderColor}`}; 
  background-color: ${({ bg }) => bg}; 
  border-radius: 4px;
`;

const IconWrapper = styled.div`
  width: ${({ size }) => (size === 'large' ? '24px' : '16px')};
  margin-right: 16px;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  display: block;
`;

const Text = styled.span`
  font-size: 14px;
  margin-right: 16px;
`;

const Button = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`;

const colorDictionary = {
  alertError: '#f05454',
  alertInfo: '#39a0ff',
  alertSuccess: '#89dc6d',
  alertWarning: '#fce96d',
};

const bgDictionary = {
  alertError: '#fff8f8',
  alertInfo: '#f2f9ff',
  alertSuccess: '#f7fff5',
  alertWarning: '#fffffb',
};

const iconDictionary = {
  alertError: AlertErrorIcon,
  alertInfo: AlertInfoIcon,
  alertSuccess: AlertSuccessIcon,
  alertWarning: AlertWarningIcon,
};


const Alert = (props) => {
  const {
    title, text, variant: type, size, noCross, isOpen, setIsOpen,
  } = props;
  const IconAlert = iconDictionary[type];
  const borderColor = colorDictionary[type];
  const bg = bgDictionary[type];

  return (
    <AlertContainer
      role="alert"
      isOpen={isOpen}
      size={size}
      bg={bg}
      borderColor={borderColor}
    >
      <IconWrapper size={size}>
        <IconAlert
          name={type}
          width={size === 'large' ? 24 : 16}
          color={colorDictionary[type]}
        />
      </IconWrapper>

      <ContentWrapper>
        { title && <Title>{title}</Title>}
        { text && <Text variant="body">{text}</Text>}
      </ContentWrapper>

      <div>
        {
          noCross ? '' : (
            <Button
              type="button"
              data-dismiss="alert"
              aria-label="Close"
              onClick={() => setIsOpen(false)}
            >
              <CloseIcon
                name="close"
                width={size === 'large' ? 12 : 10}
                color="#a6acb2"
              />
            </Button>
          )
        }

      </div>
    </AlertContainer>
  );
};

Alert.propTypes = {
  isOpen: PropTypes.bool,
  noCross: PropTypes.bool,
  setIsOpen: PropTypes.func,
  size: PropTypes.oneOf(['large', 'short']),
  text: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.oneOf(['alertError', 'alertInfo', 'alertSuccess', 'alertWarning']),
};

Alert.defaultProps = {
  isOpen: true,
  noCross: false,
  setIsOpen: () => {},
  size: 'large',
  text: '',
  title: '',
  variant: 'alertInfo',
};

export default Alert;
