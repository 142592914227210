import styled from 'styled-components/macro';


export const PageSubMenu = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  display: flex;
  border: 0.2rem solid ${(props) => props.theme.colors.greyDark};
  border-radius: 3rem;
  overflow: hidden;
  width: 100%;
`;

export const PageSubMenuItem = styled.div`
  text-align: center;
  flex-grow: 1;
  color: ${({ theme, active }) => (active ? theme.colors.textInverse : theme.colors.textPrimary)};
  background-color: ${({ theme, active }) => (active ? theme.colors.themePrimary : theme.colors.textInverse)};
  font-weight: ${({ active }) => (active ? 700 : 400)};
  padding: 1.4rem;
  cursor: ${({ active }) => (active ? 'initial' : 'pointer')};
  transition: background-color 0.1s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.themePrimary};
    color: ${({ theme }) => theme.colors.textInverse};
    font-weight: 700;

    svg {
      fill: ${({ theme }) => theme.colors.textInverse};
    }
  }

  @media (max-width: 400px) {
    padding: 1.4rem 0.5rem;
  }
`;

export const VSeparator = styled.div`
  width: 0.15rem;
  background-color: ${(props) => props.theme.colors.greyDark};
`;
