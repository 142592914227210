import React from 'react';
import _get from 'lodash/get';
import { useForm, Controller } from 'react-hook-form';

import ButtonPrimary from '../../../common/Buttons/ButtonPrimary';
import ButtonSecondary from '../../../common/Buttons/ButtonSecondary';
import { showOptions } from '../../helpers';
import useListPublicEntity from '../../../../hooks/useListPublicEntity';

import { DatePicker } from '../../../common';
import {
  Input,
  Label,
  InputColumn,
  ContactForm,
  Error,
  CardContentWrapper,
  WhiteSpace,
  FullFlexCont,
  TextArea,
  Column,
  Select,
} from '../../formStyledComponents';

const NewExperience = ({
  addExperience,
  setSelectedExperience,
  littleDevice,
  isAddModal,
  setSelectedCvAdd,
  profile,
  isEditModal,
}) => {
  const { register, handleSubmit, errors, watch, control, setValue } = useForm();
  const watchFields = watch(['iniDate', 'endDate']);

  const { data: residences } = useListPublicEntity('residence');

  const onSubmit = (data, e) => {
    e.preventDefault();
    const workExperience = _get(profile, 'workExperience', []);

    if (workExperience.length < 5) {
      addExperience(data);
    } else {
      window.alert('Puedes añadir un máximo de 5 experiencias a esta sección');
    }
  };

  /* handle errors  */
  const companyError = errors.company && <Error>Por favor, indica el nombre de la empresa</Error>;
  const jobError = errors.jobName && <Error>Por favor, indica el título o cargo</Error>;
  const cityError = errors.city && <Error>Debes indicar la ciudad</Error>;
  const countryError = errors.workExperienceCountryId && <Error>Debes indicar el País</Error>;
  const iniDateError = errors.iniDate && !watchFields.iniDate && <Error>Por favor, indica la fecha de inicio</Error>;
  const endDateError = errors.endDate && !watchFields.endDate && <Error>Por favor, indica la fecha de finalización</Error>;


  return (
    <CardContentWrapper mobilePadding="0" padding={isAddModal || isEditModal && '0'}>
      <ContactForm onSubmit={handleSubmit(onSubmit)}>

        <FullFlexCont padding="0" between mobilePadding="0">
          <InputColumn margin="0" width="40%" alignCenter={littleDevice}>
            <Label>Empresa:</Label>
            <Input
              width="100%"
              name="company"
              placeholder="empresa"
              ref={register({ required: true })}
              mobileWidth="80%"
              mobileMargin="0"
            />
            {companyError}
          </InputColumn>

          <InputColumn margin="0" width="40%" alignCenter={littleDevice}>
            <Label>Puesto de trabajo:</Label>
            <Input
              width="100%"
              name="jobName"
              placeholder="puesto de trabajo"
              ref={register({ required: true })}
              mobileWidth="80%"
              mobileMargin="0"
            />
            {jobError}
          </InputColumn>
        </FullFlexCont>

        <FullFlexCont padding="0" between mobilePadding="0">
          <InputColumn margin="0" width="40%" alignCenter={littleDevice}>
            <Label>Fecha de inicio:</Label>
            <Controller
              name="iniDate"
              control={control}
              rules={{ required: true }}
              render={() => (
                <DatePicker
                  name="iniDate"
                  setValue={setValue}
                  value={watchFields.iniDate}
                  onValueChange={(value) => setValue('iniDate', value)}
                />
              )}
            />
            {iniDateError}
          </InputColumn>

          <InputColumn margin="0" width="40%" alignCenter={littleDevice}>
            <Label>Finalización:</Label>
            <Controller
              name="endDate"
              control={control}
              rules={{ required: true }}
              render={() => (
                <DatePicker
                  name="endDate"
                  setValue={setValue}
                  value={watchFields.endDate}
                  onValueChange={(value) => setValue('endDate', value)}
                />
              )}
            />
            {endDateError}
          </InputColumn>
        </FullFlexCont>

        <FullFlexCont padding="0" between mobilePadding="0">
          <InputColumn margin="0" width="40%" alignCenter={littleDevice}>
            <Label>País:</Label>
            <Select
              name="workExperienceCountryId"
              ref={register({ required: true })}
              mobileWidth="80%"
              mobileMargin="0"
            >
              {showOptions(residences, '', 'nombre país')}
            </Select>
            {countryError}
          </InputColumn>

          <InputColumn margin="0" width="40%" alignCenter={littleDevice}>
            <Label>Ciudad:</Label>
            <Input
              name="city"
              placeholder="ciudad"
              ref={register({ required: true })}
              mobileWidth="80%"
              mobileMargin="0"
            />
            {cityError}
          </InputColumn>
        </FullFlexCont>

        <FullFlexCont padding="0" mobilePadding="0" mobileJustifyCenter column alignCenter>
          <Label>Funciones:</Label>
          <TextArea
            name="desc"
            placeholder="descripción de las funciones realizadas"
            ref={register({ required: false })}
            rows="4"
            maxLength="200"
            mBottom="0"
            mobileWidth="80%"
          />
        </FullFlexCont>

        {!littleDevice && (
          <>
            <WhiteSpace height="4rem" />

            <ButtonPrimary
              iconColor="#105285"
              type={2}
              text="Guardar datos"
              htmlType="submit"
              margin="0 0 0 auto"
            />
          </>
        )}

        {littleDevice && (
          <FullFlexCont justify="space-between">

            <WhiteSpace height="1rem" />

            <Column width="46" padding="0">
              <ButtonSecondary
                text="Atrás"
                onClick={!isAddModal ? () => setSelectedExperience(null) : () => setSelectedCvAdd(null)}
                margin="auto"
                fullWidth
              />
            </Column>

            <Column width="46" padding="0">
              <ButtonPrimary
                type={2}
                text="Guardar"
                htmlType="submit"
                margin="auto"
                fullWidth
              />
            </Column>

          </FullFlexCont>
        )}

      </ContactForm>
    </CardContentWrapper>
  );
};

export default NewExperience;
