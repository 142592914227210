import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';


const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const Image = (props) => {
  const { url } = props;

  return (
    <Img src={encodeURI(url)} />
  );
};

Image.propTypes = {
  url: PropTypes.string,
};

Image.defaultProps = {
  url: '',
};

export default Image;
