import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import _find from 'lodash/find';

import { useSelector } from 'react-redux';
import {
  Vacancy, VacancyResult, Filters, MobileSearch,
} from '../components/vacancies';
import LoggedLayout from '../components/layout/LoggedLayout';
import { listPublicEntity } from '../services/api/data';
import localStorage from '../services/localStorage';
import { Modal } from '../components/common';
import FullProfileModal from '../components/vacancies/FullProfileModal';

import { onSearch, onFilter } from '../components/vacancies/helpers';


const sortByCompanyAndDepartment = (a, b) => {
  if (a.company < b.company) return -1;
  if (a.company > b.company) return 1;
  if (a.department < b.department) return -1;
  if (a.department > b.department) return 1;
  return 0;
};

const VacancyFinder = () => {
  const history = useHistory();
  const { student } = useSelector((state) => state);
  const departmentsLooking = student.internship?.departmentsLooking || [];

  const initialPage = window.sessionStorage.getItem('activePageVacancies') || 'Búsqueda';
  const [activeSection, setActiveSection] = useState(initialPage);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [vacancies, setVacancies] = useState([]);
  const [featuredVacancies, setFeaturedVacancies] = useState([]);
  const [page, setPage] = useState(1);
  const [filteredList, setFilteredList] = useState([]);
  const [vacanciesToShow, setVacanciesToShow] = useState([]);
  const [vacancyId, setVacancyId] = useState('');
  const [vacancy, setVacancy] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({ departments: departmentsLooking, city: '', datesOpen: { dateIni: '', dateEnd: '' } });
  const [search, setSearch] = useState('');
  const [mobileSearch, setMobileSearch] = useState(false);
  const [total, setTotal] = useState('');

  useEffect(() => {
    window.sessionStorage.setItem('activePageVacancies', activeSection);
  }, [activeSection]);

  // student state => coming from the back
  const hasFullProfile = true;
  // show / hide profile-not-100%-modal
  const [fullProfileModalVisible, setFullProfileModalVisible] = useState(!hasFullProfile);

  const defaultFilters = { departments: [], city: '', datesOpen: { dateIni: '', dateEnd: '' } };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const { success, message, data } = await listPublicEntity('vacancy');
      if (success && data) {
        const sortedData = data.sort(sortByCompanyAndDepartment);
        setVacancies(sortedData);
        setFeaturedVacancies(sortedData.filter((el) => el.featured));
        console.log('Vacancies loaded:', sortedData); // Log after loading data
      } else {
        setError(message);
      }
      setLoading(false);
    };
    fetchData();

    if (!departmentsLooking.length) {
      const storedFilters = localStorage.getFilters();
      if (storedFilters) setFilters(storedFilters);
    }
  }, [departmentsLooking]);

  
  const onSetVacanciesToShow = () => {
    if (filteredList.length <= 0) {
      console.log('No filtered results found.');
      setVacanciesToShow([]); // Establece la lista de resultados mostrados como vacía
      setTotal(0); // Ajusta el total a 0
      return;
    }

    const pageItems = page === 1 ? 5 : 6 * (page - 1) + 5;
    const newItems = filteredList.sort((a, b) => b.featured - a.featured).slice(0, pageItems);
    setVacanciesToShow(newItems);
    console.log('Vacancies to show:', newItems); // Log the vacancies that will be shown

    // Ajustar el total restante
    const remainingItems = Math.max(0, filteredList.length - newItems.length);
    setTotal(remainingItems);
    console.log('Remaining items:', remainingItems); // Log the remaining items
  };

  useEffect(() => {
    onSetVacanciesToShow();
  }, [page, filteredList]);

  useEffect(() => {
    if (!vacancyId) return;
    const newItem = _find(vacancies, { id: vacancyId });
    setVacancy(newItem);
    console.log('Vacancy selected:', newItem); // Log the selected vacancy
  }, [vacancyId, vacancies]);

  const goToProfile = () => {
    history.push('/profile');
  };

  const onChangePage = () => {
    if (lastPage) return; // Evitar cargar más si ya estamos en la última página
  
    setPage((prevPage) => {
      const newPage = prevPage + 1;
      console.log('Changing to page:', newPage); // Log the page change
      onSetVacanciesToShow(newPage);
      return newPage;
    });
  };

  const handleOnSearch = (localSearch) => {
    console.log('Search term:', localSearch); // Log the search term
    onSearch({ localSearch, vacancies, search, setFilteredList, setFilters, defaultFilters, setPage });
  };

  const handleOnFilter = (newFilters) => {
    console.log('Applying filters:', newFilters); // Log the filters being applied
    onFilter({ newFilters, vacancies, search, setFilteredList, setPage, setFilters });
  };

  useEffect(() => {
    handleOnFilter(filters);
    if (filters?.departments?.length || filters?.city || filters?.datesOpen?.dateIni || filters?.datesOpen?.dateEnd) {
      localStorage.addFilters(JSON.stringify(filters));
      localStorage.removeSearch();
      setSearch('');
    }
  }, [filters, vacancies]);

  useEffect(() => {
    if (vacancies.length <= 0) return;
    const localSearch = localStorage.getSearch();
    if (localSearch) {
      setSearch(localSearch);
      handleOnSearch(localSearch);
    }
  }, [vacancies]);

  const lastPage = vacanciesToShow.length >= filteredList.length;

  if (vacancyId) {
    return (
      <Vacancy
        key={vacancyId}
        identifier={vacancyId}
        item={vacancy}
        setVacancyId={setVacancyId}
        allVacancies={vacancies}
        hasFullProfile={hasFullProfile}
        setFullProfileModalVisible={setFullProfileModalVisible}
        fullProfileModalVisible={fullProfileModalVisible}
      />
    );
  }

  if (showFilters) {
    return (
      <Filters
        setFilter={setShowFilters}
        onFilter={handleOnFilter}
        filters={filters}
        setFilters={setFilters}
      />
    );
  }

  if (mobileSearch) {
    return (
      <MobileSearch
        search={search}
        setSearch={setSearch}
        onSearch={handleOnSearch}
        setMobileSearch={setMobileSearch}
      />
    );
  }

  return (
    <LoggedLayout>
      <VacancyResult
        setActiveSection={setActiveSection}
        activeSection={activeSection}
        setFilter={setShowFilters}
        filters={filters}
        search={search}
        setSearch={setSearch}
        onSearch={handleOnSearch}
        setMobileSearch={setMobileSearch}
        items={vacanciesToShow}
        featuredVacancies={featuredVacancies}
        hasFullProfile={hasFullProfile}
        goToProfile={goToProfile}
        onChangePage={onChangePage}
        setVacancyId={setVacancyId}
        lastPage={lastPage}
        loading={loading}
        total={total}
        setFullProfileModalVisible={setFullProfileModalVisible}
      />
      {fullProfileModalVisible && (
        <Modal onClose={() => setFullProfileModalVisible(false)}>
          <FullProfileModal />
        </Modal>
      )}
    </LoggedLayout>
  );
};

export default VacancyFinder;
