import React from 'react';
import styled from 'styled-components/macro';
import { system } from 'styled-system';


const Icon = styled('svg')(
  system({
    fill: {
      property: 'fill',
      scale: 'colors',
    },
    stroke: {
      property: 'stroke',
      scale: 'colors',
    },
  }),
);


const Icons = ({
  icon, height = '100%', width = '100%', disabled, iconColor, strokeColor, large = false,
}) => {
  let iconCode = '';
  switch (icon) {
    case 'trash':
      iconCode = <path fill={iconColor} fillRule="evenodd" d="M28.4 13.737V33.42H11.6V13.737h16.8zM25 3H15l-2 1.79H6v3.578h28V4.79h-7L25 3zm7.133 7.158H7.867v23.007c0 2.11 1.82 3.835 4.044 3.835H28.09c2.224 0 4.044-1.726 4.044-3.835V10.158z" />;
      break;

    case 'edit':
      iconCode = (
        <g fill="none" fillRule="evenodd">
          <path fill={iconColor} fillRule="nonzero" d="M27.448 13.09V36.54H4V13.09h23.448zm-1.6 1.6H5.6v20.248h20.248V14.69z" />
          <path fill={iconColor} d="M34.223 3.461L15.785 21.9l-1.459 4.775 4.775-1.459L37.54 6.777l-3.316-3.316z" />
        </g>
      );
      break;

    case 'deal':
      iconCode = <path d="M51.864 8.658l-4.388-4.215C46.504 3.52 45.192 3 43.825 3H22.917c-1.365 0-2.683.522-3.65 1.443l-4.389 4.215H4v22.64h5.9c1.623 0 2.932-1.257 2.94-2.807h.84l7.799 6.765c2.848 2.222 6.803 2.275 9.735.336C32.366 36.548 33.61 37 35.003 37c1.677 0 3.254-.655 4.498-2.125 2.038.77 4.444.23 5.9-1.487l2.416-2.86c.516-.611.839-1.31 1.005-2.037h5.337c.01 1.55 1.328 2.807 2.941 2.807H63V8.658H51.864zm-8.232 19.385l-2.397 2.921c-.257.309-.716.363-1.037.11l-2.194-1.76-2.755 3.31c-.55.663-1.377.436-1.652.218l-3.379-2.858-1.432 1.742c-1.276 1.552-3.599 1.788-5.077.6l-8.933-7.984h-2.543V12.72h3.846l5.665-5.588.523-.209h5.206l-3.553 3.22c-2.7 2.441-2.855 6.56-.404 9.19 1.359 1.47 5.619 3.739 9.319.4l.753-.68 9.934 7.965c.312.254.358.717.11 1.025zm11.135-4.12h-6.416c-.213-.248-.455-.478-.714-.682l-9.523-7.388 1.159-1.01c.603-.532.649-1.426.093-2.002l-1.011-1.037c-.556-.576-1.493-.611-2.096-.088l-5.118 4.482c-.881.77-2.383.833-3.209 0-.862-.877-.788-2.223.112-3.003l6.083-5.324c.686-.602 1.576-.93 2.503-.93l7.761-.018c.195 0 .38.07.51.204l5.722 5.457h4.144v11.34zm4.117 3.923c-.755 0-1.372-.588-1.372-1.308 0-.719.617-1.307 1.372-1.307.754 0 1.372.588 1.372 1.307 0 .728-.618 1.308-1.372 1.308z" />;
      break;

    case 'user':
      iconCode = (
        <g fillRule="evenodd">
          <circle cx="20.377" cy="12.643" r="7.643" />
          <path d="M26 24c5.944 0 11 2 11 4.45v5.548c0 .552-.448 1-1 1h-.002l-31-.078c-.552 0-.998-.448-.998-1v-5.553C4 25.734 10.32 24 15 24h.146c1.285 1.34 3.153 2.184 5.231 2.184s3.946-.844 5.23-2.183L26 24z" />
        </g>
      );
      break;

    case 'avatar':
      iconCode = (
        <g fill="none" fillRule="evenodd">
          <circle cx="20" cy="20" r="17" fill="#A4B3B7" />
          <circle cx="20" cy="14.632" r="4.474" fill="#FFF" />
          <path fill="#FFF" d="M20 22.326c1.24 0 2.357-.545 3.128-1.414 1.78.164 6.714 1.505 6.714 2.846v4.295H10.158v-4.295c1.875-1.909 3.75-2.863 5.624-2.863h1.075c.772.879 1.894 1.431 3.143 1.431z" />
        </g>
      );
      break;

    case 'heart':
      iconCode = <path fillRule="evenodd" d="M20 9.222c-2.814-3.175-8.028-4.485-12.25-1.695-2.24 1.48-3.647 3.977-3.743 6.612-.224 5.98 5.278 10.774 13.674 18.127l.16.138c1.216 1.064 3.087 1.064 4.303-.015l.176-.154c8.396-7.337 13.882-12.13 13.674-18.111-.096-2.62-1.503-5.117-3.742-6.597C28.029 4.753 22.815 6.047 20 9.222z" />;
      break;

    case 'heart-shape':
      iconCode = <path fillRule="evenodd" strokeWidth="2" d="M20 9.222c-2.814-3.175-8.028-4.485-12.25-1.695-2.24 1.48-3.647 3.977-3.743 6.612-.224 5.98 5.278 10.774 13.674 18.127l.16.138c1.216 1.064 3.087 1.064 4.303-.015l.176-.154c8.396-7.337 13.882-12.13 13.674-18.111-.096-2.62-1.503-5.117-3.742-6.597C28.029 4.753 22.815 6.047 20 9.222z" />;
      break;

    case 'chevronRight':
      iconCode = <path d="M27.5 6.5L27.5 32 24.5 32 24.5 9.5 2 9.5 2 6.5z" transform="rotate(45 14.75 19.25)" />;
      break;

    case 'chevronLeft':
      iconCode = <path d="M39.5 6.5L39.5 32 36.5 32 36.5 9.5 14 9.5 14 6.5z" transform="rotate(-135 26.75 19.25)" />;
      break;

    case 'chevronUp':
      iconCode = <path fill={iconColor} width={width} fillRule="evenodd" d="M33.5 13.5L33.5 39 30.5 39 30.5 16.5 8 16.5 8 13.5z" transform="rotate(-45 20.75 26.25)" />;
      break;

    case 'chevronDown':
      iconCode = <path d="M33.5 -0.5L33.5 25 30.5 25 30.5 2.5 8 2.5 8 -0.5z" transform="rotate(135 20.75 12.25)" />;
      break;

    case 'bell':
      iconCode = (
        <g fillRule="evenodd">
          <path fillRule="nonzero" d="M20.167 7.167c-6.306 0-11.417 5.111-11.417 11.416V30h22.833V18.583c0-6.305-5.111-11.416-11.416-11.416zm.252 3.003c4.532.134 8.164 3.85 8.164 8.413V27H11.75v-8.416c0-4.648 3.768-8.416 8.417-8.416l.252.003z" />
          <path d="M6 27.083H34.333V31.333H6zM15.917 32.75H24.417V37H15.917z" />
          <path fillRule="nonzero" d="M21.667 3L21.667 8.667 18.667 8.667 18.667 3z" />
        </g>
      );
      break;

    case 'location':
      iconCode = (
        <g fillRule="evenodd">
          <path d="M20 37c10-7.852 15-14.413 15-19.684C35 9.409 28.284 3 20 3 11.716 3 5 9.41 5 17.316 5 22.586 10 29.148 20 37z" />
          <circle cx="20" cy="16.947" r="5" fill="#FFF" />
        </g>
      );
      break;

    case 'burger':
      iconCode = (
        <g>
          <path d="M37.15 8.308L37.15 10.308 2.85 10.308 2.85 8.308zM37.15 18.308L37.15 20.308 2.85 20.308 2.85 18.308zM37.15 28.308L37.15 30.308 2.85 30.308 2.85 28.308z" />
        </g>
      );
      break;

    case 'alert':
      iconCode = (
        <g fillRule="evenodd" transform="translate(3 3)">
          <circle cx="17" cy="17" r="17" />
          <path fill="#FFF" fillRule="nonzero" d="M18.48 20.71L18.9 7h-3.813l.42 13.71h2.973zM16.994 27c.602 0 1.088-.195 1.455-.584.367-.39.551-.883.551-1.481 0-.607-.184-1.105-.551-1.495-.367-.39-.853-.584-1.455-.584-.595 0-1.076.197-1.443.591-.367.394-.551.89-.551 1.488s.184 1.091.551 1.48c.367.39.848.585 1.443.585z" />
        </g>
      );
      break;

    case 'clip':
      iconCode = (
        <g fillRule="evenodd">
          <path fillRule="nonzero" d="M20.13 28.727c-1.911 0-3.467-1.515-3.565-3.414l-.005-.191h2.06c0 .856.68 1.545 1.51 1.545.785 0 1.435-.614 1.505-1.404l.006-.141h2.06c0 1.988-1.595 3.605-3.57 3.605zM22.671 1.97c3.304 0 5.99 2.649 6.108 5.955l.004.227h-2.06c0-2.28-1.817-4.122-4.052-4.122-2.167 0-3.94 1.732-4.046 3.916l-.005.206h-2.06c0-3.411 2.733-6.182 6.111-6.182zM20.13 37.485c-4.694 0-8.51-3.783-8.648-8.495l-.004-.262h2.06c0 3.702 2.955 6.697 6.592 6.697 3.56 0 6.467-2.87 6.589-6.462l.004-.235h2.06c0 4.833-3.87 8.757-8.653 8.757z" />
          <path fillRule="nonzero" d="M23.701 9.182L23.701 25.667 21.641 25.667 21.641 9.182zM28.783 8.152L28.783 28.758 26.723 28.758 26.723 8.152zM18.62 8.152L18.62 25.667 16.56 25.667 16.56 8.152z" />
          <path fillRule="nonzero" d="M13.538 17.394L13.538 29.758 11.478 29.758 11.478 17.394z" />
        </g>
      );
      break;

    case 'clip-white':
      iconCode = (
        <g fillRule="evenodd">
          <path fill="#FFF" d="M20.13 28.727c-1.911 0-3.467-1.515-3.565-3.414l-.005-.191h2.06c0 .856.68 1.545 1.51 1.545.785 0 1.435-.614 1.505-1.404l.006-.141h2.06c0 1.988-1.595 3.605-3.57 3.605zM22.671 1.97c3.304 0 5.99 2.649 6.108 5.955l.004.227h-2.06c0-2.28-1.817-4.122-4.052-4.122-2.167 0-3.94 1.732-4.046 3.916l-.005.206h-2.06c0-3.411 2.733-6.182 6.111-6.182zM20.13 37.485c-4.694 0-8.51-3.783-8.648-8.495l-.004-.262h2.06c0 3.702 2.955 6.697 6.592 6.697 3.56 0 6.467-2.87 6.589-6.462l.004-.235h2.06c0 4.833-3.87 8.757-8.653 8.757z" />
          <path fill="#FFF" d="M23.701 9.182L23.701 25.667 21.641 25.667 21.641 9.182zM28.783 8.152L28.783 28.758 26.723 28.758 26.723 8.152zM18.62 8.152L18.62 25.667 16.56 25.667 16.56 8.152z" />
          <path fill="#FFF" d="M13.538 17.394L13.538 29.758 11.478 29.758 11.478 17.394z" />
        </g>
      );
      break;

    case 'clip-dark-blue':
      iconCode = (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
          <g fill="none" fillRule="evenodd" stroke="#105285" strokeWidth="2.06">
            <path d="M17.59 25.152c0 1.422 1.137 2.575 2.54 2.575 1.404 0 2.541-1.153 2.541-2.575M27.753 8.152C27.753 5.306 25.478 3 22.67 3c-2.806 0-5.081 2.306-5.081 5.152M12.508 28.758c0 4.267 3.413 7.727 7.622 7.727 4.21 0 7.623-3.46 7.623-7.727M22.671 9.182L22.671 25.667M27.753 8.152L27.753 28.758M17.59 8.152L17.59 25.667M12.508 16.394L12.508 28.758" />
          </g>
        </svg>
      );
      break;

    case 'close':
      iconCode = (
        <g>
          <path d="M35.834 2.197L37.638 4 4 37.638 2.197 35.834z" />
          <path d="M2.197 4.014L4 2.211 37.638 35.849 35.834 37.652z" />
        </g>
      );
      break;

    case 'close-white':
      iconCode = (
        <g>
          <path fill="#FFF" d="M35.834 2.197L37.638 4 4 37.638 2.197 35.834z" />
          <path fill="#FFF" d="M2.197 4.014L4 2.211 37.638 35.849 35.834 37.652z" />
        </g>
      );
      break;

    case 'search':
      iconCode = (
        <g>
          <path d="M16.867 4c7.658 0 13.866 6.208 13.866 13.867 0 7.658-6.208 13.866-13.866 13.866C9.208 31.733 3 25.525 3 17.867 3 10.208 9.208 4 16.867 4zm0 2.5C10.589 6.5 5.5 11.589 5.5 17.867c0 6.277 5.089 11.366 11.367 11.366 6.277 0 11.366-5.089 11.366-11.366 0-6.278-5.089-11.367-11.366-11.367z" />
          <path d="M23.994 27.822L26.822 24.994 36.584 34.756 33.756 37.584z" />
        </g>
      );
      break;

    case 'card':
      iconCode = (
        <g fillRule="evenodd">
          <path fillRule="nonzero" d="M47 7c1.105 0 2 .895 2 2v23c0 1.105-.895 2-2 2H8c-1.105 0-2-.895-2-2V9c0-1.105.895-2 2-2h39zm0 1.6H8c-.193 0-.355.137-.392.32L7.6 9v23c0 .193.137.355.32.392L8 32.4h39c.193 0 .355-.137.392-.32L47.4 32V9c0-.193-.137-.355-.32-.392L47 8.6z" />
          <path d="M6 12H49V18H6zM11 21H28V24H11z" />
        </g>
      );
      break;

    case 'bank':
      iconCode = (
        <g fillRule="evenodd">
          <path fillRule="nonzero" d="M18.95 2.276l-14.28 13.6c-.653.622-.213 1.724.69 1.724h28.56c.903 0 1.343-1.102.69-1.724l-14.28-13.6c-.387-.368-.993-.368-1.38 0zm.69 2.105L31.42 15.6H7.859L19.64 4.381z" />
          <rect width="31.28" height="2.72" x="4" y="34.28" rx="1" />
          <rect width="2.72" height="16.32" x="8.08" y="19.32" rx="1" />
          <rect width="2.72" height="16.32" x="14.88" y="19.32" rx="1" />
          <rect width="2.72" height="16.32" x="21.68" y="19.32" rx="1" />
          <rect width="2.72" height="16.32" x="28.48" y="19.32" rx="1" />
        </g>
      );
      break;

    case 'transfer':
      iconCode = (
        <g fillRule="evenodd">
          <path fillRule="nonzero" d="M50 6c.552 0 1 .448 1 1v26c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1V7c0-.552.448-1 1-1h46zm-.6 1.599H4.6v24.8h44.8v-24.8z" />
          <path d="M9 12H46V15H9zM9 17H31V20H9zM9 22H25V25H9zM34 26H46V29H34z" />
        </g>
      );
      break;

    case 'practice':
      iconCode = (
        <g fillRule="evenodd">
          <circle cx="17.331" cy="20.474" r="3.405" strokeWidth="1.66" />
          <path strokeLinejoin="round" strokeWidth="1.66" d="M17.33 27.77c.92 0 1.748-.37 2.32-.96 1.32.11 4.977 1.022 4.977 1.933v2.918h0-14.593v-2.918c1.39-1.297 2.78-1.946 4.17-1.946H15c.572.597 1.405.973 2.33.973z" />
          <path fillRule="nonzero" d="M51.069 9v28.138H3V9h48.069zm-2.417 2.416H5.416v23.305h43.236V11.416z" />
          <path fillRule="nonzero" d="M30.62 3c.419 0 .76.34.76.76v5.514c0 .42-.341.76-.76.76h-7.86c-.42 0-.76-.34-.76-.76V3.76c0-.42.34-.76.76-.76h7.86zm-.898 1.656h-6.066v3.721h6.066V4.656zM43.644 15.654c.457 0 .828.371.828.829 0 .422-.316.77-.724.822l-.104.006H30.356c-.457 0-.828-.37-.828-.828 0-.423.316-.771.724-.822l.104-.007h13.288zM43.644 20.344c.457 0 .828.37.828.828 0 .423-.316.771-.724.822l-.104.007H30.356c-.457 0-.828-.371-.828-.829 0-.422.316-.77.724-.822l.104-.006h13.288zM43.644 25.034c.457 0 .828.37.828.828 0 .422-.316.77-.724.822l-.104.006H30.356c-.457 0-.828-.37-.828-.828 0-.422.316-.77.724-.822l.104-.006h13.288z" />
        </g>
      );
      break;

    case 'add':
      iconCode = (
        <g>
          <path d="M37.194 18.684L37.194 21.234 2.725 21.234 2.725 18.684z" />
          <path d="M21.234 2.725L21.234 37.194 18.684 37.194 18.684 2.725z" />
        </g>
      );
      break;

    case 'check-blue':
      iconCode = (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
          <path fillRule="nonzero" d="M36.036 8.033L37.964 10.331 13.739 30.661 2.949 20.071 5.051 17.929 13.896 26.611z" />
        </svg>
      );
      break;

    case 'check-white':
      iconCode = (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
          <path fill="#FFF" d="M36.036 8.033L37.964 10.331 13.739 30.661 2.949 20.071 5.051 17.929 13.896 26.611z" />
        </svg>
      );
      break;

    case 'review-white':
      iconCode = (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
          <g fill="#FFF" fillRule="evenodd">
            <path fillRule="nonzero" d="M17.044 4.178c7.659 0 13.867 6.208 13.867 13.866 0 7.659-6.208 13.867-13.867 13.867-7.658 0-13.866-6.208-13.866-13.867 0-7.658 6.208-13.866 13.866-13.866zm0 2.5c-6.277 0-11.366 5.089-11.366 11.366 0 6.278 5.089 11.367 11.366 11.367 6.278 0 11.367-5.089 11.367-11.367 0-6.277-5.089-11.366-11.367-11.366z" />
            <circle cx="17.044" cy="18.044" r="7.467" />
            <path fillRule="nonzero" d="M24.172 28L27 25.172 36.762 34.933 33.933 37.762z" />
          </g>
        </svg>
      );
      break;

    case 'bloqueo-white':
      iconCode = (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
          <g fill="#FFF" fillRule="evenodd">
            <path fillRule="nonzero" d="M33 15.526V37H7V15.526h26zm-2 2H9V35h22V17.526z" />
            <path fillRule="nonzero" d="M19.579 3c5.523 0 10 4.477 10 10v4h-20v-4c0-5.523 4.477-10 10-10zm0 2c-4.335 0-7.865 3.448-7.996 7.75l-.004.25-.001 2h16v-2c0-4.335-3.447-7.865-7.75-7.996L19.578 5z" />
            <circle cx="20.316" cy="24.474" r="3.579" />
            <path d="M18.526 26.263H22.105V31.631H18.526z" />
          </g>
        </svg>
      );
      break;

    case 'bloqueo':
      iconCode = (
        <g fillRule="evenodd">
          <path fillRule="nonzero" d="M33 15.526V37H7V15.526h26zm-2 2H9V35h22V17.526z" />
          <path fillRule="nonzero" d="M19.579 3c5.523 0 10 4.477 10 10v4h-20v-4c0-5.523 4.477-10 10-10zm0 2c-4.335 0-7.865 3.448-7.996 7.75l-.004.25-.001 2h16v-2c0-4.335-3.447-7.865-7.75-7.996L19.578 5z" />
          <circle cx="20.316" cy="24.474" r="3.579" />
          <path d="M18.526 26.263H22.105V31.631H18.526z" />
        </g>
      );
      break;

    case 'location-red':
      iconCode = (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
          <g fill="none" fillRule="evenodd">
            <path fill="#F07F5C" d="M20 37c10-7.852 15-14.413 15-19.684C35 9.409 28.284 3 20 3 11.716 3 5 9.41 5 17.316 5 22.586 10 29.148 20 37z" />
            <circle cx="20" cy="16.947" r="5" fill="#FFF" />
          </g>
        </svg>
      );
      break;

    case 'arrow-download':
      iconCode = (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
          <g fill="#105285">
            <path d="M32.333 6.722L32.333 30.389 30.333 30.389 30.333 8.722 8.667 8.722 8.667 6.722z" transform="rotate(135 20.5 18.556)" />
            <path d="M19.029 2.944L21.029 2.945 20.999 35.001 18.999 34.999z" />
          </g>
        </svg>
      );
      break;

    case 'arrow-download-white':
      iconCode = (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
          <g fill="#FFF">
            <path d="M32.333 6.722L32.333 30.389 30.333 30.389 30.333 8.722 8.667 8.722 8.667 6.722z" transform="rotate(135 20.5 18.556)" />
            <path d="M19.029 2.944L21.029 2.945 20.999 35.001 18.999 34.999z" />
          </g>
        </svg>
      );
      break;

    case 'logout':
      iconCode = (
        <svg height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg">
          <g fill="#4798b6" fillRule="evenodd">
            <path d="m18 3h4v17h-4z" />
            <path d="m14.9999123 7.85355954.0004425 4.34567826c-3.5618688 1.8206463-6.0003548 5.5259348-6.0003548 9.8007622 0 6.0751322 4.9248678 11 11 11s11-4.9248678 11-11c0-4.2744289-2.4380314-7.9794251-5.9993588-9.800253l.0004452-4.34583444c5.8253943 2.05948874 9.9989136 7.61539924 9.9989136 14.14608744 0 8.2842712-6.7157288 15-15 15s-15-6.7157288-15-15c0-6.5310614 4.17399624-12.08723366 9.9999123-14.14644046zm5.0000877-.85355954c.6783294 0 1.3461424.04502626 2.0006132.13225305l-.0001976 4.04919955c-.6486825-.1191794-1.3172767-.1814526-2.0004156-.1814526-.6827857 0-1.3510417.0622088-1.9994094.1812678l-.0002021-4.04914822c.6541505-.08713926 1.3216282-.13211958 1.9996115-.13211958z" />
          </g>
        </svg>
      );
      break;

    case 'findVacancy':
      iconCode = (
        <g>
          <path d="M44.718 17.292c1.092-1.397 1.741-3.153 1.741-5.064C46.46 7.683 42.776 4 38.23 4 33.685 4 30 7.683 30 12.228s3.684 8.228 8.23 8.228c1.91 0 3.667-.648 5.064-1.74 3.463 3.755 5.317 5.509 5.563 5.261l1.12-1.12c.247-.247-1.505-2.103-5.259-5.565zm-6.488 0c-2.798 0-5.065-2.263-5.065-5.064 0-2.797 2.263-5.063 5.065-5.063 2.797 0 5.064 2.262 5.064 5.063 0 2.797-2.263 5.064-5.064 5.064zM27 4H3v32h24V4zm-2 2v28H5V6h20z" />
          <path d="M21.5 11.5L21.5 12.5 8.5 12.5 8.5 11.5zM21.5 18.5L21.5 19.5 8.5 19.5 8.5 18.5zM21.5 26.5L21.5 27.5 8.5 27.5 8.5 26.5z" />
        </g>
      );
      break;

    default:
      break;
  }
  const finalHeight = height !== '100%' ? `${height}rem` : '100%';
  const finalWidth = width !== '100%' ? `${width}rem` : '100%';
  return !large
    ? (
      <Icon disabled={disabled} fill={iconColor} stroke={strokeColor} height={finalHeight} width={finalWidth} viewBox="0 0 40 40">
        {iconCode}
      </Icon>
    )
    : (
      <Icon disabled={disabled} fill={iconColor} stroke={strokeColor} height={finalHeight} width={finalWidth} viewBox="0 0 60 40">
        {iconCode}
      </Icon>
    );
};

export default Icons;
