import styled from 'styled-components/macro';


export const FormContainer = styled.form`
  width: 100%;
`;

export const Label = styled.label`
  font-size: 1.8rem;
  font-weight: bold;
  display: inline-block;
  margin: 0.5rem 0;
`;

export const Error = styled.span`
  color: red;
  display: flex;
  padding: 0.5rem;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin: 2rem auto;

  button {
    max-height: 4.5rem;

    &:hover {
      svg {
        path {
          fill: ${({ theme }) => theme.colors.textInverse};
        }
      }
    }
    svg {
      margin-right: 1rem;
    }
  }
`;