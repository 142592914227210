import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 7.8rem;
  margin: 3rem 0;
  border-radius: 6rem;
  background-color: ${(props) => props.theme.colors.greyLight};
  cursor: pointer;
  transition: background-color 0.1s ease-in-out; 

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {}

  &:hover {
    background-color: #DCEEF8;
  }
`;


const ButtonBig = (props) => {
  const { children, onClick } = props;

  return (
    <Container onClick={onClick} onKeyPress={onClick} role="button" tabIndex={0}>
      {children}
    </Container>
  );
};

ButtonBig.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ButtonBig;
