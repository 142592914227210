export const UPDATE_WINDOW_RESIZE = 'UPDATE_WINDOW_RESIZE';


const update = (dispatch, oldStatus) => {
  const { screen } = oldStatus().windowResize;
  const { innerHeight, innerWidth } = window;

  // Added mixins.scss breakpoints
  if (innerWidth < 600 && screen !== 'phone') {
    return dispatch({
      type: UPDATE_WINDOW_RESIZE,
      payload: { screen: 'phone', heightScreen: innerHeight },
    });
  }

  if (innerWidth >= 600 && innerWidth < 900 && screen !== 'tab-port') {
    return dispatch({
      type: UPDATE_WINDOW_RESIZE,
      payload: { screen: 'tab-port', heightScreen: innerHeight },
    });
  }

  if (innerWidth >= 900 && innerWidth < 1200 && screen !== 'tab-land') {
    return dispatch({
      type: UPDATE_WINDOW_RESIZE,
      payload: { screen: 'tab-land', heightScreen: innerHeight },
    });
  }

  if (innerWidth >= 1200 && innerWidth < 1800 && screen !== 'normal') {
    return dispatch({
      type: UPDATE_WINDOW_RESIZE,
      payload: { screen: 'normal', heightScreen: innerHeight },
    });
  }

  if (innerWidth >= 1800 && screen !== 'big-desktop') {
    return dispatch({
      type: UPDATE_WINDOW_RESIZE,
      payload: { screen: 'big-desktop', heightScreen: innerHeight },
    });
  }

  return null;
};


const listener = () => (dispatch, oldStatus) => {
  update(dispatch, oldStatus);

  window.addEventListener('resize', update.bind(this, dispatch, oldStatus));
};


export default { listener };
