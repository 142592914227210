import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';

import Header from '../../Header';


const LayoutContainer = styled.div`
  padding-top: ${(props) => props.theme.sizes.headerHeight};
`;

const Layout = ({ children, isLogin, noMenuLink }) => {
  const userName = useSelector((state) => state.student.name);
  return (
    <div>
      <Header isLogin={isLogin} noMenuLink={noMenuLink} userName={userName}/>
      <LayoutContainer>
        {children}
      </LayoutContainer>
    </div>
  );
};

export default Layout;