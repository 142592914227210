import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import InputCheck from '../common/formFields/Inputs/InputCheck';
import InputText from '../common/formFields/Inputs/InputText';
import ButtonPrimary from '../common/Buttons/ButtonPrimary';
import ButtonSecondary from '../common/Buttons/ButtonSecondary';
import Select from '../common/formFields/Select';
import {
  StepTitle,
  CentralCard,
  CentralCardLeft,
  OnBoardingIllustration,
  CentralRight,
  CentralCardLine,
  SlidesDots,
  SlidesDotsItem,
} from './Styles';
import HSpacer from '../layout/HSpacer';
import VSpacer from '../layout/VSpacer';
import useListPublicEntity from '../../hooks/useListPublicEntity';
import InfoPopUp from '../common/InfoPopUp/InfoPopUp';
import { Error } from '../profile/formStyledComponents';

const Step2Title = styled(StepTitle)`
  position: relative;
  h2{
    font-size:3rem;
    
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    font-size:2.3rem;
    }
  }
`;

const ChecksQuestion = styled.p`
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.colors.greyDark};
`;

const ChecksContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ChecksContainerVertical = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {   
    flex-direction:row;
    align-items:center;
    justify-content:center;
  }
`;

const ChecksContainerItem = styled.div`
  width: 45px;
`;
const ChecksInputContainerItem = styled.div`
  height: ${(props) => (props.hasInput ? '26px' : 'auto')};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


const Step2 = ({
  onStepCompleted,
  onStepBack,
  onDotClick,
  formData,
  onFormDataChange,
}) => {
  const { data: universities } = useListPublicEntity('university');
  const universityOptions = universities
    .filter((u) => !u.country || u.country.id === formData.residence)
    .sort((uniA, uniB) => {
      if (uniA.name === 'OTRAS') return 1;
      if (uniB.name === 'OTRAS') return -1;
      return uniA.name < uniB.name ? -1 : 1;
    });

  const [clicked, setClicked] = useState(false);
  const [errors, setErrors] = useState({});
  const [areErrors, setAreErrors] = useState(false);

  useEffect(() => {
    const errorValues = Object.values(errors);
    const isTrue = (value) => value === true;
    const someError = errorValues.some(isTrue);

    if (!someError) {
      setAreErrors(false);
    } else {
      setAreErrors(true);
    }
  }, [formData, errors]);

  // Set errors
  const onSetErrors = (values) => {
    const { university, finishedStudy } = values;
    if (!finishedStudy) setErrors({ university: !university });
    else setErrors({});
  };

  // Check errors and continue
  const onContinue = (values) => {
    setClicked(true);
    onSetErrors(values);
    if (areErrors) return;
    onStepCompleted();
  };

  /* handle errors  */
  const universityError = clicked && errors.university && (
    <Error>Debes indicar tu universidad</Error>
  );

  useEffect(() => {
    onSetErrors(formData);
  }, [formData]);

  const infoPopUpText = 'Si has terminado tus estudios y hace menos de dos años que has obtenido el título, también puedes aplicar.';

  return (
    <CentralCard>
      <CentralCardLeft>
        <OnBoardingIllustration src="/images/on-boarding/onboarding-step-2.png" />
      </CentralCardLeft>
      <CentralCardLine />
      <CentralRight>
        <Step2Title>
          ¿Has terminado tus estudios?
          <InfoPopUp
            height="auto"
            left="-85px"
            step="2"
            text={infoPopUpText}
            top="-30px"
            width="500px"
          />
        </Step2Title>
        <HSpacer />
        <ChecksContainer>
          <ChecksContainerItem>
            <InputCheck
              text="No"
              checked={!formData.finishedStudy}
              value={false}
              onChecked={(checked) => onFormDataChange('finishedStudy', checked)}
            />
          </ChecksContainerItem>
          <ChecksContainerItem>
            <InputCheck
              text="Sí"
              checked={formData.finishedStudy}
              value
              onChecked={(checked) => onFormDataChange('finishedStudy', checked)}
            />
          </ChecksContainerItem>
        </ChecksContainer>
        {!formData.finishedStudy && (
          <>
            <HSpacer height="30px" />
            <Select
              placeholder="Indica tu universidad"
              options={universityOptions}
              onSelectChange={(value) => onFormDataChange('university', value)}
              value={formData.university}
            />
            {universityError}
            <HSpacer height="30px" />
            <ChecksQuestion>Número de horas requeridas</ChecksQuestion>
            <HSpacer height="10px" />
            <ChecksContainerVertical>
              <ChecksContainerItem>
                <InputCheck
                  text="No"
                  checked={!formData.areRequiredHours}
                  value={false}
                  onChecked={(checked) => onFormDataChange('areRequiredHours', checked)}
                />
              </ChecksContainerItem>
              <ChecksInputContainerItem hasInput>
                <InputCheck
                  text="Sí"
                  checked={formData.areRequiredHours}
                  value
                  onChecked={(checked) => onFormDataChange('areRequiredHours', checked)}
                />
                {formData.areRequiredHours && (
                  <InputText
                    name="requiredHours"
                    onChange={(value) => onFormDataChange('requiredHours', value)}
                    placeholder="horas"
                    value={formData.requiredHours}
                    controlled
                  />
                )}
              </ChecksInputContainerItem>
            </ChecksContainerVertical>
          </>
        )}
        <HSpacer height="40px" />
        <ButtonsContainer>
          <ButtonSecondary text="Atrás" onClick={onStepBack} fullWidth />
          <VSpacer />
          <ButtonPrimary text="Siguiente" onClick={() => onContinue(formData)} fullWidth />
        </ButtonsContainer>
        <SlidesDots>
          <SlidesDotsItem onClick={() => onDotClick(0)} />
          <SlidesDotsItem onClick={() => onDotClick(1)} active />
          <SlidesDotsItem onClick={() => onDotClick(2)} />
          <SlidesDotsItem onClick={() => onDotClick(3)} />
        </SlidesDots>
      </CentralRight>
    </CentralCard>
  );
};

Step2.propTypes = {
  onStepCompleted: PropTypes.func.isRequired,
  onStepBack: PropTypes.func.isRequired,
  onDotClick: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    residence: PropTypes.string,
    finishedStudy: PropTypes.bool,
    university: PropTypes.string,
    areRequiredHours: PropTypes.bool,
    requiredHours: PropTypes.string,
  }).isRequired,
  onFormDataChange: PropTypes.func.isRequired,
};

export default Step2;
