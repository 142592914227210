const calculatePriceWithIva = (price, iva) => Number((price * (1 + iva / 100)).toFixed(2));

const calculateTotalPaymentAndIva = (firstPayment, secondPayment, iva) => {
  const totalPayment = +firstPayment + +secondPayment;
  const totalPaymentWithIva = calculatePriceWithIva(totalPayment, iva);
  const totalIva = totalPaymentWithIva - totalPayment;

  return { totalPaymentWithIva, totalIva };
};

const getPrice = (firstPayment, secondPayment = 0, iva = 21) => {
  const firstPaymentWithIva = calculatePriceWithIva(+firstPayment, iva);

  if (!secondPayment || secondPayment === 0) return { firstPayment: firstPaymentWithIva };

  const { totalIva } = calculateTotalPaymentAndIva(firstPayment, secondPayment, iva);
  const secondPaymentWithIva = (+secondPayment + totalIva).toFixed(2);

  return {
    firstPayment,
    secondPayment: secondPaymentWithIva,
  };
};

const calculateOriginalPayment = (totalPayment, ivaPercentage) => {
  const { firstPayment, secondPayment, iva } = totalPayment;

  const totalIva = 1 + (ivaPercentage / 100);

  if (!secondPayment || secondPayment === 0) {
    const originalPaymentOne = firstPayment / totalIva;
    const totalIvaAmount = Number((firstPayment - originalPaymentOne).toFixed(2));
    return { originalFirstPayment: originalPaymentOne, totalIvaAmount };
  }

  const originalSecondPayment = Number(((secondPayment + firstPayment) / totalIva - firstPayment).toFixed(2));
  const totalPrice = firstPayment + originalSecondPayment;
  const totalPriceWithIva = calculatePriceWithIva(totalPrice, iva);
  const totalIvaAmount = Number((totalPriceWithIva - totalPrice).toFixed(2));

  return { originalFirstPayment: firstPayment, originalSecondPayment, totalIvaAmount };
};

const getServices = (prices, formData) => {
  const pricesFiltered = prices.filter((price) => (
    !price.service?.key.includes('prorroga')
    && (!price.destination || price.destination?.id === formData.country)
    && (!price.residence || price.residence?.id === formData.residence)
    && ((formData.eu && price.eu) || (!formData.eu && !price.eu))
    && (!price?.universities?.length || (price.universities?.filter((u) => u?.id === formData.university).length > 0))
  ));

  return [
    pricesFiltered.filter((price) => price.service.key === 'full')[0],
    pricesFiltered.filter((price) => price.service.key === 'flex1')[0],
    pricesFiltered.filter((price) => price.service.key === 'flex2')[0],
  ].filter(Boolean);
};

const currencyCountries = {
  MX: 'MEXICO',
  UR: 'URUGUAY',
  GU: 'GUATEMALA',
  EC: 'ECUADOR',
  CO: 'COLOMBIA',
  CH: 'CHILE',
  PE: 'PERU',
  EU: 'UE (EUROPA)',
  OT: 'OTROS PAISES',
};

const currencyCountriesCode = {
  DEFAULT: 'EUR',
  MX: 'MXN',
  UR: 'UYU',
  GU: 'GTQ',
  EC: 'ECS',
  CO: 'COP',
  CH: 'CLP',
  PE: 'PEN',
  EU: 'EUR',
  OT: 'USD',
};

const currencySymbols = {
  DEFAULT: '€',
  MXN: 'MX$',
  UYU: 'UYU$',
  GTQ: 'GTQ',
  ECU: 'USD',
  COP: 'COP$',
  CLP: 'CLP$',
  PEN: 'PEN$',
  EUR: '€',
  USD: '$',
  OP: '$',
};

const getInsurancePrice = (prices, code) => {
  if (!prices || prices.length === 0) return '';
  const insurance = prices.find((price) => price?.service?.key === 'Seguro');

  if (insurance.otherCurrencyPrices && code) {
    const matchingPrice = insurance.otherCurrencyPrices
      .find((price) => price.country === currencyCountries[code]);
    if (matchingPrice) {
      return matchingPrice.price;
    }
  }

  return '';
};

export default {
  calculateOriginalPayment,
  calculatePriceWithIva,
  currencyCountries,
  currencyCountriesCode,
  currencySymbols,
  getInsurancePrice,
  getPrice,
  getServices,
};
