import styled from 'styled-components/macro';

const HSpacer = styled.div`
  width: 100%;
  height: ${(props) => props.height || '20px'};

  @media (max-width: ${(props) => props.theme.breakpoints.lgMin}) {   
    height:20px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    height:${(props) => (props.step4 ? '9px' : '20px')};
  }
`;

export default HSpacer;
