import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { uploadFile } from '../../../../services/storage';
import {
  ContactForm,
  CardContentWrapper,
  FullFlexCont,
} from '../../formStyledComponents';


const Input = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const Label = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
  border-radius: 3rem;
  border: solid 2px #cff2ff;
  background-color: #cff2ff;
  color: #105285;
  font-size: 1.5rem;
  font-weight: 700;
  padding: 1rem 1.5rem;
  text-align: center;

  &:hover {
    background-color: #105285;
    border: solid 2px #105285;
    color: #FFFFFF;
  }
`;

const NewDocument = (props) => {
  const {
    addDocument, isAddModal, isEditModal, userId,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsLoading(true);
    const filename = `${file.name}`.replace(/\s/g, '-').replace(/á|à|â|é|è|ê|í|ì|î|ó|ò|ô|ú|ù|û|ç|ñ`/gi, '-');
    const fileLocation = `${userId}/otherdocuments/${filename}`;
    const response = await uploadFile(file, fileLocation);
    const { success, data, message } = response;
    if (success) addDocument(data);
    setIsLoading(false);
  };


  return (
    <CardContentWrapper aligncenter mobilePadding="0" padding={(isAddModal || isEditModal) && '0'}>
      <ContactForm>

        <FullFlexCont padding="0" justify="center" mobilePadding="0">

          <Input
            name="document"
            type="file"
            id={`${userId}-document-upload`}
            onChange={handleFileUpload}
            disabled={isLoading}
          />

          <Label htmlFor={`${userId}-document-upload`}>
            { isLoading ? 'Guardando...' : 'Seleccionar documento'}
          </Label>

        </FullFlexCont>

      </ContactForm>
    </CardContentWrapper>
  );
};

NewDocument.propTypes = {
  addDocument: PropTypes.func.isRequired,
  isAddModal: PropTypes.bool,
  isEditModal: PropTypes.bool,
  userId: PropTypes.string.isRequired,
};

NewDocument.defaultProps = {
  isAddModal: false,
  isEditModal: false,
};

export default NewDocument;
