import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

const LinkStyled = styled(Link)`
  font-weight: bold;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.themePrimary};
`;

export default LinkStyled;
