import styled from 'styled-components/macro';

import Box from '../../common/Flex/Box';


export const Container = styled.div`
  width: calc(100vW - 18rem);

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
  }
`;

export const MobileBgImage = styled.div`
  width: 100%;
  height: 25rem;
  background-color: ${({ theme }) => theme.colors.greyLight};
  background: url(${({ bgImage }) => bgImage});
  background-position: center;
  background-size: cover;
`;

export const HeaderContainer = styled.div`
  // height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
    height: 25rem;
    background-color: ${({ theme }) => theme.colors.greyLight};
    background: url(${({ bgImage }) => bgImage});
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
`;

export const DepartmentContainer = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    height: 20rem;
    min-width: 25rem;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 4rem 3rem;
  }
`;

export const LogoContainer = styled.div`
  height: 10rem;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    max-height: 20rem;
  }
`;

export const DepartmentText = styled.p`
  line-height: 3.5rem;
  font-size: ${({ theme }) => theme.fontSizes.titleMedium};
  font-weight: bold;
`;

export const ContentContainer = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 3.5rem 2.5rem 4rem 2.5rem;
    max-width: 1100px;
  }
`;

export const TitleContainer = styled.div`
  line-height: 2.5rem;
  font-size: ${({ theme }) => theme.fontSizes.titleXSmall};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    text-align: center;
  }

  h1 {
    display: inline-block;
    font-size: ${({ theme }) => theme.fontSizes.medium};
    vertical-align: middle;
  }
`;

export const ReturnIcon = styled.div`
    display: inline-block;
    margin-right: 0.8rem;
    vertical-align: middle;
    cursor: pointer;
`;

export const Line = styled.p`
  color: ${(props) => props.theme.colors.themePrimary};
  font-size: ${(props) => props.theme.fontSizes.medium};
  svg {
    vertical-align: middle;
    margin-left: 8px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 1rem 0 3rem 3.2rem;
  }
`;

export const Subtitle = styled.h2`
  margin-left: 3.2rem;
  font-size: ${(props) => props.theme.fontSizes.titleXSmall};
  
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 0;
    padding: 0 3rem;
  }
`;

export const Contents = styled(Box)`
  margin-left: 3.2rem;
  
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 0;
    padding: 1rem 3rem 6rem;
  }
`;

export const SmallTitle = styled.p`
  margin-top: 2rem;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSizes.titleXSmall};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-top: 3rem;
  }
`;

export const Text = styled.p`
  font-size: ${(props) => props.theme.fontSizes.medium};
  line-height: 2.2rem;
`;

export const Link = styled.a`
  font-size: ${(props) => props.theme.fontSizes.medium};
  color: ${(props) => props.theme.colors.themeSecondary};
  cursor: pointer;
  text-decoration: underline;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-top: 3rem;
  }
`;

export const FavButton = styled.div`
  button {
    max-height: 4.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
