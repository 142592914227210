import React, { useState } from 'react';
import {
  PageContainer,
} from '../components/common';
import ProrrogaStep1 from '../components/common/ProrrogaSteps/ProrrogaStep1';
import ProrrogaStep2 from '../components/common/ProrrogaSteps/ProrrogaStep2';
import LoggedLayout from '../components/layout/LoggedLayout';


const Prorroga = () => {
  const [currentStep, setCurrentStep] = useState(1);
  return (
    <LoggedLayout>
      <PageContainer>
        {currentStep === 1 && <ProrrogaStep1 setCurrentStep={setCurrentStep} />}
        {currentStep === 2 && <ProrrogaStep2 />}
      </PageContainer>
    </LoggedLayout>
  );
};

export default Prorroga;
