import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';

import {
  SingleRow, TagDepartamento, Box, VerticalLine,
} from '../common';


const HSeparator = styled.div`
  margin: ${({ margin }) => margin};
`;

const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.titleXSmall};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    text-align: center;
  }
`;

const Text = styled.p`
  font-size: ${(props) => props.theme.fontSizes.medium};
  line-height: 2.2rem;
  margin-bottom: 1.5rem;
`;

const Link = styled.a`
  color: ${(props) => props.theme.colors.themeSecondary};
  text-decoration: underline;
`;


const Interviews = () => {
  const screen = useSelector((state) => state.windowResize.screen);
  const lang = useSelector((state) => state.language.lang);
  const interviews = useSelector((state) => state.student?.internship?.interviews || '');

  if (!interviews || !interviews.length) {
    return (
      <>
        <SingleRow />
        <Box>
          <Title>Todavía no se han programado entrevistas</Title>
        </Box>
      </>
    );
  }

  const isSmallScreen = screen === 'phone' || screen === 'tab-port' || screen === 'tab-land';
  const isPhone = screen === 'phone';
  const w = isPhone ? 1 : 0.9 / 3;

  return (
    <>
      <SingleRow />
      {
        interviews.map((interview) => {
          const {
            companyName,
            date,
            interviewerName,
            interviewerPosition,
            interviewerSkypeUsername,
            link,
            timeLang,
            formatLang,
          } = interview;

          return (
            <>
              <TagDepartamento title="" bgColor="themePrimary" full />
              <HSeparator margin="0 0 2.4rem" />
              <Box container align="strech" wrap="wrap">
                <Box w={w}>
                  <Title>{companyName}</Title>
                </Box>

                {isPhone ? <SingleRow margin="2rem 0 2.5rem" /> : <VerticalLine margin={isSmallScreen ? '0 1rem' : '0 2rem'} />}

                <Box w={w}>
                  <Text>
                    <strong>Día de la entrevista:</strong>
                    <br />
                    {date}
                  </Text>

                  <Text>
                    <strong>Hora de la entrevista:</strong>
                    <br />
                    {timeLang[lang]}
                  </Text>

                  <Text>
                    <strong>Formato y contacto entrevista:</strong>
                    <br />
                    {formatLang[lang]}
                    <br />
                    <Link href={link} target="_blank" rel="noopener noreferrer">{link}</Link>
                    <br />
                    {interviewerSkypeUsername}
                  </Text>
                </Box>

                {isPhone ? <SingleRow /> : <VerticalLine margin={isSmallScreen ? '0 1rem' : '0 2rem'} />}

                <Box w={w}>
                  <Text>
                    <strong>Datos entrevistador:</strong>
                    <br />
                    Nombre: {interviewerName} <br />
                    Puesto trabajo: {interviewerPosition}
                  </Text>
                </Box>
              </Box>
              <SingleRow margin="3rem 0" />
            </>
          );
        })
      }
    </>
  );
};

export default Interviews;
