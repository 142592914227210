import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import {
  ButtonProrroga,
  ButtonsWrapper,
  CloseIcon,
  ImageWrapper,
  QuestionTitle,
  Title,
} from './styledComponents';
import { Icons, Image, Loader } from '..';

import { studentActions } from '../../../redux/actions';
import { addProrroga } from '../../../services/api/prorroga';


const ProrrogaStep2 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const student = useSelector((state) => state.student);

  const [loading, setLoading] = useState(false);

  const createNewInternship = async () => {
    setLoading(true);
    const response = await addProrroga(student);
    if (response.success) {
      dispatch(studentActions.save(response.student));
      history.push('/menu');
    }
    setLoading(false);
    // error msg.
  };

  return (
    <>
      <Link to="/menu">
        <CloseIcon>
          <Icons icon="close" width={2.8} />
        </CloseIcon>
      </Link>
      <Title>PEDIR PRÓRROGA</Title>
      <QuestionTitle>
        ¿Dónde quieres realizar la prórroga de tus prácticas?
      </QuestionTitle>
      <ImageWrapper>
        <Image url="/images/on-boarding/onboarding-step-4.svg" />
      </ImageWrapper>
      <ButtonsWrapper>
        <ButtonProrroga
          onClick={createNewInternship}
        >
          {
            loading ? <Loader /> : 'En la misma empresa'
          }
        </ButtonProrroga>
        <ButtonProrroga disabled>En la una empresa distinta</ButtonProrroga>
      </ButtonsWrapper>
    </>
  );
};

export default ProrrogaStep2;
