import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';
import ButtonPrimary from '../common/Buttons/ButtonPrimary';
import ButtonSecondary from '../common/Buttons/ButtonSecondary';
import Select from '../common/formFields/Select';
import {
  StepTitle,
  CentralCard,
  CentralCardLeft,
  OnBoardingIllustration,
  CentralRight,
  CentralCardLine,
  SlidesDots,
  SlidesDotsItem,
  CentralRightWrapper,
  StepBox,
  ModalWrapper,
  Label,
} from './Styles';
import DatePicker from '../common/DatePicker';
import Box from '../common/Flex/Box';
import HSpacer from '../layout/HSpacer';
import Step3DepartmentMobile from './Step3DepartmentMobile';
import Alert from '../common/Alert';

const SubTitle = styled.h3`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: ${({ theme }) => theme.fontSizes.small};
  text-align: center;
`;

const Step3 = ({
  onStepCompleted, onStepBack, onDotClick, formData, onFormDataChange, vacancies,
}) => {
  const handleSelectedDepartment = (department, index) => {
    const { selectedDepartments = [] } = formData;
    const newSelectedDepartments = [...selectedDepartments.slice(0, index), department, ...selectedDepartments.slice(index + 1)];
    const selectedDepartmentsDeDuplicated = newSelectedDepartments.filter((dep, i) => newSelectedDepartments.indexOf(dep) === i);
    onFormDataChange('selectedDepartments', selectedDepartmentsDeDuplicated);
  };
  const [showDepartments, setShowDepartments] = useState(false);
  const [initMessage, setInitMessage] = useState('');
  const [endMessage, setEndMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const departments = useMemo(
    () => vacancies
      .map(({ department }) => department)
      .filter(
        (department, index, allDepartments) => department && allDepartments.indexOf(department) === index,
      )
      .sort(),
    [vacancies],
  );

  const handleAddDepartment = () => {
    const { selectedDepartments = [] } = formData;
    if (selectedDepartments.length < departments.length) {
      onFormDataChange('selectedDepartments', [...selectedDepartments, '']);
    }
  };

  const { selectedDepartments = [] } = formData;
  const screen = useSelector((state) => state.windowResize.screen);

  const handleChooseDepartment = () => {
    if (screen === 'phone') {
      setShowDepartments(true);
    }
    handleAddDepartment();
  };

  const datesw = (screen === 'normal' || screen === 'tab-port') ? '1 / 2' : '1';
  let datesWidth = '100%';
  if (screen === 'normal') datesWidth = '49%';
  if (screen === 'tab-port') datesWidth = '48%';

  const onChangeInitDate = (value) => {
    setEndMessage('');

    if (!formData.eu) {
      // 3 months from today
      const today = new Date();
      const minDate = today.setMonth(today.getMonth() + 3);
      
      if (new Date(value) < minDate) {
        setInitMessage('Te recomendamos comenzar el proceso con nosotros al menos 3 meses antes de la fecha de inicio de tu práctica.');
      } else setInitMessage('');
    }
    onFormDataChange('initDate', value);
  };

  const onChangeEndDate = (value) => {
    if (!formData.eu) {
      const initDate = new Date(formData.initDate);
      const endDate = new Date(value);
      const sixMonths = initDate.setMonth(initDate.getMonth() + 6);
      if (endDate > sixMonths) {
        setEndMessage('Para prácticas de más de 6 meses nos tendrás que solicitar la prórroga a lo largo del 5to mes de prácticas.');
      } else setEndMessage('');
    }
    onFormDataChange('endDate', value);
  };




  const isPhone = screen === 'phone';
  const limitDocuments = selectedDepartments.length > 2;

  const handleStepCompleted = () => {
    let missingFields = [];

    // Verificar fechas
    if (!formData.initDate) {
      missingFields.push("fecha de inicio");
    }
    if (!formData.endDate) {
      missingFields.push("fecha de fin");
    }

    // Verificar departamentos
    if (selectedDepartments.length === 0 || selectedDepartments.some(dep => !dep || dep === "")) {
      missingFields.push("al menos un departamento válido");
    }

    if (missingFields.length > 0) {
      setErrorMessage(`Por favor, complete los siguientes campos: ${missingFields.join(", ")}.`);
    } else {
      setErrorMessage('');
      onStepCompleted();
    }
  };

  return (
    <CentralCard>
      <CentralCardLeft>
        <OnBoardingIllustration src="/images/on-boarding/onboarding-step-3.png" />
      </CentralCardLeft>
      <CentralCardLine />
      <CentralRightWrapper>
        <CentralRight>
          <StepTitle>¿En qué departamento deseas buscar prácticas?</StepTitle>
          <HSpacer height={isPhone ? '30px' : '20px'} />
          <>
            <SubTitle>SELECCIONA UN MÁXIMO DE 3 DEPARTAMENTOS</SubTitle>
            <HSpacer height="20px" />
          </>

            <div style={{ overflow: 'scroll', scrollbarWidth: 'none' }}>
              {selectedDepartments.map((department, i) => (
                <div key={`${department}-${i}`}>
                  <Select
                    onSelectChange={(dep) => handleSelectedDepartment(dep, i)}
                    value={department}
                    placeholder="Seleccione un departamento"
                    options={departments}
                  />
                  <HSpacer height="10px" />
                </div>
              ))}
            </div>
          <ButtonSecondary disabled={!isPhone && limitDocuments} text="Añadir departamento" onClick={handleChooseDepartment} fullWidth />
          <HSpacer />
          <StepBox container w={1} width="100%" direction="row" signUpMobile wrap="wrap">
            <StepBox w={datesw} width={datesWidth} wrap="wrap">
              <Label>Fecha de inicio</Label>
              <DatePicker
                className="onboarding-datepicker"
                value={formData.initDate}
                onBoardingDatePicker
                placeholder="Fecha de inicio"
                onValueChange={onChangeInitDate}
                minDate={new Date()}
              />
            </StepBox>
            <StepBox w={datesw} width={datesWidth} wrap="wrap">
              <Label>Fecha final</Label>
              {formData.initDate ? (
                <DatePicker
                  className="onboarding-datepicker"
                  value={formData.endDate}
                  onBoardingDatePicker
                  onValueChange={onChangeEndDate}
                  placeholder="Fecha final"
                  minDate={new Date(formData.initDate)}
                />
              ) : (
                // Un input de texto deshabilitado o un mensaje que indique que la fecha no está lista
                <input
                  type="text"
                  className="onboarding-datepicker"
                  placeholder="Fecha final"
                  disabled
                  style={{border: "2px solid #a4b3b7", borderRadius: "2.5rem",flex: "1 1 auto", width:"100%", paddingLeft: "9px", height: "38.56px"}}
                />
              )}
            </StepBox>
          </StepBox>
          {initMessage && (
            <>
              <HSpacer />
              <Alert variant="alertInfo" noCross text={initMessage} />
            </>
          )}
          {endMessage && (
            <>
              <HSpacer />
              <Alert variant="alertInfo" noCross text={endMessage} />
            </>
          )}
          {errorMessage && (
            <>
              <HSpacer />
              <Alert variant="alertError" noCross text={errorMessage} />
            </>
          )}
          <HSpacer height="40px" />
          <Box container>
            <StepBox w={1 / 2} spacing={1}>
              <ButtonSecondary text="Atrás" onClick={onStepBack} fullWidth />
            </StepBox>
            <StepBox w={1 / 2} spacing={1}>
              <ButtonPrimary text="Siguiente" onClick={handleStepCompleted} fullWidth />
            </StepBox>
          </Box>
          <SlidesDots>
            <SlidesDotsItem onClick={() => onDotClick(0)} />
            <SlidesDotsItem onClick={() => onDotClick(1)} />
            <SlidesDotsItem onClick={() => onDotClick(2)} active />
            <SlidesDotsItem onClick={() => onDotClick(3)} />
          </SlidesDots>
        </CentralRight>
      </CentralRightWrapper>
      
    </CentralCard>
  );
};

export default Step3;
