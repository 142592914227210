import React, { useRef, useContext, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes, css } from 'styled-components/macro';
import { minWidth } from 'styled-system';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import CardNotificacion from '../Cards/CardNotificacion';
import Icons from '../Icons';
import { updateNotificationSeen } from '../../../services/api/notifications';

const fadeIn = keyframes`from { opacity: 0; }`;

const Container = styled.div`
  position: relative;
  z-index: 0;
`;

const Overlay = styled.div`
  animation: ${fadeIn} 200ms ease-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;
`;

const Dialog = styled.div`
  background: white;
  border-radius: ${({ theme }) => theme.borderRadius.card};
  position: ${({ isNotification }) => (isNotification ? 'fixed' : 'absolute')};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  overflow: ${({ hasDatePicker }) => (hasDatePicker ? 'visible' : 'auto')};
  max-height: ${({ isLegal }) => (isLegal ? '90vh' : '80vh')};
  min-height: ${({ minHeight }) => minHeight};
  width: ${({ isLegal }) => (isLegal ? '90vw' : 'fit-content')};
  max-width: 90vw;
  min-width: ${({ minWidth: width }) => width};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
    max-height: 100%;
    height: 100%;
    border-radius: 0;
  }
`;

const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: end;
  gap: 20px;
  padding: 0.2rem;
  top: 90px;
  right: 20px;
  z-index: 1;
  width: 250px;
  position: fixed;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 90vw;
    border-radius: 0;
    max-height: unset;
    height: unset;
  }
`;

const NotificationDialog = styled.div`
  opacity: ${({ isReaded }) => (isReaded ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
  width: 100%;
`;

const CrossButton = styled.button`
  background: none;
  border: none;

  &:hover {
    cursor: pointer;
  }
`;

const CrossButtonContainer = styled.div`
  width: 100%;
  text-align: right;
  padding: ${({ paddingCrossButton }) => paddingCrossButton || '2rem'};
`;

const Context = React.createContext();

export function ModalProvider({ children }) {
  const modalRef = useRef();
  const [context, setContext] = useState();

  // make sure re-render is triggered after initial
  // render so that modalRef exists
  useEffect(() => {
    setContext(modalRef.current);
  }, []);

  return (
    <Container>
      <Context.Provider value={context}>{children}</Context.Provider>
      <div ref={modalRef} />
    </Container>
  );
}

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function Modal(props) {
  const modalNode = useContext(Context);
  const { children, onClose, isLegal, minHeight, paddingCrossButton, hasDatePicker } = props;

  return modalNode
    ? ReactDOM.createPortal(
      <Overlay>
        <Dialog hasDatePicker={hasDatePicker} isLegal={isLegal} minHeight={minHeight} minWidth={minWidth}>
          <CrossButtonContainer paddingCrossButton={paddingCrossButton}>
            <CrossButton onClick={onClose} type="button">
              <Icons icon="close" iconColor="textPrimary" width={3} />
            </CrossButton>
          </CrossButtonContainer>
          {children}
        </Dialog>
      </Overlay>,
      modalNode,
    )
    : null;
}

export function Notification(props) {
  const { unseenNotifications, onReadNotification } = props;
  const [showNotification, setShowNotification] = useState(false);
  const [unseenNotification, setUnseenNotification] = useState([]);
  const modalNode = useContext(Context);
  const history = useHistory();

  const handleGoToNotifications = (notificationTimestamp) => {
    history.push('/notifications');
    onReadNotification(notificationTimestamp);
  };

  useEffect(() => {
    const unseenNotificationSorted = unseenNotifications
      ?.filter((unreadNotification) => !unreadNotification.seen)
      .sort((a, b) => b.timestamp - a.timestamp);

    const handleUpdateNotification = async (notifications) => (
      notifications.map((notification) => updateNotificationSeen({ ...notification, seen: true }))
    );

    setUnseenNotification(unseenNotificationSorted);
    setShowNotification(unseenNotificationSorted.length > 0);

    setTimeout(() => {
      setShowNotification(false);
      if (unseenNotificationSorted.length > 0) {
        handleUpdateNotification(unseenNotificationSorted);
      }
    }, '3000');
  }, [unseenNotifications]);


  return modalNode
    ? ReactDOM.createPortal(
      <NotificationWrapper>
        {
          unseenNotification.map((notification) => {
            const { title, content, timestamp } = notification;

            return (
              <NotificationDialog
                NotificationDialog
                isNotification
                isReaded={showNotification}
                key={`${title}-${content}`}
              >
                <CardNotificacion
                  lineCamp={1}
                  title={title}
                  text={content}
                  isReaded={showNotification}
                  isPopup
                  onClick={() => handleGoToNotifications(timestamp)}
                />
              </NotificationDialog>
            );
          })
        }
      </NotificationWrapper>,
      modalNode,
    )
    : null;
}
