/* eslint-disable no-console */
import AWS from 'aws-sdk';
import Auth from '@aws-amplify/auth';

import awsConfig from '../aws-config';


const response = (success, msg, data) => ({
  success,
  message: !success && !msg ? 'UnexpectedError' : msg,
  data,
});

export async function signUp({ password, email, ...attributes }) {
  // console.log(userParams)
  try {
    const { user } = await Auth.signUp({
      username: email,
      password,
      attributes,
    });
    // return user;
    return response(true, '', user);
  } catch (error) {
    console.log('error signing up:', error);
    return response(false, error.code);
  }
}

export async function login(username, password) {
  try {
    const user = await Auth.signIn(username, password);
    return response(true, '', user);
  } catch (error) {
    console.log('login -> error', error);
    return response(false, error.code);
  }
}

export async function setNewPassword(user, password) {
  try {
    await Auth.completeNewPassword(user, password);
    return response(true);
  } catch (error) {
    console.log('setNewPassword -> error', error);
    return response(false, error.code);
  }
}

export async function getCurrentUser() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const group = user.signInUserSession.accessToken.payload['cognito:groups'][0];
    return response(true, '', { ...user, group });
  } catch (error) {
    return response(false, error.code);
  }
}

export async function signout() {
  try {
    await Auth.signOut();
    return response(true);
  } catch (error) {
    return response(false, error.code);
  }
}

export async function getCredentials() {
  const session = await Auth.currentSession();
  const loginPool = `cognito-idp.${awsConfig.Auth.region}.amazonaws.com/${awsConfig.Auth.userPoolId}`;

  return new AWS.CognitoIdentityCredentials({
    IdentityPoolId: awsConfig.Auth.identityPoolId,
    Logins: {
      [loginPool]: session.getIdToken().getJwtToken(),
    },
  }, { region: awsConfig.Auth.region });
}

export async function askResetPassword({ email }) {
  try {
    await Auth.forgotPassword(email);

    return response(true, null);
  } catch (error) {
    console.log('TCL: resetPassword -> error', error);
    return response(false, error.code);
  }
}

export async function resetPassword({ email, newPassword, code }) {
  try {
    await Auth.forgotPasswordSubmit(email, code, newPassword);

    return response(true, null);
  } catch (error) {
    console.log('TCL: resetPassword -> error', error);
    return response(false, error.code);
  }
}
