import styled from 'styled-components/macro';
import theme from '../../CustomTheme/Theme';

export const QuestionsAndAnswersBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 2rem;
`;

export const CloseIcon = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Title = styled.div`
  font-size: ${theme.fontSizes.medium};
  font-weight: bold;
  line-height: 1.47;
  text-align: center;
  color: ${theme.colors.textPrimary};
  margin-bottom: 2rem;
`;

export const TextTermsWrapper = styled.div`
  display: flex;
  justify-content:center;
  align-items:center;

  & * {
    cursor: pointer;
  }
`;

export const BluePoint = styled.div`
  border-color:${theme.colors.themePrimary};
  border-style:solid;
  height:1.5rem;
  width:1.5rem;
  border-radius: 50%;
  margin-right: 1rem;
  background-color:${(props) => (props.bluePointState === true ? theme.colors.themePrimary : 'transparent')};
`;

export const Text = styled.div`
  font-size: ${theme.fontSizes.medium};
  line-height: 1.47;
  color:${theme.colors.textPrimary};
`;

export const ButtonWrapper = styled.div`
  display:flex;
  justify-content:center;
  margin-top: 3rem;
`;

export const QuestionTitle = styled.div`
  font-size: ${theme.fontSizes.titleMedium};
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  color: ${theme.colors.textPrimary};
  margin-top: 2rem;
  width: 35rem;
  display: flex;
  margin: auto;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width:30rem;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem auto;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 3rem auto;
  }
`;

export const ButtonsWrapper = styled.div`
  display:flex;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    display:flex;
    flex-direction: column;
    align-items:center;
  }
`;

export const ButtonProrroga = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height:4.5rem;
  font-size: ${theme.fontSizes.large};
  font-weight: bold;
  line-height: 1.17;
  color: ${theme.colors.themeSecondary};
  background-color:${theme.colors.themeTertiary};
  border-radius: 5rem;
  width: 30rem;
  margin: 1rem;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  :hover {
    cursor: ${({ disabled }) => (disabled ? 'inherit' : 'pointer')};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 0.5rem;
  }
`;

export const Error = styled.span`
  color: red;
  padding: 0.5rem;
`;
