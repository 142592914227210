const paddingMultiple = 4;

const Theme = {
  colors: {
    themePrimary: '#4798b6',
    themeSecondary: '#105285',
    themeTertiary: '#cff2ff',
    themeInverse: '#FFFFFF',

    textPrimary: '#4d4d4d',
    textSecondary: '#a4b3b7',
    textTertiary: '#e1f3f8',
    textInverse: '#FFFFFF',

    alertError: '#f07f5c',
    alertAccepted: '#7ab660',
    alertWarning: '#ffd742',
    alertNotification: '#ff322f',

    black: '#1b1f24',
    greyDark: '#4d4d4d',
    grey: '#a4b3b7',
    greyLight: '#e1f3f8',
    white: '#ffffff',

    transparency1: 'rgba(255, 255, 255,0.8)',
    transparency2: 'rgba(0,0,0,0.37)',

    footer: '#353535',
  },
  fonts: ['sans-serif', 'Roboto'],
  fontSizes: {
    xsmall: '1.2rem',
    small: '1.3rem',
    medium: '1.5rem',
    large: '1.7rem',

    titleXSmall: '1.8rem',
    titleSmall: '2.3rem',
    titleMedium: '2.5rem',
    titleLarge: '3rem',
    titleXLarge: '3.5rem',
    titleXXLarge: '4rem',

    giant1: '6.5rem',
    giant2: '7rem',
  },
  display: {
    block: 'block',
    flex: 'flex',
  },
  breakpoints: {
    xs: '0',
    sm: '599px',
    smMin: '600px',
    md: '899px',
    mdMin: '900px',
    lg: '1199px',
    lgMin: '1200px',
    xl: '1799px',
    xlMin: '1800px',
  },
  margins: {
    sectionMargin: '2rem',
  },
  paddings: {
    xxs: `${paddingMultiple * 3 / 16}rem`,
    xs: `${paddingMultiple * 5 / 16}rem`,
    s: `${paddingMultiple * 10 / 16}rem`,
    m: `${paddingMultiple * 20 / 16}rem`,
    l: `${paddingMultiple * 30 / 16}rem`,
    xl: `${paddingMultiple * 40 / 16}rem`,
    xxl: `${paddingMultiple * 60 / 16}rem`,
  },
  borderRadius: {
    card: '8px',
    tag: '15px',
  },
  widths: {
    cardWidth: '18rem',
  },
  shadows: {
    shadow1: '0 0 4px 0 rgba(0, 0, 0, 0.37)',
    header: '0 0 44px -21px rgba(0, 0, 0, 0.5)',
  },
  sizes: {
    headerHeight: '70px',
  },
  transitions: {
    opacity: 'opacity 0.4s',
    all: 'all 0.3s',
    property: (prop) => `${prop} 0.3s`,
  },
};


export default Theme;
