import React from 'react';
import styled from 'styled-components/macro';
import ButtonPrimary from '../../common/Buttons/ButtonPrimary';


const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  max-width: 50vh;
`;

const TextDiv = styled.div`
  text-align: center;
  line-height: 1.5;
  padding: 2rem;
`;

const ButtonsDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  margin: 2rem;
`;

const ButtonDiv = styled.div`
  width: 45%;
`;

const PayLaterPopUp = ({ confirm, onClose }) => (
  <ContentWrapper>
    <TextDiv>
      Al confirmar que deseas pagar más adelante, saldrás de la aplicación.
      Recuerda que debes realizar el primer pago para poder acceder al contenido.
    </TextDiv>
    <ButtonsDiv>
      <ButtonDiv>
        <ButtonPrimary
          text="Cancelar"
          onClick={onClose}
          margin="auto"
          fullWidth
          mobilePadding="1rem 0.5rem"
        />
      </ButtonDiv>
      <ButtonDiv>
        <ButtonPrimary
          text="Confirmar"
          onClick={confirm}
          margin="auto"
          fullWidth
          mobilePadding="1rem 0.5rem"
        />
      </ButtonDiv>
    </ButtonsDiv>
  </ContentWrapper>
);

export default PayLaterPopUp;
