import React, { createContext, useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { Box, Modal } from '../components/common';
import useListPublicEntity from '../hooks/useListPublicEntity';
import { studentActions } from '../redux/actions';

const Title = styled.h3`
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: ${(props) => props.theme.fontSizes.titleMedium};
  line-height: 1.3;
  font-weight: 700;
`;

const Text = styled.p`
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: ${(props) => props.theme.fontSizes.medium};
  line-height: 1.3;
`;

export const PopupHelperContext = createContext({});

const convertCamelToKebabCase = (str) => str?.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();

export const PopupHelperProvider = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const student = useSelector((state) => state.student);
  const { data } = useListPublicEntity('popuphelper');

  const [popupData, setPopupData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const findPopupHelper = (popupsData) => popupsData
    .find((popup) => convertCamelToKebabCase(popup.screen) === location.pathname.substring(1)
      && popup.studentStatus === student.status);

  useEffect(() => {
    if (!student || data.length === 0) return;

    const { popupView } = student;

    const popup = findPopupHelper(data);
    const noText = !popup?.title && !popup?.desc;

    if (!popup || noText || (popupView && popupView.includes(popup.id))) {
      setPopupData(null);
      setShowModal(false);
      return;
    }

    setPopupData(popup);
    setShowModal(true);
    dispatch(studentActions.save({ popupView: popupView ? [...popupView, popup.id] : [popup.id] }));
  }, [data, location, student.loading]);

  return (
    <PopupHelperContext.Provider>
      {showModal && (
        <Modal onClose={() => setShowModal(false)}>
          <Box container direction="column" gap="2rem" padding="1rem 4rem 4rem">
            <Title>{popupData.title}</Title>
            <Text>{popupData.desc}</Text>
          </Box>
        </Modal>
      )}
      {children}
    </PopupHelperContext.Provider>
  );
};

export const usePopupHelper = () => useContext(PopupHelperContext);
