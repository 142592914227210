/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSelector } from 'react-redux';
import {
  BrowserRouter as Router, Switch, Route, Redirect,
} from 'react-router-dom';

import {
  Cancel,
  Documents,
  Favourites,
  Home,
  Login,
  Menu,
  MyAccount,
  MyPractices,
  Notifications,
  OnBoarding,
  Page404,
  PaymentHistory,
  Privacy,
  Processes,
  Profile,
  Prorroga,
  Terms,
  VacancyFinder,
  VacancyPreview,
  Payment,
  PasswordRecover,
  PasswordReset,
} from './pages';
import { PopupHelperProvider } from './contexts/PopupHelperContext';

const AuthRoute = (props) => {
  const student = useSelector((state) => state.student);
  const isLoading = student.loading;
  if (isLoading) return 'Loading...';
  if (!isLoading && !student.id) return <Redirect to="/on-boarding" />;

  return (
    <Route {...props} />
  );
};


const Routes = () => (
  <Router>
    <PopupHelperProvider>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/on-boarding" component={OnBoarding} />
        <Route path="/login" component={Login} />
        <Route path="/password-recover" component={PasswordRecover} />
        <Route path="/password-reset" component={PasswordReset} />

        <Route path="/privacy" component={Privacy} />
        <Route path="/terms-and-conditions" component={Terms} />
        <Route path="/cancel" component={Cancel} />

        <AuthRoute path="/documents" component={Documents} />
        <AuthRoute path="/prorroga" component={Prorroga} />
        <AuthRoute path="/profile" component={Profile} />
        <AuthRoute path="/menu" component={Menu} />
        <AuthRoute path="/vacancy-finder" component={VacancyFinder} />
        <AuthRoute path="/processes" component={Processes} />
        <AuthRoute path="/my-practices" component={MyPractices} />
        <AuthRoute path="/my-account" component={MyAccount} />
        <AuthRoute path="/payment-history" component={PaymentHistory} />
        <AuthRoute path="/favourites" component={Favourites} />
        <AuthRoute path="/notifications" component={Notifications} />
        <AuthRoute path="/payment" component={Payment} />

        <AuthRoute path="/vacancy-preview/:id" component={VacancyPreview} />

        <Route component={Page404} />
      </Switch>
    </PopupHelperProvider>
  </Router>
);

export default Routes;
