import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import theme from '../CustomTheme/Theme';
import ContactPill from '../common/ContactPill';
import DocumentBox from '../documents/DocumentBox';

import { studentActions } from '../../redux/actions';
import { getStudent } from '../../services/api/student';
import { Box, ErrorMessage } from '../common';

const Text = styled.p`
  font-size: ${theme.fontSizes.xsmall};
  line-height: 1.38; 
  text-align:center;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    text-align:left;
  }
`;

const BoxesWrapper = styled.div`
  margin: auto;
`;


const Documents = () => {
  const dispatch = useDispatch();
  const student = useSelector((state) => state?.student || {});
  const { id, internship } = student;
  const documents = internship?.documents || [];

  const [error, setError] = useState(false);

  const onSaveFile = async (file, docId) => {
    if (!file) return;
    if (error) setError(false);

    const { data: fetchedStudentData, success } = await getStudent();

    if (!success) return setError(true);
    const { internship: { documents: fetchedDocuments } } = fetchedStudentData;

    const docIndex = fetchedDocuments.findIndex((doc) => doc.docpatternId === docId);
    if (docIndex === -1) return;

    const updatedDocuments = fetchedDocuments.map((doc, index) => {
      if (index !== docIndex) return doc;

      return {
        ...doc,
        documentFile: file,
        status: doc.status === 'rejected' || doc.status === 'pending' ? 'received' : doc.status,
      };
    });

    dispatch(studentActions.save({ internship: { ...internship, documents: updatedDocuments } }));
  };

  return (
    <>
      <Text>
        *En esta sección podrás descargar los documentos que vas a necesitar
        para realizar tu práctica. Si tienes los originales deberás traerlos contigo.
      </Text>
      <ContactPill width="100%" />
      {error && (
        <Box margin="1rem 0">
          <ErrorMessage>No se ha podido subir el documento correctamente, porfavor vuelve a intentarlo</ErrorMessage>
        </Box>
      )}
      <BoxesWrapper>
        {
          documents?.length > 0 && documents.map((doc) => (
            <DocumentBox
              key={doc.name}
              document={doc}
              userId={id}
              index={doc.docpatternId}
              onSaveFile={onSaveFile}
              entity="internship"
            />
          ))
        }
      </BoxesWrapper>
    </>
  );
};

export default Documents;
