import { API, graphqlOperation } from 'aws-amplify';

import { getStudentNotifications } from '../../graphql/queries';
import { readNotification, updateNotification } from '../../graphql/mutations';


const response = (success, msg, data) => ({
  success,
  message: !success && !msg ? 'UnexpectedError' : msg,
  data,
});

export async function getNotificationsList() {
  try {
    const { data } = await API.graphql(graphqlOperation(getStudentNotifications));
    const notifications = data.getStudentNotifications;
    return response(true, 'success', notifications);
  } catch (error) {
    const { data, errors } = error;
    return response(false, '', data);
  }
}

export async function putNotificationRead(timestamp) {
  try {
    const graphqlOptions = { timestamp };
    const res = await API.graphql(graphqlOperation(readNotification, graphqlOptions));
    const { data } = res;
    return response(true, 'success', data.readNotification);
  } catch (error) {
    const { data, errors } = error;
    return response(false, '', data);
  }
}

export async function updateNotificationSeen(notificationData) {
  try {
    const graphqlOptions = { input: notificationData };
    const res = await API.graphql(graphqlOperation(updateNotification, graphqlOptions));
    const { data } = res;
    return response(true, 'success', data.updateNotification);
  } catch (error) {
    const { data, errors } = error;
    return response(false, '', data);
  }
}

