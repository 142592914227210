import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import FormTitle from '../FormTitle';
import EmergencyContactForm from './EmergencyContactForm';
import UsersContactDataForm from './UsersContactDataForm';
import { SingleRow, DoubleRow } from '../../common';
import {
  P,
  FullFlexCont,
  NoIconTitle,
  MobilePadding,
} from '../formStyledComponents';


const ContactDataForm = ({ profile, littleDevice }) => {
  const [emergencyContactData, setEmergencyContactData] = useState([]);

  useEffect(() => {
    const emergencyContacts = _get(profile, 'emergencyContacts', []);
    setEmergencyContactData(emergencyContacts);
  }, [profile]);

  const contactData = profile
    ? {
      email: _get(profile, 'email', ''),
      skypeUsername: _get(profile, 'skypeUsername', ''),
      mobilePhone: _get(profile, 'mobilePhone', ''),
      facebookProfileURL: _get(profile, 'facebookProfileURL', ''),
    }
    : null;

  /* set the form to active/inactive to be edited */
  const initialFormState = contactData && false;
  const [formActive, setFormActive] = useState(initialFormState);


  return (
    <>
      <MobilePadding>
        <SingleRow />

        <FormTitle title="TUS DATOS CONTACTO:" onClick={() => setFormActive(!formActive)} />

        <UsersContactDataForm
          profile={profile}
          contactData={contactData}
          formActive={formActive}
          setFormActive={setFormActive}
        />

        <DoubleRow />

        <FullFlexCont baseline justify={littleDevice ? 'center' : 'flex-start'}>
          <NoIconTitle>CONTACTO DE EMERGENCIA: </NoIconTitle>
          <P size={1.3}> *Necesitas un contacto de emergencia como mínimo</P>
        </FullFlexCont>

      </MobilePadding>

      <EmergencyContactForm
        emergencyContactData={emergencyContactData}
        littleDevice={littleDevice}
        contactData={contactData}
        formActive={formActive}
        setFormActive={setFormActive}
        profile={profile}
      />
    </>
  );
};

ContactDataForm.propTypes = {
  profile: PropTypes.shape({}).isRequired,
  littleDevice: PropTypes.bool,
};

ContactDataForm.defaultProps = {
  littleDevice: false,
};

export default ContactDataForm;
