import React from 'react';
import styled from 'styled-components/macro';

import Icons from '../../Icons';

const Container = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`;
const CircleBg = styled.div`
  border-radius: 10rem;
  padding: 1rem;
  height: ${(props) => props.size}rem;
  width: ${(props) => props.size}rem;
  background-color: ${(props) => (props.active ? (props.bgColor || props.theme.colors.themeTertiary) : 'transparent')};
  margin: 5px auto;
`;
const Title = styled.p`
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 500;
`;

const ButtonIcon = ({ icon, iconColor, disabled, square, large, title, width = 2.5, bgColor, onClick, active }) => {
  const size = width + 2;

  return (
    <Container onClick={onClick}>
      <CircleBg
        size={size}
        bgColor={bgColor}
        active={active}
      >
        <Icons
          icon={icon}
          iconColor={iconColor}
          disabled={disabled}
          square={square}
          large={large}
          width={width}
        />
      </CircleBg>
      {!!title && <Title>{title}</Title>}
    </Container>
  );
};

export default ButtonIcon;
