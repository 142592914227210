import React from 'react';

export const AlertErrorIcon = ({ ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...rest}>
    <path
      d="M16.409 16.421c-.303.301-.793.301-1.094-.002L12 13.096l-3.315 3.323c-.3.303-.79.303-1.094.002-.303-.3-.303-.79-.002-1.093L10.91 12l-3.32-3.328c-.3-.303-.3-.793.002-1.093.15-.15.348-.226.546-.226.198 0 .398.075.548.228L12 10.904l3.315-3.325c.15-.153.35-.228.548-.228.198 0 .395.075.546.225.303.301.303.791.002 1.094L13.09 12l3.318 3.328c.303.303.303.793 0 1.093zM12 23C5.924 23 1 18.076 1 12S5.924 1 12 1s11 4.924 11 11-4.924 11-11 11zm6.684-17.684c-.868-.868-1.88-1.551-3.006-2.026-1.164-.494-2.402-.743-3.678-.743-1.276 0-2.514.25-3.678.741-1.126.477-2.136 1.158-3.006 2.026-.868.868-1.551 1.88-2.026 3.006-.494 1.166-.743 2.404-.743 3.68 0 1.276.25 2.514.741 3.678.477 1.126 1.158 2.136 2.026 3.006.868.868 1.88 1.551 3.006 2.026 1.166.494 2.404.743 3.68.743 1.276 0 2.514-.25 3.678-.741 1.126-.477 2.136-1.158 3.006-2.026.868-.868 1.551-1.88 2.026-3.006.494-1.166.743-2.404.743-3.68 0-1.276-.25-2.514-.741-3.678-.477-1.126-1.158-2.138-2.028-3.006z"
      transform="translate(-364 -430) translate(348 414) translate(16 16) matrix(1 0 0 -1 0 24)"
      fill="currentColor"
    />
  </svg>
);

export const AlertInfoIcon = ({ ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svgviewBox=" viewBox="0 0 24 24" {...rest}>
    <path
      d="M12 23C5.924 23 1 18.076 1 12S5.924 1 12 1s11 4.924 11 11-4.924 11-11 11zm6.684-17.684c-.868-.868-1.88-1.551-3.006-2.026-1.164-.494-2.402-.743-3.678-.743-1.276 0-2.514.25-3.678.741-1.126.477-2.136 1.158-3.006 2.026-.868.868-1.551 1.88-2.026 3.006-.494 1.166-.743 2.404-.743 3.68 0 1.276.25 2.514.741 3.678.477 1.126 1.158 2.136 2.026 3.006.868.868 1.88 1.551 3.006 2.026 1.166.494 2.404.743 3.68.743 1.276 0 2.514-.25 3.678-.741 1.126-.477 2.136-1.158 3.006-2.026.868-.868 1.551-1.88 2.026-3.006.494-1.166.743-2.404.743-3.68 0-1.276-.25-2.514-.741-3.678-.477-1.126-1.158-2.138-2.028-3.006zm-4.145 2.525h-1.83v7.521c0 .428-.346.774-.773.774H9.873c-.427 0-.773-.346-.773-.774 0-.427.346-.773.773-.773h1.29V7.84H9.331c-.428 0-.774-.346-.774-.774 0-.427.346-.773.774-.773h5.207c.428 0 .774.346.774.773 0 .428-.346.774-.774.774zm-3.377 10.422c0 .427.346.773.774.773.427 0 .773-.346.773-.773 0-.427-.346-.774-.773-.774-.428 0-.774.347-.774.774z"
      transform="translate(-364 -558) translate(348 542) translate(16 16) matrix(1 0 0 -1 0 24)"
      fill="currentColor"
    />
  </svg>
);

export const AlertSuccessIcon = ({ ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svgviewBox=" viewBox="0 0 24 24" {...rest}>
    <path
      d="M16.497 16.434L9.46 9.377l-1.835 3.212c-.213.371-.683.5-1.055.288-.372-.213-.5-.684-.288-1.055l2.338-4.093c.141-.25.403-.391.672-.391.131 0 .262.032.382.1.07.042.131.089.183.145l.009.008 7.723 7.748c.301.303.301.792-.002 1.093-.3.305-.788.305-1.091.002zM12 23C5.924 23 1 18.076 1 12S5.924 1 12 1s11 4.924 11 11-4.924 11-11 11zm6.684-17.684c-.868-.868-1.88-1.551-3.006-2.026-1.164-.494-2.402-.743-3.678-.743-1.276 0-2.514.25-3.678.741-1.126.477-2.136 1.158-3.006 2.026-.868.868-1.551 1.88-2.026 3.006-.494 1.166-.743 2.404-.743 3.68 0 1.276.25 2.514.741 3.678.477 1.126 1.158 2.136 2.026 3.006.868.868 1.88 1.551 3.006 2.026 1.166.494 2.404.743 3.68.743 1.276 0 2.514-.25 3.678-.741 1.126-.477 2.136-1.158 3.006-2.026.868-.868 1.551-1.88 2.026-3.006.494-1.166.743-2.404.743-3.68 0-1.276-.25-2.514-.741-3.678-.477-1.126-1.158-2.138-2.028-3.006z" 
      transform="translate(-364 -686) translate(348 670) translate(16 16) matrix(1 0 0 -1 0 24)"
      fill="currentColor"
    />
  </svg>
);

export const AlertWarningIcon = ({ ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svgviewBox=" viewBox="0 0 24 24" {...rest}>
    <path
      d="M12 21.453c1.276 0 2.514-.25 3.678-.741 1.126-.477 2.136-1.158 3.006-2.026.868-.868 1.551-1.88 2.026-3.006.494-1.166.743-2.404.743-3.68 0-1.276-.25-2.514-.741-3.678-.477-1.126-1.158-2.136-2.026-3.006-.868-.868-1.88-1.551-3.006-2.026-1.166-.494-2.404-.743-3.68-.743-1.276 0-2.514.25-3.678.741-1.126.477-2.136 1.158-3.006 2.026-.868.868-1.551 1.88-2.026 3.006-.494 1.166-.743 2.404-.743 3.68 0 1.276.25 2.514.741 3.678.477 1.126 1.158 2.136 2.026 3.006.868.868 1.88 1.551 3.006 2.026 1.166.494 2.404.743 3.68.743zM12 23C5.924 23 1 18.076 1 12S5.924 1 12 1s11 4.924 11 11-4.924 11-11 11zm0-13.75c-.475 0-.86.385-.86.86v7.927c0 .475.385.86.86.86s.86-.385.86-.86V10.11c0-.474-.385-.859-.86-.859zm-.967-2.342c0 .534.433.967.967.967s.967-.433.967-.967-.433-.967-.967-.967-.967.433-.967.967z" 
      transform="translate(-364 -814) translate(348 798) translate(16 16) matrix(1 0 0 -1 0 24)"
      fill="currentColor"
    />
  </svg>
);

export const CloseIcon = ({ ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...rest}>
    <path d="M23 3.2L20.8 1 12 9.8 3.2 1 1 3.2 9.8 12 1 20.8 3.2 23l8.8-8.8 8.8 8.8 2.2-2.2L14.2 12 23 3.2z" fill="currentColor" />
  </svg>
);
