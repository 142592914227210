import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import Icons from '../../common/Icons';

export const TitleIconDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  margin-left: 2rem;
  min-height: 4rem;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 1rem 0 2rem 0;
    align-items: center;
  }

`;

export const Title = styled.h3`
  font-size: ${({ theme, size }) => theme.fontSizes[size]};
`;

export const IconDiv = styled.div`
  cursor: pointer;
  margin-left: 1rem;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-left: auto;
  }
`;

const FormTitle = ({ title = '', onClick, children }) => (
  <TitleIconDiv onClick={onClick}>
    <Title size="titleXSmall">{ title }</Title>
    <IconDiv>
      <Icons icon="edit" iconColor="#4E5152" width={3.5} />
    </IconDiv>
    {children && children}
  </TitleIconDiv>
);

FormTitle.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
};

FormTitle.defaultProps = {
  children: '',
};

export default FormTitle;
