import styled, { css } from 'styled-components/macro';


export const CardWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  margin: 2rem 0;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {  
    margin: 4rem 0;
  }
`;

export const CardContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {  
    flex-direction: column;
    padding: 4rem 2rem 0;
  }

`;

export const BlueCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.themeTertiary};
  overflow: hidden;
  height: auto;
  min-height: 2px;

  ${(props) => props.closed && css`
    max-height: 2px;
    transition: max-height 0.6s ease-out;
  `};
  
  ${({ closed }) => !closed && css`
    max-height: 1100px;
    transition: max-height 0.6s ease-in;
  `};
`;

export const SeeLessDiv = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.themeTertiary};
  height: 3.4rem;
  border-top: 2px solid ${({ theme }) => theme.colors.grey};
  display: flex;
  justify-content: center;
  cursor: pointer;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {  
    margin-top: 2rem;
  }
`;
