import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Icons from '../../Icons';

const ContainerLayer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius.card};
  cursor: pointer;
`;

const Container = styled.div`
  box-shadow: ${(props) => props.theme.shadows.shadow1};
  border-radius: ${(props) => props.theme.borderRadius.card};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ isPopup }) => (isPopup ? '0.4rem' : '2.6rem')};
  background-color: ${({ isPopup }) => (isPopup ? 'rgba(207, 242, 255, 0.5)' : 'initial')};
`;

const Part = styled.div`
  display: flex;
`;

const PartIcon = styled.div`
  padding: 0rem 1rem;
  cursor: pointer;
  transition: all .2s ease-in-out;
`;

const PartFluid = styled.div`
  width: calc(100% - 4rem);
  padding: 1.5rem 2rem;

  @media(min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 2.5rem 3rem;
  }
`;

const LineCenter = styled.div`
  display:flex;
  justify-content:center;
  align-items: center;
  margin-bottom: 1.5rem;

  svg {
    margin-right: 0.5rem;
  }
`;

const VerticalSeparator = styled.div`
  width: 2px;
  background-color: ${(props) => props.theme.colors.themePrimary};
`;

const Title = styled.p`
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: ${(props) => props.theme.fontSizes.large};
  font-weight: 700;
`;

const Text = styled.p`
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: ${(props) => props.theme.fontSizes.medium};
  display: ${({ open }) => (open ? '' : '-webkit-box')};
  -webkit-line-clamp: ${({ open, lineCamp }) => (open ? '' : lineCamp)};
  -webkit-box-orient: ${({ open }) => (open ? '' : 'vertical')};
  overflow: ${({ open }) => (open ? '' : 'hidden')};
`;

const CardNotification = (props) => {
  const { title, text, isReaded, isPopup, onClick, lineCamp = 4 } = props;
  const [open, setOpen] = useState(false);

  return (
    <ContainerLayer isreaded={isReaded} onClick={() => !isPopup && setOpen(!open)}>
      <Container isPopup={isPopup} onClick={onClick}>
        <PartFluid>
          <LineCenter>
            {(!isReaded || isPopup) && <Icons icon="bell" iconColor="alertAccepted" width={2} />}
            <Title>{title}</Title>
          </LineCenter>
          <Text lineCamp={lineCamp} open={open} dangerouslySetInnerHTML={{ __html: text }} />
        </PartFluid>

        <Part><VerticalSeparator /></Part>

        <PartIcon style={{ transform: open ? 'rotate(180deg)' : '' }}>
          <Icons icon="chevronDown" iconColor="themePrimary" width={2.4} />
        </PartIcon>
      </Container>
    </ContainerLayer>
  );
};

CardNotification.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isReaded: PropTypes.bool.isRequired,
  isPopup: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  lineCamp: PropTypes.number,
};

CardNotification.defaultProps = {
  isPopup: false,
};

export default CardNotification;
