import React from 'react';
import { Link } from '../../components/common';

const errorMessages = {
  UserNotFoundException: 'El usuario no existe',
  NotAuthorizedException: 'La contraseña introducida no es correcta',
  LimitExceededException: 'Limite excedido, pruebe más tarde',
  ExpiredCodeException: () => (
    <>
      El codigo ha expirado,
      {' '}
      <Link to="/password-recover">vuelve a repetir el proceso</Link>
    </>
  ),
  NetworkError: 'Error de conexión',
  AccessDenied: 'Acceso denegado',
  UnexpectedError: 'Error inesperado, vuelva a intentarlo',
};

// eslint-disable-next-line import/prefer-default-export
export const getErrorMessage = (errorType) => errorMessages[errorType] || null;
