import React from 'react';
import styled from 'styled-components/macro';

const NotificationNumber = styled.div`
  width: 20px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.alertNotification};
  color: ${({ theme }) => theme.colors.white};
`;

const NotificationIcon = ({ number }) => (
  <NotificationNumber>{number}</NotificationNumber>
);

export default NotificationIcon;
