import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import {
  BackArrowTitle,
  ErrorMessage,
  PageContainer,
  PageSubMenuPill,
  ProgressBar,
  SingleRow,
} from '../components/common';
import DocumentBox from '../components/documents/DocumentBox';
import LoggedLayout from '../components/layout/LoggedLayout';
import { studentActions } from '../redux/actions';
import { getStudent } from '../services/api/student';


const Text = styled.div`
display:flex;
justify-content:center;
height: 2.2rem;
font-size: ${({ theme }) => theme.fontSizes.medium};
line-height: 1.47;
`;

const BoxesWrapper = styled.div`
  margin: auto;
`;

const HSeparator = styled.div`
  margin-top: 40px;
`;

const Documents = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);

  const student = useSelector((state) => state?.student || {});
  const { id } = student;
  const documents = student?.documents || [];
  const internshipDocuments = (student?.internship?.documents || []);

  const [activeSection, setActiveSection] = useState('Estudiante');

  const studentDocuments = documents.filter((doc) => doc.responsible !== 'nexus');
  const nexusDocuments = documents.filter((doc) => doc.responsible === 'nexus');
  const nexusDocumentsAll = [...nexusDocuments, ...internshipDocuments];

  const documentsCount = studentDocuments.length;
  const documentsAccepted = studentDocuments.filter((doc) => doc.status === 'accepted').length;
  const documentsText = documentsCount ? `${documentsAccepted} aceptado${documentsAccepted !== 1 ? 's' : ''} de ${documentsCount} necesarios` : 'No hay solicitud de documentos';
  const progress = (documentsAccepted * 100) / documentsCount || 0;

  const onSaveFile = async (file, docId) => {
    if (!file) return;
    if (error) setError(false);

    const { data: fetchedStudentData, success } = await getStudent();

    if (!success) return setError(true);

    const docIndex = fetchedStudentData.documents.findIndex((doc) => doc.docpatternId === docId);
    if (docIndex === -1) return;

    const updatedDocuments = fetchedStudentData.documents.map((doc, index) => {
      if (index !== docIndex) return doc;

      return {
        ...doc,
        documentFile: file,
        status: doc.status === 'rejected' || doc.status === 'pending' ? 'received' : doc.status,
      };
    });

    dispatch(studentActions.save({ documents: updatedDocuments }));
  };

  return (
    <LoggedLayout>
      <PageContainer>
        <BackArrowTitle title="Mis Documentos" />
        <SingleRow />
        <PageSubMenuPill title1="Estudiante" title2="Nexus" active={activeSection} setActive={setActiveSection} />
        {error && <ErrorMessage>No se ha podido subir el documento correctamente, porfavor vuelve a intentarlo</ErrorMessage>}

        {
          activeSection === 'Estudiante' && (
            <>
              <ProgressBar progress={Math.round(progress)} />
              <Text>{documentsText}</Text>
              <BoxesWrapper>
                {
                  studentDocuments?.length > 0 && studentDocuments.map((doc) => (
                    <DocumentBox
                      entity="docpattern"
                      key={doc.name}
                      document={doc}
                      userId={id}
                      index={doc.docpatternId}
                      onSaveFile={onSaveFile}
                    />
                  ))
                }
              </BoxesWrapper>
            </>
          )
        }

        {
          activeSection === 'Nexus' && (
            <>
              <HSeparator />
              <Text>{nexusDocumentsAll?.length === 0 && 'Aún no hay documentos'}</Text>
              <BoxesWrapper>
                {
                  nexusDocumentsAll?.length > 0 && nexusDocumentsAll.filter((document) => (
                    document.documentFile && (document.showToStudent || document.onExtension)
                  )).map((doc) => (
                    <DocumentBox
                      entity="docpattern"
                      key={doc.name}
                      document={doc}
                      userId={id}
                      index={doc.docpatternId}
                      onSaveFile={onSaveFile}
                    />
                  ))
                }
              </BoxesWrapper>
            </>
          )
        }

      </PageContainer>
    </LoggedLayout>
  );
};

export default Documents;
