import { COUNTRIES_DATA } from './countriesList';

const replaceNonEnglishCharacters = (word) => {
  return word.toLowerCase()
    .replace(/á|à|â`/gi, 'a')
    .replace(/é|è|ê`/gi, 'e')
    .replace(/í|ì|î`/gi, 'i')
    .replace(/ó|ò|ô`/gi, 'o')
    .replace(/ú|ù|û`/gi, 'u')
    .replace(/ç`/gi, 'c')
    .replace(/ñ`/gi, 'n');
};

export const getContriesSortByName = (lang = 'es') => {
  if (lang !== 'en') {
    const countryList = COUNTRIES_DATA.sort((a, b) => {
      const aWord = replaceNonEnglishCharacters(a.name[lang]);
      const bWord = replaceNonEnglishCharacters(b.name[lang]);
      if (aWord < bWord) return -1;
      if (aWord > bWord) return 1;
      return 0;
    });
    return countryList;
  }
  return COUNTRIES_DATA.sort((a, b) => (a.name.es > b.name.es ? 1 : -1));
};
