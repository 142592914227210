import { matchSorter } from 'match-sorter';
import localStorage from '../../../services/localStorage';

const onSearch = ({
  localSearch, vacancies, search, setFilteredList, setFilters, defaultFilters, setPage,
}) => {
  if (vacancies.length <= 0) return;

  const newSearch = search || localSearch;
  console.log('Search term:', newSearch);
  
  if (!newSearch?.length) {
    setFilteredList([]);
    setFilters(defaultFilters);
    setPage(1);
    localStorage.removeSearch();
    return;
  }

  setFilters(defaultFilters);

  const terms = newSearch.split(' ');
  const lang = 'es';

  const result = terms.reduceRight(
    (accumulator, term) => matchSorter(accumulator, term, {
      threshold: matchSorter.rankings.WORD_STARTS_WITH, 
      keys: ['city', 'company', 'department', 'type', `descriptionLang.${lang}`],
    }),
    vacancies,
  );

  console.log('MatchSorter result:', result);

  const filteredByActive = result.filter((elem) => elem.active);
  console.log('Filtered by active:', filteredByActive);

  setFilteredList(filteredByActive);

  if (filteredByActive.length === 0) {
    console.log('No results found');
    setFilteredList([]);
  }

  setPage(1);

  if (search) localStorage.addSearch(search);
  localStorage.removeFilters();
};

export default onSearch;
