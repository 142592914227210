import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _get from 'lodash/get';
import _remove from 'lodash/remove';
import ButtonSecondary from '../../../common/Buttons/ButtonSecondary';
import { Modal, SingleRow } from '../../../common';
import NewDigital from './NewDigital';
import Digital from './Digital';
import { studentActions } from '../../../../redux/actions';

import {
  FullFlexCont,
  WhiteSpace,
  NoIconTitle,
  MobilePadding,
} from '../../formStyledComponents';
import { digitalLevels } from '../../helpers';
import { translateDigitalLevel } from '../../helpers/constants';

const DigitalExperience = ({ profile, littleDevice }) => {
  const dispatch = useDispatch();

  const [digitalExperience, setDigitalExperience] = useState([]);

  useEffect(() => {
    const digitals = _get(profile, 'digitalExperience', []);
    setDigitalExperience(digitals);
  }, [profile]);

  /* delete digital experience button */
  const deleteExperience = (digitalName) => {
    const digitals = [];
    digitalExperience.forEach((digit) => digitals.push(digit));
    const findDigitalToDelete = (digit) => digit.name === digitalName;
    const deleted = _remove(digitals, findDigitalToDelete);
    dispatch(studentActions.save({ digitalExperience: digitals }));
  };

  /* open / close add-digital-experience-modal */
  const [digitModalActive, setDigitModalActive] = useState(false);

  const onClose = () => {
    setDigitModalActive(false);
  };

  const openModal = () => {
    setDigitModalActive(true);
  };

  /* add-digital-experience button */
  const addDigital = (newDigital) => {
    const digitals = [];
    digitalExperience.forEach((digit) => digitals.push(digit));
    digitals.push(newDigital);
    dispatch(studentActions.save({ digitalExperience: digitals }));
    onClose();
  };

  return (
    <>
      <MobilePadding>
        <FullFlexCont mobilePadding="0">
          <NoIconTitle>COMPETENCIAS DIGITALES</NoIconTitle>
        </FullFlexCont>
      </MobilePadding>

      {!littleDevice && <SingleRow />}

      {digitalExperience && digitalExperience.map((digit) => {
        const name = _get(digit, 'name', '');
        const level = _get(digit, 'level', '');
        const certificate = _get(digit, 'certificate', '');

        return (
          <Digital
            key={name + level}
            name={name}
            level={translateDigitalLevel(level)}
            certificate={certificate}
            deleteExperience={deleteExperience}
            onClose={onClose}
            levels={digitalLevels}
            profile={profile}
            littleDevice={littleDevice}
            digitalExperience={digitalExperience}
          />
        );
      })}

      <WhiteSpace height="4rem" />

      {digitalExperience && !littleDevice && <ButtonSecondary text="Añadir competencia" fullWidth onClick={openModal} />}

      {digitModalActive
        && (
          <Modal onClose={onClose}>
            <NewDigital
              addDigital={addDigital}
              levels={digitalLevels}
              littleDevice={littleDevice}
            />
          </Modal>
        )}
    </>
  );
};

export default DigitalExperience;
