import React from 'react';
import styled, { css } from 'styled-components/macro';
import PropTypes from 'prop-types';


const Container = styled.div`
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-right: 2rem;
  padding-left: 2rem;
  width: 100%;
  min-height: calc(100vh - 18rem);

  @media (min-width: ${({ theme }) => theme.breakpoints.smMin}) {
    padding-top: 6rem;
    padding-bottom: 6rem;
    padding-right: 3rem;
    padding-left: 3rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.mdMin}) {
    padding-right: 6rem;
    padding-left: 6rem;
    width: calc(100vW - 18rem)
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lgMin}) {
    padding-right: 14rem;
    padding-left: 12rem;
  }

  /* ${(props) => props.noPadding && css`
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      padding-right: 0;
      padding-left: 0;
    }
  `} */

  ${(props) => props.processes && css`
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      padding-right: 2rem;
      padding-left: 2rem;
    }
  `}

  ${(props) => props.$profile && css`
    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      height: 170vh;
    }
  `}
  ${(props) => props.contents && css`
    margin: auto;
    // overflow: scroll;
  `}
`;


const PageContainer = ({ children, noPadding, processes, profile, contents }) => (
  <Container noPadding={noPadding} processes={processes} $profile={profile} contents={contents}>
    {children}
  </Container>
);

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageContainer;


