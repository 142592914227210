import React from 'react';
import styled from 'styled-components/macro';
import { color } from 'styled-system';
import PropTypes from 'prop-types';

const Container = styled.div`
  ${color}
  color: ${(props) => props.theme.colors.textInverse};
  padding: ${({ small }) => (small ? '0.2rem 1.5rem' : '0.4rem 1.5rem')} ;
  width: ${(props) => (props.full ? '100%' : 'fit-Content')};
  border-radius:${(props) => props.theme.borderRadius.tag};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: 700;
  text-align: center;
`;

const TagDepartamento = (props) => {
  const {
    title, bgColor, full, small,
  } = props;

  return (
    <Container bg={bgColor} full={full} small={small}>
      {title}
    </Container>
  );
};

TagDepartamento.propTypes = {
  bgColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  full: PropTypes.bool,
  small: PropTypes.bool,
};

TagDepartamento.defaultProps = {
  full: false,
  small: false,
};

export default TagDepartamento;
