import React, { useState } from 'react';
import EmergencyContactList from './EmergencyContactList';
import SingleRow from '../../../common/SingleRow';
import ButtonPrimary from '../../../common/Buttons/ButtonPrimary';
import ButtonSecondary from '../../../common/Buttons/ButtonSecondary';

import {
  WhiteSpace,
  FullFlexCont,
  Column,
} from '../../formStyledComponents';

const EditEmergencyContact = ({
  setSelectedEdit,
  emergencyContactData,
  openModal,
  profile,
}) => {

  const [contactShown, setContactShown] = useState(null);

  return (
    <>
      <WhiteSpace mobileHeight="2rem" height= '2rem'/>

      <EmergencyContactList
        emergencyContactData={emergencyContactData}
        setContactShown={setContactShown}
        contactShown={contactShown}
        key={contactShown}
        profile={profile}
      />

      {!contactShown && (

        <>
          <SingleRow mobileMargin="0" margin="0"/>

          <FullFlexCont justify="space-between" margin="2rem 0 0 0" >
            <Column width="46" padding="0" margin="0">
              <ButtonSecondary
                text="Atrás"
                onClick={() => setSelectedEdit(null)}
                margin="auto"
                fullWidth
              />
            </Column>

            <Column width="46" padding="0" margin="0">
              <ButtonPrimary
                type={2}
                text="Añadir"
                margin="auto"
                fullWidth
                onClick={openModal}
              />
            </Column>
          </FullFlexCont>
        </>
      )}
    </>
  );
};

export default EditEmergencyContact;