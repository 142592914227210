import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import _get from 'lodash/get';
import SingleRow from '../../../common/SingleRow';
import CourseToEdit from './CourseToEdit';
import ButtonPrimary from '../../../common/Buttons/ButtonPrimary';
import ButtonSecondary from '../../../common/Buttons/ButtonSecondary';
import NewCourse from '../otherCourses/NewCourse';
import { studentActions } from '../../../../redux/actions';

import {
  FullFlexCont,
  ModalMenuP,
  NoIconTitle,
  WhiteSpace,
  Column
} from '../../formStyledComponents';


const CoursesList = ({
  profile,
  setSelectedCvEdit,
  littleDevice,
}) => {

  const dispatch = useDispatch();

  const [selectedCourse, setSelectedCourse] = useState(null);

  const onSelectField = (field) => {
    setSelectedCourse(field);
  };

  /* add-prev-studies button */
  const addCourse = (data) => {
    const courses = [];
    const otherExperiences = _get(profile, 'otherExperience', []);
    otherExperiences.forEach(exp => {
      courses.push(exp);
    });
    courses.push(data.name);
    dispatch(studentActions.save({ otherExperience: courses }));
    setSelectedCourse(null);
  };

  const otherExperience = _get(profile, 'otherExperience', []);

  return (
    <FullFlexCont padding="0" between alignEnd mobilePadding="0">

      <NoIconTitle width="100%" mmargin=" 2rem 0" center>OTROS CURSOS</NoIconTitle>

      <WhiteSpace mobileHeight="5rem" height="2rem" />

      {
        otherExperience.map((course, i) => {

          return (
            selectedCourse === course && (
              <CourseToEdit
                i={i}
                key={course + i}
                name={course}
                setSelectedCourse={setSelectedCourse}
                profile={profile}
              />
            )
          );
        })
      }

      {!selectedCourse && (
        <>
          {otherExperience.map(course => {
            return <ModalMenuP key={course} onClick={() => onSelectField(course)}>{course}</ModalMenuP>;
          })}

          <SingleRow mobileMargin="0" margin="0" />
        </>
      )}

      {!selectedCourse &&
        (
          <FullFlexCont justify="space-between">
            <Column width="46" padding="0">
              <ButtonSecondary
                text="Atrás"
                onClick={() => setSelectedCvEdit(null)}
                margin="auto"
                fullWidth
              />
            </Column>

            <Column width="46" padding="0">
              <ButtonPrimary
                type={2}
                text="Añadir"
                margin="auto"
                fullWidth
                onClick={() => setSelectedCourse('addCourse')}
              />
            </Column>
          </FullFlexCont>
        )}

      {selectedCourse === 'addCourse' && (
        <NewCourse
          addCourse={addCourse}
          profile={profile}
          littleDevice={littleDevice}
          setSelectedCourse={setSelectedCourse}
          isEditModal
        />
      )}

    </FullFlexCont>
  );
};

export default CoursesList;

