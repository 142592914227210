import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import {
  PageContainer, BackArrowTitle, SingleRow, CardPagos, Modal,
} from '../components/common';
import HSpacer from '../components/layout/HSpacer';
import LoggedLayout from '../components/layout/LoggedLayout';
import { PaymentMethods } from '../components/payments';

import { dates, payment } from '../utils';
import usePaymentSuccess from '../hooks/usePaymentSuccess';


const Message = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  text-align: center;
  line-height: 1.47;
`;

const ModalContent = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 0 4rem 4rem;
`;

const PaymentDataLine = styled.h3`
  color: ${({ theme }) => theme.colors.themePrimary};
  margin-bottom: 2rem;
  text-align: center;
`;

const quotaNameDictionary = {
  firstPayment: 'Primer pago',
  secondPayment: 'Segundo pago',
  insurance: 'Seguro',
  extension: 'Prórroga',
};

const PaymentHistory = () => {
  const student = useSelector((state) => state.student);
  const { payments } = student;
  const screen = useSelector((state) => state.windowResize.screen);
  const isSmallScreen = screen === 'phone' || screen === 'tab-port';

  const [selected, setSelected] = useState(null);
  const [method, setMethod] = useState('deposit');
  const [totalAmount, setTotalAmount] = useState(0);
  const [quotaNameToPay, setQuotaNameToPay] = useState('');
  const [id, setId] = useState('');

  const {
    error,
    isOpen,
    onPaymentSuccess,
    setIsOpen,
    setShowPaymentSuccessModal,
    showPaymentSuccessModal,
  } = usePaymentSuccess({
    payments,
    paymentId: id,
    paymentMethod: method,
  });

  const onClick = (paymentId) => {
    const paymentClicked = payments.find((item) => item.id === paymentId);
    setId(paymentId);
    setTotalAmount(paymentClicked.amount);
    setQuotaNameToPay(paymentClicked.quotaName);
    setSelected(paymentClicked);
  };

  const onChange = (name, value) => {
    setMethod(value);
  };

  const currencySymbol = payments ? payment.currencySymbols[payments[0].currency] : payment.currencySymbols.DEFAULT;

  return (
    <LoggedLayout>
      <PageContainer>

        <BackArrowTitle title="Historial de pagos" linkto="/menu" />

        {isSmallScreen ? <HSpacer height="2.5rem" /> : <SingleRow />}

        <Message>
          SELECCIONA LOS PAGOS A REALIZAR
        </Message>

        {
          payments?.map((item) => {
            if (!item) return null;
            const { status, dateBefore, quotaName, amount, id: paymentId } = item;
            if (!amount) return null;
            const title = quotaNameDictionary[quotaName];
            // const active = selected.indexOf(title) !== -1;
            const active = paymentId === id;
            const dateData = dateBefore ? dates.getYearMonthDay(new Date(dateBefore)) : '';
            const dateText = dateData ? `${dateData.day}-${dateData.month}-${dateData.year}` : '';

            return (
              <CardPagos
                key={paymentId}
                id={paymentId}
                title={title}
                money={amount}
                date={dateText}
                status={status}
                onClick={() => onClick(paymentId)}
                active={active}
                setIsOpen={setIsOpen}
                currencySymbol={currencySymbol}
              />
            );
          })
        }

      </PageContainer>

      {
        isOpen && (
          <Modal onClose={() => setIsOpen(false)} minHeight="50vh" minWidth="40vw">
            <ModalContent>
              <PaymentDataLine>
                Datos de pago: {quotaNameDictionary[quotaNameToPay]}, {totalAmount}{currencySymbol}
              </PaymentDataLine>
              <PaymentMethods
                amount={totalAmount}
                error={error}
                method={method}
                onChange={onChange}
                onPaymentSuccess={onPaymentSuccess}
                paymentId={id}
                setShowPaymentSuccessModal={setShowPaymentSuccessModal}
                showPaymentSuccessModal={showPaymentSuccessModal}
              />
            </ModalContent>
          </Modal>
        )
      }
    </LoggedLayout>
  );
};

export default PaymentHistory;
