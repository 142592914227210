import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import ButtonPrimary from '../Buttons/ButtonPrimary';


const Container = styled.div`
  display: flex;
  justify-content: ${(props) => (props.width ? 'flex-end' : 'center')};;
  align-items: center;
  position: ${(props) => (props.position ? 'relative' : 'fixed')};
  bottom: 20rem;
  right: ${(props) => (props.rightPositioned ? '-30px' : '-62px')};
  width: ${(props) => (props.width ? props.width : null)};
  margin-left: ${(props) => (props.margin ? null : '-12rem')};
  margin-right:${(props) => (props.margin ? props.margin : null)};
  
  button {
    margin-left: -25rem;
    /* align-items: flex-end; */
    max-height:6rem;
    width:20rem;

    svg {
      width: 2.4rem;
      margin-right: 0.5rem;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    margin-left: -6rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-left: -3rem;
    bottom: 0;
    button {
      margin-left: 0rem;
      width: ${({ blockedButton }) => (blockedButton ? '9rem' : '20rem')};
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-left: -2rem;
  }
`;

const FixedFooterButton = (props) => {
  const {
    text,
    icon,
    onClick,
    width,
    margin,
    rightPositioned,
    position,
    payButton,
    blockedButton,
    buttonWidth,
    disabled,
  } = props;

  return (
    <Container width={width} margin={margin} rightPositioned={rightPositioned} position={position} blockedButton={blockedButton}>
      <ButtonPrimary
        text={text}
        icon={icon}
        onClick={onClick}
        type={2}
        fontSize="large"
        buttonWidth={buttonWidth}
        blockedButton={blockedButton}
        payButton={payButton}
        disabled={disabled}
      />
    </Container>
  );
};

FixedFooterButton.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

FixedFooterButton.defaultProps = {
  icon: '',
};

export default FixedFooterButton;
