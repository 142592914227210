import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { Box, ButtonPrimary, ButtonSecondary, Icons, Modal, SingleRow } from '../../common';
import { studentActions } from '../../../redux/actions';
import HSpacer from '../../layout/HSpacer';
import { paddingBottom } from 'styled-system';

const PopUpContainer = styled.div`
  @media(min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 50rem;
    margin-bottom: 1rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
  padding: 0rem 2rem 4rem;
  margin: 0 auto;

  .loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #4798B6;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  text-align: center;
  margin: auto;
}
   @keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
`;

const PopUpTitle = styled.p`
  font-weight: bold;
  margin: 1.5rem auto;
  text-align: center;
`;

const OrderButton = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.themeTertiary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  color: ${({ theme }) => theme.colors.themeSecondary};
  transition: 0.1s ease-in-out;
  margin: 1rem;

  &:hover {
    color: ${({ theme }) => theme.colors.themeInverse};
    background-color: ${({ theme }) => theme.colors.themeSecondary};
    cursor: pointer;
  }
`;

const PopUpSubtitle = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: bold;
  text-align: center;
`;

const VacancyLine = styled(Box)`
  min-height: 2rem;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

const Order = styled.p`
  font-weight: bold;
  margin-right: 0.5rem;
`;

const VacancyTitle = styled.p`
  text-align: center;
  flex-grow: 1;
  color:${(props) => (props.empty ? 'lightgray' : null)};
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  margin-top: 3rem;
`;

const RemoveIconContainer = styled.div`

  &:hover {
    cursor: pointer;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    right: 0.5rem;
    top: 0.5rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {   
    right: 2rem;
  }
`;

const CardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 4rem;
  flex-wrap: wrap;
  text-align: center;
  line-height: 2;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    text-align: center;
    padding: 2rem;
  }
`;

const OrderPopUp = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { newVacancy, setVacancyId } = props;
  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idToRemove, setIdToRemove] = useState('');

  const student = useSelector((state) => state.student);
  const realPriorities = student.priorities || [];
  realPriorities.sort((prio1, prio2) => (prio1.order - prio2.order));
  const priorityInput = realPriorities.map((prio) => ({ order: prio.order, priorityVacancyId: prio.vacancy.id }));
  const auxArray = new Array(4).fill(1);

  const orderPriorities = async (vacancy, order) => {
    setLoading(true);
    try {
      const { id } = vacancy;
      const updatedPriorities = priorityInput.filter((priority) => priority.priorityVacancyId !== id);
      const withoutCurrentOrder = updatedPriorities.filter((priority) => priority.order !== order);
      const newStudent = { priorities: [...withoutCurrentOrder, { order, priorityVacancyId: id }] };
      
      await dispatch(studentActions.save(newStudent));
    } finally {
      setLoading(false);
    }
  };

  const onOpenModal = (order) => {
    const { id } = realPriorities.find((prio) => prio.order === order).vacancy;
    setIdToRemove(id);
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    setIdToRemove('');
    setIsModalOpen(false);
  };

  const onRemove = async () => {
    setLoading(true);
    try {
      const updatedPriorities = priorityInput.filter((item) => item.priorityVacancyId !== idToRemove);
      onCloseModal();
      await dispatch(studentActions.save({ priorities: updatedPriorities }));
    } finally {
      setLoading(false);
    }
  };
  const hasAllPrioritiesSelected = priorityInput.length === 4;

  return (
    <>
      <PopUpContainer>
        <PopUpTitle>
          ASIGNA EL ORDEN DE PRIORIDAD
        </PopUpTitle>

        <Box container justify="space-around">
          {
          auxArray.length > 0 && auxArray.map((prio, index) => {
            const newOrder = index + 1;
            return (
              <OrderButton
                key={index}
                onClick={() => { orderPriorities(newVacancy, newOrder); }}
              >
                {index + 1}
              </OrderButton>
            );
          })
        }
        </Box>

        <SingleRow margin="1.5rem 0" />

        <PopUpSubtitle>
          Vacantes priorizadas
        </PopUpSubtitle>

        {
              loading 
              ? 
              <>
              <SingleRow margin="1.5rem 0" />
              <Box container justify="center" direction="column">
                <div style={{fontWeight:"bold",paddingTop: 10,paddingBottom:10,color:"#4798B6",}}>Actualizando tus vacantes...</div>
              </Box>
              <div className="loader"></div>
              </>
              :
        auxArray.length > 0 && auxArray.map((elem, index) => {
          const order = index + 1;
          let empty = true;
          let message = 'Esta posición está vacía';
          realPriorities.forEach((prio) => {
            if (prio.order === order) {
              empty = false;
              message = `${prio.vacancy.department} (${prio.vacancy.company})`;
            }
          });

          return (
            <React.Fragment key={`listedPriority${order}`}>
              <SingleRow margin="1.5rem 0" />
              <VacancyLine container>
                <Order>
                  {`${order}.`}
                </Order>
                <VacancyTitle empty={empty}>{message}</VacancyTitle>
                {!empty
                && (
                  <RemoveIconContainer onClick={() => onOpenModal(order)}>
                    <Icons height="1.6" icon="trash" iconColor="alertError" width={2} />
                  </RemoveIconContainer>
                )}
              </VacancyLine>
            </React.Fragment>
          );
        })
      }

        <SingleRow margin="1.5rem 0 2rem 0" />

        <ButtonsContainer>
          {hasAllPrioritiesSelected && (
            <Box container justify="center" direction="column">
              <div>¡Has seleccionado tus vacantes!</div>
              <HSpacer height="1.2rem" />
              <ButtonPrimary
                iconColor="#105285"
                type={2}
                text="Ir a bloquear vacantes"
                fontSize="medium"
                onClick={() => history.push('/processes')}
              />
            </Box>
          )}
          {!hasAllPrioritiesSelected && (
            <>
              <ButtonPrimary fontSize="medium" onClick={() => history.push('/processes')} text="Mis procesos" type={2} />
              <ButtonPrimary fontSize="medium" onClick={() => setVacancyId('')} text="Vacantes" type={2} />
            </>
          )}
        </ButtonsContainer>

      </PopUpContainer>
      {
        isModalOpen && (
          <Modal onClose={onCloseModal}>
            <CardContentWrapper>
              <p>¿Estás seguro de que quieres quitar la vacante de las prioridades?</p>

              <HSpacer height="1.2rem" />

              <ButtonsContainer>
                <ButtonSecondary
                  iconColor="#105285"
                  type={2}
                  text="Cancelar"
                  fontSize="medium"
                  onClick={onCloseModal}
                />

                <ButtonPrimary
                  iconColor="#105285"
                  type={2}
                  text="Confirmar"
                  fontSize="medium"
                  onClick={onRemove}
                />
              </ButtonsContainer>
            </CardContentWrapper>
          </Modal>
        )
      }
    </>
  );
};

export default OrderPopUp;
