import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Icons, Box, Loader, Modal } from '../common';
import theme from '../CustomTheme/Theme';
import { getFileURL, uploadFile } from '../../services/storage';


const BoxWrapper = styled.div`
  display:flex;
  align-items: stretch;
  justify-content: space-between;
  border-radius: 1.2rem;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.37);
  background-color: #ffffff;
  margin:22px 0;
  min-height: ${({ isStudentDocument }) => (isStudentDocument ? '120px' : '90px')};
`;

const InfoStateWrapper = styled(Box)`
  flex-grow: 1;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    flex-direction: column;
    align-items: flex-start;
  }
`;

const DocumentInfo = styled.div`
 display:flex;
 flex-direction:column;
 flex-grow: 1;
 padding: 2.8rem 2.6rem 2.2rem 4.2rem;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    padding: 2.1rem 3.4rem 0.5rem 1.7rem;
  }
`;

const DocumentName = styled.div`
  font-size: ${theme.fontSizes.medium};
  font-weight: bold;
  line-height: 1.47;
`;

const EndDate = styled.div`
font-size: ${theme.fontSizes.medium};
line-height: 1.47;
`;

const StateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    align-items: flex-start;
  }
`;

const State = styled.div`
  display:flex;
  justify-content:center;
  margin: 1rem 10rem;
  width: 15rem;
  border-radius: 1.2rem;
  background-color:${(props) => (props.backgroundColor ? props.backgroundColor : '')};;
  font-size: ${theme.fontSizes.medium};
  font-weight: bold;
  line-height: 1.47;
  color: #ffffff;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {   
    margin: 0rem 1.8rem 1.4rem 1.4rem;
    width: 18.5rem;
  }
`;

const ReasonText = styled.p`
  padding: 1rem;
  max-width: 500px;
`;

const Slash = styled.div`
  background-color:${theme.colors.textTertiary};
  height:100%;
  width:2px;
`;

const IconWrapper = styled.div`
  width: 12rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    width: 10rem;
  }
`;

const Icon = styled.div`
  display:flex;
  justify-content:center;
  background-color: ${(props) => (props.iconBackground ? props.iconBackground : theme.colors.textSecondary)};
  width: 5rem;
  height: 5rem;
  border-radius:50%;
  cursor: pointer;

  &:hover{
    background-color: ${theme.colors.themeSecondary};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    width: 4rem;
    height: 4rem;
  }
`;

const ModalContent = styled.div`
  padding: 4rem 2rem 2rem 2rem;
  font-size: 18px;
`;

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

const DocumentBox = ({ document, index, onSaveFile, entity = 'student' }) => {
  const { documentFile, dateEnd, status, name, statusReason, responsible } = document;
  const states = {
    pending: {
      color: theme.colors.alertError,
      text: 'Pendiente',
      up: true,
      down: documentFile && true,
    },
    received: {
      color: theme.colors.themePrimary,
      text: 'Recibido',
      up: true,
      down: true,
    },
    inReview: {
      color: theme.colors.textPrimary,
      text: 'En Revisión',
      up: true,
      down: true,
    },
    accepted: {
      color: theme.colors.alertAccepted,
      text: 'Aceptado',
      down: true,
    },
    rejected: {
      color: theme.colors.alertNotification,
      text: 'Rechazado',
      up: true,
      down: true,
    },
  };

  const icons = {
    up: {
      icon: 'clip-white',
    },
    down: {
      icon: 'arrow-download-white',
    },
  };
  const state = states[status];
  const isStudentDocument = responsible !== 'nexus';

  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [maxFileSize, setMaxFileSize] = useState(false);

  const onUpload = async (event) => {
    const newFile = event.target.files[0];
    if (!newFile) return;

    if (newFile.size > MAX_FILE_SIZE) {
      setMaxFileSize(true);
      return;
    }

    setIsLoading(true);
    const timestamp = +new Date();
    const filename = `${timestamp}-${newFile.name}`.replace(/\s/g, '-').replace(/á|à|â|é|è|ê|í|ì|î|ó|ò|ô|ú|ù|û|ç|ñ`/gi, '-');
    const fileLocation = `${entity}/documentFile/${filename}`;
    const response = await uploadFile(newFile, fileLocation);
    const { success /* data, message */ } = response;
    if (success) onSaveFile(filename, index);
    setIsLoading(false);
  };

  const onDownload = () => {
    const fileUrl = getFileURL(`${entity}/documentFile/${documentFile}`);
    window.open(`${fileUrl}`, `${name}`);
  };

  return (
    <>
      <BoxWrapper isStudentDocument={isStudentDocument}>
        <InfoStateWrapper container justify="space-between">
          <DocumentInfo>
            <DocumentName>{name}</DocumentName>
            {isStudentDocument
            && (
              <EndDate>
                <p>Necesario antes de:</p>
                {dateEnd}
              </EndDate>
            )}
          </DocumentInfo>

          {isStudentDocument
          && (
            <StateContainer>
              <State backgroundColor={state?.color}>
                {state?.text}
              </State>
              {status === 'rejected' && statusReason ? <ReasonText>{statusReason}</ReasonText> : ''}
            </StateContainer>
          )}
        </InfoStateWrapper>

        <Box container>
          <Slash />
          <IconWrapper>
            {
            state?.down && !isLoading && (
              <Icon
                iconBackground={state?.iconBackground}
                onClick={onDownload}
              >
                <Icons icon={icons.down.icon} width={2.8} />
              </Icon>
            )
          }
            {
            state?.up && isStudentDocument && (
              <>
                {isLoading && <Loader loaderColor={theme.colors.textSecondary} />}
                {!isLoading && (
                  <>
                    <Icon
                      iconBackground={state.iconBackground}
                      onClick={() => inputRef.current.click()}
                    >
                      <Icons icon={icons.up.icon} width={2.8} />
                    </Icon>
                    <input
                      ref={inputRef}
                      type="file"
                      onChange={onUpload}
                      style={{ display: 'none' }}
                    />
                  </>
                )}
              </>
            )
          }

          </IconWrapper>
        </Box>
      </BoxWrapper>
      {maxFileSize
        && (
          <Modal onClose={() => setMaxFileSize(false)} paddingCrossButton="2rem 2rem 0 2rem" minHeight="20rem">
            <ModalContent>
              El tamaño máximo permitido para la carga de archivos es de 10MB. Por favor, selecciona un archivo más pequeño.
            </ModalContent>
          </Modal>
        )}
    </>
  );
};

DocumentBox.propTypes = {
  document: PropTypes.shape({
    documentFile: PropTypes.string,
    dateEnd: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    statusReason: PropTypes.string,
    responsible: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onSaveFile: PropTypes.func.isRequired,
  entity: PropTypes.string,
};

DocumentBox.defaultProps = {
  entity: 'student',
};

export default DocumentBox;
