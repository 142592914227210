import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { windowResize, studentActions } from './redux/actions';
import Routes from './routes';
import CustomTheme from './components/CustomTheme';
import { ModalProvider } from './components/common/Modal';
// import Catalog from './pages/Catalog';

import './scss/base/index.scss';
import { NotificationsProvider } from './contexts/NotificationsContext';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(studentActions.refresh());
  }, [dispatch]);

  useEffect(() => {
    dispatch(windowResize.listener());
  });

  return (
    <CustomTheme>
      <NotificationsProvider>
        <ModalProvider>
          <Routes />
          {/* <Catalog /> */}
        </ModalProvider>
      </NotificationsProvider>
    </CustomTheme>
  );
}

export default App;
