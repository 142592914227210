import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _get from 'lodash/get';
import _remove from 'lodash/remove';
import ButtonSecondary from '../../common/Buttons/ButtonSecondary';
import ButtonPrimary from '../../common/Buttons/ButtonPrimary';
import EmergencyContact from './EmergencyContact';
import NewContact from './NewContact';
import EditContactModal from './EditContactModal';
import { EditIcon } from '../../common/Icons/IconsList';
import { Modal } from '../../common';
import { studentActions } from '../../../redux/actions';

import {
  WhiteSpace,
  Column,
  BottomButtons,
} from '../formStyledComponents';

const EmergencyContactForm = ({
  emergencyContactData,
  littleDevice,
  contactData,
  formActive,
  setFormActive,
  profile,
}) => {
  const dispatch = useDispatch();

  const [modalActive, setModalActive] = useState(false);

  const [editModalActive, setEditModalActive] = useState(false);

  /* open / close add-contact-modal */
  const onClose = () => {
    setModalActive(false);
  };

  const openModal = () => {
    setModalActive(true);
  };

  /* open / close edit-modal */
  const onCloseEdit = () => {
    setEditModalActive(false);
  };

  const openModalEdit = () => {
    setEditModalActive(true);
  };

  /* delete-contact button */
  const deleteField = (name, email) => {
    const contacts = [];
    emergencyContactData.forEach((contact) => contacts.push(contact));
    const findContactToDelete = (contact) => contact.name === name && contact.email === email;
    const deleted = _remove(contacts, findContactToDelete);
    dispatch(studentActions.save({ emergencyContacts: contacts }));
  };

  /* add-contact button */
  const addContact = (contactData) => {
    const contacts = [];
    if (emergencyContactData) emergencyContactData.forEach(contact => contacts.push(contact));
    contacts.push(contactData);
    dispatch(studentActions.save({ emergencyContacts: contacts }));
    onClose();
  };


  return (
    <>
      {emergencyContactData && emergencyContactData.map((contact, index) => {
        const name = _get(contact, 'name', '');
        const email = _get(contact, 'email', '');
        const phone = _get(contact, 'phone', '');
        const relationship = _get(contact, 'relationship', '');
        return (
          <EmergencyContact
            key={name + email}
            index={index}
            name={name}
            email={email}
            phone={phone}
            relationship={relationship}
            deleteField={deleteField}
            modalActive={modalActive}
            profile={profile}
            emergencyContactData={emergencyContactData}
          />
        );
      })}

      <WhiteSpace height="2rem" />

      {!littleDevice
        && (
          <ButtonSecondary
            text="Añadir contacto de emergencia"
            fullWidth
            onClick={openModal}
          />
        )}

      {littleDevice
        && (
          <>
            <WhiteSpace mobileHeight="10rem" />

            <BottomButtons justify="space-between">
              <Column width="46">
                <ButtonSecondary
                  text="Añadir +"
                  onClick={openModal}
                  margin="auto"
                  fullWidth
                />
              </Column>

              <Column width="46">
                <ButtonPrimary
                  iconComponent={<EditIcon height="3rem" />}
                  type={2}
                  text="Editar"
                  onClick={openModalEdit}
                  margin="auto"
                  fullWidth
                  mobilePadding="0.5rem"
                />
              </Column>
            </BottomButtons>
          </>
        )}

      {modalActive
        && (
          <Modal onClose={onClose}>
            <NewContact addContact={addContact} littleDevice={littleDevice} onClose={onClose} />
          </Modal>
        )}

      {editModalActive
        && (
          <Modal onClose={onCloseEdit}>
            <EditContactModal
              addContact={addContact}
              onClose={onCloseEdit}
              contactData={contactData}
              formActive={formActive}
              setFormActive={setFormActive}
              openModal={openModal}
              emergencyContactData={emergencyContactData}
              profile={profile}
            />
          </Modal>
        )}

    </>
  );
};

export default EmergencyContactForm;
