import { useState, useEffect } from 'react';

import { listPublicEntity } from '../services/api/data';

const useListPublicEntity = (entity) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const { success, message, data } = await listPublicEntity(entity);
      if (success) {
        setData(data);
      } else {
        setError(message);
      }
      setLoading(false);
    };
    fetchData();

  }, [entity]);

  return { data, error, loading };
}

export default useListPublicEntity;
