import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import EditDeleteIcons from '../../EditDeleteIcons';
import showOptions from '../../helpers/showOptions';
import { SingleRow } from '../../../common';
import SeeMoreCard from '../../SeeMoreCard';
import { studentActions } from '../../../../redux/actions';

import {
  Label,
  InputColumn,
  FullFlexCont,
  ContactForm,
  Input,
  EditDeleteIconsCont,
  Select,
  Column,
  DisabledBlueInput,
  MobilePadding,
} from '../../formStyledComponents';

const Digital = ({
  name,
  level,
  certificate,
  deleteExperience,
  levels,
  profile,
  littleDevice,
  digitalExperience,
}) => {

  const { register, handleSubmit } = useForm();

  const dispatch = useDispatch();

  /* set the form to active/inactive to be edited */
  const initialFormState = digitalExperience !== [] && false;
  const [digitalFormActive, setDigitalFormActive] = useState(initialFormState);

  //Asign a ref to the card, to open it with the "edit" button
  const cardRefDigital = useRef(`digital-${name + level}`)

  const [selectedCardDigital, setSelectedCardDigital] = useState(null);

  const edit = (e) => {
    e.preventDefault();
    setDigitalFormActive(!digitalFormActive);
    setSelectedCardDigital(cardRefDigital.current);
  };

  const openByRef = selectedCardDigital === `digital-${name + level}`;

  const onSubmit = (data, e) => {
    e.preventDefault();
    const digitals = digitalExperience;
    digitals.forEach((exp, index) => {
      if (data.name === exp.name) {
        digitals.splice(index, 1, data);
        dispatch(studentActions.save({ digitalExperience: digitals }));
      }
    });
    setDigitalFormActive(!digitalFormActive);
  };


  return (
    <>
      <ContactForm key={name + level} onSubmit={handleSubmit(onSubmit)}>

        {!littleDevice && (
          <FullFlexCont justify="flex-end" padding="0">

            <InputColumn width="20%" margin="0 auto 0 2rem">
              <DisabledBlueInput
                bold
                width="100%"
                mBottom="0"
                disabled={!digitalFormActive}
                name="name"
                placeholder="competencia digital"
                ref={register({ required: true })}
                value={name}
              />
            </InputColumn>

            <InputColumn width="20%">
              <Label>Nivel:</Label>
              <Select
                width="100%"
                mBottom="0"
                disabled={!digitalFormActive}
                name="level"
                ref={register({ required: true })}
                defaultValue={level}
              >
                {showOptions(levels, level, "nivel")}
              </Select>
            </InputColumn>

            <InputColumn width="20%">
              <Label>Certificado:</Label>
              <Input
                disabled={!digitalFormActive}
                name="certificate"
                ref={register({ required: false })}
                defaultValue={certificate}
              />
            </InputColumn>

            <InputColumn width="20%">
              <EditDeleteIconsCont>
                <EditDeleteIcons
                  edit={edit}
                  deleteField={deleteExperience}
                  isActive={digitalFormActive}
                  onSubmit={onSubmit}
                  confirmText="¿Estás seguro de que quieres eliminar esta competencia?"
                  value1={name}
                  value2={null}
                />
              </EditDeleteIconsCont>
            </InputColumn>

          </FullFlexCont>
        )}

        {littleDevice && (
          <>
            <MobilePadding>
              <FullFlexCont mobilePadding="2rem 0" mobileAlignCenter>
                <DisabledBlueInput
                  bold
                  width="100%"
                  mBottom="0"
                  disabled={!digitalFormActive}
                  name="name"
                  placeholder="competencia digital"
                  ref={register({ required: true })}
                  defaultValue={name}
                  mobileWidth="50%"
                />

                <EditDeleteIconsCont>
                  <EditDeleteIcons
                    edit={edit}
                    deleteField={deleteExperience}
                    isActive={digitalFormActive}
                    onSubmit={onSubmit}
                    confirmText="¿Estás seguro de que quieres eliminar esta competencia?"
                    value1={name}
                    value2={null}
                  />
                </EditDeleteIconsCont>
              </FullFlexCont>
            </MobilePadding>

            <SeeMoreCard
              ref={cardRefDigital}
              openByRef={openByRef}
              selectedCard={selectedCardDigital}
              profile={profile}
              setSelectedCard={setSelectedCardDigital}
            >

              <Column width={30} center mobileFull mobileMargin="0">
                <InputColumn>
                  <Label>Nivel:</Label>
                  <Select
                    width="100%"
                    mBottom="0"
                    disabled={!digitalFormActive}
                    name="level"
                    ref={register({ required: true })}
                    defaultValue={level}
                  >
                    {showOptions(levels, level, "nivel")}
                  </Select>
                </InputColumn>
              </Column>
              <Column width={30} center mobileFull mobileMargin="0">
                <InputColumn>
                  <Label>Certificado:</Label>
                  <Input
                    disabled={!digitalFormActive}
                    name="certificate"
                    ref={register({ required: false })}
                    defaultValue={certificate}
                    mobileWidth="100%"
                    mobileMargin="0"
                    key={name + certificate + level}
                  />
                </InputColumn>
              </Column>

            </SeeMoreCard>
          </>
        )}

      </ContactForm>

      {!littleDevice && <SingleRow />}
    </>
  );
};

export default Digital;