import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { useSelector } from 'react-redux';

import Icons from '../../Icons';

const Container = styled.button`
  max-height: ${({ big, blockedButton }) => (big ? 'initial' : blockedButton ? '6rem' : '4.5rem')};
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  right: ${({ blockedButton, right }) => (blockedButton ? '-25px' : right)};
  margin: ${({ margin }) => margin};
  outline: none;
  cursor: pointer;
  border-radius: 3rem;
  border: transparent;
  border: solid 2px ${(props) => (props.disabled ? props.theme.colors.grey : props.bgColor)};
  width: ${(props) => (props.buttonWidth ? `${props.buttonWidth}rem` : props.fullWidth ? '100%' : 'auto')};
  background-color: ${(props) => (props.disabled ? props.theme.colors.grey : props.bgColor)};
  color: ${({ TextColor }) => TextColor};
  font-size: ${({ theme, fontSize }) => theme.fontSizes[fontSize]};
  font-weight: 700;
  padding: ${({ icon, iconComponent, blockedButton }) => (blockedButton && icon ? '1rem  1rem' : icon || iconComponent ? '1rem 4.2rem' : '1rem  1.5rem')};
  display: ${({ icon, iconComponent }) => (icon || iconComponent ? 'flex' : 'block')};
  justify-content: center;
  align-items: center;
  transition: background-color 0.1s ease-in-out , border-color  0.1s ease-in-out; 
  text-align:center;

  @media (max-width: ${(props) => props.theme.breakpoints.lgMin}) {
    width:  ${({ blockedButton }) => (blockedButton ? '12rem' : null)};
    right:${(props) => (props.blockedButton ? '-25px' : '-20px')};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mdMin}) {
    width:  ${({ blockedButton }) => (blockedButton ? '10rem' : null)};
    margin-bottom:${({ blockedButton }) => (blockedButton && '30px')};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: ${({
    blockedButton, mobileFullWidth, payButton, fullWidth,
  }) => (blockedButton ? '9rem' : mobileFullWidth || fullWidth ? '100%' : payButton ? '20rem' : 'auto')};
    justify-content: ${({ blockedButton }) => (blockedButton ? 'start' : null)};
    margin: ${(props) => (props.mobileFullWidth && 'auto')};
    padding: ${({ icon, iconComponent }) => (icon || iconComponent ? '1rem 2.2rem' : '1rem  1.5rem')}; 
    text-align: ${({
    blockedButton, paymentButton, mobileFullWidth, fullWidth,
  }) => (blockedButton || paymentButton || mobileFullWidth || fullWidth ? 'center' : 'left')};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {   
    padding: ${({ mobilePadding }) => (mobilePadding)};
    width: ${({ mobileWidth }) => (mobileWidth)};
  }

  svg {
    fill: ${({ TextColor }) => TextColor};
    stroke: ${({ TextColor }) => TextColor};
    height: 3.5rem;
    margin-right: 1rem;
  }

  &:hover {
    background-color: ${(props) => (props.disabled ? props.theme.colors.grey : props.hover)};
    border: solid 2px ${(props) => (props.disabled ? props.theme.colors.grey : props.hover)};
    color: ${(props) => (props.disabled ? props.TextColor : props.theme.colors.textInverse)};

    svg {
      fill: ${(props) => (props.disabled ? props.TextColor : props.theme.colors.textInverse)};
      stroke: ${(props) => (props.disabled ? props.TextColor : props.theme.colors.textInverse)};
    }
  }

  ${(props) => props.withActive && css`
    &:active {
      background-color: ${(props) => props.theme.colors.themeSecondary};
      border: 2px solid ${(props) => props.theme.colors.themeSecondary};

    }

    &:focus {
      background-color: ${(props) => props.theme.colors.themeSecondary};
      border: 2px solid ${(props) => props.theme.colors.themeSecondary};
      color: ${(props) => props.theme.colors.textInverse};
    }
  `};

  ${(props) => props.payButton && css`
    display: flex;
    align-items: center;
    justify-content: center;
  `};
`;

const ButtonPrimary = (props) => {
  const {
    text, type, full, onClick, fullWidth, disabled,
    fontSize, icon, position, top, left, right, margin,
    mobileFullWidth, htmlType, big, iconComponent, withActive, buttonWidth, blockedButton, payButton, logIn,
    mobilePadding, mobileWidth,
  } = props;

  const screen = useSelector((state) => state.windowResize.screen);
  const smallDevices = screen === 'phone' || screen === 'tab-port' || screen === 'tab-land';

  const handleOnClick = () => {
    !disabled && onClick();
  };

  let TextColor;
  let bgColor;
  let hover;

  switch (type) {
    case 1:
      TextColor = ({ theme }) => theme.colors.textInverse;
      bgColor = ({ theme }) => theme.colors.greyDark;
      hover = ({ theme }) => theme.colors.themeSecondary;
      break;

    case 2:
      TextColor = ({ theme }) => theme.colors.themeSecondary;
      bgColor = ({ theme }) => theme.colors.themeTertiary;
      hover = ({ theme }) => theme.colors.themeSecondary;
      break;

    case 3:
      TextColor = ({ theme }) => theme.colors.textInverse;
      bgColor = ({ theme }) => theme.colors.themeSecondary;
      hover = ({ theme }) => theme.colors.themePrimary;
      break;

    case 4:
      TextColor = ({ theme }) => theme.colors.themeSecondary;
      bgColor = ({ theme }) => theme.colors.themeTertiary;
      hover = ({ theme }) => theme.colors.themePrimary;
      break;

    default:
      TextColor = ({ theme }) => theme.colors.textInverse;
      bgColor = ({ theme }) => theme.colors.themePrimary;
      hover = ({ theme }) => theme.colors.themeSecondary;
      break;
  }

  return (
    <Container
      bgColor={bgColor}
      big={big}
      disabled={disabled}
      fontSize={fontSize}
      full={full}
      fullWidth={fullWidth}
      hover={hover}
      icon={icon}
      iconComponent={iconComponent}
      margin={margin}
      mobileFullWidth={mobileFullWidth}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(onClick && { onClick: () => handleOnClick() })}
      position={position}
      left={left}
      right={right}
      top={top}
      TextColor={TextColor}
      type={htmlType}
      withActive={withActive}
      buttonWidth={buttonWidth}
      blockedButton={blockedButton}
      logIn={logIn}
      payButton={payButton}
      mobilePadding={mobilePadding}
      mobileWidth={mobileWidth}
    >
      {iconComponent && iconComponent}
      {icon && <Icons icon={icon} width={3} />}
      {(!smallDevices || !blockedButton) && <>{text}</>}
    </Container>
  );
};

ButtonPrimary.propTypes = {
  disabled: PropTypes.bool,
  fontSize: PropTypes.string,
  full: PropTypes.bool,
  fullWidth: PropTypes.bool,
  htmlType: PropTypes.string,
  icon: PropTypes.string, // let the button compatible with olds buttons
  iconComponent: PropTypes.node,
  margin: PropTypes.string,
  mobileFullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  type: PropTypes.number,
  position: PropTypes.string,
  left: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  right: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  top: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  big: PropTypes.bool,
  withActive: PropTypes.bool,
  buttonWidth: PropTypes.string,
  blockedButton: PropTypes.bool,
  payButton: PropTypes.bool,
};

ButtonPrimary.defaultProps = {
  disabled: false,
  fontSize: 'large',
  full: false,
  fullWidth: false,
  htmlType: 'button',
  icon: '',
  iconComponent: '',
  margin: '',
  mobileFullWidth: false,
  onClick: () => { },
  position: '',
  left: '',
  right: '',
  top: '',
  type: 0,
  big: false,
  withActive: false,
  buttonWidth: '',
  blockedButton: false,
  payButton: false,
};

export default ButtonPrimary;

