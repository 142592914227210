import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _remove from 'lodash/remove';
import _get from 'lodash/get';
import ButtonSecondary from '../../../common/Buttons/ButtonSecondary';
import { Modal, SingleRow } from '../../../common';
import NewCourse from './NewCourse';
import Course from './Course';
import { studentActions } from '../../../../redux/actions';

import {
  FullFlexCont,
  WhiteSpace,
  NoIconTitle,
} from '../../formStyledComponents';

const OtherCourses = ({ profile, littleDevice }) => {

  const dispatch = useDispatch();

  const [otherExperiences, setOtherExperiences] = useState([]);

  useEffect(() => {
    const experiences = _get(profile, 'otherExperience', []);
    setOtherExperiences(experiences);
  }, [profile]);

  /* delete course button */
  const deleteCourse = (courseName) => {
    const courses = [];
    otherExperiences.forEach(course => courses.push(course));
    const findCoursesToDelete = course => course === courseName;
    const deleted = _remove(courses, findCoursesToDelete);
    dispatch(studentActions.save({ otherExperience: courses }));
  };

  const [coursesModalActive, setCourseModalActive] = useState(false);

  /* open / close add-course-modal */
  const onClose = () => {
    setCourseModalActive(false);
  };

  const openModal = () => {
    setCourseModalActive(true);
  };

  /* add-course button */
  const addCourse = (newCourse) => {
    const courses = [];
    otherExperiences.forEach(course => courses.push(course));
    courses.push(newCourse.name);
    dispatch(studentActions.save({ otherExperience: courses }));
    onClose();
  };


  return (
    <>
      <FullFlexCont mobilePadding="0 0 2rem 0">
        <NoIconTitle>OTROS CURSOS</NoIconTitle>
      </FullFlexCont>

      <SingleRow />

      {otherExperiences && otherExperiences.map((course, i) => {

        return (
          <Course
            i={i}
            key={course}
            name={course}
            deleteCourse={deleteCourse}
            onClose={onClose}
            profile={profile}
            littleDevice={littleDevice}
            otherExperiences={otherExperiences}
          />
        );
      })}

      <WhiteSpace height="4rem" />
      {!littleDevice && <ButtonSecondary text="Añadir curso" fullWidth onClick={openModal} />}

      {coursesModalActive &&
        (
          <Modal onClose={onClose}>
            <NewCourse
              addCourse={addCourse}
              littleDevice={littleDevice}
            />
          </Modal>
        )}
    </>
  );
};

export default OtherCourses;
