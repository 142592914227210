import React from 'react';
import styled from 'styled-components/macro';

import Icons from '../../Icons';

const Container = styled.div`
position: relative;
cursor: pointer;
`;
const SelectIcon = styled.div`
position: absolute;
right: 1.375rem;    
top: 0;
height: 100%;
display: flex;
align-items: center;
pointer-events: none;
`;
const SelectMain = styled.select`
border: transparent;
width: 100%;
padding: 0.625rem 1.375rem;
color: ${(props) => props.theme.colors.textPrimary};
font-size: ${(props) => props.theme.fontSizes.medium};
line-height:1.5;
margin-bottom:${(props) => props.mBottom}rem;
border: solid 2px ${(props) => props.theme.colors.textSecondary};
border-radius: 2rem;
outline: none;
-moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
`;
const Option = styled.option`
color: ${(props) => props.theme.colors.textPrimary};
font-size: ${(props) => props.theme.fontSizes.medium};
line-height:1.5;
padding: 0.625rem 1.375rem;
`;

const getOptionName = (op) => {
  if (!op) return '';
  if (op.nameLang) return op.nameLang.es;
  if (op.name) return op.name;
  return op;
};

const Select = ({ placeholder, options = [], onSelectChange, value }) => (
  <Container>
    <SelectMain value={value} onChange={({ target: { value } }) => onSelectChange(value)}>
      <Option value="">{placeholder}</Option>
      {options.map((op) => <Option key={op.id || op} value={op.id}>{getOptionName(op)}</Option>)}
    </SelectMain>
    <SelectIcon>
      <Icons icon="chevronDown" iconColor="themeSecondary" width={1.5} height={1.5} />
    </SelectIcon>
  </Container>
);

export default Select;

