import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import useOnClickOutside from '@bit/iamtechnologies.iamtech-js.use-on-click-outside';

const PopUpContainer = styled.div`
  display: inline-block;
  position: relative;
  margin-left: 10px;
  margin-right:10px;
  top: -4px;
`;

const InfoText = styled.div`
  position: absolute;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  top: ${({ top, visible }) => (!visible ? '-35px' : top)};
  opacity: ${(props) => (props.visible ? '1' : '0')};
  pointer-events: none;
  left: ${({ left }) => left};
  margin-left: -150px;
  font-size: ${(props) => props.theme.fontSizes.small};
  padding: ${(props) => props.theme.paddings.xxs};
  color: ${(props) => props.theme.colors.greyDark};
  background-color: ${(props) => props.theme.colors.white};
  transition: all 0.5s;
  border-radius:15px;
`;

const IconContainer = styled.span`
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${(props) => props.theme.colors.greyDark};
  color: ${(props) => props.theme.colors.greyDark};
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 700;
  cursor:pointer;
  text-transform:lowercase;
`;

const Title = styled.div`
  margin: 10px 38px 6px 39px;
  font-size: ${(props) => props.theme.fontSizes.medium};;
  font-weight: bold;
  line-height: 1.47;
  text-align: center;
  color: ${(props) => props.theme.colors.greyDark};
`;

const Line = styled.div`
  color: ${(props) => props.theme.colors.greyDark};
  border: 1px solid ${(props) => props.theme.colors.greyDark};
  margin-bottom:5px;
  width: 90%;
  display: flex;
  margin: auto;
`;

const PaymentsWrapper = styled.div`
  display:flex;
  flex-direction:column;
  padding:20px;
  text-transform: capitalize;
`;

const Payment = styled.div`
  display:flex;
  flex-direction:row;
  font-weight: bold;
  margin-bottom:10px;
`;

const Concept = styled.div`
  font-weight: lighter;
  margin-left: 10px;
`;

const IvaMessage = styled.div`
  font-weight: lighter;
  font-size: ${(props) => props.theme.fontSizes.xsmall};
  text-transform: none;
`;

const Amount = styled.div`
  display:flex;
  margin-left:auto;
  font-weight: lighter;
`;

const VerticalSpace = styled.div`
  height:30px;
`;

const InfoPopUp = ({
  firstPayment = 500,
  height = '229px',
  iva,
  insuranceTax,
  left = '75px',
  price = 0,
  secondPayment = 0,
  step,
  text,
  totalPriceWithIva = 0,
  top = '-250px',
  width = '300px',
  currencySymbol = '€',
}) => {
  const [infoVisible, setInfoVisible] = useState(false);
  const tooltipTextRef = useRef(null);
  useOnClickOutside(tooltipTextRef, () => infoVisible && setInfoVisible(false));
  const ivaPrice = Number((totalPriceWithIva - price).toFixed(2));
  const hasSecondPayment = !!secondPayment;
  const paymentType = hasSecondPayment ? 'segundo pago' : 'primer pago';

  return (
    <>
      {step === '4' && (
        <PopUpContainer ref={tooltipTextRef}>
          <InfoText
            height={height}
            left={left}
            step={step}
            top={top}
            visible={infoVisible}
            width={width}
          >
            <Title>Desglose de pago</Title>
            <Line />
            <PaymentsWrapper>
              <Payment>
                1r pago:
                <Concept> Inscripción</Concept>
                {hasSecondPayment && <Amount>{firstPayment}{currencySymbol}</Amount>}
                {!hasSecondPayment && <Amount>{firstPayment + ivaPrice}{currencySymbol}</Amount>}
              </Payment>
              {hasSecondPayment
                && (
                  <Payment>
                    2o pago:
                    <Concept> Programa</Concept>
                    <Amount>{secondPayment + ivaPrice}{currencySymbol}</Amount>
                  </Payment>
                )}
              <Payment>
                <IvaMessage>*Se incluye el {iva}% de IVA en el {paymentType}</IvaMessage>
              </Payment>
              <VerticalSpace />
              <Payment>
                Coste Seguro Obligatorio
                <Amount>{insuranceTax}{currencySymbol}/mes de las prácticas</Amount>
              </Payment>
            </PaymentsWrapper>
          </InfoText>
          <IconContainer onClick={() => setInfoVisible(!infoVisible)}>i</IconContainer>
        </PopUpContainer>
      )}
      {(step === '2' || step === 'payment')
        && (
          <PopUpContainer ref={tooltipTextRef}>
            <InfoText
              height={height}
              left={left}
              step={step}
              top={top}
              visible={infoVisible}
              width={width}
            >
              {text}
            </InfoText>
            <IconContainer onClick={() => setInfoVisible(!infoVisible)}>i</IconContainer>
          </PopUpContainer>
        )}
    </>
  );
};

export default InfoPopUp;
