import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components/macro';
import PropTypes from 'prop-types';
import IconButton from '../../common/Buttons/IconButton';
import { EditIcon, TrashIcon } from '../../common/Icons/IconsList';
import { ButtonPrimary } from '../../common';

const IconsContainer = styled.div`
  display: flex;
  width: 8.5rem;
  height: fit-content;
  margin-right: 2rem;
`;

const VerticalSpace = styled.div`
  min-width: 1rem;
`;

const InputSubmit = styled.input`
  color: ${(props) => props.theme.colors.themeSecondary};
  background: none;
  border: none;
  outline: none;
  margin-right: 1rem;
  cursor: pointer;
  font-size: 1.5rem;
  text-decoration: underline;
`;

const EditDeleteIcons = ({
  edit,
  deleteField,
  isActive,
  onSubmit,
  value1,
  value2,
  confirmText,
  deleteOne,
}) => {
  const themeContext = useContext(ThemeContext);

  const onDelete = (e) => {
    e.preventDefault();
    if (window.confirm(confirmText)) {
      deleteField ? deleteField(value1, value2) : deleteOne();
    }
  };


  return (
    <IconsContainer>
      {!isActive
        && (
          <IconButton
            icon={<EditIcon />}
            type={2}
            width={2.4}
            padding="1rem"
            onClick={edit}
          />
        )}

      {isActive
         && (
         <ButtonPrimary
           type={2}
           text="Guardar"
           htmlType="submit"
           margin="0 0 0 auto"
         />
         )}

      <VerticalSpace />

      <IconButton
        icon={<TrashIcon />}
        type={2}
        width={2.4}
        padding="1rem"
        onClick={onDelete}
      />

    </IconsContainer>
  );
};

EditDeleteIcons.propTypes = {
  edit: PropTypes.func.isRequired,
  deleteField: PropTypes.func,
  deleteOne: PropTypes.func,
  value1: PropTypes.string,
  value2: PropTypes.string,
  confirmText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

EditDeleteIcons.defaultProps = {
  deleteField: null,
  delteOne: null,
  value1: null,
  value2: null,
};

export default EditDeleteIcons;
