import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { payStudentPayment } from '../services/api/payments';
import { studentActions } from '../redux/actions';

const usePaymentSuccess = ({ payments, paymentId, paymentMethod }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false);

  const onPaymentSuccess = useCallback(async (filename, paymentIntentId) => {
    const newPayment = payments.find((item) => item.id === paymentId);
    const today = new Date();

    newPayment.type = paymentMethod;
    if (filename) newPayment.receiptFile = filename;
    newPayment.status = paymentMethod === 'card' ? 'done' : 'inReview';
    if (paymentMethod === 'card') {
      newPayment.dateDone = today;
      newPayment.paymentIntentId = paymentIntentId;
    }

    const { success, message } = await payStudentPayment(newPayment);

    if (success) {
      dispatch(studentActions.refresh());
      setIsOpen(false);
      setShowPaymentSuccessModal(true);
      return;
    }

    setError(message);
  }, [dispatch, payments, paymentId, paymentMethod]);

  return {
    error,
    isOpen,
    onPaymentSuccess,
    setIsOpen,
    setShowPaymentSuccessModal,
    showPaymentSuccessModal,
  };
};

export default usePaymentSuccess;
