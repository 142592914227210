import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import ButtonPrimary from '../../../common/Buttons/ButtonPrimary';
import ButtonSecondary from '../../../common/Buttons/ButtonSecondary';
import { studentActions } from '../../../../redux/actions';
import { showOptions, getId } from '../../helpers';
import useListPublicEntity from '../../../../hooks/useListPublicEntity';

import {
  Column,
  Input,
  Label,
  InputColumn,
  FullFlexCont,
  WhiteSpace,
  ContactForm,
  Error,
  TextArea,
  DisabledBlueInput,
  Select,
} from '../../formStyledComponents';

const ExperienceToEdit = ({
  profile,
  setSelectedExperience,
  company,
  jobName,
  iniDate,
  endDate,
  desc,
  country,
  city,
  workExperience,
}) => {
  const { register, handleSubmit, errors, reset } = useForm();

  const dispatch = useDispatch();

  const { data: residences } = useListPublicEntity('residence');

  /* handle errors  */
  const companyError = errors.company && <Error>Por favor, indica el nombre de la empresa</Error>;
  const jobError = errors.jobName && <Error>Por favor, indica el título o cargo</Error>;
  const cityError = errors.city && <Error>Debes indicar la ciudad</Error>;
  const countryError = errors.country && <Error>Debes indicar el País</Error>;

  const onSubmit = (data, e) => {
    e.preventDefault();

    const experiences = [];

    workExperience.forEach((exp) => {
      experiences.push({
        jobName: exp.jobName,
        iniDate: exp.iniDate,
        endDate: exp.endDate,
        workExperienceCountryId: getId(residences, exp.country.nameLang.es),
        city: exp.city,
        desc: exp.desc,
        company: exp.company,
      });
    });

    experiences.forEach((experience, index) => {
      if (data.name === experience.name && data.company === experience.company) {
        experiences.splice(index, 1, {
          jobName: data.jobName,
          iniDate: data.iniDate,
          endDate: data.endDate,
          workExperienceCountryId: getId(residences, data.workExperienceCountryId),
          city: data.city,
          desc: data.desc,
          company: data.company,
        });
      }
      dispatch(studentActions.save({ workExperience: experiences }));
      setSelectedExperience(null);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      reset({
        workExperienceCountryId: country,
      });
    };
    fetchData();
  }, [reset, profile]);


  return (
    <>
      <ContactForm key={`${company + city}experienceToEdit`} onSubmit={handleSubmit(onSubmit)}>

        <FullFlexCont mobilePadding="0">
          <InputColumn width="fit-content">

            <DisabledBlueInput
              bold
              width="300px"
              mBottom="0"
              name="company"
              placeholder="Empresa"
              ref={register({ required: true })}
              value={company}
              mobileWidth="80%"
              readOnly
            />
            {companyError}

            <DisabledBlueInput
              width="300px"
              name="jobName"
              placeholder="Puesto de trabajo"
              ref={register({ required: true })}
              value={jobName}
              mobileWidth="80%"
              readOnly
            />
            {jobError}
          </InputColumn>

        </FullFlexCont>

        <Column width={100} center mobileFull mobileMargin="0" margin="0" padding="0">
          <InputColumn alignCenter margin="0">
            <Label>Fecha inicio:</Label>
            <Input
              type="date"
              name="iniDate"
              ref={register({ required: true })}
              defaultValue={iniDate}
              mobileMargin="0"
              mobileWidth="80%"
            />
          </InputColumn>
        </Column>

        <Column width={100} center mobileFull mobileMargin="0" margin="0" padding="0">
          <InputColumn alignCenter margin="0">
            <Label>Finalización:</Label>
            <Input
              type="date"
              name="endDate"
              ref={register({ required: true })}
              defaultValue={endDate}
              mobileMargin="0"
              mobileWidth="80%"
            />
          </InputColumn>
        </Column>

        <Column width={100} center mobileFull mobileMargin="0" margin="0" padding="0">
          <InputColumn alignCenter margin="0">
            <Label>País:</Label>
            <Select
              name="workExperienceCountryId"
              ref={register({ required: true })}
              mobileMargin="0"
              mobileWidth="80%"
            >
              {showOptions(residences, '', 'nombre país')}
            </Select>
            {countryError}
          </InputColumn>
        </Column>

        <Column width={100} center mobileFull mobileMargin="0" margin="0" padding="0">
          <InputColumn alignCenter margin="0">
            <Label>Ciudad:</Label>
            <Input
              name="city"
              placeholder="ciudad"
              ref={register({ required: true })}
              defaultValue={city}
              mobileMargin="0"
              mobileWidth="80%"
            />
            {cityError}
          </InputColumn>
        </Column>

        <FullFlexCont padding="0" mobilePadding="0 0 0.5rem 0" center>
          <Label>Funciones:</Label>
          <TextArea
            name="desc"
            placeholder="descripción de las funciones realizadas"
            ref={register({ required: false })}
            defaultValue={desc}
            rows="4"
            maxLength="200"
            mBottom="0"
            mobileWidth="80%"
          />
        </FullFlexCont>

        <FullFlexCont justify="space-between">

          <WhiteSpace height="1rem" />

          <Column width="46" padding="0">
            <ButtonSecondary
              text="Atrás"
              onClick={() => setSelectedExperience(null)}
              margin="auto"
              fullWidth
            />
          </Column>

          <Column width="46" padding="0">
            <ButtonPrimary
              type={2}
              text="Guardar"
              htmlType="submit"
              margin="auto"
              fullWidth
            />
          </Column>

        </FullFlexCont>

      </ContactForm>
    </>
  );
};

export default ExperienceToEdit;
