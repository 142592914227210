import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import Layout from '../components/layout/Layout';
import {
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  BgContainer,
  OnBoardingStep,
} from '../components/OnBoarding';

import { getPartnerIva } from '../services/api/iva';
import { device as studentDevice } from '../utils';
import useListPublicEntity from '../hooks/useListPublicEntity';


const OnBoardingStep5 = styled(OnBoardingStep)`
  height: inherit;
  // overflow: scroll;
  overflow: auto;
  margin: 4rem 0;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin:0;
  }
`;


const OnBoarding = () => {
  const isLogged = useSelector((state) => (!!state.student.id));
  const query = new URLSearchParams(useLocation().search);
  const code = query.get('code') || 'NEXUS';
  const history = useHistory();
  const { data: vacancies } = useListPublicEntity('vacancy');
  const [formStep, setFormStep] = useState(0);
  const [formIllustrationsOpacity, setFormIllustrationsOpacity] = useState([1, 0, 0, 0, 0]);
  const [formData, setFormData] = useState({ finishedStudy: false, eu: false, payment: 'deposit' });
  const [iva, setIva] = useState(21);
  const [device, setDevice] = useState('');
  const [loading, setLoading] = useState(true);

  // Check if student is logged
  useEffect(() => {
    if (isLogged && formStep === 0) history.push('/menu');
    setLoading(false);
  }, [isLogged]);

  const setStep = (step) => {
    setFormIllustrationsOpacity([0, 0, 0, 0, 0]);
    setTimeout(() => {
      setFormStep(step);
      const currentStep = [0, 0, 0, 0, 0];
      currentStep[step] = 1;
      setFormIllustrationsOpacity(currentStep);
    }, 200);
  };

  const handleNextSlide = () => {
    if (formStep < 5) setStep(formStep + 1);
    else history.push('/menu');
  };

  const handlePrevSlide = () => {
    if (formStep > 0) setStep(formStep - 1);
  };

  const handleDotClick = (step) => {
    if (step < formStep) {
      setStep(step);
    }
  };

  const handleFormDataChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const getDevice = () => {
    const deviceType = studentDevice.getDeviceType();
    setDevice(deviceType);
  };

  useEffect(() => {
    getDevice();
  }, []);

  useEffect(() => {
    if (!code) return;
    const getIva = async () => {
      const newIva = await getPartnerIva(code);
      if (newIva.data) setIva(newIva.data);
    };
    getIva();
  }, [code]);

  const getOnboardingStepTop = (step) => `${((step - formStep) * 100) + 50}%`;
  const screen = useSelector((state) => state.windowResize.screen);

  if (loading) return '...loading';

  return (
    <Layout noMenuLink>
      <BgContainer>
        <OnBoardingStep
          style={{ opacity: formIllustrationsOpacity[0], top: getOnboardingStepTop(0) }}
        >
          <Step1
            formData={formData}
            onFormDataChange={handleFormDataChange}
            onDotClick={handleDotClick}
            onStepCompleted={handleNextSlide}
            iva={iva}
          />
        </OnBoardingStep>
        <OnBoardingStep
          style={{ opacity: formIllustrationsOpacity[1], top: getOnboardingStepTop(1) }}
        >
          <Step2
            formData={formData}
            onFormDataChange={handleFormDataChange}
            onDotClick={handleDotClick}
            onStepBack={handlePrevSlide}
            onStepCompleted={handleNextSlide}
            iva={iva}
          />
        </OnBoardingStep>
        <OnBoardingStep
          style={{ opacity: formIllustrationsOpacity[2], top: getOnboardingStepTop(2) }}
        >
          <Step3
            formData={formData}
            onFormDataChange={handleFormDataChange}
            onDotClick={handleDotClick}
            onStepBack={handlePrevSlide}
            onStepCompleted={handleNextSlide}
            iva={iva}
            vacancies={vacancies}
          />
        </OnBoardingStep>
        <OnBoardingStep
          style={{ opacity: formIllustrationsOpacity[3], top: getOnboardingStepTop(3) }}
          marginTop="15px"
        >
          <Step4
            formData={formData}
            onFormDataChange={handleFormDataChange}
            onDotClick={handleDotClick}
            onStepBack={handlePrevSlide}
            onStepCompleted={handleNextSlide}
            iva={iva}
            vacancies={vacancies}
          />
        </OnBoardingStep>
        <OnBoardingStep5
          style={{ opacity: formIllustrationsOpacity[4], top: getOnboardingStepTop(4) }}
        >
          <Step5
            formData={formData}
            onFormDataChange={handleFormDataChange}
            onDotClick={handleDotClick}
            onStepBack={handlePrevSlide}
            onStepCompleted={handleNextSlide}
            screen={screen}
            iva={iva}
            device={device}
          />
        </OnBoardingStep5>
      </BgContainer>
    </Layout>
  );
};

export default OnBoarding;
