import React, { useEffect, useState, useMemo } from 'react';
import _get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import OrderPopUp from './OrderPopUp';
import {
  Image, Icons, Box, ButtonPrimary,
  ButtonSecondary, DatesOpen, Modal,
} from '../../common';
import HSpacer from '../../layout/HSpacer';
import {
  Container, MobileBgImage, HeaderContainer,
  DepartmentContainer, LogoContainer, DepartmentText,
  ContentContainer, TitleContainer, ReturnIcon,
  Line, Subtitle, Contents, SmallTitle,
  Text, Link, FavButton,
} from './styledComponents';
import { images, text } from '../../../utils';
import { studentActions } from '../../../redux/actions';
import FullProfileModal from '../FullProfileModal';


const Vacancy = (props) => {
  const { item, setVacancyId, preview, identifier } = props;

  const dispatch = useDispatch();

  const [fullProfileModalVisible, setFullProfileModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasSelected, setHasSelected] = useState(false);

  const student = useSelector((state) => state.student);
  const screen = useSelector((state) => state.windowResize.screen);
  const arePrioritiesLocked = useSelector(
    (state) => state.student.vacancyPrioritiesLocked,
  ) || false;

  const { priorities = [], favourites = [], statusHistory = [] } = student;
  const favouritesIds = useMemo(
    () => (favourites ? favourites.map((elem) => elem?.id) : []), [favourites],
  );
  const prioritiesIds = useMemo(
    () => (priorities?.length ? priorities.map((elem) => elem.vacancy.id) : []), [priorities],
  );
  const isFavourite = favouritesIds.indexOf(item.id) >= 0;
  const isSelected = prioritiesIds.indexOf(item.id) >= 0;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const addFavourites = () => {
    const newList = [...favouritesIds];
    if (isFavourite) {
      const index = favouritesIds.indexOf(item.id);
      newList.splice(index, 1);
    } else newList.push(item.id);
    dispatch(studentActions.save({ studentFavouritesId: newList }));
  };

  const hasFullProfile = statusHistory.some((event) => event.status === 'profileCompleted');

  const isPhone = screen === 'phone';
  const w = isPhone ? 1 : 1 / 3;
  const lang = 'es';

  const bgImage = _get(item, 'bgImage', '');
  const bgImageFile = images.getUrl('vacancy/bgImage', bgImage);

  const logoImage = _get(item, 'logoImage', '');
  const logoImageFile = images.getUrl('vacancy/logoImage', logoImage);

  const datesOpen = _get(item, 'datesOpen', []);

  const benefitEconomy = text.getTrullyText(_get(item, 'benefitEconomy', ''));
  const benefitMealLang = text.getTrullyText(_get(item, `benefitMealLang[${lang}]`, ''));
  const benefitHose = text.getTrullyText(_get(item, 'benefitHouse', ''));

  const seleccionarOnClick = hasFullProfile
    ? () => setIsModalOpen(true)
    : () => setFullProfileModalVisible(true);

  return (
    <Container>
      <HeaderContainer bgImage={encodeURI(bgImageFile)}>
        <DepartmentContainer>
          <LogoContainer>
            <Image url={logoImageFile} />
          </LogoContainer>
          <DepartmentText>{_get(item, 'department', '')}</DepartmentText>
        </DepartmentContainer>
      </HeaderContainer>

      <ContentContainer>
        <TitleContainer>
          {
            !isPhone && (
              <ReturnIcon onClick={() => setVacancyId('')}>
                <Icons icon="chevronLeft" iconColor="#4d4d4d" width={2.5} />
              </ReturnIcon>
            )
          }
          <h1>{_get(item, 'company', '')}</h1>
          <Line>
            {isPhone && <Icons icon="location" iconColor="alertError" width={2} />}
            <span>{_get(item, 'city', '')}</span>
            {!isPhone && <Icons icon="location" iconColor="alertError" width={2} />}
          </Line>
        </TitleContainer>

        {
          isPhone && (
            <>
              <HSpacer height="3rem" />
              <Box container justify="space-around" padding="0 10px">
                <ButtonSecondary text="Atrás" onClick={() => setVacancyId('')} />
                <Box padding="0 15px">
                  {arePrioritiesLocked && <Text>Prioridades seleccionadas y bloqueadas</Text>}
                  {
                  !arePrioritiesLocked && !isSelected
                    ? (
                      <ButtonPrimary
                        text="Seleccionar +"
                        type={2}
                        onClick={seleccionarOnClick}
                      />
                    )
                    : <Text>Vacante seleccionada</Text>
                }
                </Box>
                <FavButton>
                  <ButtonSecondary
                    icon="heart-shape"
                    stroke
                    fullWidth
                    onClick={addFavourites}
                    insideFavourites={isFavourite}
                  />
                </FavButton>
              </Box>
              <HSpacer height="3rem" />
              <MobileBgImage bgImage={encodeURI(bgImageFile)} />
              <HSpacer height="4rem" />
            </>
          )
        }

        <Subtitle>BENEFICIOS</Subtitle>

        <Contents container align="strech" wrap="wrap">
          <Box w={w} spacing={4}>
            <SmallTitle>Ayuda económica</SmallTitle>
            <Text>{benefitEconomy}</Text>

            <SmallTitle>Comidas</SmallTitle>
            <Text>{benefitMealLang}</Text>

            <SmallTitle>Alojamiento</SmallTitle>
            <Text>{benefitHose}</Text>

            <SmallTitle>Fechas de apertura</SmallTitle>
            <Text>
              <DatesOpen datesOpen={datesOpen} />
            </Text>
          </Box>

          <Box w={w} spacing={4} container direction="column" align="flex-start">
            <div>
              <SmallTitle>Descripción</SmallTitle>
              <Text>{_get(item, `descriptionLang[${lang}]`, '')}</Text>
            </div>
            <Link href={_get(item, 'websiteURL', '')} target="_blank" rel="noopener noreferrer">
              Ver página de la empresa
            </Link>
          </Box>

          {
            !isPhone && !preview ? (
              <Box w={1 / 3} spacing={4}>
                <HSpacer height="1rem" />
                <FavButton>
                  <ButtonSecondary
                    text="Favoritos"
                    icon="heart-shape"
                    stroke
                    fullWidth
                    onClick={addFavourites}
                    insideFavourites={isFavourite}
                  />
                </FavButton>
                <HSpacer height="1rem" />
                {arePrioritiesLocked && <Text>Prioridades seleccionadas y bloqueadas</Text>}
                {
                  !arePrioritiesLocked && !isSelected
                    ? (
                      <ButtonPrimary
                        text="Seleccionar +"
                        fullWidth
                        type={2}
                        onClick={seleccionarOnClick}
                      />
                    )
                    : <Text>Vacante ya seleccionada</Text>
                }
              </Box>
            ) : <Box w={1 / 3} spacing={4} />
          }

        </Contents>
      </ContentContainer>

      {
        isModalOpen && (
          <Modal minHeight="55rem" onClose={() => setIsModalOpen(false)} paddingCrossButton="2rem 2rem 0 2rem">
            <OrderPopUp newVacancy={item} setVacancyId={setVacancyId} />
          </Modal>
        )
      }

      {fullProfileModalVisible && (
        <Modal onClose={() => setFullProfileModalVisible(false)}>
          <FullProfileModal />
        </Modal>
      )}

    </Container>
  );
};

Vacancy.propTypes = {
  item: PropTypes.shape().isRequired,
  setVacancyId: PropTypes.func.isRequired,
  preview: PropTypes.bool,
};

Vacancy.defaultProps = {
  preview: false,
};

export default Vacancy;
