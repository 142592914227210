import styled from 'styled-components/macro';


export const BarContainer = styled.div `
background-color: ${({theme}) => theme.colors.themeTertiary};
display: flex;
border-radius: 1rem;
width: 100%;
height: 2rem;
margin: ${({ margin }) => margin};

@media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
  margin: ${({ mobileMargin }) => mobileMargin};
}
`;

export const BarContent = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: ${({ progress })=> progress}%;
background-color: ${({theme}) => theme.colors.themeSecondary};
border-radius: 1rem;
font-size: ${({ theme }) => theme.fontSizes.medium};
color: ${({theme}) => theme.colors.textInverse};
font-weight: bold;
`;