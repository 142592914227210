import React from 'react';
import styled from 'styled-components/macro';

const CardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 4rem;
  flex-wrap: wrap;
  text-align: center;
  line-height: 2;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    text-align: center;
    padding: 2rem;
  }
`;

const P = styled.p`
  font-size: 1.5rem;
  margin: 2rem 0;
`;


const FirstStepsModal = ({ messageType }) => {
  const title = messageType === 'inReview' ? '¡Pago en proceso!' : 'Completa tu perfil';
  const message = messageType === 'inReview'
    ? 'Gracias por darte de alta en NEXUS. Estamos procesando tu pago.'
    : 'Completa tu perfil para poder acceder a todo el contenido de la app.';

  return (
    <CardContentWrapper>
      <>
        <h2>{title}</h2>
        <P>{message}</P>
      </>
    </CardContentWrapper>
  );
};

export default FirstStepsModal;
