/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import theme from '../CustomTheme/Theme';
import HSpacer from '../layout/HSpacer';
import ButtonPrimary from '../common/Buttons/ButtonPrimary';
import Loader from '../common/Loader';

import { setupIntent } from '../../services/api/payments';


const Form = styled.form`
  width: 100%;
`;

const CardRow = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
  border: solid 1px ${theme.colors.textSecondary};
  border-radius: 2rem;
  padding: 0.625rem 1.375rem;
  
  .StripeElement--webkit-autofill {
    background: transparent !important;
  }

  .StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
  }
`;

const CARD_OPTIONS = {
  hidePostalCode: true,
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: theme.colors.themePrimary,
      color: theme.colors.textPrimary,
      // fontWeight: 400,
      // fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: theme.colors.themePrimary,
      },
    },
    invalid: {
      iconColor: theme.colors.alertNotification,
      color: theme.colors.alertNotification,
    },
  },
};


const CheckoutForm = (props) => {
  const { amount, currency, paymentId, onPaymentSuccess, firstPaymentDone } = props;
  const stripe = useStripe();
  const elements = useElements();

  const [paymentIntent, setPaymentIntent] = useState(null);
  const [paymentError, setPaymentError] = useState('');
  const [loadingPayment, setLoadingPayment] = useState(false);

  useEffect(() => {
    if (!amount || !paymentId) return;
    const callSetupIntent = async () => {
      const response = await setupIntent(amount, paymentId, currency);
      const { success, data } = response;
      if (success) setPaymentIntent(data.paymentIntent);
    };
    callSetupIntent();
  }, [amount, paymentId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoadingPayment(true);
    const cardElement = elements.getElement(CardElement);
    const response = await stripe.confirmCardPayment(paymentIntent.client_secret, {
      payment_method: {
        card: cardElement,
      },
    });

    const { error } = response;
    setLoadingPayment(false);

    if (error) {
      setPaymentError(error.message);
      return;
    }
    onPaymentSuccess('', paymentIntent.id);
  };

  // if (!amount || !clientSecret) return null;

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <CardRow>
          <CardElement options={CARD_OPTIONS} />
        </CardRow>
        <HSpacer />

        {
          paymentIntent && !firstPaymentDone && (
            <ButtonPrimary
              type="submit"
              htmlType="submit"
              disabled={!stripe}
              text={loadingPayment ? <Loader /> : 'Pagar'}
              fullWidth
            />
          )
        }
      </Form>

      <HSpacer />

      {paymentError && paymentError}
    </>
  );
};

const stripePromise = loadStripe("pk_live_51H3L0YImLGTkuni59bC42ODxktA5Txp0wX6bsQnvEL99E1hN9hsMVizkMutGXloGNPbOgpVEEP0tmUvzGpnMQLrA00RjPgqNZv");

const Stripe = (props) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm {...props} />
  </Elements>
);

Stripe.propTypes = {};

Stripe.defaultProps = {};

export default Stripe;

