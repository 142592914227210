import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Icons from '../Icons';


import {
  TitleContainer,
  LeftArrow,
  Title,
} from './styledComponents';

const BackArrowTitle = ({ title, linkto="/menu" }) => {
  return (
    <TitleContainer>
      <Link to={linkto}>
        <LeftArrow>
          <Icons icon="chevronLeft" iconColor="#4d4d4d" width={2.5} />
        </LeftArrow>
      </Link>
      <Title>{title}</Title>
    </TitleContainer>
  );
};

BackArrowTitle.propTypes = {
  title: PropTypes.string.isRequired,
  linkto: PropTypes.string.isRequired,
};

export default BackArrowTitle;
