import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';

import Icons from '../Icons';
import { dates } from '../../../utils';
import Theme from '../../CustomTheme/Theme';


const Container = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
  margin-bottom:${(props) => props.mBottom}rem;

  input {
    ${(props) => props.onBoardingDatePicker && css`
      border: 2px solid ${({ theme }) => theme.colors.grey};
      border-radius: 2.5rem;
    `}
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    display:flex;
    justify-content:center;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 11px;
  right: 11px;
`;

const DatePickerUI = (props) => {
  const {
    placeholder,
    onValueChange,
    value,
    maxDate = null,
    minDate = null,
    name,
    className,
    setValue,
    onBoardingDatePicker,
  } = props;
  // string yyyy-mm-dd

  const handleChange = (e) => {
    const newDate = dates.getAWSStringDate(e.value);
    onValueChange(newDate);
  };

  addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
    monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
    today: 'Hoy',
    clear: 'Limpiar',
  });

  return (
    <Container onBoardingDatePicker={onBoardingDatePicker}>
      <Calendar
        appendTo="self"
        className={className}
        dateFormat="dd/mm/yy"
        icon="pi pi-times"
        locale="es"
        maxDate={maxDate}
        minDate={minDate}
        name={name}
        onChange={(e) => handleChange(e)}
        placeholder={placeholder}
        value={value ? new Date(value) : null}
      />
      <IconWrapper onClick={() => setValue(name, null)}>
        <Icons icon="close" iconColor={Theme.colors.themeSecondary} width={1} />
      </IconWrapper>
    </Container>
  );
};

export default DatePickerUI;
