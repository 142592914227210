import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { studentActions } from '../redux/actions';
import { login, resetPassword } from '../services/api/auth';
import { getErrorMessage } from '../services/api/errors';
import ButtonPrimary from '../components/common/Buttons/ButtonPrimary';
import InputText from '../components/common/formFields/Inputs/InputText';
import HSpacer from '../components/layout/HSpacer';
import Layout from '../components/layout/Layout';
import { BgContainer, Container, OnBoardingStep } from '../components/OnBoarding';
import { ErrorMessage, Loader } from '../components/common';

const PasswordReset = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit, watch, errors } = useForm();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const query = new URLSearchParams(useLocation().search);
  const code = query.get('code');
  const email = query.get('email');

  const password = watch('newPassword', '');

  const handleLogin = async (userEmail, newPassword) => {
    const { success, message } = await login(userEmail, newPassword);

    if (success) {
      setLoading(false);
      dispatch(studentActions.refresh());
      history.push('/');
      return;
    }

    setError(getErrorMessage(message));
  };

  const onSubmit = async (data, event) => {
    event.preventDefault();
    const { newPassword } = data;
    setError(false);
    setLoading(true);

    const { success, message } = await resetPassword({ email, newPassword, code });

    if (!success) {
      setError(getErrorMessage(message));
      setLoading(false);
      return;
    }

    await handleLogin(email, newPassword);
  };

  return (
    <Layout isLogin noMenuLink>
      <BgContainer logIn>
        <OnBoardingStep>
          <Container>
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputText
                inputRef={register({ required: true })}
                defaultValue=""
                name="newPassword"
                label="Nueva contraseña"
                placeholder="Introduce tu nueva contraseña"
                type="password"
              />
              <HSpacer />
              <InputText
                name="confirmPassword"
                defaultValue=""
                label="Confirmar contraseña"
                inputRef={register({
                  required: true,
                  validate: (value) => value === password,
                })}
                placeholder="Confirma tu nueva contraseña"
                type="password"
                controlled={false}
              />
              <HSpacer height="20px" />
              {errors.confirmPassword && (
                <ErrorMessage>Las contraseñas no coinciden</ErrorMessage>
              )}
              {error && <ErrorMessage>{error}</ErrorMessage>}
              <HSpacer height="20px" />
              <ButtonPrimary
                htmlType="submit"
                text={loading ? <Loader /> : 'Actualizar contraseña'}
                fullWidth
                logIn
              />
            </form>
          </Container>
        </OnBoardingStep>
      </BgContainer>
    </Layout>
  );
};

export default PasswordReset;
