import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import EditDeleteIcons from '../../EditDeleteIcons';
import SeeMoreCard from '../../SeeMoreCard';
import { showOptions, getId } from '../../helpers';
import useListPublicEntity from '../../../../hooks/useListPublicEntity';
import { studentActions } from '../../../../redux/actions';
import HSpacer from '../../../layout/HSpacer';

import {
  Column,
  Input,
  Label,
  InputColumn,
  FullFlexCont,
  WhiteSpace,
  ContactForm,
  EditDeleteIconsCont,
  Error,
  TextArea,
  DisabledBlueInput,
  MobilePadding,
  Select,
} from '../../formStyledComponents';
import { ButtonPrimary } from '../../../common';

const OneExperience = ({
  company,
  jobName,
  iniDate,
  endDate,
  desc,
  country,
  city,
  deleteExperience,
  profile,
  i,
  workExperience,
  scrollToTop,
}) => {
  const {
    register, handleSubmit, errors, reset, setValue,
  } = useForm();

  const dispatch = useDispatch();

  const { data: residences } = useListPublicEntity('residence');

  /* set the form to active/inactive to be edited */
  const initialFormState = workExperience !== [] && false;
  const [experienceFormActive, setExperienceFormActive] = useState(initialFormState);

  // Asign a ref to the card, to open it with the "edit" button
  const cardRefExperience = useRef(`experience-${company + jobName}`);

  const [selectedCardExperience, setSelectedCardExperience] = useState(null);

  const edit = (e) => {
    e.preventDefault();
    setExperienceFormActive(!experienceFormActive);
    setSelectedCardExperience(cardRefExperience.current);
  };

  const openByRef = selectedCardExperience === `experience-${company + jobName}`;

  /* handle errors  */
  const companyError = errors.company && <Error>Por favor, indica el nombre de la empresa</Error>;
  const jobError = errors.jobName && <Error>Por favor, indica el título o cargo</Error>;
  const cityError = errors.city && <Error>Debes indicar la ciudad</Error>;
  const countryError = errors.workExperienceCountryId && <Error>Debes indicar el País</Error>;

  const onSubmit = (data, e) => {
    e.preventDefault();
    const experiences = [];

    workExperience.forEach((exp) => {
      experiences.push({
        jobName: exp.jobName,
        iniDate: exp.iniDate,
        endDate: exp.endDate,
        workExperienceCountryId: getId(residences, exp.country.nameLang.es),
        city: exp.city,
        desc: exp.desc,
        company: exp.company,
      });
    });

    experiences.forEach((experience, index) => {
      if (data.name === experience.name && data.company === experience.company) {
        experiences.splice(index, 1, {
          jobName: data.jobName,
          iniDate: data.iniDate,
          endDate: data.endDate,
          workExperienceCountryId: getId(residences, data.workExperienceCountryId),
          city: data.city,
          desc: data.desc,
          company: data.company,
        });
      }
      dispatch(studentActions.save({ workExperience: experiences }));
    });

    setExperienceFormActive(!experienceFormActive);
  };

  useEffect(() => {
    const fetchData = async () => {
      reset({
        workExperienceCountryId: country,
      });
    };
    fetchData();
  }, [reset, profile]);


  return (
    <ContactForm key={`${jobName}oneExperience`} onSubmit={handleSubmit(onSubmit)}>

      <WhiteSpace height="1rem" />

      <MobilePadding>
        <FullFlexCont>
          <InputColumn width="fit-content">

            <DisabledBlueInput
              bold
              width="300px"
              mBottom="0"
              disabled={!experienceFormActive}
              name="company"
              placeholder="Empresa"
              ref={register({ required: true })}
              value={company}
            />
            {companyError}

            <DisabledBlueInput
              width="300px"
              disabled={!experienceFormActive}
              name="jobName"
              placeholder="Puesto de trabajo"
              ref={register({ required: true })}
              value={jobName}
            />
            {jobError}
          </InputColumn>

          <EditDeleteIconsCont>
            <EditDeleteIcons
              edit={edit}
              deleteField={deleteExperience}
              isActive={experienceFormActive}
              onSubmit={onSubmit}
              confirmText="¿Estás seguro de que quieres eliminar esta ficha?"
              value1={company}
              value2={jobName}
            />
          </EditDeleteIconsCont>

        </FullFlexCont>
      </MobilePadding>

      <SeeMoreCard
        ref={cardRefExperience}
        openByRef={openByRef}
        selectedCard={selectedCardExperience}
        profile={profile}
        setSelectedCard={setSelectedCardExperience}
      >

        <WhiteSpace height="4rem" />

        <Column width={27} center mobileFull mobileMargin="0">
          <InputColumn>
            <Label>Fecha inicio:</Label>
            <Input
              disabled={!experienceFormActive}
              type="date"
              name="iniDate"
              ref={register({ required: true })}
              defaultValue={iniDate}
              mobileMargin="0"
              key={iniDate + i}
            />
          </InputColumn>
        </Column>

        <Column width={27} center mobileFull mobileMargin="0">
          <InputColumn>
            <Label>Finalización:</Label>
            <Input
              disabled={!experienceFormActive}
              type="date"
              name="endDate"
              ref={register({ required: true })}
              defaultValue={endDate}
              mobileMargin="0"
              key={endDate + i}
            />
          </InputColumn>
        </Column>

        <Column width={21} center mobileFull mobileMargin="0">
          <InputColumn>
            <Label>País:</Label>
            <Select
              disabled={!experienceFormActive}
              name="workExperienceCountryId"
              ref={register({ required: true })}
              mobileMargin="0"
              mobileWidth="50%"
              key={country + i}
              selected={country}
            >
              {showOptions(residences, '', country || 'Nombre país')}
            </Select>
            {countryError}
          </InputColumn>
        </Column>

        <Column width={21} center mobileFull mobileMargin="0">
          <InputColumn>
            <Label>Ciudad:</Label>
            <Input
              disabled={!experienceFormActive}
              name="city"
              placeholder="ciudad"
              ref={register({ required: true })}
              defaultValue={city}
              mobileMargin="0"
              key={city + i}
            />
            {cityError}
          </InputColumn>
        </Column>

        <FullFlexCont padding="0 2.7rem" mobilePadding="0 0 0.5rem 0">
          <Label>Funciones:</Label>
          <TextArea
            disabled={!experienceFormActive}
            name="desc"
            placeholder="descripción de las funciones realizadas"
            ref={register({ required: false })}
            defaultValue={desc}
            rows="4"
            maxLength="200"
            mBottom="0"
            key={desc + i}
          />
        </FullFlexCont>

        {experienceFormActive
          && (
            <>
              <HSpacer height="1.5rem" />
              <ButtonPrimary
                type={3}
                text="Guardar"
                htmlType="submit"
                onClick={Object.keys(errors).length === 0 ? scrollToTop : null}
                margin="0 0 0 auto"
              />
            </>
          )}

      </SeeMoreCard>
    </ContactForm>
  );
};

export default OneExperience;
