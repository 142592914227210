import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _get from 'lodash/get';
import _remove from 'lodash/remove';
import ButtonSecondary from '../../../common/Buttons/ButtonSecondary';
import { Modal } from '../../../common';
import NewExperience from './NewExperience';
import OneExperience from './OneExperience';
import { studentActions } from '../../../../redux/actions';
import { getId } from '../../helpers';
import useListPublicEntity from '../../../../hooks/useListPublicEntity';

import {
  FullFlexCont,
  WhiteSpace,
  BeforeTitle,
  NoIconTitle,
  MobilePadding,
} from '../../formStyledComponents';

const Experience = ({ profile, littleDevice, scrollToTop }) => {
  const dispatch = useDispatch();

  const { data: residences } = useListPublicEntity('residence');

  /* add-experience button */
  const addExperience = (newExperience) => {
    const workExperience = _get(profile, 'workExperience', []);

    const experiences = [];

    workExperience.forEach((exp) => experiences.push({
      jobName: exp.jobName,
      iniDate: exp.iniDate,
      endDate: exp.endDate,
      workExperienceCountryId: getId(residences, exp.country.nameLang.es),
      city: exp.city,
      desc: exp.desc,
      company: exp.company,
    }));

    experiences.push({
      jobName: newExperience.jobName,
      iniDate: newExperience.iniDate,
      endDate: newExperience.endDate,
      workExperienceCountryId: getId(residences, newExperience.workExperienceCountryId),
      city: newExperience.city,
      desc: newExperience.desc,
      company: newExperience.company,
    });
    dispatch(studentActions.save({ workExperience: experiences }));
    onClose();
  };

  /* delete prev-studies button */
  const deleteExperience = (company, jobName) => {
    const workExperience = _get(profile, 'workExperience', []);
    const experiences = [];

    workExperience.forEach((exp) => experiences.push({
      jobName: exp.jobName,
      iniDate: exp.iniDate,
      endDate: exp.endDate,
      workExperienceCountryId: getId(residences, exp.country.nameLang.es),
      city: exp.city,
      desc: exp.desc,
      company: exp.company,
    }));

    const findExperienceToDelete = (experience) => experience.company === company && experience.jobName === jobName;

    const deleted = _remove(experiences, findExperienceToDelete);
    dispatch(studentActions.save({ workExperience: experiences }));
  };

  /* open / close add-experience-modal */
  const [experienceModalActive, setExperienceModalActive] = useState(false);

  const onClose = () => {
    setExperienceModalActive(false);
  };

  const openModal = () => {
    setExperienceModalActive(true);
  };

  const workExperience = _get(profile, 'workExperience', []);

  return (
    <>
      <MobilePadding>
        <FullFlexCont key="experience" mobilePadding="0">
          {!littleDevice && <BeforeTitle>(5 max)</BeforeTitle>}
          <NoIconTitle>
            {!littleDevice ? 'EXPERIENCA LABORAL/PRÁCTICAS:' : 'EXPERIENCIA LABORAL:'}
          </NoIconTitle>
        </FullFlexCont>
      </MobilePadding>

      {workExperience && workExperience.map((experience, i) => {
        const company = _get(experience, 'company', '');
        const jobName = _get(experience, 'jobName', '');
        const iniDate = _get(experience, 'iniDate', '');
        const endDate = _get(experience, 'endDate', '');
        const desc = _get(experience, 'desc', '');
        const country = _get(experience, 'country.nameLang.es', '');
        const city = _get(experience, 'city', '');

        return (
          <OneExperience
            i={i}
            key={`${company + jobName + i}oneExperience`}
            company={company}
            jobName={jobName}
            iniDate={iniDate}
            endDate={endDate}
            desc={desc}
            country={country}
            city={city}
            deleteExperience={deleteExperience}
            onClose={onClose}
            profile={profile}
            scrollToTop={scrollToTop}
            workExperience={workExperience}
          />
        );
      })}

      {workExperience && !littleDevice && workExperience.length < 5 && (
        <>
          <WhiteSpace height="4rem" />
          <ButtonSecondary text="Añadir experiencia laboral" fullWidth onClick={openModal} />
        </>
      )}

      {experienceModalActive
        && (
          <Modal hasDatePicker onClose={onClose}>
            <NewExperience
              addExperience={addExperience}
              workExperience={workExperience}
            />
          </Modal>
        )}
    </>
  );
};

export default Experience;
