/* eslint-disable no-console */
import { API } from 'aws-amplify';
import capitalize from '@bit/iamtechnologies.iamtech-js.capitalize';
import * as queries from '../../graphql/queries';

const response = (success, msg, data) => ({
  success,
  message: !success && !msg ? 'UnexpectedError' : msg,
  data,
});

export async function listPublicEntity(entity) {
  const queryName = `list${capitalize(entity)}s`;
  try {
    const { data } = await API.graphql({
      query: queries[queryName],
      authMode: 'API_KEY',
    });
    return response(true, null, data[queryName].items);
  } catch (error) {
    console.log('listEntity -> error', error);
    if (error.data) return response(true, null, error.data[queryName]?.items);
    return response(false, error.code);
  }
}

export async function getItemById(entity, id) {
  const queryName = `get${capitalize(entity)}`;
  try {
    const { data } = await API.graphql({
      query: queries[queryName],
      authMode: 'API_KEY',
      variables: { id },
    });
    return response(true, null, data[queryName]);
  } catch (error) {
    console.log('getItemById -> error', error);
    if (error.data) return response(true, null, error.data[queryName]);
    return response(false, error.code);
  }
}
