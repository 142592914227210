import React from 'react';
import styled, { keyframes } from 'styled-components';

const BounceAnimation = keyframes`
  0% { margin-bottom: 0; }
  50% { margin-bottom: 15px }
  100% { margin-bottom: 0 }
`;

const DotWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height:20px;
`;

const Dot = styled.div`
  background-color: ${({ loaderColor }) => loaderColor};
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;

  /* Animation */
  animation: ${BounceAnimation} 0.5s linear infinite;
  animation-delay: ${(props) => props.delay};
`;

const Loader = ({ loaderColor = 'white' }) => (
  <DotWrapper>
    <Dot loaderColor={loaderColor} delay="0s" />
    <Dot loaderColor={loaderColor} delay=".1s" />
    <Dot loaderColor={loaderColor} delay=".2s" />
  </DotWrapper>
);

export default Loader;
