import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';


const FadeIn = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const { duration, children, translate } = props;

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div
      style={{
        transition: `opacity ${duration}, transform ${duration}`,
        transform: translate && !isVisible ? 'translateX(100%)' : 'translateX(-0%)',
        opacity: isVisible ? 1 : 0,
      }}
    >
      {children}
    </div>
  );
};

FadeIn.propTypes = {
  children: PropTypes.node.isRequired,
  duration: PropTypes.string,
  translate: PropTypes.bool,
};

FadeIn.defaultProps = {
  duration: '400ms',
  translate: false,
};

export default FadeIn;
