import { getStudent, saveStudent } from '../../services/api/student';

export const STUDENT_BEGIN = 'STUDENT_BEGIN';
export const STUDENT_SUCCESS = 'STUDENT_SUCCESS';
export const STUDENT_FAILURE = 'STUDENT_FAILURE';
export const STUDENT_REMOVE = 'STUDENT_REMOVE';

const studentBegin = () => ({ type: STUDENT_BEGIN });

const studentSuccess = (student) => ({
  type: STUDENT_SUCCESS,
  student,
});

const studentFailure = (error) => ({
  type: STUDENT_FAILURE,
  error,
});


const refresh = () => async (dispatch) => {
  dispatch(studentBegin());
  const { success, data, error } = await getStudent();
  // if (success) dispatch(studentSuccess(data));
  if (data) dispatch(studentSuccess(data));
  else dispatch(studentFailure(error));
};


const save = (studentInfo) => (
  async (dispatch) => {
    const { success, data, error } = await saveStudent(studentInfo);
    if (success) dispatch(studentSuccess(data));
    else dispatch(studentFailure(error));
  }
);

const empty = () => ({ type: 'STUDENT_REMOVE' });


export default {
  refresh,
  save,
  empty,
};
