import styled from 'styled-components/macro';

import InputText from '../../common/formFields/Inputs/InputText';
import Box from '../../common/Flex/Box';


export const FilterButtonContainer = styled.div`
  min-width: 14rem;
  margin-right: 1rem;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    min-width: 48%;
  }

  button {
    height: 4.6rem;
    font-size: ${({ theme }) => theme.fontSizes.medium};
  }
`;

export const SearchBar = styled(Box)`
  flex-grow: 1;
  border-radius: 3rem;

  input {
    padding: 1rem 1.5rem;
    border-radius: 3rem 0rem 0rem 3rem;
    border-width: 2px;
    height: 4.6rem;
  }
  button {
    border-radius: 0rem 3rem 3rem 0rem;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    height: 4.6rem;
  }
`;

export const InputString = styled(InputText)`
  width: 30%;
`;

export const SearchButtonContainer = styled.div`
  min-width: 17rem;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    min-width: 48%;
  }

  button {
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      height: 4.6rem;
    }
  }
`;

export const Text = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${({ theme, isPhone }) => (isPhone ? theme.fontSizes.small : theme.fontSizes.medium)};
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
  color: ${({ color }) => color};
  line-height: 1.47;
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

export const Form = styled.form`
  width: 100%;
`;
