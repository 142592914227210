import React from 'react';
import { useDispatch } from 'react-redux';
import _get from 'lodash/get';
import { useForm } from 'react-hook-form';
import ButtonPrimary from '../../../common/Buttons/ButtonPrimary';
import ButtonSecondary from '../../../common/Buttons/ButtonSecondary';
import { studentActions } from '../../../../redux/actions';

import {
  FullFlexCont,
  Column,
  Input,
  Label,
  DobleInputDiv,
  InputColumn,
  Error,
  FlexForm,
} from '../../formStyledComponents';

const EditUserContact = ({
  setSelectedEdit,
  contactData,
  formActive,
  setFormActive,
}) => {
  const dispatch = useDispatch();

  const { register, handleSubmit, errors } = useForm();


  /* get data from profileData for the initial input values */
  const initialEmail = _get(contactData, 'email', '');
  const initialSkype = _get(contactData, 'skypeUsername', '');
  const initialMobile = _get(contactData, 'mobilePhone', '');
  const initialFacebook = _get(contactData, 'facebookPrfileURL', '');

  /* handle errors  */
  const emailError = errors.email?.type === 'required' && <Error>Por favor, indica tu email de contacto</Error>;
  const emailError2 = errors.email?.type === 'pattern' && <Error>Formato de email incorrecto</Error>;
  const skypeError = errors.skypeUsername && <Error>Debes indicar tu ID de Skype</Error>;
  const mobileError = errors.mobilePhone?.type === 'required' && <Error>Debes indicar un teléfono móvil de contacto</Error>;
  const phoneError2 = errors.mobilePhone?.type === 'pattern' && <Error>Número de teléfono no válido</Error>;

  /* edit / save data button */
  const onSubmit = (data, e) => {
    e.preventDefault();
    const contact = {
      email: data.email,
      skypeUsername: data.skypeUsername,
      mobilePhone: data.mobilePhone,
      facebookProfileURL: data.facebookPrfileURL,
    }
    dispatch(studentActions.save({ ...contact }));
    setFormActive(!formActive);
  };

  return (
    <>
      <FlexForm onSubmit={handleSubmit(onSubmit)} column>

        <Column width={100} padding="0 0 0 2rem" mobileFull margin="2rem 0 0 0">
          <DobleInputDiv column>
            <InputColumn margin="0">
              <Label mobileMargin="0" width="100%">Email*:</Label>
              <Input
                disabled={!formActive}
                name="email"
                placeholder="email"
                ref={register({
                  required: true,
                  pattern: {
                    value: /[\w\-\._]+@[\w\-\._]+\.\w{2,10}/,
                  },
                })}
                defaultValue={initialEmail}
                mobileWidth="100%"
                mobileMargin="0 0 1rem"
                mobileCenter
                mobilePadding="1rem"
                center
              />
              {emailError}
              {emailError2}
            </InputColumn>

            <InputColumn margin="0">
              <Label mobileMargin="0" width="100%">SkypeID*:</Label>
              <Input
                disabled={!formActive}
                name="skypeUsername"
                placeholder="skype"
                ref={register({ required: true })}
                defaultValue={initialSkype}
                mobileWidth="100%"
                mobileMargin="0 0 1rem"
                mobilePadding="1rem"
                mobileCenter
                center
              />
              {skypeError}
            </InputColumn>
          </DobleInputDiv>

          <DobleInputDiv column>
            <InputColumn margin="0">
              <Label mobileMargin="0" width="100%">Teléfono celular*:</Label>
              <Input
                disabled={!formActive}
                name="mobilePhone"
                placeholder="mobile"
                ref={register({
                  required: true,
                  pattern: {
                    value: /^\+[0-9][0-9]{6,20}$/,
                  },
                }
                )}
                defaultValue={initialMobile}
                mobileWidth="100%"
                mobileMargin="0 0 1rem"
                mobileCenter
                mobilePadding="1rem"
                center
              />
              {mobileError}
              {phoneError2}
            </InputColumn>
            <InputColumn margin="0">
              <Label mobileMargin="0" width="100%">Facebook:</Label>
              <Input
                disabled={!formActive}
                name="facebookPrfileURL"
                placeholder="facebook"
                ref={register({ required: false })}
                defaultValue={initialFacebook}
                mobileWidth="100%"
                mobileMargin="0 0 1rem"
                mobileCenter
                mobilePadding="1rem"
                center
              />
            </InputColumn>
          </DobleInputDiv>
        </Column>

        <Column width={100} center mobileFull mobileMargin="0" padding="0" >

          <FullFlexCont justify="space-between" padding="0" >
            <Column width="46" mobileMargin="0" padding="0" margin="0">
              <ButtonSecondary
                text="Atrás"
                onClick={() => setSelectedEdit(null)}
                margin="auto"
                fullWidth
              />
            </Column>

            <Column width="46" mobileMargin="0" padding="0" margin="0">
              {!formActive
                && (
                  <ButtonPrimary
                    fontSize="medium"
                    type={2}
                    text="Editar"
                    onClick={() => setFormActive(!formActive)}
                    margin="0 0 0 auto"
                    mobileFullWidth
                    fullWidth
                  />
                )}

              {formActive
                && (
                  <ButtonPrimary
                    type={2}
                    text="Guardar"
                    htmlType="submit"
                    margin="auto"
                    fullWidth
                  />
                )}
            </Column>
          </FullFlexCont>

        </Column>

      </FlexForm>
    </>
  );
};

export default EditUserContact;