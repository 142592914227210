import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

// import PaymentMessage from '../OnBoarding/PaymentMessage';
import { studentActions } from '../../redux/actions';
import Box from '../common/Flex/Box';
import ButtonPrimary from '../common/Buttons/ButtonPrimary';
import ButtonSecondary from '../common/Buttons/ButtonSecondary';
import HSpacer from '../layout/HSpacer';
import { PaymentMethods } from '.';
import { signout } from '../../services/api/auth';
import { Loader, Modal } from '../common';
import PayLaterPopUp from '../OnBoarding/PayLaterPopUp';
import { BgContainer, OnBoardingStep } from '../OnBoarding';
import { payStudentPayment } from '../../services/api/payments';
import InfoPopUp from '../common/InfoPopUp/InfoPopUp';

import {
  StepTitle,
  Packs,
  PacksTab,
  PacksTabMiddle,
  PackContent,
  CentralCard,
  CentralCardLeft,
  CentralRight,
  CentralCardLine,
  PackContentTitle,
} from '../OnBoarding/Styles';
import { payment } from '../../utils';


const PackContentPayment = styled(PackContent)`
  min-height: auto;
  border-bottom: none;
`;

const InputLabel = styled.label`
  display: block;
  text-align: center;
  color: ${(props) => props.theme.colors.greyDark};
  font-size: ${(props) => props.theme.fontSizes.large};
  margin-bottom: 3px;
`;

const AbsoluteStepTitle = styled(StepTitle)`
  position: absolute;
  top: 4rem;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {   
    position: relative;
    margin-bottom: 2rem;
    top: 2rem;
  }
`;

const PaymentScreen = ({
  onStepCompleted,
  onStepBack,
  formData,
  onFormDataChange,
  screen,
  iva,
  insuranceTax,
}) => {
  const { priceSelected = { service: {}, residence: {} } } = formData;
  const paymentMethod = formData.payment;
  const dispatch = useDispatch();
  const student = useSelector((state) => state.student);
  const [activeTab, setActiveTab] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [firstPaymentDone, setFirstPaymentDone] = useState(false);
  const [payLaterModalOpen, setPayLaterModalOpen] = useState(false);
  const [payLaterButtonVisible, setPayLaterButtonVisible] = useState(true);
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false);

  const isCardPaymentMethod = paymentMethod === 'card';
  const onClosePayLaterModal = () => {
    setPayLaterModalOpen(false);
  };

  // Pay later
  const history = useHistory();

  const handleLogout = async () => {
    const { success } = await signout();

    if (success) {
      history.push('/login');
      dispatch(studentActions.empty());
    }
  };

  const onPaymentSuccess = async (filename, paymentIntentId) => {
    const { payments, code, number } = student;
    const preparedPayments = payments.map((item) => {
      if (item.code !== `${code}/${number}/firstPayment/1`) return item;

      const updatedPayment = {
        ...item,
        type: paymentMethod,
        status: isCardPaymentMethod ? 'done' : 'inReview',
        paymentServiceId: item.service.id,
        ...(filename && { receiptFile: filename }),
        ...(isCardPaymentMethod && { dateDone: new Date(), paymentIntentId }),
      };

      delete updatedPayment.service;
      return updatedPayment;
    });

    try {
      const results = await Promise.all(preparedPayments.map(payStudentPayment));
      const failedResults = results.filter((result) => !result.success);

      if (failedResults.length) {
        setError(failedResults.map((result) => result.message).join(', '));
        setIsSuccess(false);
        return;
      }

      dispatch(studentActions.save({ payments: preparedPayments }));
      setIsSuccess(true);
      setShowPaymentSuccessModal(true);
    } catch (err) {
      setError(err.message || 'An unknown error occurred');
    }
  };


  if (!priceSelected) return null;
  const { firstPayment, secondPayment, service, totalIvaAmount, inscriptionPrice } = priceSelected;

  const isPhone = screen === 'phone';
  const infoPopUpText = `El valor del IVA (${iva} %) y del seguro completo se abonan junto con el ${secondPayment ? 'segundo' : ''} pago`;
  const currencySymbol = payment.currencySymbols[student?.payments[0]?.currency] || payment.currencySymbols.DEFAULT;

  return (
    <BgContainer>
      <OnBoardingStep>
        <CentralCard padding="10rem 6rem 10rem 6rem" mobileMargin="auto" mobilePadding="0">
          <AbsoluteStepTitle>
            Datos de pago: inscripción {inscriptionPrice}{currencySymbol}
          </AbsoluteStepTitle>
          <HSpacer />

          <CentralCardLeft>
            {/* {isSuccess ? <PaymentMessage paymentMethod={paymentMethod} /> : ''} */}
            {
              student?.id && firstPayment && (
                <PaymentMethods
                  amount={+firstPayment}
                  error={error}
                  firstPaymentDone={firstPaymentDone}
                  method={paymentMethod}
                  onChange={onFormDataChange}
                  onPaymentSuccess={onPaymentSuccess}
                  onStepCompleted={onStepCompleted}
                  paymentId={`${student?.id}/firstPayment/1`}
                  showPaymentSuccessModal={showPaymentSuccessModal}
                />
              )
            }

            {
              isSuccess && !isCardPaymentMethod && (
                <ButtonPrimary
                  text="Continuar"
                  onClick={onStepCompleted}
                  margin="auto"
                  fullWidth
                  mobilePadding="1rem 0.5rem"
                  mobileWidth="10rem"
                />
              )
            }
          </CentralCardLeft>

          <CentralCardLine />

          <CentralRight>
            <InputLabel>Detalle de los pagos</InputLabel>
            <HSpacer />
            <Packs>
              <Box container>
                <PacksTab active={activeTab === 0} onClick={() => setActiveTab(0)} w={1 / 3}>
                  desglose pago
                </PacksTab>
                <PacksTabMiddle active={activeTab === 1} onClick={() => setActiveTab(1)} w={1 / 3}>
                  plazos pago
                </PacksTabMiddle>
                <PacksTab active={activeTab === 2} onClick={() => setActiveTab(2)} w={1 / 3}>
                  no incluye
                </PacksTab>
              </Box>
              <PackContentPayment>
                <HSpacer />
                {activeTab === 0 && (
                  <>
                    {!firstPayment && (<Box margin="6rem"><Loader /></Box>) }
                    {firstPayment && (
                      <>
                        <PackContentTitle>
                          {secondPayment ? 'Primer pago:' : 'Pago inscripción:'}
                          {' '}
                          {firstPayment}
                          {currencySymbol}
                        </PackContentTitle>
                        <HSpacer />
                      </>
                    )}
                    {secondPayment && (
                      <>
                        <PackContentTitle>
                          Segundo pago:
                          {' '}
                          {secondPayment}
                          {currencySymbol}
                        </PackContentTitle>
                        <HSpacer />
                      </>
                    )}
                    {(firstPayment || secondPayment) && iva && (
                      <>
                        <PackContentTitle>
                          + IVA (
                          {iva}
                          %):
                          {' '}
                          {totalIvaAmount}{currencySymbol}
                        </PackContentTitle>
                        <HSpacer />
                      </>
                    )}
                    {insuranceTax !== '' && <PackContentTitle>Coste seguro obligatorio: {insuranceTax}{currencySymbol}/mes de prácticas</PackContentTitle>}
                    <HSpacer />
                    <InfoPopUp
                      height="auto"
                      left="0x"
                      step="payment"
                      text={infoPopUpText}
                      top="-50px"
                      width="300px"
                      currencySymbol={currencySymbol}
                    />
                  </>
                )}
                {activeTab === 1 && (
                  <>
                    <PackContentTitle>Primer pago: ahora</PackContentTitle>
                    <HSpacer />
                    {secondPayment ? <PackContentTitle>Segundo pago: tras asignación de vacante</PackContentTitle> : ''}
                    {insuranceTax !== '' && <PackContentTitle>Pago del seguro: tras asignación de vacante</PackContentTitle>}
                  </>
                )}
                {activeTab === 2 && (
                  service.notIncludes.map((ni) => (
                    <>
                      <PackContentTitle>{ni.es}</PackContentTitle>
                      <HSpacer />
                    </>
                  ))
                )}
              </PackContentPayment>
            </Packs>
            <HSpacer height="2.5rem" />
            <Box container>
              {isPhone
                && (
                  <ButtonSecondary
                    text="Atrás"
                    onClick={onStepBack}
                    margin="auto"
                    fullWidth
                    mobilePadding="1rem 0.5rem"
                    mobileWidth="10rem"
                  />
                )}
              {payLaterButtonVisible
                && (
                  <ButtonPrimary
                    text="Pagar más adelante"
                    onClick={() => setPayLaterModalOpen(true)}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...(!isPhone && { fullWidth: true })}
                  />
                )}
            </Box>
          </CentralRight>
          {payLaterModalOpen
            && (
              <Modal onClose={onClosePayLaterModal}>
                <PayLaterPopUp
                  onClose={onClosePayLaterModal}
                  confirm={handleLogout}
                />
              </Modal>
            )}
        </CentralCard>
      </OnBoardingStep>
    </BgContainer>
  );
};

export default PaymentScreen;
