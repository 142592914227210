const addFilters = (filters) => {
  if ('localStorage' in window && window.localStorage !== null) {
    localStorage.setItem('nexusFilters', filters);
  }
};

const getFilters = () => {
  if ('localStorage' in window && window.localStorage !== null && localStorage.nexusFilters) {
    const { nexusFilters } = localStorage;
    return JSON.parse(nexusFilters);
  }
  return undefined;
};

const removeFilters = () => {
  if ('localStorage' in window && window.localStorage !== null && localStorage.nexusFilters) {
    localStorage.removeItem('nexusFilters');
  }
  return true;
};

const addSearch = (search) => {
  if ('localStorage' in window && window.localStorage !== null) {
    localStorage.setItem('nexusSearch', search);
  }
};

const getSearch = () => {
  if ('localStorage' in window && window.localStorage !== null && localStorage.nexusSearch) {
    const { nexusSearch } = localStorage;
    return nexusSearch;
  }
  return undefined;
};

const removeSearch = () => {
  if ('localStorage' in window && window.localStorage !== null && localStorage.nexusSearch) {
    localStorage.removeItem('nexusSearch');
  }
  return true;
};

export default {
  addFilters,
  getFilters,
  removeFilters,
  addSearch,
  getSearch,
  removeSearch,
};

