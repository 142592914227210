import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  FilterButtonContainer,
  SearchBar,
  InputString,
  SearchButtonContainer,
  Text,
  Form,
} from './styledComponents';
import Vacancies from '../Vacancies';
import Box from '../../common/Flex/Box';
import ButtonSecondary from '../../common/Buttons/ButtonSecondary';
import ButtonPrimary from '../../common/Buttons/ButtonPrimary';
import HSpacer from '../../layout/HSpacer';
import {
  BackArrowTitle, SingleRow, PageSubMenuPill, PageContainer,
} from '../../common';
import { dates } from '../../../utils';
import Theme from '../../CustomTheme/Theme';


const VacancyResult = (props) => {
  const screen = useSelector((state) => state.windowResize.screen);
  const favourites = useSelector((state) => state.student.favourites);

  const {
    activeSection,
    setActiveSection,
    setFilter,
    search,
    setSearch,
    onSearch,
    items,
    hasFullProfile,
    goToProfile,
    onChangePage,
    setVacancyId,
    lastPage,
    loading,
    total,
    setMobileSearch,
    setFullProfileModalVisible,
    featuredVacancies,
    filters,
  } = props;

  const onSubmit = (e) => {
    e.preventDefault();
    if (hasFullProfile) onSearch();
    else setFullProfileModalVisible(true);
  };

  const getVacanciesItems = () => {
    if (activeSection === 'Favoritos') return favourites;
    if (activeSection === 'Destacadas') return featuredVacancies;
    return items;
  };

  const getFiltersText = () => {
    let text = '';
    if (filters?.departments?.length) text = text.concat(filters?.departments?.join(', '));
    if (filters?.zones?.length) text = text.concat(', ').concat(filters.zones.join(', '));
    if (filters?.datesOpen?.dateIni) text = text.concat(', inicio: ').concat(dates.getStringDate(filters.datesOpen.dateIni));
    if (filters?.datesOpen?.dateEnd) text = text.concat(', fin: ').concat(dates.getStringDate(filters.datesOpen.dateEnd));
    return text;
  };

  const filtersText = getFiltersText();

  const isPhone = screen === 'phone';

  return (
    <PageContainer>
      <BackArrowTitle title="Vacantes" linkto="/menu" />

      <SingleRow />

      <PageSubMenuPill
        title1="Búsqueda"
        title2="Destacadas"
        title3="Favoritos"
        active={activeSection}
        setActive={setActiveSection}
        isVacancies
        hasFullProfile={hasFullProfile}
        setFullProfileModalVisible={setFullProfileModalVisible}
      />

      <Box container>
        <FilterButtonContainer>
          <ButtonSecondary
            text="Filtrar"
            fontSize="medium"
            fullWidth
            onClick={
              hasFullProfile ? () => setFilter(true) : () => setFullProfileModalVisible(true)
            }
          />
        </FilterButtonContainer>
        {
          screen !== 'phone' ? (
            <Form onSubmit={onSubmit}>
              <SearchBar container>
                <InputString
                  type="text"
                  name="search"
                  placeholder="Buscar por nombre o ciudad"
                  value={search}
                  onChange={(value) => setSearch(value)}
                  controlled
                />
                <SearchButtonContainer>
                  <ButtonPrimary
                    text="Buscar"
                    icon="search"
                    onClick={hasFullProfile ? onSearch : () => setFullProfileModalVisible(true)}
                    type={2}
                    fontSize="medium"
                    fullWidth
                  />
                </SearchButtonContainer>
              </SearchBar>
            </Form>
          ) : (
            <SearchButtonContainer>
              <ButtonPrimary
                text="Buscar"
                icon="search"
                onClick={
                  hasFullProfile
                    ? () => setMobileSearch(true)
                    : () => setFullProfileModalVisible(true)
                }
                type={2}
                fontSize="medium"
                fullWidth
              />
            </SearchButtonContainer>
          )
        }
      </Box>

      <HSpacer height="2.5rem" />

      {isPhone && search ? <Text>Búsqueda: {search}</Text> : ''}

      {activeSection === 'Búsqueda' && filtersText && <Text>Filtros activos: {filtersText}</Text>}

      {activeSection === 'Búsqueda' && <Text marginBottom="2rem" isPhone={isPhone}>Estas son nuestras vacantes disponibles:</Text>}

      {activeSection === 'Destacadas' && (
        <Box margin="0 0 20px" container gap="5px" justify="center" direction={isPhone ? 'column' : 'row'}>
          <Text color={Theme.colors.alertNotification} fontWeight="bold" isPhone={isPhone}>
            Vacantes con descuento en el precio del programa.
          </Text>
          <Text isPhone={isPhone}>
            Para más detalles entra en la vacante.
          </Text>
        </Box>
      )}

      {activeSection === 'Favoritos' && (
        <Box margin="0 0 20px" container gap="5px" justify="center" direction="column">
          <Text isPhone={isPhone}>
            Vacantes pre-seleccionadas por ti.
          </Text>
          <Text fontWeight="bold" isPhone={isPhone}>
            Recuerda: Entrar en cada vacante para seleccionar y priorizar.
          </Text>
        </Box>
      )}

      <Vacancies
        vacancyType={activeSection}
        items={getVacanciesItems()}
        hasFullProfile={hasFullProfile}
        goToProfile={goToProfile}
        onChangePage={onChangePage}
        setVacancyId={setVacancyId}
        lastPage={lastPage}
        loading={loading}
        total={total}
        setFullProfileModalVisible={setFullProfileModalVisible}
      />

    </PageContainer>
  );
};

VacancyResult.propTypes = {
  vacancyType: PropTypes.string.isRequired,
  setVacancyType: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  search: PropTypes.string,
  setSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasFullProfile: PropTypes.bool.isRequired,
  goToProfile: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  setVacancyId: PropTypes.func.isRequired,
  lastPage: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  total: PropTypes.number,
  setMobileSearch: PropTypes.func,
  featuredVacancies: PropTypes.arrayOf(PropTypes.object),
  filters: PropTypes.shape(),
};

VacancyResult.defaultProps = {
  search: '',
  total: 0,
  setMobileSearch: () => { },
  featuredVacancies: [],
  filters: {},
};

export default VacancyResult;
