import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import _find from 'lodash/find';

import {
  PageContainer, BackArrowTitle, SingleRow,
  CardVacante, Box,
} from '../components/common';
import HSpacer from '../components/layout/HSpacer';
import { Vacancy } from '../components/vacancies';
import { images } from '../utils';
import LoggedLayout from '../components/layout/LoggedLayout';
import FadeIn from '../hooks/fadeIn';


const Favourites = () => {
  const student = useSelector((state) => state.student);
  const { favourites = [] } = student;

  const screen = useSelector((state) => state.windowResize.screen);
  const isSmallScreen = screen === 'phone' || screen === 'tab-port';

  const [vacancyId, setVacancyId] = useState('');

  if (vacancyId) {
    const item = _find(favourites, { id: vacancyId });
    return (
      <Vacancy
        key={vacancyId}
        identifier={vacancyId}
        item={item}
        setVacancyId={setVacancyId}
      />
    );
  }

  let w = 1 / 3;
  if (screen === 'phone') w = 1;
  if (screen === 'tab-port') w = 1 / 2;


  return (
    <LoggedLayout>
      <PageContainer>

        <BackArrowTitle title="Favoritos" linkto="/menu" />

        {isSmallScreen ? <HSpacer height="2.5rem" /> : <SingleRow />}

        <Box container wrap="wrap">
          {
            favourites.length ? favourites.map((item) => {
              const id = _get(item, 'id', '');
              const tagName = _get(item, 'department', '');
              const workplaceName = _get(item, 'company', '');
              const economicHelp = _get(item, 'benefitEconomy', '');
              const logoImage = _get(item, 'logoImage', '');
              const file = images.getUrl('vacancy/logoImage', logoImage);
              const city = _get(item, 'city', '');
              const duration = '400ms';

              return (
                <Box w={w} spacing={3} key={id}>
                  <FadeIn duration={duration} fade>
                    <CardVacante
                      file={file}
                      tagName={tagName}
                      workplaceName={workplaceName}
                      economicHelp={economicHelp}
                      ubication={city}
                      id={id}
                      setVacancyId={setVacancyId}
                      hasFullProfile
                    />
                  </FadeIn>
                </Box>
              );
            }) : ''
          }
        </Box>
      </PageContainer>
    </LoggedLayout>
  );
};

export default Favourites;
