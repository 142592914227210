const getEntity = (entities, name) => {
  let result;
  entities.map(entity => {
    if(entity.name) {
      if (entity.name === name) {
        result = entity;
      }
    } else {
      if (entity.nameLang.es === name) {
        result = entity;
      }
    }
  });
  return result;
};

export default getEntity;