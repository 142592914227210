import React from 'react';
import { useSelector } from 'react-redux';

import {
  PageContainer, BackArrowTitle, SingleRow,
  CardNotificacion,
} from '../components/common';
import HSpacer from '../components/layout/HSpacer';
import LoggedLayout from '../components/layout/LoggedLayout';
import { useNotifications } from '../contexts/NotificationsContext';
import { putNotificationRead } from '../services/api/notifications';


const Notifications = () => {
  const screen = useSelector((state) => state.windowResize.screen);
  const isSmallScreen = screen === 'phone' || screen === 'tab-port';
  const { notifications, getList } = useNotifications();

  const handleOnClick = async (notificationTimestamp) => {
    await putNotificationRead(notificationTimestamp);
    getList();
  };

  return (
    <LoggedLayout>
      <PageContainer>
        <BackArrowTitle title="Notificaciones" linkto="/menu" />

        {isSmallScreen ? <HSpacer height="2.5rem" /> : <SingleRow />}

        {
          notifications
          .filter(({ type }) => type === 'web' || type === 'web_correo')
          .sort((a, b) => b.timestamp - a.timestamp)
          .map((notification) => {
            const { title, content, read, timestamp } = notification;
        
            return (
              <CardNotificacion
                isReaded={read}
                title={title}
                text={content}
                onClick={() => handleOnClick(timestamp)}
                key={`${title}-${timestamp}`}
              />
            );
          })
        }
      </PageContainer>
    </LoggedLayout>
  );
};

export default Notifications;
