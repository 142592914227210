import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

const CardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 4rem;
  flex-wrap: wrap;
  text-align: center;
  line-height: 2;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    text-align: center;
    padding: 2rem;
  }
`;

const P = styled.p`
  font-size: 1.5rem;
  margin: 2rem 0;
`;

const GoProfileLink = styled.p`
  font-size: 1.8rem;
  font-weight: bold;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.themePrimary};
`;



const FullProfileModal = () => {
  return (
    <CardContentWrapper>
      <h2>¡Sólo unos cuantos datos más!</h2>
      <P>Debes <b>completar el 100% </b>de tu perfil para poder acceder a todas las vacantes disponibles, buscar las que más te interesen y guardarlas en tus <b>favoritos</b>.</P>
      <Link to="/profile">
        <GoProfileLink>
          Completar mi perfil
        </GoProfileLink>
      </Link>
    </CardContentWrapper>
  )
};

export default FullProfileModal;