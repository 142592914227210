import React from 'react';
import _get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import ButtonPrimary from '../../../common/Buttons/ButtonPrimary';
import ButtonSecondary from '../../../common/Buttons/ButtonSecondary';
import showOptions from '../../helpers/showOptions';
import { studentActions } from '../../../../redux/actions';

import {
  Column,
  Label,
  InputColumn,
  FullFlexCont,
  WhiteSpace,
  ContactForm,
  Select,
  DisabledBlueInput,
} from '../../formStyledComponents';

const LangToEdit = ({
  profile,
  setSelectedLang,
  languageName,
  speak,
  write,
  understand,
  levels,
}) => {
  const dispatch = useDispatch();

  const { register, handleSubmit } = useForm();

  const onSubmit = (data, e) => {
    e.preventDefault();
    const languages = _get(profile, 'languageExperience', []);

    languages.forEach((lang, index) => {
      if (data.languageName === lang.languageName) {
        languages.splice(index, 1, data);
      }
    });

    dispatch(studentActions.save({ languageExperience: languages }));
    setSelectedLang(null);
  };


  return (
    <>
      <ContactForm onSubmit={handleSubmit(onSubmit)}>

        <FullFlexCont justify="flex-end" padding="0" column>

          <InputColumn width="100%" margin="0 auto 0 2rem">
            <DisabledBlueInput
              bold
              width="100%"
              mBottom="0"
              name="languageName"
              placeholder="Idioma"
              ref={register({ required: true })}
              value={languageName}
            />
          </InputColumn>

          <InputColumn width="100%">
            <Label>Hablado:</Label>
            <Select
              width="100%"
              mBottom="0"
              name="speak"
              ref={register({ required: true })}
              defaultValue={speak}
            >
              {showOptions(levels, speak, 'nivel hablado')}
            </Select>
          </InputColumn>

          <InputColumn width="100%">
            <Label>Escrito:</Label>
            <Select
              width="100%"
              mBottom="0"
              name="write"
              ref={register({ required: true })}
              defaultValue={write}
            >
              {showOptions(levels, write, 'nivel escrito')}
            </Select>
          </InputColumn>

          <InputColumn width="100%">
            <Label>Comprensión:</Label>
            <Select
              width="100%"
              mBottom="0"
              name="understand"
              ref={register({ required: true })}
              defaultValue={understand}
            >
              {showOptions(levels, understand, 'comprensión')}
            </Select>
          </InputColumn>

        </FullFlexCont>

        <FullFlexCont justify="space-between">

          <WhiteSpace height="1rem" />

          <Column width="46" padding="0">
            <ButtonSecondary
              text="Atrás"
              onClick={() => setSelectedLang(null)}
              margin="auto"
              fullWidth
            />
          </Column>

          <Column width="46" padding="0">
            <ButtonPrimary
              type={2}
              text="Guardar"
              htmlType="submit"
              margin="auto"
              fullWidth
            />
          </Column>

        </FullFlexCont>

      </ContactForm>
    </>
  );
};

export default LangToEdit;
