/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';


const InputLabel = styled.label`
  display: block;
  text-align: center;
  color: ${(props) => props.theme.colors.greyDark};
  font-size: ${(props) => props.theme.fontSizes.large};
  margin-bottom: 3px;
`;

const InputWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: ${({ mobileWidth }) => (mobileWidth || '100%')};
`;

const InputContainer = styled.input`
  border: transparent;
  width: ${({ mobileWidth }) => (mobileWidth || '100%')};
  padding: ${({ disabled }) => (disabled ? '0.625rem 0rem' : '0.625rem 1.375rem')};
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: ${(props) => props.theme.fontSizes.medium};
  line-height:1.5;
  margin-bottom:${(props) => props.mBottom}rem;
  border: ${({ disabled, theme }) => (disabled ? 'solid 1px transparent' : `solid 1px ${theme.colors.textSecondary}`)};
  border-radius: 2rem;
  outline: none;
  background: none;
`;

const PasswordToggle = styled.div`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSizes.medium};
  color: ${(props) => props.theme.colors.greyDark};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InputText = (props) => {
  const {
    mBottom,
    placeholder,
    label,
    name,
    value,
    defaultValue,
    type,
    onChange,
    controlled,
    inputRef,
    active,
    mobileWidth,
  } = props;

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handlePasswordVisibility = (e) => {
    e.preventDefault();
    setIsPasswordVisible(!isPasswordVisible);
  };

  const inputType = type === 'password' && !controlled && isPasswordVisible ? 'text' : type;

  return (
    <>
      {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
      <InputWrapper>
        <InputContainer
          type={inputType}
          name={name}
          placeholder={placeholder}
          onChange={(e) => (onChange && type === 'file' ? onChange(e.target.files) : onChange(e.target.value))}
          {...(controlled && { onChange: (e) => (onChange && type === 'file' ? onChange(e.target.files) : onChange(e.target.value)) })}
          {...(controlled && { value })}
          {...(!controlled && { defaultValue })}
          mBottom={mBottom}
          mobileWidth={mobileWidth}
          disabled={!active}
          ref={inputRef}
        />
        {!controlled && type === 'password' && (
          <PasswordToggle onMouseDown={handlePasswordVisibility}>
            {isPasswordVisible
              ? <i className="pi pi-eye-slash" style={{ fontSize: '1.5rem', marginRight: '3px' }} />
              : <i className="pi pi-eye" style={{ fontSize: '1.5rem', marginRight: '3px' }} />}
          </PasswordToggle>
        )}
      </InputWrapper>
    </>
  );
};

InputText.propTypes = {
  controlled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.string,
  mBottom: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({}),
  ]),
  active: PropTypes.bool,
};

InputText.defaultProps = {
  controlled: false,
  defaultValue: '',
  label: '',
  mBottom: 0,
  name: '',
  onChange: () => {},
  placeholder: '',
  type: 'text',
  value: '',
  inputRef: () => {},
  active: true,
};


export default InputText;
