import React from 'react';
import styled, { css } from 'styled-components/macro';

const HamburgerCont = styled.div`
  display: inline-block;
  cursor: pointer;
  margin: 10px;
`;

const Bar = styled.div`
  width: 25px;
  height: 3px;
  margin: 6px 0;
  background-color: #4e5152;
  transition: 0.4s;
`;

const Bar1 = styled(Bar)`
  ${(props) => props.showMenu && css`
    -webkit-transform: rotate(-45deg) translate(-9px, 6px) ;
    transform: rotate(-45deg) translate(-7px, 7px);
  `}
`;

const Bar2 = styled(Bar)`
  opacity: ${({ showMenu }) => (showMenu && '0')};
`;

const Bar3 = styled(Bar)`
  ${(props) => props.showMenu && css`
    -webkit-transform: rotate(45deg) translate(-8px, -8px) ;
    transform: rotate(45deg) translate(-6px, -6px) ;
  `}
`;

const Hamburger = ({ activateMenu, showMenu }) => {

  return (
    <>
      <HamburgerCont onClick={activateMenu}>
        <Bar1 showMenu={showMenu} />
        <Bar2 showMenu={showMenu} />
        <Bar3 showMenu={showMenu} />
      </HamburgerCont>
    </>
  );
};

export default Hamburger;