import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import Icons from '../../Icons';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  width: 100%;
  min-width:  25rem;
  min-height: 40rem;
  margin-bottom: 2rem;
  padding: 3rem 3rem 2rem;
  border-radius: ${(props) => props.theme.borderRadius.card};
  background-color: ${(props) => props.theme.colors.greyLight};


  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 2.5rem 3.5rem;   
    width: 100%;
    min-height: ${({ loader }) => (loader ? '20rem' : 'initial')};
    display:flex;
    justify-content: space-between;
    flex-direction: row;
    border-radius: ${({ loader, theme }) => (loader ? theme.borderRadius.card : '6rem')};
    text-align: left;
    font-size: ${({ theme }) => theme.fontSizes.large};
    height:80px;
  }
  p {
    /* margin-bottom: 2rem; */
  }
  span {
    cursor: pointer;
  }
`;


const CardButton = (props) => {
  const { text, onClick, loader } = props;

  return (
    <Container loader={loader}>
      {text && <p>{text}</p>}
      {
        onClick && (
          <span onClick={onClick} onKeyPress={onClick} role="button" tabIndex={0}>
            <Icons icon="chevronRight" iconColor="themePrimary" width={4} />
          </span>
        )
      }
    </Container>
  );
};

CardButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  loader: PropTypes.bool,
};

CardButton.defaultProps = {
  text: '',
  onClick: '',
  loader: false,
};

export default CardButton;
