import React from 'react';
import Layout from '../../layout/Layout';
import PageContainer from '../Container';
import LegalContent from './LegalContent';


const LegalContentLayout = ({ contentKey }) => (
  <Layout>
    <PageContainer contents>
      <LegalContent contentKey={contentKey} />
    </PageContainer>
  </Layout>
);

export default LegalContentLayout;
