import React from 'react';

import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import theme from '../../CustomTheme/Theme';
import HSpacer from '../../layout/HSpacer/HSpacer';

const ContactPillWrapper = styled.div`
  width: ${({ width }) => width || '23rem'};
  height: 20rem;
  padding: 2rem;
  border-radius: 1.2rem;
  background-color: ${theme.colors.themeTertiary};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    display:none;
  }
`;


const Contact = styled.span`
font-size: ${theme.fontSizes.small};
font-weight: bold;
line-height: 0.69;
`;

const Mail = styled.span`
font-size: ${theme.fontSizes.small};
margin-top: 0.8rem;
`;


const ContactPill = ({ width }) => (
  <>
    <ContactPillWrapper width={width}>
      <Contact>Contacto Nexus</Contact>
      <Mail>merifsanchez@nexusmobility.es</Mail>
      <Mail>+34 638 39 21 24</Mail>
      <HSpacer height="1.6rem" />
      <Contact>Contacto Seguro Adeslas:</Contact>
      <Mail>+34 902 10 93 93</Mail>
      <HSpacer height="1.6rem" />
      <Contact>Contacto Seguro Sanitas:</Contact>
      <Mail>+34 91 752 29 01</Mail>
    </ContactPillWrapper>
  </>
);

ContactPill.propTypes = {
  width: PropTypes.string,
};

ContactPill.defaultProps = {
  width: null,
};

export default ContactPill;
