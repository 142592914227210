import { Auth } from 'aws-amplify';

const REGION = 'eu-central-1';

export default {
  Auth: {
    userPoolId: 'eu-central-1_HPX09fnOc',
    userPoolWebClientId: '38fbod2ibmj6o3ovhrttjumiji',
    region: REGION,
    identityPoolId: 'eu-central-1:459eae4d-5a79-4bbd-93f0-e5c7d95923d4',
  },
  aws_appsync_graphqlEndpoint:
    'https://kce6yajaljdkxkejmke46hji7q.appsync-api.eu-central-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-central-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-g6gtqsevonfgnkwcfo35pbv2hq',
  endpoints: [
    {
      name: 'payments',
      endpoint: 'https://ygca53zfn9.execute-api.eu-central-1.amazonaws.com/',
      region: REGION,
      custom_header: async () => (
        { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
      ),
    },
  ],
  Storage: {
    imagesCloudfront: '',
    bucket: 'nexus-backend-nexus-prod-platform-bucket-767397827072',
    region: 'eu-central-1',
    identityPoolId: 'eu-central-1:459eae4d-5a79-4bbd-93f0-e5c7d95923d4',
  },
};
