import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import useListPublicEntity from '../../../hooks/useListPublicEntity';

const CenterContainer = styled.div`
  width: 80%;
  margin: auto;
  padding-bottom: 2rem;
`;

const LegalContent = ({ contentKey }) => {
  const { data: contents } = useListPublicEntity('content');
  const lang = useSelector((state) => state.language.lang);

  const content = useMemo(() => contents?.find(({ key }) => key === contentKey), [contents, contentKey]);

  if (!content) return null;

  return (
    <CenterContainer
      dangerouslySetInnerHTML={{ __html: content.richTextLang[lang] }}
    />
  );
};

export default LegalContent;
