import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { showOptions } from '../helpers';
import ButtonPrimary from '../../common/Buttons/ButtonPrimary';
import {
  Select,
  Label,
  ContactForm,
  CardContentWrapper,
  WhiteSpace,
  ModalsLogo,
  NoIconTitle,
} from '../formStyledComponents';

import { getContriesSortByName } from '../../../libs/countries';


const countriesList = getContriesSortByName('es');


const NewNationality = (props) => {
  const { onClose, setValue, watch } = props;

  const [nationality, setNationality] = useState('');

  const addNationality = () => {
    if (!nationality) return;
    const newNationalities = watch('nationalitiesList') || [];
    newNationalities.push(nationality);
    setValue('nationalitiesList', newNationalities, { shouldValidate: true });
    onClose();
  };

  return (
    <CardContentWrapper mobileCenter>
      <ModalsLogo src="/images/logo.jpg" alt="Nexus logo" />

      <NoIconTitle margin="0 0 2rem 0">AÑADIR NACIONALIDAD</NoIconTitle>
      <ContactForm>

        <Label>Nueva nacionalidad:</Label>
        <Select
          name="nationality"
          onChange={(e) => setNationality(e.target.value)}
        >
          <option value="">Nacionalidades</option>
          {
            countriesList.map((country) => <option key={country.iso} value={country.name.es}>{country.name.es}</option>)
          }
        </Select>

        <WhiteSpace height="4rem" />

        <ButtonPrimary
          type={2}
          text="Añadir nacionalidad"
          onClick={addNationality}
          margin="0 0 0 auto"
        />

        <WhiteSpace height="4rem" />

      </ContactForm>

    </CardContentWrapper>
  );
};

NewNationality.propTypes = {
  residences: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClose: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
}

export default NewNationality;
