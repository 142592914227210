import { STUDENT_BEGIN, STUDENT_FAILURE, STUDENT_SUCCESS, STUDENT_REMOVE } from '../actions/studentActions';

const initialState = {
  id: undefined,
  loading: true,
  error: undefined,
};


export default (state = initialState, action) => {
  switch (action.type) {
    case STUDENT_BEGIN:
      return {
        ...state,
        loading: true,
        error: undefined,
      }

      case STUDENT_SUCCESS:
        return {
          ...state,
          loading: false,
          ...action.student,
        };

      case STUDENT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };
  
      case STUDENT_REMOVE:
        return initialState;
  
      default:
        return state;
  }
};
