import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import SingleRow from '../common/SingleRow';
import ContactPill from '../common/ContactPill';
import { listPublicEntity } from '../../services/api/data';
import AnswersAndQuestions from './AnswersAndQuestions';

const InformationWrapper = styled.div`
  display: flex;
  width:100%;
`;

const QuestionsAndAnswersBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  margin-right: 2rem;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    width:100%;
    margin-right:0;
  }
`;

const PillWrapper = styled.div`
  margin-top: -4rem;
`;

const Container = styled.div`
  width:65%;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    width:100%;
  }
`;

const UsefulInformation = () => {
  const user = useSelector((state) => state.student);
  const countryId = user?.country?.id;
  const [loading, setLoading] = useState(true);
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await listPublicEntity('faq');
      const list = data.filter((faq) => faq?.country?.id === countryId && faq?.type === 'general');
      setFaqs(list);
      setLoading(false);
    };
    fetchData();
  }, [countryId]);

  return (
    <>
      <Container><SingleRow width={100} /></Container>
      <InformationWrapper>
        <QuestionsAndAnswersBlock>
          {!loading && faqs && faqs.map((faq) => (
            <AnswersAndQuestions faq={faq} />
          ))}
        </QuestionsAndAnswersBlock>
        <PillWrapper>
          <ContactPill />
        </PillWrapper>
      </InformationWrapper>
    </>
  );
};

export default UsefulInformation;
