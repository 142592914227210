import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _remove from 'lodash/remove';
import _get from 'lodash/get';

import ButtonSecondary from '../../../common/Buttons/ButtonSecondary';
import { Modal, SingleRow } from '../../../common';
import NewDocument from './NewDocument';
import Document from './Document';
import { studentActions } from '../../../../redux/actions';

import {
  FullFlexCont,
  WhiteSpace,
  NoIconTitle,
  SubTitle,
} from '../../formStyledComponents';

const OtherDocuments = ({ profile, littleDevice }) => {
  const dispatch = useDispatch();
  const userId = profile.id;

  const [otherDocumentss, setOtherExperiences] = useState([]);
  const [documentsModalActive, setDocumentModalActive] = useState(false);

  useEffect(() => {
    const experiences = _get(profile, 'otherDocuments', []);
    setOtherExperiences(experiences);
  }, [profile]);

  /* delete document button */
  const deleteDocument = (documentName) => {
    const documents = [...otherDocumentss];
    const findDocumentsToDelete = (document) => document === documentName;
    _remove(documents, findDocumentsToDelete);
    dispatch(studentActions.save({ otherDocuments: documents }));
  };

  /* open / close add-document-modal */
  const onClose = () => {
    setDocumentModalActive(false);
  };

  const openModal = () => {
    setDocumentModalActive(true);
  };

  /* add-document button */
  const addDocument = (newDocument) => {
    if (!newDocument) return;
    const documents = [...otherDocumentss, newDocument];
    dispatch(studentActions.save({ otherDocuments: documents }));
    onClose();
  };

  return (
    <>
      <FullFlexCont mobilePadding="0 0 2rem 0">
        <NoIconTitle>OTROS DOCUMENTOS</NoIconTitle>
        <br />
        <SubTitle>
          *Ejemplos: Carta de recomendación, Carta de motivación, certificado de
          cursos realizados, CV, premios etc…
        </SubTitle>
      </FullFlexCont>

      <SingleRow />

      {otherDocumentss
        && otherDocumentss.map((document, i) => {
          const name = document?.substring(document.lastIndexOf('/') + 1);
          return (
            <Document
              i={i}
              key={document}
              name={name}
              path={document}
              deleteDocument={deleteDocument}
              onClose={onClose}
              profile={profile}
              littleDevice={littleDevice}
              otherDocumentss={otherDocumentss}
            />
          );
        })}

      <WhiteSpace height="4rem" />
      {!littleDevice && (
        <ButtonSecondary
          text="Añadir documento"
          fullWidth
          onClick={openModal}
        />
      )}

      {documentsModalActive && (
        <Modal onClose={onClose}>
          <NewDocument
            addDocument={addDocument}
            littleDevice={littleDevice}
            userId={userId}
            onClose={onClose}
          />
        </Modal>
      )}
    </>
  );
};

OtherDocuments.propTypes = {
  profile: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  littleDevice: PropTypes.bool,
};

OtherDocuments.defaultProps = {
  littleDevice: false,
};

export default OtherDocuments;
