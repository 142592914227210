import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components/macro';

import Box from '../common/Flex/Box';
import ButtonPrimary from '../common/Buttons/ButtonPrimary';
import ButtonSecondary from '../common/Buttons/ButtonSecondary';
import {
  StepTitle,
  CentralCard,
  CentralCardLeft,
  CentralRight,
  CentralCardLine,
  SlidesDots,
  SlidesDotsItem,
  Packs,
  PacksTab,
  PacksTabMiddle,
  PackContent,
  PackContentTitle,
  PackContentItem,
  PackPrice,
} from './Styles';
import HSpacer from '../layout/HSpacer';
import useListPublicEntity from '../../hooks/useListPublicEntity';
import InfoPopUp from '../common/InfoPopUp/InfoPopUp';
import { payment } from '../../utils';

const CardLeftContainer = styled(CentralCardLeft)`
  padding: 0 ${(props) => props.theme.paddings.l} 0 0;
  max-height: 480px;
  overflow: auto;
  display: block;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {   
   display:none;
  }
`;

const TotalVacants = styled.div`
  margin-bottom: 1rem;
  font-size: ${(props) => props.theme.fontSizes.titleXXLarge};
  line-height: ${(props) => props.theme.fontSizes.titleXXLarge};
  color: ${(props) => props.theme.colors.themeSecondary};
  text-align: center;
`;

const TotalVacantsText = styled.div`
  font-size: ${(props) => props.theme.fontSizes.medium};
  font-weight: 700;
  color: ${(props) => props.theme.colors.themeSecondary};
  text-align: center;
`;

const BlueLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.themeTertiary};
`;

const VacantItem = styled.div`
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes.large};
  padding: 0 ${(props) => props.theme.paddings.xxs};
  color: ${(props) => props.theme.colors.textPrimary};
`;

const RightContainer = styled(CentralRight)`
  padding: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    padding: 0rem 0rem 0rem 0rem;
  }
`;

const MiniBlueLine = styled.div`
  margin: 0 auto;
  width: 17px;
  height: 1px;
  background-color: ${(props) => props.theme.colors.themePrimary};
`;

const Step4 = ({
  onStepCompleted,
  onStepBack,
  onDotClick,
  formData,
  onFormDataChange,
  iva,
  vacancies,
}) => {
  const { data: prices } = useListPublicEntity('price');
  const insurance = prices.find((p) => p?.service?.key === 'Seguro');
  const [activeTab, setActiveTab] = useState(0);
  const services = useMemo(() => payment.getServices(prices, formData), [prices, formData]);

  useEffect(() => {
    const selectedService = services[activeTab];
    if (selectedService && (!formData.priceSelected || formData.priceSelected.id !== selectedService.id)) {
      const otherCurrencyPrices = getOtherCountryPrices(selectedService);
      onFormDataChange('priceSelected', { ...selectedService, otherCurrencyPrices });
    }
  }, [services, activeTab]);

  const exampleVacancies = useMemo(() => {
    const filteredAndShuffledVacancies = vacancies
      .filter((v) => v.active && (!formData.selectedDepartments || formData.selectedDepartments.includes(v.department)))
      .toSorted(() => Math.random() - 0.5);
    return filteredAndShuffledVacancies.slice(0, 10);
  }, [vacancies, formData.selectedDepartments]);

  const getOtherCountryPrices = (service) => {
    if (service.otherCurrencyPrices) {
      const matchingPrice = service.otherCurrencyPrices
        .find((price) => price.country === payment.currencyCountries[service.residence.code]);
      if (matchingPrice) return matchingPrice;
    }
    return null;
  };

  if (services.length === 0) {
    return (
      <CentralCard>
        <CardLeftContainer />
        <CentralCardLine />
        <RightContainer>
          <VacantItem>
            La opción combinada de &apos;Destino - Residencia - Pasaporte - Universidad&apos; escogida no está disponible, por favor,
            póngase en contacto con los administradores
          </VacantItem>
          <HSpacer />
          <Box container>
            <Box w={1 / 2} spacing={1}>
              <ButtonSecondary text="Atrás" onClick={onStepBack} fullWidth />
            </Box>
          </Box>
        </RightContainer>
      </CentralCard>
    );
  }

  const selectedService = services[activeTab];
  const otherCountryPrices = getOtherCountryPrices(selectedService);
  const displayedPrice = otherCountryPrices ? otherCountryPrices.price : selectedService.price;
  const displayedFirstPayment = otherCountryPrices ? otherCountryPrices.firstPayment : selectedService.firstPayment;
  const displayedSecondPayment = otherCountryPrices ? otherCountryPrices.secondPayment : selectedService.secondPayment;
  const totalPriceWithIva = payment.calculatePriceWithIva(displayedPrice, iva);
  const insuranceTax = selectedService?.residence 
  ? payment.getInsurancePrice(prices, selectedService.residence.code) 
  : 0;

  const serviceIncludes = selectedService ? selectedService.service.includes : [];
  const currencySymbol = otherCountryPrices ? payment.currencySymbols[otherCountryPrices.currency] : payment.currencySymbols.DEFAULT;

  return (
    <CentralCard padding="3rem 6rem 3rem 6rem">
      <CardLeftContainer>
        <BlueLine />
        <HSpacer step4 />
        <TotalVacants>Más de 500 vacantes esperan por ti</TotalVacants>
        <TotalVacantsText>
          Aquí te mostramos sólo un adelanto de las prácticas a las que podrás acceder
        </TotalVacantsText>
        <HSpacer step4 />
        <BlueLine />
        {exampleVacancies.map((vac) => (
          <React.Fragment key={vac.id}>
            <HSpacer step4 />
            <VacantItem>
              {vac.department}
              {' '}
              (
              {vac.company}
              )
            </VacantItem>
            <HSpacer step4 />
            <BlueLine />
          </React.Fragment>
        ))}
      </CardLeftContainer>
      <CentralCardLine />
      <RightContainer>
        <StepTitle>Selecciona un Pack</StepTitle>
        <HSpacer step4 />
        <Packs>
          <Box container>
            {services.length === 1
              
              ? (<PacksTab active={activeTab === 0} onClick={() => setActiveTab(0)} w={1}>{services[0].service.nameLang.es}</PacksTab>)
              : (
                <>
                  <PacksTab active={activeTab === 0} onClick={() => setActiveTab(0)} w={1 / 3}>{services[0].service.nameLang.es}</PacksTab>
                  {services[2] && <PacksTabMiddle active={activeTab === 2} onClick={() => setActiveTab(2)} w={1 / 3}>{services[2].service.nameLang.es}</PacksTabMiddle>}
                  <PacksTab active={activeTab === 1} onClick={() => setActiveTab(1)} w={1 / 3}>{services[1].service.nameLang.es}</PacksTab>
                </>
              )}
          </Box>
          <PackContent>
            <PackContentTitle>Contiene los servicios:</PackContentTitle>
            <HSpacer step4 />
            {serviceIncludes.map((item, i) => (
              <React.Fragment key={item.es}>
                <PackContentItem>{item.es}</PackContentItem>
                <HSpacer height="10px" />
                {i < serviceIncludes.length - 1 && (
                  <>
                    <MiniBlueLine />
                    <HSpacer step4 height="10px" />
                  </>
                )}
              </React.Fragment>
            ))}
          </PackContent>
          <PackPrice>
            <InfoPopUp
              step="4"
              firstPayment={displayedFirstPayment}
              secondPayment={displayedSecondPayment}
              insuranceTax={insuranceTax.price}
              iva={iva}
              totalPriceWithIva={totalPriceWithIva}
              price={displayedPrice}
              currencySymbol={currencySymbol}
            />
            <span>
              COSTE:
              {' '}
              {totalPriceWithIva}
              {currencySymbol}
            </span>
          </PackPrice>
        </Packs>
        <HSpacer />
        <Box container>
          <Box w={1 / 2} spacing={1}>
            <ButtonSecondary text="Atrás" onClick={onStepBack} fullWidth />
          </Box>
          <Box w={1 / 2} spacing={1}>
            <ButtonPrimary text="Empezar" onClick={onStepCompleted} fullWidth />
          </Box>
        </Box>
        <SlidesDots>
          <SlidesDotsItem onClick={() => onDotClick(0)} />
          <SlidesDotsItem onClick={() => onDotClick(1)} />
          <SlidesDotsItem onClick={() => onDotClick(2)} />
          <SlidesDotsItem onClick={() => onDotClick(3)} active />
        </SlidesDots>
      </RightContainer>
    </CentralCard>
  );
};

export default Step4;
