import styled from 'styled-components/macro';
import { gridAutoColumns } from 'styled-system';
import Box from '../common/Flex/Box';

export const CentralCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 6rem 6rem 7rem 6rem;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.shadows.header};
  transition: ${(props) => props.theme.transitions.property('height')};
  width: ${({ fit }) => (fit ? 'fit-content' : '100%')};
  padding: ${({ padding }) => (padding || '6rem 6rem 7rem 6rem')};
  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {   
   flex-direction:column;
   box-shadow: none;
   padding: ${({ mobilePadding }) => (mobilePadding || '0rem 2rem 0rem 2rem')};
   margin: ${({ mobileMargin }) => (mobileMargin)};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    position:relative;
  }
`;

export const CentralCardLeft = styled.div`
  position: relative;
  flex: 0 0 50%;
  padding: 2rem 3rem 2rem 0rem;
  min-height: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:column;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {   
   display:flex;
   justify-content:center;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    min-height: 13rem;
    padding:0;
  }
`;

export const OnBoardingIllustration = styled.img`
  position: absolute;
  width: calc(100% - 6rem);
  transition: ${(props) => props.theme.transitions.opacity};

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {   
   width:40rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
   width:12rem;
  }
`;

export const CentralRightWrapper = styled.div`

  @media (max-width: ${(props) => props.theme.breakpoints.lgMin}) {   
   width:38%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {   
   width:100%;
  }
`;

export const CentralRight = styled.div`
  position: relative;
  flex: 0 0 50%;
  padding: 2rem 2rem 2rem 2rem;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    padding: 0rem 0rem 2rem 0rem;
    margin-top: 4rem;
    margin-bottom: ${(props) => props.theme.sizes.headerHeight};
  }
`;

export const CentralCardLine = styled.div`
  flex: 0 0 2px;
  margin-right: 3rem;
  background-color: ${(props) => props.theme.colors.grey};

  @media (max-width: ${(props) => props.theme.breakpoints.lgMin}) {   
   margin-right:0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {   
   display:none;
  }
`;

export const SlidesDots = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  width: 100%;
`;

export const SlidesDotsItem = styled.div`
  width: ${(props) => (props.active ? '10px' : '6px')};
  height: ${(props) => (props.active ? '10px' : '6px')};
  border-radius: ${(props) => (props.active ? '5px' : '3px')};
  margin: 0 5px;
  background-color: ${(props) => (props.active ? props.theme.colors.themePrimary : props.theme.colors.grey)};
  cursor: pointer;
`;

export const StepTitle = styled.h2`
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.colors.greyDark};
  font-size: ${(props) => props.theme.fontSizes.titleLarge};
  font-weight: 700;

  @media (max-width: ${(props) => props.theme.breakpoints.lgMin}) {   
    font-size: ${(props) => props.theme.fontSizes.titleSmall};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    font-size: ${(props) => props.theme.fontSizes.titleSmall};
  }
`;

export const Packs = styled.div`
  border-radius: ${(props) => props.theme.borderRadius.tag};
  background-color: ${(props) => props.theme.colors.themeTertiary};
  overflow: hidden;
`;

export const PacksTab = styled(Box)`
  background-color: ${(props) => (props.active ? props.theme.colors.themePrimary : props.theme.colors.themeTertiary)};
  text-transform: uppercase;
  color: ${(props) => (props.active ? props.theme.colors.white : props.theme.colors.themePrimary)};
  font-weight: 700;
  font-size: ${(props) => props.theme.fontSizes.small};
  padding: ${(props) => props.theme.paddings.xs};
  text-align: center;
  cursor: pointer;
  white-space: nowrap;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    padding:0.75rem;
  }
`;

export const PacksTabMiddle = styled(PacksTab)`
  border-left: 1px solid ${(props) => props.theme.colors.white};  
  border-right: 1px solid ${(props) => props.theme.colors.white};  
`;

export const PackContent = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.themePrimary};  
  border-bottom: 1px solid ${(props) => props.theme.colors.themePrimary};  
  padding: ${(props) => props.theme.paddings.s};
  text-align: center;
  min-height: 260px;
`;

export const PackContentTitle = styled.div`
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes.large};
  color: ${(props) => props.theme.colors.textPrimary};
  font-weight: 700;
`;

export const PackContentItem = styled.div`
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes.medium};
  color: ${(props) => props.theme.colors.textPrimary};
`;

export const PackPrice = styled.div`
  padding: ${(props) => props.theme.paddings.xs};
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes.titleXSmall};
  color: ${(props) => props.theme.colors.textPrimary};
  text-transform: uppercase;
  font-weight: 700;
`;

export const BgContainer = styled.div`
  width: 100%;
  height: calc(100vh - ${(props) => props.theme.sizes.headerHeight});
  /* height: 120vh; */
  background-image: url("/images/on-boarding/onboarding-bg.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {   
    display: block;
    background-image: ${({ logIn }) => !logIn && 'none'};
    height: 100vh;
  }
`;

export const OnBoardingStep = styled.div`
  position: absolute;
  width: 80vw;
  max-width: 1073px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: ${(props) => props.theme.transitions.opacity};
  bottom: ${({ bottom }) => bottom};
  margin-top: ${({ marginTop }) => (marginTop || null)};

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {   
   width: 90vw;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {   
   width: 100%;
   padding: 0 1rem;
  }
`;

export const StepBox = styled(Box)`
  display: ${({ container }) => (container ? 'flex' : 'block')};
  width: ${({ width }) => width};
  
  @media (max-width: ${(props) => props.theme.breakpoints.lgMin}) {   
    display:flex;
    margin-bottom: 1rem;
  }

  @media(max-width: ${(props) => props.theme.breakpoints.md}) {
    display: flex;
    justify-content: center;
    margin-right: 1rem;
  }

  /* @media(max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: ${(props) => (props.container ? '100%' : '49%')};
  } */
`;

export const ModalWrapper = styled.div`
  height: calc(100vh - 60px);
  position: fixed;
  z-index: 1;
  width: 100%;
  top: -33px;
  left: 0px;
  background-color: white;
`;

export const Label = styled.p`
  width: 100%;
  color: ${(props) => props.theme.colors.greyDark};
  margin: 0 0 10px 10px;
`;

export const Container = styled.div`
  width: 40%;
  min-width: 20rem;
  margin: 0 auto 2rem auto;
  padding: 3rem;
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  border-radius: ${({ theme }) => theme.borderRadius.card};
  background: white;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 2rem 1rem;   
    width: auto;
    min-height: initial;
    margin:0 20px;
  }
`;
