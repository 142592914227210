import React from 'react';

const getOptionName = (op) => {
  if (!op) return '';
  if (op.nameLang) return op.nameLang.es;
  if (op.name) return op.name;
  return op;
};

const showOptions = (arr, initialValue, placeHolder) => {
  const filterOptions = (op) => getOptionName(op) !== initialValue;

  const options = arr?.filter(filterOptions) || [];
  const initialOption = <option key={initialValue} value={initialValue}>{initialValue !== '' ? initialValue : placeHolder}</option>;
  const everyOption = options.map((op) => (
    <option key={op.id || op} value={getOptionName(op.value || op)}>{getOptionName(op.label || op)}</option>
  ));
  const allOptions = (
    <>
      {initialOption}
      {everyOption}
    </>
  );

  return allOptions;
};

export default showOptions;

// export const SelectOptions = (props) => {
//   const { arr, initialValue, placeHolder } = props;
//   const filterOptions = (op) => getOptionName(op) !== initialValue;
//   const options = arr.filter(filterOptions);

//   return (
//     <>
//       <option
//         key={initialValue}
//         value={initialValue}
//       >
//         {initialValue !== '' ? initialValue : placeHolder}
//       </option>
//       {
//         options.map((op) => {
//           const key = op.id || op;
//           const name = getOptionName(op);
//           return (
//             <option
//               key={key}
//               defaultValue={name}
//             >
//               {name}
//             </option>
//           );
//         })
//       }
//     </>
//   );
// };
