import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import ButtonPrimary from '../components/common/Buttons/ButtonPrimary';
import InputText from '../components/common/formFields/Inputs/InputText';
import HSpacer from '../components/layout/HSpacer';
import Layout from '../components/layout/Layout';
import { BgContainer, Container, OnBoardingStep } from '../components/OnBoarding';
import { login } from '../services/api/auth';
import { studentActions } from '../redux/actions';
import Loader from '../components/common/Loader';
import { Box, ErrorMessage, Link } from '../components/common';

const Login = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const history = useHistory();

  const [credentials, setCredentials] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleLogin = async (values, event) => {
    event.preventDefault();
    setLoading(true);

    const { email, password } = values;
    const normalizedEmail = email.trim().toLowerCase();
    const { success } = await login(normalizedEmail, password);

    if (success) {
      dispatch(studentActions.refresh());
      history.push('/menu');
      return;
    }

    values.email = normalizedEmail;
    setCredentials(values);
  };

  const showError = async (email, password) => {
    const response = await login(email, password);
    const errorResponse = response.message;

    switch (errorResponse) {
      case 'UserNotFoundException':
        setError({ error, message: { en: 'User does not exist', es: 'El usuario no existe' } });
        break;
      case 'NotAuthorizedException':
        setError({ error, message: { en: 'Incorrect username or password', es: 'Nombre de usuario o contraseña incorrectos' } });
        break;
      default:
        if (email === '' || password === '') {
          setError({ error: 'EmptyField', message: { en: "User and password can't be empty", es: 'El usuario y la contraseña no pueden estar vacíos' } });
        } else {
          setError({ error: '', message: { en: 'An error ocurred', es: 'Ha ocurrido un error' } });
        }
    }
  };

  useEffect(() => {
    if (credentials) {
      setLoading(false);
      const { email, password } = credentials;

      showError(email, password);
    }
  }, [credentials]);

  return (
    <Layout isLogin noMenuLink>
      <BgContainer logIn>
        <OnBoardingStep>
          <Container>
            <form onSubmit={handleSubmit(handleLogin)}>
              <InputText
                inputRef={register}
                defaultValue=""
                name="email"
                label="Email"
                placeholder="Introduce tu email"
              />
              <HSpacer />
              <InputText
                name="password"
                defaultValue=""
                label="Contraseña"
                inputRef={register}
                placeholder="Introduce tu contraseña"
                type="password"
                controlled={false}
              />
              <HSpacer height="20px" />
              {error && <ErrorMessage>{error.message.es}</ErrorMessage>}
              <HSpacer height="20px" />
              <ButtonPrimary htmlType="submit" text={loading ? <Loader /> : 'Iniciar sesión'} fullWidth logIn />
              <HSpacer height="30px" />
              <Box container justify="center">
                <Link to="/password-recover">¿Olvidaste tu contraseña?</Link>
              </Box>
            </form>
          </Container>
        </OnBoardingStep>
      </BgContainer>
    </Layout>
  );
};

export default Login;
