import React from 'react';
import PropTypes from 'prop-types';

import {
  FullFlexCont,
  ModalMenuP,
} from '../../formStyledComponents';


const FieldsToEditList = ({ setSelectedCvEdit }) => {
  const fieldsToEdit = [
    'Última formación académica',
    'Estudios superiores',
    'Experiencia laboral',
    'Idiomas',
    'Competencias digitales',
    'Otros cursos',
    'Aptitudes y habilidades',
    // 'Otros documentos',
  ];

  const onSelectField = (field) => {
    setSelectedCvEdit(field);
  };

  return (
    <FullFlexCont padding="0" between alignEnd mobilePadding="0">
      {
        fieldsToEdit.map((field) => (
          <ModalMenuP key={field} onClick={() => onSelectField(field)}>{field}</ModalMenuP>
        ))
      }
    </FullFlexCont>
  );
};

FieldsToEditList.propTypes = {
  setSelectedCvEdit: PropTypes.func.isRequired,
};

export default FieldsToEditList;
