import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Icons from '../../Icons';
import ButtonPrimary from '../../Buttons/ButtonPrimary';
import TagDepartamento from '../../Tags/TagDepartamento';
import { text } from '../../../../utils';


const Container = styled.div`
  margin: 3rem 1.5rem;
  box-shadow: ${(props) => props.theme.shadows.shadow1};
  border-radius: ${(props) => props.theme.borderRadius.card};
  display:flex;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    margin: 3rem 0;
  }
`;

const Part = styled.div`
  display: flex;
`;

const PartIcons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 9rem;
  padding: 1.5rem;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {   
      width: 7rem;
      padding: 1.5rem;

      svg {
        width: 4rem;
      }
  }
`;

const PartFluid = styled.div`
  flex-grow: 1;
  padding: 5rem;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {   
    padding: 3rem 2.5rem;
  }
`;

const PartContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 2rem;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {   
    display: block;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    margin-top: 1rem;
  }
`;

const PartBlock = styled.div`
display: block;
`;

const InfoContainer = styled.div`
  width: ${({ width }) => width};

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {   
      width: 100%;
  }
`;

const Line = styled.div`
  display: flex;
  align-items: center;
`;

const LineBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LineCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  button {
    min-width: 14rem;
    margin-bottom: 1rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    flex-direction: column;
  }
`;

const VerticalSeparator = styled.div`
  width: 2px;
  background-color: ${(props) => props.theme.colors.themeTertiary};
`;

const HorizontalSeparator = styled.div`
  height: 2px;
  background-color: ${(props) => props.theme.colors.themeTertiary};
  margin: 2rem 0;
`;

const Title = styled.p`
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: ${(props) => props.theme.fontSizes.medium};
  line-height: 1.3;
  font-weight: 700;
`;

const Text = styled.p`
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: ${(props) => props.theme.fontSizes.medium};
  line-height: 1.3;
`;

const TextUbication = styled.p`
  color: ${(props) => props.theme.colors.themePrimary};
  font-size: ${(props) => props.theme.fontSizes.medium};
  line-height: 1.3;
  margin-right: 0.5rem;
`;

const Separator = styled.div`
  height: 0rem;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {   
      height: 1rem;
  }
`;

const SeparatorFixed = styled.div`
  height: 1rem;
  width: 1.5rem;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {   
    height: 0rem;
  }
`;

const IconChevronCover = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  height: 4rem;
  width: 4rem;
  background-color: ${(props) => props.theme.colors.themeTertiary};
  border-radius: 100%;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;

  &:hover {
    background-color: ${(props) => props.theme.colors.themeSecondary};
    color: ${(props) => props.theme.colors.textInverse};

    svg {
      fill: ${(props) => props.theme.colors.textInverse};
    }
  }
`;


const CardRecomendado = (props) => {
  const {
    department, company, onClick,
    datesOpen, city, onAccept, onReject,
    recommendedStatus,
  } = props;
  const benefitEconomy = text.getTrullyText(props.benefitEconomy);

  const showButtons = recommendedStatus !== 'rejected' && recommendedStatus !== 'accepted';
  let message = '';
  if (recommendedStatus === 'rejected') message = 'Recomendación rechazada';
  if (recommendedStatus === 'accepted') message = 'Práctica aceptada';

  return (
    <Container>
      <PartIcons>
        <Icons icon="alert" iconColor="themeSecondary" width={5.5} />
      </PartIcons>

      <Part><VerticalSeparator /></Part>

      <PartFluid>
        <TagDepartamento full title={department} bgColor="themePrimary" />
        <SeparatorFixed />
        <PartContent>
          <InfoContainer width="39%">
            <Title>{company}</Title>
            <SeparatorFixed />
            <Line>
              <TextUbication>{city}</TextUbication>
              <Icons icon="location" iconColor="alertError" width={1.8} />
            </Line>
            <Separator />
          </InfoContainer>
          <InfoContainer width="20%">
            <Title>Beneficios:</Title>
            <Text>{benefitEconomy}</Text>
            <Separator />
          </InfoContainer>
          <InfoContainer width="39%">
            <LineBetween>
              <PartBlock>
                <Title>Fechas de cierre:</Title>
                <Text>{datesOpen}</Text>
              </PartBlock>
              <IconChevronCover onClick={onClick}>
                <Icons icon="chevronRight" iconColor="themeSecondary" width={2.6} />
              </IconChevronCover>
            </LineBetween>
          </InfoContainer>
        </PartContent>

        <HorizontalSeparator />

        <LineCenter>
          {
            showButtons ? (
              <>
                <ButtonPrimary text="Rechazar" type={2} onClick={onReject} fontSize="medium" />
                <SeparatorFixed />
                <ButtonPrimary text="Aceptar" onClick={onAccept} fontSize="medium" />
              </>
            ) : message
          }
        </LineCenter>
      </PartFluid>
    </Container>
  );
};

CardRecomendado.propTypes = {
  department: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  benefitEconomy: PropTypes.string.isRequired,
  datesOpen: PropTypes.node.isRequired,
  city: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  recommendedStatus: PropTypes.string,
};

CardRecomendado.defaultProps = {
  recommendedStatus: 'pending',
};

export default CardRecomendado;
