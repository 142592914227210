import { UPDATE_WINDOW_RESIZE } from '../actions/windowResizeActions';


const initialState = { screen: 'phone' };

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_WINDOW_RESIZE: {
      return { ...action.payload };
    }

    default: return state;
  }
};
