import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Icons from '../../Icons';
import TagDepartamento from '../../Tags/TagDepartamento';
import { text } from '../../../../utils';


const CardContainer = styled.div`
  position: relative;
`;

const DisabledDiv = styled.div`
  display: ${({ isLocked }) => (isLocked ? 'block' : 'none')};
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 1;
`;

const Container = styled.div`
  margin: 3rem 1.5rem;
  box-shadow: ${(props) => props.theme.shadows.shadow1};
  border-radius: ${(props) => props.theme.borderRadius.card};
  display:flex;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    margin: 3rem 0;
  }
`;

const Part = styled.div`
  display: flex;
`;

const PartIcons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 9rem;
  padding: 1.5rem;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {   
    width: 7rem;
    padding: 1.5rem;
  }
`;

const PartFluid = styled.div`
  flex-grow: 1;
  padding: 5rem;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {   
    padding: 3rem 2.5rem;
  }
`;

const PartContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 2rem;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {   
    display: block;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    margin-top: 1rem;
  }
`;

const PartBlock = styled.div`
  display: block;
`;

const InfoContainer = styled.div`
  width: ${({ width }) => width};
  min-width: 6rem;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {   
      width: 100%;
  }
`;

const Line = styled.div`
  display: flex;
  align-items: center;
`;

const LineBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const VerticalSeparator = styled.div`
  width: 2px;
  background-color: ${(props) => props.theme.colors.themeTertiary};
`;

const Title = styled.p`
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: ${(props) => props.theme.fontSizes.medium};
  line-height: 1.3;
  font-weight: 700;
`;

const Text = styled.p`
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: ${(props) => props.theme.fontSizes.medium};
  line-height: 1.3;
`;

const TextUbication = styled.p`
  color: ${(props) => props.theme.colors.themePrimary};
  font-size: ${(props) => props.theme.fontSizes.medium};
  line-height: 1.3;
  margin-right: 0.5rem;
`;

const Separator = styled.div`
  height: 0rem;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {   
    height: 1rem;
  }
`;

const SeparatorFixed = styled.div`
  height: 1rem;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {   
    height: 0rem;
  }
`;

const IconChevronCover = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  height: 4rem;
  width: 4rem;  
  background-color: ${(props) => props.theme.colors.themeTertiary};
  border-radius: 100%;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;

  &:hover {
    background-color: ${(props) => props.theme.colors.themeSecondary};
    color: ${(props) => props.theme.colors.textInverse};

    svg {
      fill: ${(props) => props.theme.colors.textInverse};
    }
  }
`;

const PositionNumber = styled.p`
  color: ${(props) => props.theme.colors.themePrimary};
  font-size: ${(props) => props.theme.fontSizes.giant1};
  font-weight: 300;
  margin: 0.25rem 0;
  text-align: center;
`;

const PositionArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const PositionArrowCover = styled.div`
  height: 3rem;
  width: 3rem;
`;

const PositionArrowText = styled.p`
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: ${(props) => props.theme.fontSizes.xsmall};
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
      font-size: 1rem;
  }
`;

const RemoveIconContainer = styled.div`
  position: absolute;
  right: 3rem;
  top: 1rem;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    right: 0.5rem;
    top: 0.5rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {   
    right: 2rem;
  }
`;


const CardPrioridad = (props) => {
  const {
    position, department, company,
    datesOpen, city, first, last,
    onClick, onUp, onDown, isLocked,
    onRemove, benefitEconomy,
  } = props;
  const benefitEconomyText = text.getTrullyText(benefitEconomy);

  return (
    <CardContainer>
      <DisabledDiv isLocked={isLocked} />
      <Container>
        <PartIcons>
          <PositionArrowContainer onClick={!first ? () => onUp(position) : () => {}}>
            {
              !first && (
                <>
                  <PositionArrowCover>
                    <Icons icon="chevronUp" iconColor="textPrimary" width={3} />
                  </PositionArrowCover>
                  <PositionArrowText>Subir<br />posición</PositionArrowText>
                </>
              )
            }
          </PositionArrowContainer>

          <PositionNumber>{position}</PositionNumber>

          <PositionArrowContainer onClick={!last ? () => onDown(position) : () => { }}>
            {
              !last && (
                <>
                  <PositionArrowText>Bajar<br /> posición</PositionArrowText>
                  <PositionArrowCover>
                    <Icons icon="chevronDown" iconColor="textPrimary" width={3} />
                  </PositionArrowCover>
                </>
              )
            }

          </PositionArrowContainer>
        </PartIcons>

        <Part><VerticalSeparator /></Part>

        <PartFluid>
          <RemoveIconContainer onClick={onRemove}>
            <Icons icon="trash" iconColor="alertError" width={2} />
          </RemoveIconContainer>
          <TagDepartamento full title={department} bgColor="themePrimary" />
          <SeparatorFixed />
          <PartContent>
            <InfoContainer width="39%">
              <Title>{company}</Title>
              <SeparatorFixed />
              <Line>
                <TextUbication>{city}</TextUbication>
                <Icons icon="location" iconColor="alertError" width={1.8} />
              </Line>
              <Separator />
            </InfoContainer>
            <InfoContainer width="20%">
              <Title>Beneficios:</Title>
              <Text>{benefitEconomyText}</Text>
              <Separator />
            </InfoContainer>
            <InfoContainer width="39%">
              <LineBetween>
                <PartBlock>
                  <Title>Fechas de apertura:</Title>
                  <Text>{datesOpen}</Text>
                </PartBlock>
                <IconChevronCover onClick={onClick}>
                  <Icons icon="chevronRight" iconColor="themeSecondary" width={2.6} />
                </IconChevronCover>
              </LineBetween>
            </InfoContainer>
          </PartContent>
        </PartFluid>
      </Container>
    </CardContainer>
  );
};

CardPrioridad.propTypes = {
  benefitEconomy: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  datesOpen: PropTypes.node.isRequired,
  department: PropTypes.string.isRequired,
  first: PropTypes.bool,
  isLocked: PropTypes.bool,
  last: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onDown: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onUp: PropTypes.func.isRequired,
  position: PropTypes.number.isRequired,
};

CardPrioridad.defaultProps = {
  first: false,
  last: false,
  isLocked: false,
};

export default CardPrioridad;
