import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import FormTitle from '../FormTitle';
import ButtonPrimary from '../../common/Buttons/ButtonPrimary';
import ButtonSecondary from '../../common/Buttons/ButtonSecondary';
import { studentActions } from '../../../redux/actions';

import {
  FullFlexCont,
  WhiteSpace,
  ContactForm,
  TextArea,
  NoIconTitle,
  Column,
} from '../formStyledComponents';

const Skills = ({
  currentSkills,
  isModal,
  setSelectedCvEdit,
}) => {
  const { register, handleSubmit } = useForm();

  const dispatch = useDispatch();

  /* set the form to active/inactive to be edited */
  const initialFormState = currentSkills !== '' || isModal;

  const [skillsFormActive, setCvSkillsActive] = useState(initialFormState);

  const edit = () => {
    setCvSkillsActive(!skillsFormActive);
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    setCvSkillsActive(!skillsFormActive);
    dispatch(studentActions.save({ skills: data.skills }));
    isModal && setSelectedCvEdit(null);
  };

  return (
    <>
      {skillsFormActive && (
        <FullFlexCont mobileMargin="0" center={!!isModal}>
          <NoIconTitle margin={isModal ? '0' : '0 0 0 2rem'}>APTITUDES Y HABILIDADES*:</NoIconTitle>
        </FullFlexCont>
      )}

      {!isModal && !skillsFormActive && <FormTitle title="APTITUDES Y HABILIDADES" onClick={edit} />}

      <ContactForm key={currentSkills} onSubmit={handleSubmit(onSubmit)}>

        <WhiteSpace height="1rem" />

        <FullFlexCont padding="0 2.7rem" mobilePadding="0">
          <TextArea
            disabled={!skillsFormActive}
            name="skills"
            placeholder="indica algunas de tus habilidades y aptitudes"
            ref={register({ required: false })}
            defaultValue={currentSkills}
            rows="8"
            maxLength="710"
            mBottom="0"
          />

          {!isModal && skillsFormActive && <ButtonPrimary type={2} text="Guardar" htmlType="submit" margin="15px 0 0 auto" />}

        </FullFlexCont>

        {isModal && (
          <FullFlexCont justify="space-between">

            <WhiteSpace height="1rem" />

            <Column width="46" padding="0">
              <ButtonSecondary
                text="Atrás"
                onClick={() => setSelectedCvEdit(null)}
                margin="auto"
                fullWidth
              />
            </Column>

            <Column width="46" padding="0">
              <ButtonPrimary
                type={2}
                text="Guardar"
                htmlType="submit"
                margin="auto"
                fullWidth
              />
            </Column>

          </FullFlexCont>
        )}
      </ContactForm>
    </>
  );
};

export default Skills;
