/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import _set from 'lodash/set';

import { useSelector, useDispatch } from 'react-redux';

import { Interviews, Payments, Priorities } from '../components/processes';
import {
  BackArrowTitle,
  SingleRow,
  PageSubMenuPill,
  PageContainer,
} from '../components/common';
import { Vacancy } from '../components/vacancies';
import LoggedLayout from '../components/layout/LoggedLayout';

import { studentActions } from '../redux/actions';


const Message = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.small};
  text-align: center;
`;

const messageOne = (
  <Message>
    <strong>* TIENES 15 DÍAS PARA BLOQUEAR TUS PRIORIDADES: </strong>
    Al bloquear tus prioridades podrás iniciar el proceso de acceso a Entrevistas y Pagos.
  </Message>
);

const messageTwo = (
  <Message />
  // <Message>
  //   * Lo sentimos tus solucitudes priorizadas no están disponibles en las fechas solicitadas.
  //   <strong> NUESTRO EQUIPO HA ENCONTRADO VACANTES RECOMENDADAS PARA TU PERFIL.</strong>
  // </Message>
);

const Processes = () => {
  const initialPage = window.sessionStorage.getItem('activePageProcesses') || 'Prioridades';
  const [active, setActive] = useState(initialPage);
  const [message, setMessage] = useState('');
  const [vacancyId, setVacancyId] = useState('');
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  const student = useSelector((state) => state.student);
  const isLocked = (student && student.vacancyPrioritiesLocked) || false;

  useEffect(() => {
    window.sessionStorage.setItem('activePageProcesses', active);
  }, [active]);

  useEffect(() => {
    if (!student) return;
    const { statusHistory } = student;
    const statusList = statusHistory.map((item) => item.status);
    const hasAccess = statusList.includes('profileCompleted');
    if (!hasAccess) history.push('/menu');
    setLoading(false);
  }, [student, history]);

  useEffect(() => {
    const newMessage = isLocked ? messageTwo : messageOne;
    setMessage(newMessage);
  }, [isLocked]);

  const onChange = (path, value, multiple) => {
    let newUser = {};
    if (multiple) newUser = value;
    else _set(newUser, path, value);
    dispatch(studentActions.save(newUser));
  };

  const renderContent = () => {
    if (active === 'Prioridades') {
      return (
        <Priorities
          user={student}
          onChange={onChange}
          message={message}
          isLocked={isLocked}
          setVacancyId={setVacancyId}
        />
      );
    }
    if (active === 'Entrevistas') return <Interviews />;
    if (active === 'Pagos') return <Payments />;
    return '';
  };

  if (loading) return <LoggedLayout><PageContainer>Loading...</PageContainer></LoggedLayout>;

  if (vacancyId) {
    let item = {};
    if (vacancyId === student?.recommended?.id) item = student.recommended;
    else item = student.priorities.filter((elem) => elem.vacancy.id === vacancyId)[0].vacancy || {};

    return (
      <Vacancy
        key={vacancyId}
        identifier={vacancyId}
        item={item}
        setVacancyId={setVacancyId}
      />
    );
  }

  return (
    <LoggedLayout>
      <PageContainer processes>

        <BackArrowTitle title="Mis procesos" linkto="/menu" />

        <SingleRow />

        <PageSubMenuPill
          title1="Prioridades"
          title2="Entrevistas"
          title3="Pagos"
          active={active}
          setActive={setActive}
          isLocked={isLocked}
        />
        {renderContent()}
      </PageContainer>
    </LoggedLayout>
  );
};

export default Processes;

