import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { askResetPassword } from '../services/api/auth';
import { getErrorMessage } from '../services/api/errors';
import ButtonPrimary from '../components/common/Buttons/ButtonPrimary';
import InputText from '../components/common/formFields/Inputs/InputText';
import HSpacer from '../components/layout/HSpacer';
import Layout from '../components/layout/Layout';
import { BgContainer, Container, OnBoardingStep } from '../components/OnBoarding';
import { Box, ErrorMessage, Link, Loader } from '../components/common';

const PasswordRecover = () => {
  const { register, handleSubmit, errors } = useForm();
  const [sendEmailSuccess, setEmailSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handlePasswordRecovery = async (data, event) => {
    event.preventDefault();
    setLoading(true);

    const { success, message } = await askResetPassword({ ...data });

    setEmailSuccess(success);
    if (!success) setError(getErrorMessage(message));
    setLoading(false);
  };

  return (
    <Layout isLogin noMenuLink>
      <BgContainer logIn>
        <OnBoardingStep>
          <Container>
            <form onSubmit={handleSubmit(handlePasswordRecovery)}>
              <InputText
                inputRef={register({ required: true })}
                defaultValue=""
                name="email"
                label="Email"
                placeholder="Introduce tu email"
              />
              <HSpacer height="20px" />
              {errors.email && <ErrorMessage>Por favor, introduce tu email</ErrorMessage>}
              {sendEmailSuccess && (
                <Box container justify="center">
                  Se ha enviado un correo para recuperar la contraseña
                </Box>
              )}
              {error && <ErrorMessage>{error}</ErrorMessage>}
              <HSpacer height="20px" />
              <ButtonPrimary htmlType="submit" text={loading ? <Loader /> : 'Recuperar contraseña'} fullWidth logIn />
            </form>
            <HSpacer height="30px" />
            <Link to="login">Volver</Link>
          </Container>
        </OnBoardingStep>
      </BgContainer>
    </Layout>
  );
};

export default PasswordRecover;
