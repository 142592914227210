import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import _set from 'lodash/set';
import _get from 'lodash/get';
import { useSelector } from 'react-redux';

import {
  Box, ButtonPrimary, FilterTag, Select,
  DatePicker, Icons,
} from '../common';
import HSpacer from '../layout/HSpacer';
import FadeIn from '../../hooks/fadeIn';
import { GEOGRAPHICZONE } from '../../libs/data/geographicZone';
import useListPublicEntity from '../../hooks/useListPublicEntity';


const FiltersContainer = styled.div`
  max-width: 55rem;
  margin: 0 auto 10rem;
  padding: 8rem 0;
  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
   text-align: center;
   padding-left: 2rem;
   padding-right: 2rem;
   // width: initial;
  }
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: ${({ theme }) => theme.fontSizes.titleSmall};
  text-align: center;
`;

const BlueDivider = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.themeTertiary};
  margin: 2rem 0;
`;

const ButtonContainer = styled.div`
  width: 30rem;
  margin: 2rem auto;

  button {
    @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
      // font-size: ${({ theme }) => theme.fontSizes.titleSmall};
    }
  }
`;

const Text = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin-bottom: 1rem;
  
  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    display: block;
  }
`;

const TagsSeparator = styled.div`
  width: 100%;
`;

const CloseCross = styled.div`
  position: absolute;
  right: -18rem;
  top: -4rem;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    right: -6rem;
    top: -4rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    right: 0rem;
    top: -6rem;
  }
`;

const Error = styled.p`
  color: red;
  display: flex;
  padding: 0.5rem;
  justify-content: center;
  margin-top: 1rem;
`;


const Filters = (props) => {
  const { data: vacancies } = useListPublicEntity('vacancy');
  const screen = useSelector((state) => state.windowResize.screen);
  const isPhone = screen === 'phone';
  const boxFlex = !isPhone;

  const {
    setFilter, filters, onFilter, setFilters,
  } = props;

  const [newFilters, setNewFilters] = useState(filters);
  const [isVisible, setIsVisible] = useState(false);
  const [dateError, setDateError] = useState(false);

  const departments = useMemo(
    () => vacancies
      .map(({ department }) => department)
      .filter(
        (department, index, allDepartments) => department && allDepartments.indexOf(department) === index,
      )
      .sort(),
    [vacancies],
  );


  useEffect(() => {
    setIsVisible(true);
  }, []);

  const onSelect = (name, value) => {
    const changedFilters = { ...newFilters };
    _set(changedFilters, name, value);
    if (changedFilters.datesOpen.dateIni < changedFilters.datesOpen.dateEnd) {
      setNewFilters(changedFilters);
      setDateError(false);
    } else {
      setDateError(true);
    }
  };

  const onAdd = (value) => {
    const departments = [...newFilters.departments, value];
    setNewFilters({ ...newFilters, departments });
  };

  const onRemove = (value) => {
    const { departments } = newFilters;
    const index = departments.indexOf(value);
    departments.splice(index, 1);
    setNewFilters({ ...newFilters, departments });
  };

  const onSubmit = () => {
    setFilter(false);
    setFilters(newFilters);
  };

  const addZone = (value) => {
    const zones = newFilters.zones ? [...newFilters.zones, value] : [value];
    setNewFilters({ ...newFilters, zones });
  };

  const removeZone = (value) => {
    const { zones } = newFilters;
    const index = zones.indexOf(value);
    zones.splice(index, 1);
    setNewFilters({ ...newFilters, zones });
  };

  return (
    <FiltersContainer>
      <FadeIn translate>

        <CloseCross onClick={() => setFilter(false)} role="button" tabIndex={0} onKeyPress={() => setFilter(false)}>
          <Icons icon="close" iconColor="textPrimary" width={3} />
        </CloseCross>

        <Title>Filtrar por:</Title>

        <BlueDivider />

        <Text>Departamento:</Text>

        <HSpacer height="0.5rem" />

        <Box
          container
          wrap="wrap"
          justify="center"
        >
          {
            departments.map((item) => {
              const active = newFilters.departments.indexOf(item) !== -1;

              if (active) {
                return (
                  <FilterTag
                    key={item}
                    title={item}
                    active={active}
                    onAdd={onAdd}
                    onRemove={onRemove}
                  />
                );
              }
              return null;
            })
          }
          <TagsSeparator />
          {
            departments.map((item) => {
              const active = newFilters.departments.indexOf(item) !== -1;

              if (!active) {
                return (
                  <FilterTag
                    key={item}
                    title={item}
                    active={active}
                    onAdd={onAdd}
                    onRemove={onRemove}
                  />
                );
              }
              return null;
            })
          }
        </Box>

        <BlueDivider />

        <Box container={boxFlex}>
          <Box w={1 / 3} spacing="5">
            <Text>Ubicación:</Text>
          </Box>
          {/* <Box w={2 / 3} spacing="5">
            <Select
              placeholder="Selecciona un destino"
            />
          </Box> */}
          <Box
            container
            wrap="wrap"
            justify="center"
          >
            {
              GEOGRAPHICZONE.map((item) => {
                const active = newFilters.zones ? newFilters.zones.indexOf(item) !== -1 : null;

                if (active) {
                  return (
                    <FilterTag
                      key={item}
                      title={item}
                      active={active}
                      onAdd={addZone}
                      onRemove={removeZone}
                    />
                  );
                }
                return null;
              })
            }
            <TagsSeparator />
            {
              GEOGRAPHICZONE.map((item) => {
                const active = newFilters.zones ? newFilters.zones.indexOf(item) !== -1 : null;

                if (!active) {
                  return (
                    <FilterTag
                      key={item}
                      title={item}
                      active={active}
                      onAdd={addZone}
                      onRemove={removeZone}
                    />
                  );
                }
                return null;
              })
            }
          </Box>

        </Box>

        <Box container={boxFlex} style={{display:'none'}}> 
          <Box w={1 / 3} spacing="5">
            <Text>Fechas:</Text>
          </Box>

          <Box w={2 / 3} spacing="5">
            <Box container justify="space-between">
              <DatePicker
                value={_get(filters, 'datesOpen.dateIni', '')}
                onValueChange={(value) => onSelect('datesOpen.dateIni', value)}
                placeholder="Fecha de inicio"
              />
              <DatePicker
                value={_get(filters, 'datesOpen.dateEnd', '')}
                onValueChange={(value) => onSelect('datesOpen.dateEnd', value)}
                placeholder="Fecha de inicio"
              />
            </Box>
          </Box>
        </Box>

        {dateError && <Error>La fecha de inicio debe ser menor a la fecha de finalización.</Error>}

        <BlueDivider />
        <HSpacer height="1.5rem" />

        <ButtonContainer>
          <ButtonPrimary
            text="Aplicar filtros"
            onClick={onSubmit}
            type={2}
            fullWidth
          />
        </ButtonContainer>
      </FadeIn>
    </FiltersContainer>
  );
};

Filters.propTypes = {
  setFilter: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    departments: PropTypes.arrayOf(PropTypes.string),
    city: PropTypes.string,
    datesOpen: PropTypes.shape({
      dateIni: PropTypes.string,
      dateEnd: PropTypes.string,
    }),
  }).isRequired,
  onFilter: PropTypes.func.isRequired,
};

export default Filters;
