import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import SingleRow from '../../../common/SingleRow';
import ToAddList from './ToAddList';
import NewStudy from '../prevStudies/NewStudy';
import NewExperience from '../workExperience/NewExperience';
import NewLang from '../languages/NewLang';
import NewDigital from '../digitalExperience/NewDigital';
import NewCourse from '../otherCourses/NewCourse';
import NewDocument from '../otherDocuments/NewDocument';
import { studentActions } from '../../../../redux/actions';
import { getId, levels, digitalLevels } from '../../helpers';
import useListPublicEntity from '../../../../hooks/useListPublicEntity';

import {
  CardContentWrapper,
  WhiteSpace,
  ModalsLogo,
  NoIconTitle,
} from '../../formStyledComponents';


const AddCvModal = ({
  littleDevice,
  profile,
  onClose,
}) => {
  const dispatch = useDispatch();

  const { data: residences } = useListPublicEntity('residence');

  const [selectedCvAdd, setSelectedCvAdd] = useState(null);

  /* Select options */
  const finishSelect = ['Sí', 'No'];

  /* add-prev-studies button */
  const addPrevStudy = (studyData) => {
    const studies = [];
    const prevStudies = _get(profile, 'prevStudies', []);

    prevStudies.forEach((study) => {
      studies.push({
        ended: study.ended,
        gradDate: study.gradDate,
        iniDate: study.iniDate,
        institutionName: study.institutionName,
        name: study.name,
      });
    });
    studies.push(studyData);
    dispatch(studentActions.save({ prevStudies: studies }));
    setSelectedCvAdd(null);
  };

  /* add-experience button */
  const addExperience = (newExperience) => {
    const experiences = [];
    const workExperiences = _get(profile, 'workExperience', []);

    workExperiences.forEach((exp) => {
      experiences.push({
        jobName: exp.jobName,
        iniDate: exp.iniDate,
        endDate: exp.endDate,
        workExperienceCountryId: getId(residences, exp.country.nameLang.es),
        city: exp.city,
        desc: exp.desc,
        company: exp.company,
      });
    });

    experiences.push({
      jobName: newExperience.jobName,
      iniDate: newExperience.iniDate,
      endDate: newExperience.endDate,
      workExperienceCountryId: getId(residences, newExperience.workExperienceCountryId),
      city: newExperience.city,
      desc: newExperience.desc,
      company: newExperience.company,
    });

    dispatch(studentActions.save({ workExperience: experiences }));
    setSelectedCvAdd(null);
  };

  /* add-language button */
  const addLang = (newLang) => {
    const languages = [];
    const languageExperience = _get(profile, 'languageExperience', []);
    languageExperience.forEach((lang) => languages.push(lang));
    languages.push(newLang);
    dispatch(studentActions.save({ languageExperience: languages }));
    setSelectedCvAdd(null);
  };

  /* add-digital-experience button */
  const addDigital = (newDigital) => {
    const digitals = [];
    const digitalExperience = _get(profile, 'digitalExperience', []);
    digitalExperience.forEach((digit) => digitals.push(digit));
    digitals.push(newDigital);
    dispatch(studentActions.save({ digitalExperience: digitals }));
    setSelectedCvAdd(null);
  };

  /* add-course button */
  const addCourse = (newCourse) => {
    const courses = [];
    const otherExperiences = _get(profile, 'otherExperiences', []);
    otherExperiences.forEach((course) => courses.push(course));
    courses.push(newCourse.name);
    dispatch(studentActions.save({ otherExperience: courses }));
    setSelectedCvAdd(null);
  };

  const addDocument = (newDocument) => {
    if (!newDocument) return;
    const otherDocuments = _get(profile, 'otherDocuments', []);
    const documents = [...otherDocuments, newDocument];
    dispatch(studentActions.save({ otherDocuments: documents }));
    setSelectedCvAdd(null);
    onClose();
  };


  return (
    <CardContentWrapper mobileCenter>
      <ModalsLogo src="/images/logo.jpg" alt="Nexus logo" />

      {!selectedCvAdd && (
        <>
          <NoIconTitle margin="0 0 2rem 0">¿QUÉ QUIERES AÑADIR A TU C.V.?</NoIconTitle>

          <WhiteSpace mobileHeight="5rem" />

          <ToAddList setSelectedCvAdd={setSelectedCvAdd} />

          <SingleRow mobileMargin="0" margin="0" />
        </>
      )}

      {selectedCvAdd === 'Estudios superiores' && (
        <>
          <NoIconTitle width="100%" margin="2rem 0" center>AÑADIR ESTUDIOS PREVIOS</NoIconTitle>
          <WhiteSpace mobileHeight="5rem" />
          <NewStudy
            addPrevStudy={addPrevStudy}
            finishSelect={finishSelect}
            profile={profile}
            littleDevice={littleDevice}
            isAddModal
            setSelectedCvAdd={setSelectedCvAdd}
          />
        </>
      )}

      {selectedCvAdd === 'Experiencia laboral' && (
        <>
          <NoIconTitle width="100%" margin="2rem 0" center>AÑADIR EXPERIENCIA LABORAL</NoIconTitle>
          <WhiteSpace mobileHeight="5rem" />
          <NewExperience
            addExperience={addExperience}
            profile={profile}
            littleDevice={littleDevice}
            isAddModal
            setSelectedCvAdd={setSelectedCvAdd}
          />
        </>
      )}

      {selectedCvAdd === 'Idiomas' && (
        <>
          <NoIconTitle width="100%" margin="2rem 0" center>AÑADIR IDIOMA</NoIconTitle>
          <WhiteSpace mobileHeight="5rem" />
          <NewLang
            levels={levels}
            addLang={addLang}
            profile={profile}
            littleDevice={littleDevice}
            isAddModal
            setSelectedCvAdd={setSelectedCvAdd}
            langExperience={_get(profile, 'languageExperience', [])}
          />
        </>
      )}

      {selectedCvAdd === 'Competencias digitales' && (
        <>
          <NoIconTitle width="100%" margin="2rem 0" center>AÑADIR COMPETENCIA DIGITAL</NoIconTitle>
          <WhiteSpace mobileHeight="5rem" />
          <NewDigital
            levels={digitalLevels}
            addDigital={addDigital}
            profile={profile}
            littleDevice={littleDevice}
            isAddModal
            setSelectedCvAdd={setSelectedCvAdd}
          />
        </>
      )}

      {selectedCvAdd === 'Otros cursos' && (
        <>
          <NoIconTitle width="100%" margin="2rem 0" center>AÑADIR CURSO</NoIconTitle>
          <WhiteSpace mobileHeight="5rem" />
          <NewCourse
            addCourse={addCourse}
            profile={profile}
            littleDevice={littleDevice}
            isAddModal
            setSelectedCvAdd={setSelectedCvAdd}
          />
        </>
      )}

      {selectedCvAdd === 'Otros documentos' && (
        <>
          <NoIconTitle width="100%" margin="2rem 0" center>AÑADIR DOCUMENTO</NoIconTitle>
          <WhiteSpace mobileHeight="5rem" />
          <NewDocument
            profile={profile}
            addDocument={addDocument}
            littleDevice={littleDevice}
            onClose={onClose}
            isAddModal
          />
        </>
      )}

    </CardContentWrapper>
  );
};

AddCvModal.propTypes = {
  profile: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
  littleDevice: PropTypes.bool,
};

AddCvModal.defaultProps = {
  littleDevice: false,
};

export default AddCvModal;
