import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import EditDeleteIcons from '../../EditDeleteIcons';
import { SingleRow } from '../../../common';
import { studentActions } from '../../../../redux/actions';

import {
  InputColumn,
  FullFlexCont,
  ContactForm,
  BlueInput,
  EditDeleteIconsCont,
} from '../../formStyledComponents';

const Course = ({
  name,
  deleteCourse,
  i,
  otherExperiences,
}) => {
  const { register, handleSubmit } = useForm();

  const dispatch = useDispatch();

  /* set the form to active/inactive to be edited */
  const initialFormState = otherExperiences !== [] && false;
  const [coursesFormActive, setCoursesFormActive] = useState(initialFormState);

  const edit = () => {
    setCoursesFormActive(!coursesFormActive);
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    const courses = otherExperiences;
    const index = i;
    courses.splice(index, 1, data.name);
    dispatch(studentActions.save({ otherExperience: courses }));
    setCoursesFormActive(!coursesFormActive);
  };


  return (
    <>
      <ContactForm key={name} onSubmit={handleSubmit(onSubmit)}>

        <FullFlexCont justify="flex-end" padding="0" alignCenter >

          <InputColumn width="50%" margin="0 auto 0 2rem" mobileWidth="40%">
            <BlueInput
              bold
              width="100%"
              mBottom="0"
              disabled={!coursesFormActive}
              name="name"
              placeholder="título del curso"
              ref={register({ required: true })}
              defaultValue={name}
            />
          </InputColumn>

          <InputColumn width="20%" mobileWidth="40%">
            <EditDeleteIconsCont>
              <EditDeleteIcons
                edit={edit}
                deleteField={deleteCourse}
                isActive={coursesFormActive}
                onSubmit={onSubmit}
                confirmText="¿Estás seguro de que quieres eliminar este curso?"
                value1={name}
                value2={null}
              />
            </EditDeleteIconsCont>
          </InputColumn>

        </FullFlexCont>

      </ContactForm>

      <SingleRow />
    </>
  );
};

export default Course;