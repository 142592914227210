import React from 'react';
import styled from 'styled-components/macro';

import MenuItems from '../MenuItems';


const MenuContainer = styled.div`
  min-height: calc(100vh - 70px);
  width: 18rem;
  padding-top: 2rem;
  border: 1px solid ${({ theme }) => theme.colors.grey};
`;


const DesktopAsideMenu = ({ notificationsNumber }) => (
  <MenuContainer>
    <MenuItems notificationsNumber={notificationsNumber} />
  </MenuContainer>
);

export default DesktopAsideMenu;
