import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { CardPagos, Modal } from '../common';
import { dates } from '../../utils';
import { PaymentMethods } from '../payments';
import usePaymentSuccess from '../../hooks/usePaymentSuccess';


const Message = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  text-align: center;
  line-height: 1.47;
`;

const quotaNameDictionary = {
  firstPayment: 'Primer pago',
  secondPayment: 'Segundo pago',
  insurance: 'Seguro',
  extension: 'Prórroga',
};

const ModalContent = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 0 4rem 4rem;
`;

const PaymentDataLine = styled.h3`
  color: ${({ theme }) => theme.colors.themePrimary};
  margin-bottom: 2rem;
  text-align: center;
`;

const Payments = () => {
  const student = useSelector((state) => state.student);
  const paymentsList = student?.payments || [];

  const [selected, setSelected] = useState(null);
  const [method, setMethod] = useState('deposit');
  const [totalAmount, setTotalAmount] = useState(0);
  const [quotaNameToPay, setQuotaNameToPay] = useState('');
  const [id, setId] = useState('');

  const {
    error,
    isOpen,
    onPaymentSuccess,
    setIsOpen,
    setShowPaymentSuccessModal,
    showPaymentSuccessModal,
  } = usePaymentSuccess({
    payments: paymentsList,
    paymentId: id,
    paymentMethod: method,
  });

  const onClick = (paymentId) => {
    const payment = paymentsList.find((item) => item.id === paymentId);
    setId(paymentId);
    setTotalAmount(payment.amount);
    setQuotaNameToPay(payment.quotaName);
    setSelected(payment);
  };

  const getMessage = () => {
    const pendingPayments = paymentsList.filter((item) => {
      if (item.status === 'done') return false;
      if (!item.amount) return false;
      return true;
    });
    const pendingCount = pendingPayments.length;
    const message = pendingCount
      ? (
        <Message>
          {pendingCount} pago{pendingCount === 1 ? 's' : ''} pendiente{pendingCount === 1 ? 's' : ''}
          <br />
          SELECCIONA LOS PAGOS A REALIZAR
        </Message>
      ) : '';
    return message;
  };

  const onChange = (name, value) => {
    setMethod(value);
  };

  return (
    <>
      {getMessage()}
      {
        paymentsList.map((item) => {
          const { status, date, quotaName, amount, id: paymentId } = item;
          if (!amount) return null;
          const title = quotaNameDictionary[quotaName];
          // const active = selected.indexOf(title) !== -1;
          const active = paymentId === id;
          const dateData = date ? dates.getYearMonthDay(new Date(date)) : '';
          const dateText = dateData ? `${dateData.day}-${dateData.month}-${dateData.year}` : '';

          return (
            <CardPagos
              key={paymentId}
              id={paymentId}
              title={title}
              money={amount}
              date={dateText}
              status={status}
              onClick={() => onClick(paymentId)}
              active={active}
              setIsOpen={setIsOpen}
            />
          );
        })
      }
      {
        isOpen && (
          <Modal onClose={() => setIsOpen(false)} minHeight="50vh" minWidth="40vw">
            <ModalContent>
              <PaymentDataLine>
                Datos de pago: {quotaNameDictionary[quotaNameToPay]}, {totalAmount}€
              </PaymentDataLine>
              <PaymentMethods
                amount={totalAmount}
                error={error}
                method={method}
                onChange={onChange}
                onPaymentSuccess={onPaymentSuccess}
                paymentId={id}
                setShowPaymentSuccessModal={setShowPaymentSuccessModal}
                showPaymentSuccessModal={showPaymentSuccessModal}
              />
            </ModalContent>
          </Modal>
        )
      }
    </>
  );
};

export default Payments;
