import React from 'react';
import { useForm } from 'react-hook-form';
import ButtonPrimary from '../../common/Buttons/ButtonPrimary';
import ButtonSecondary from '../../common/Buttons/ButtonSecondary';

import {
  Input,
  Label,
  InputColumn,
  ContactForm,
  Error,
  CardContentWrapper,
  WhiteSpace,
  FullFlexCont,
  ModalsLogo,
  NoIconTitle,
  Column,
} from '../formStyledComponents';

const NewContact = ({ addContact, littleDevice, onClose }) => {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data, e) => {
    e.preventDefault();
    addContact(data);
  };

  /* handle errors  */
  const emailError = errors.email?.type === 'required' && <Error>Por favor, indica tu email de contacto</Error>;
  const emailError2 = errors.email?.type === 'pattern' && <Error>Formato de email incorrecto</Error>;
  const nameError = errors.name && <Error>El nombre del contacto de emergencia es obligatorio</Error>;
  const phoneError = errors.phone?.type === 'required' && <Error>Debes indicar un teléfono móvil de contacto</Error>;
  const phoneError2 = errors.phone?.type === 'pattern' && <Error>Número de teléfono no válido</Error>;

  return (
    <CardContentWrapper mobileCenter>
      <ModalsLogo src="/images/logo.jpg" alt="Nexus logo" />

      <NoIconTitle margin="0 0 2rem 0" mobileMargin="0 0 2rem 0">
        AÑADIR CONTACTO DE EMERGENCIA
      </NoIconTitle>

      <ContactForm onSubmit={handleSubmit(onSubmit)}>

        <InputColumn margin="0">
          <Label>Nombre*:</Label>
          <Input width="50%" name="name" placeholder="nombre" ref={register({ required: true })} mobileWidth="100%" mobileCenter mobileMargin="0 0 1rem 0" />
          {nameError}
        </InputColumn>

        <InputColumn margin="0">
          <Label>Relación o parentesco:</Label>
          <Input width="50%" name="relationship" placeholder="relationship" ref={register({ required: false })} mobileCenter mobileWidth="100%" mobileMargin="0 0 1rem 0" />
        </InputColumn>

        <FullFlexCont padding="0" mobilePadding="0" between alignEnd>
          <InputColumn margin="0" width="40%">
            <Label>Email de contacto:</Label>
            <Input
              name="email"
              placeholder="email"
              ref={register({
                pattern: {
                  value: /[\w\-\._]+@[\w\-\._]+\.\w{2,10}/,
                },
              })}
              mobileCenter
              mobileWidth="100%"
              mobileMargin="0 0 1rem 0"
            />
            {emailError}
            {emailError2}
          </InputColumn>

          <InputColumn margin="0" width="42%">
            <Label>Teléfono de contacto*:</Label>
            <Input
              name="phone"
              placeholder="phone"
              ref={register({
                required: true,
                pattern: {
                  value: /^\+[0-9][0-9]{6,20}$/,
                },
              })}
              mobileCenter
              mobileWidth="100%"
              mobileMargin="0 0 1rem 0"
            />
            {phoneError}
            {phoneError2}
          </InputColumn>
        </FullFlexCont>

        {!littleDevice && (
          <>
            <WhiteSpace height="4rem" />

            <ButtonPrimary
              type={2}
              text="Guardar datos"
              htmlType="submit"
              margin="0 0 0 auto"
            />

            <WhiteSpace height="4rem" />
          </>
        )}

        {littleDevice
          && (
            <FullFlexCont justify="space-between" padding="0">
              <WhiteSpace height="1rem" />
              <Column width="46" padding="0">
                <ButtonSecondary
                  text="Atrás"
                  onClick={onClose}
                  margin="auto"
                  fullWidth
                />
              </Column>
              <Column width="46" padding="0">
                <ButtonPrimary
                  type={2}
                  text="Guardar"
                  htmlType="submit"
                  margin="auto"
                  fullWidth
                />
              </Column>

            </FullFlexCont>
          )}

      </ContactForm>
    </CardContentWrapper>
  );
};

export default NewContact;
