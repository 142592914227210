import React from 'react';
import { useForm } from 'react-hook-form';
import ButtonPrimary from '../../../common/Buttons/ButtonPrimary';
import ButtonSecondary from '../../../common/Buttons/ButtonSecondary';

import {
  Input,
  Label,
  InputColumn,
  ContactForm,
  CardContentWrapper,
  WhiteSpace,
  FullFlexCont,
  Column,
} from '../../formStyledComponents';

const NewCourse = ({
  addCourse,
  littleDevice,
  setSelectedCourse,
  setSelectedCvAdd,
  isAddModal,
  isEditModal,
}) => {
  const { register, handleSubmit } = useForm();

  const onSubmit = (data, e) => {
    e.preventDefault();
    addCourse(data);
  };


  return (
    <CardContentWrapper mobilePadding="0" padding={isAddModal || isEditModal && '0'}>
      <ContactForm onSubmit={handleSubmit(onSubmit)}>

        <FullFlexCont padding="0" between mobilePadding="0">
          <InputColumn
            width="100%"
            margin="0 3rem 0 0"
            mobileMargin="0"
            alignCenter={littleDevice}
          >
            <Label>Curso:</Label>
            <Input
              name="name"
              placeholder="nuevo curso"
              ref={register({ required: true })}
              mobileWidth="80%"
              mobileMargin="0"
            />
          </InputColumn>
        </FullFlexCont>

        {!littleDevice && (
          <>
            <WhiteSpace height="2rem" />

            <ButtonPrimary
              iconColor="#105285"
              type={2}
              text="Guardar datos"
              htmlType="submit"
              margin="0 0 0 auto"
            />

            <WhiteSpace height="4rem" />
          </>
        )}

        {littleDevice && (
          <FullFlexCont justify="space-between">

            <WhiteSpace height="1rem" />

            <Column width="46" padding="0">
              <ButtonSecondary
                text="Atrás"
                onClick={!isAddModal ? () => setSelectedCourse(null) : () => setSelectedCvAdd(null)}
                margin="auto"
                fullWidth
              />
            </Column>

            <Column width="46" padding="0">
              <ButtonPrimary
                type={2}
                text="Guardar"
                htmlType="submit"
                margin="auto"
                fullWidth
              />
            </Column>

          </FullFlexCont>
        )}

      </ContactForm>
    </CardContentWrapper>
  )
};

export default NewCourse;
