import React from 'react';
import { useDispatch } from 'react-redux';

import DownDeleteIcons from '../../DownDeleteIcons';
import { SingleRow } from '../../../common';
import { studentActions } from '../../../../redux/actions';
import { getFileURL } from '../../../../services/storage';

import {
  InputColumn,
  FullFlexCont,
  ContactForm,
  BlueInput,
  EditDeleteIconsCont,
} from '../../formStyledComponents';

const Document = (props) => {
  const {
    name, deleteDocument, i, otherExperiences, path,
  } = props;

  const dispatch = useDispatch();

  const onDownFile = (e) => {
    e.preventDefault();
    const fileUrl = getFileURL(path);
    window.open(`${fileUrl}`, `${name}`);
  };

  const onSubmit = (file, e) => {
    if (!file) return;
    e.preventDefault();
    const documents = otherExperiences;
    const index = i;
    documents.splice(index, 1, file);
    dispatch(studentActions.save({ otherExperience: documents }));
  };


  return (
    <>
      <ContactForm>

        <FullFlexCont justify="flex-end" padding="0" alignCenter>

          <InputColumn width="50%" margin="0 auto 0 2rem" mobileWidth="40%">
            <BlueInput
              bold
              width="100%"
              mBottom="0"
              disabled
              name="name"
              placeholder="título del curso"
              defaultValue={name}
            />
          </InputColumn>

          <InputColumn width="20%" mobileWidth="40%">
            <EditDeleteIconsCont>
              <DownDeleteIcons
                download={onDownFile}
                deleteField={() => deleteDocument(path)}
                onSubmit={onSubmit}
                value={path}
                confirmText="¿Estás seguro de que quieres eliminar este curso?"
              />
            </EditDeleteIconsCont>
          </InputColumn>

        </FullFlexCont>

      </ContactForm>

      <SingleRow />
    </>
  );
};

export default Document;
