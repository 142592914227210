import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import { uploadFile, getFileURL } from '../../../../services/storage';
import { EditIcon } from '../../Icons/IconsList';


const Input = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const EmptyDiv = styled.div`
  display: flex;
  background-color: rgb(164, 179, 183);
  width: 12rem;
  height: 13rem;
  margin: 2rem;

  background-image: ${({ bgImage }) => `url(${bgImage})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  svg {
    margin-left: auto;
    height: 3rem;
    margin-top: 1rem;
    margin-right: 0.5rem;
    cursor: pointer;
  }
`;

const UploadDiv = styled.div`
  width: 100%;
  display: flex;
`;

const LabelIcon = styled.label`
  display: flex;
  width: 100%;
`;

const Label = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  outline: none;
  cursor: pointer;
  border-radius: 3rem;
  border: solid 2px #cff2ff;
  background-color: #cff2ff;
  color: #105285;
  font-size: 1.5rem;
  font-weight: 700;
  padding: 1rem 1.5rem;
  text-align: center;

  svg {
    width: 2.8rem;
    height: 2rem;
  }

  &:hover {
    background-color: #105285;
    border: solid 2px #105285;
    color: #FFFFFF;

    svg {
      fill: ${(props) => (props.theme.colors.textInverse)};
      stroke: ${(props) => (props.theme.colors.textInverse)};
    }
  }
`;

const EditText = styled.p`
  height: fit-content;
`;


const FormImageUploadField = (props) => {
  const {
    label, entity, userId, onSave, photo, disabled,
  } = props;
  const entityURL = entity.toLowerCase();

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const filename = `${file.name}`.replace(/\s/g, '-').replace(/á|à|â|é|è|ê|í|ì|î|ó|ò|ô|ú|ù|û|ç|ñ`/gi, '-');
    const fileLocation = `${userId}/${entityURL}/${filename}`;
    const response = await uploadFile(file, fileLocation);
    const { success, data, message } = response;
    if (success) onSave(data);
  };

  const getImgSrc = () => {
    if (!photo) return '';
    const url = getFileURL(`${photo}`);
    return url;
  };

  return (
    <>
      {
        photo
          ? (
            <EmptyDiv bgImage={getImgSrc()}>
              {
                !disabled && (
                  <LabelIcon htmlFor={`${userId}-image-upload`}>
                    <EditIcon iconColor="#FFFF" />
                  </LabelIcon>
                )
              }
            </EmptyDiv>
          )
          : (
            <EmptyDiv>
              {
                !disabled && (
                  <LabelIcon htmlFor={`${userId}-image-upload`}>
                    <EditIcon iconColor="#FFFF" />
                  </LabelIcon>
                )
              }
            </EmptyDiv>
          )
      }

      {
        !disabled && (
          <UploadDiv>
            <Input
              accept="image/*"
              id={`${userId}-image-upload`}
              type="file"
              onChange={handleFileUpload}
            />

            <Label htmlFor={`${userId}-image-upload`}>
              <EditIcon iconColor="#105285" />
              <EditText>{label}</EditText>
            </Label>
          </UploadDiv>
        )
      }

      {/* <div>
        {photo && (
          <div onClick={() => onSave('')}>
            Remove
          </div>
        )}
      </div> */}
    </>
  );
};

FormImageUploadField.propTypes = {
  label: PropTypes.string.isRequired,
  entity: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  photo: PropTypes.string,
  disabled: PropTypes.bool,
};

FormImageUploadField.defaultProps = {
  photo: '',
  disabled: '',
};

export default FormImageUploadField;
