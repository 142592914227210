const onFilter = ({
  newFilters, vacancies, search, setFilteredList, setPage, setFilters,
}) => {
  if (vacancies.length <= 0) return;

  const {
    departments = [], city = '', zones = [], datesOpen = { dateIni: '', dateEnd: '' },
  } = newFilters || {};

  const searchTerm = search ? search.toLowerCase() : '';

  // Filtrar según filtros generales
  const filteredByFilters = vacancies.filter((doc) => {
    if (!doc.active) return false;

    let matchesDepartment = departments.length === 0 || departments.includes(doc.department);
    let matchesCity = !city || city === doc.city;
    let matchesZone = zones.length === 0 || (doc.zonaGeografica && zones.some((zone) => zone.toLowerCase() === doc.zonaGeografica.toLowerCase()));

    // Verificación de fechas
    let matchesDate = true;
    if (datesOpen.dateIni || datesOpen.dateEnd) {
      const docDates = doc.datesOpen;
      if (!docDates) return false;

      const iniDate = datesOpen.dateIni ? Date.parse(datesOpen.dateIni) : null;
      const endDate = datesOpen.dateEnd ? Date.parse(datesOpen.dateEnd) : null;

      matchesDate = docDates.some((el) => {
        const elIni = Date.parse(el.dateIni);
        const elEnd = Date.parse(el.dateEnd);

        const validIni = iniDate ? (iniDate >= elIni && iniDate <= elEnd) : true;
        const validEnd = endDate ? (endDate >= elIni && endDate <= elEnd) : true;

        return validIni && validEnd;
      });
    }

    return matchesDepartment && matchesCity && matchesZone && matchesDate;
  });



  // Aplicar búsqueda a la lista filtrada por filtros generales
  const result = searchTerm ? filteredByFilters.filter((doc, index) => {
  
    const docName = doc.company ? doc.company.toLowerCase() : '';
    const docDescription = doc.description ? doc.description.toLowerCase() : '';
  
    console.log(`Doc ${index}: name=${docName}, description=${docDescription}`);
    
    return docName.includes(searchTerm) || docDescription.includes(searchTerm);
  }) : filteredByFilters;

  // Actualizar la lista filtrada y paginación

  if (result.length === 0) {
    setFilteredList([]); // Muestra el mensaje de "No hay resultados"
  } else {
    setFilteredList(result); // Actualiza la lista filtrada con resultados
    setPage(1);              // Reinicia la paginación a la primera página
  }

  setFilters(newFilters);  // Guarda los filtros actuales
};

export default onFilter;
