import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { signout } from '../../../services/api/auth';

import { showBurgerMenu, studentActions } from '../../../redux/actions';


const MenuItem = styled.div`
  padding: 2.5rem 2rem;
  border-bottom: 2px solid ${({ theme }) => theme.colors.themeTertiary};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  cursor: pointer;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {   
    font-size: ${({ theme }) => theme.fontSizes.titleXSmall};
    padding: 2.5rem 0;
  }
`;

const ContactContent = styled.div`
  text-align: center;
  padding: 2.5rem 2rem;
  border-bottom: 2px solid ${({ theme }) => theme.colors.themeTertiary};

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {   
    padding: 2.5rem 0;
  }
`;

const ContactTitle = styled.p`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.small};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    font-size: ${({ theme }) => theme.fontSizes.titleXSmall};
    line-height: 3rem;
  }
`;

const ContactText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    font-size: ${({ theme }) => theme.fontSizes.titleXSmall};
     line-height: 3rem;
  }
`;

const LegalLinksDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2rem;
  justify-content: flex-end;
  height: 25vh;
  padding-bottom: 2rem;
  width: 100%;
  color: ${({ theme }) => theme.colors.themeSecondary};
  line-height: 1.5;
`;

const MenuNotificationWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: ${({ isSmallScreen }) => (isSmallScreen ? 'center' : 'start')};
`;

const MenuItems = ({ isLogged, notificationsNumber }) => {
  const dispatch = useDispatch();
  const screen = useSelector((state) => state.windowResize.screen);
  const isSmallScreen = screen === 'phone' || screen === 'tab-port';
  const student = useSelector((state) => state.student);
  const statusList = student.statusHistory.map((item) => item.status);
  const profileCompleted = statusList.includes('profileCompleted');
  const firstPayment = student?.payments?.[0]?.status;
  const firstPaymentDone = firstPayment === 'done';
  const onClose = () => dispatch(showBurgerMenu.hidde());

  // log out
  const history = useHistory();

  const handleLogout = async () => {
    const { success } = await signout();

    if (success) {
      history.push('/login');
      dispatch(studentActions.empty());
    }
  };

  const onSignOut = (e) => {
    e.preventDefault();
    handleLogout();
    onClose();
  };


  return (
    <>
      <Link to="/my-account">
        <MenuItem
          onClick={isSmallScreen ? onClose : () => { }}
        >
          Datos de tu cuenta
        </MenuItem>
      </Link>

      {profileCompleted
        && (
          <Link to="/processes">
            <MenuItem
              onClick={isSmallScreen ? onClose : () => { }}
            >
              Mis procesos
            </MenuItem>
          </Link>
        )}

      <Link to="/notifications">
        <MenuItem
          onClick={isSmallScreen ? onClose : () => { }}
        >
          <MenuNotificationWrapper isSmallScreen={isSmallScreen}>
            <span>Notificaciones</span>{notificationsNumber && notificationsNumber}
          </MenuNotificationWrapper>
        </MenuItem>
      </Link>

      {firstPaymentDone
              ? (
                <Link to="/vacancy-finder">
                  <MenuItem
                    onClick={isSmallScreen ? onClose : () => { }}
                  >
                    Listado de vacantes
                  </MenuItem>
                </Link>
              )
              : (
                <MenuItem style={{color:"lightgrey",cursor:"not-allowed"}}>
                Listado de vacantes
                </MenuItem>
              )}
      

      <Link to="/payment-history">
        <MenuItem
          onClick={isSmallScreen ? onClose : () => { }}
        >
          Historial de pagos
        </MenuItem>
      </Link>

      {isLogged
        && (
          <MenuItem
            onClick={onSignOut}
          >
            Cerrar sesión
          </MenuItem>
        )}

      <ContactContent>
        <ContactTitle>Contacto</ContactTitle>
        <ContactText>
          Tel. +(34) 934 150 213 <br />
          nexus@nexusmobility.es
        </ContactText>
      </ContactContent>

      <a href="https://www.nexusmobility.es/centro-de-ayuda/" target="_blank">
        <MenuItem
          onClick={isSmallScreen ? onClose : () => { }}
        >
          Centro de Ayuda
        </MenuItem>
      </a>

      <LegalLinksDiv>
        <Link to="/terms-and-conditions">
          <div
            onClick={isSmallScreen ? onClose : () => { }}
          >
            Terms and Conditions
          </div>
        </Link>

        <Link to="/privacy">
          <div
            onClick={isSmallScreen ? onClose : () => { }}
          >
            Privacy Policy
          </div>
        </Link>

        <Link to="/cancel">
          <div
            onClick={isSmallScreen ? onClose : () => { }}
          >
            Cancellation policy
          </div>
        </Link>

      </LegalLinksDiv>
    </>
  );
};

export default MenuItems;
