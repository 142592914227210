import { combineReducers } from 'redux';

import burgerMenuReducer from './burgerMenuReducer';
import languageReducer from './languageReducer';
import windowResizeReducer from './windowResizeReducer';
import progressBarReducer from './progressBarReducer';
import studentReducer from './studentReducer';

export default combineReducers({
  language: languageReducer,
  showBurgerMenu: burgerMenuReducer,
  windowResize: windowResizeReducer,
  complete: progressBarReducer,
  student: studentReducer,
});
