import React from "react";
import styled from 'styled-components/macro';

import Icons from "../../Icons";
import TagDepartamento from "../../Tags/TagDepartamento";

const Container = styled.div`
margin-left: 1.5rem;
margin-right: 1.5rem;
box-shadow: ${props => props.theme.shadows.shadow1};
border-radius: ${props => props.theme.borderRadius.card};
display:flex;
justify-content: space-between;
flex-wrap:wrap;
`;
const Part = styled.div`
display: flex;
`;
const PartIcon = styled.div`
padding: 0rem 1rem;
display:flex;
justify-content:center;
align-items: center;
`;
const PartFluid = styled.div`
width: calc(100% - 6rem);
padding: 1.5rem 2rem;
`;

const LineBetweenMobile = styled.div`
display:flex;
justify-content: space-between;
align-items: center;
width: 100%;
@media (max-width: ${props => props.theme.breakpoints.md}) {   
    display:block;
}
`;

const VerticalSeparator = styled.div`
width: 2px;
background-color: ${props => props.theme.colors.themeTertiary};
`;
const Title = styled.p`
color: ${props => props.theme.colors.textPrimary};
font-size: ${props => props.theme.fontSizes.medium};
font-weight: 700;
line-height: 1.5;
`;
const Text = styled.p`
color: ${props => props.theme.colors.textPrimary};
font-size: ${props => props.theme.fontSizes.medium};
line-height: 1.5;
`;

const IconCover = styled.div`
display:flex;
justify-content:center;
align-items:center;
height: 3.125rem;
width: 3.125rem;
background-color: ${props => props.theme.colors.themeTertiary};
border-radius: 100%;
`;

const CardDocumentos = ({ title = 'Nombre del documento', date = '00/00/2020', tagText = 'pendiente', tagColor = 'alertError' }) => {
    return (
        <Container>
            <PartFluid>
                <LineBetweenMobile>
                    <div>
                        <Title>{title}</Title>
                        <Text>Necesario antes de:</Text>
                        <Text>{date}</Text>
                    </div>
                    <TagDepartamento title={tagText} bgColor={tagColor} />
                </LineBetweenMobile>
            </PartFluid>

            <Part><VerticalSeparator /></Part>

            <PartIcon><IconCover><Icons icon={'clip'} iconColor={'themeSecondary'} width={1.75} /></IconCover></PartIcon>
        </Container>
    );
}

export default CardDocumentos;