/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import useListPublicEntity from '../../../hooks/useListPublicEntity';
import { studentActions } from '../../../redux/actions';
import { showOptions, getEntity, getId } from '../helpers';
import { dates } from '../../../utils';

import NewNationality from './NewNationality';
import NationalityTag from './NationalityTag';
import FormTitle from '../FormTitle';
import ButtonPrimary from '../../common/Buttons/ButtonPrimary';
import ButtonSecondary from '../../common/Buttons/ButtonSecondary';
import FormImageUploadField from '../../common/formFields/FormImageUploadField';
import { EditIcon } from '../../common/Icons/IconsList';
import { Box, Modal, DatePicker } from '../../common';

import {
  CBLabel,
  CheckboxDiv,
  Column,
  DobleInputDiv,
  Error,
  FullFlexCont,
  Input,
  InputCheckbox,
  InputColumn,
  Label,
  MobilePadding,
  P,
  PersonalForm,
  Select,
  SubLabel,
  WhiteSpace,
} from '../formStyledComponents';

const genderDictionary = {
  male: 'Hombre',
  female: 'Mujer',
  other: 'Otro',
};

const PersonalDataForm = ({ profile }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.student.id);
  const screen = useSelector((state) => state.windowResize.screen);

  const methods = useForm();
  const {
    handleSubmit, errors, setValue, register, watch, reset, control, formState: { isDirty },
  } = useForm({ mode: 'onChange' });
  register('nationalitiesList', { required: true });
  register('dniCountry', { required: true });
  register('residence', { required: true });
  register('university', { required: true });
  register('photo');

  const watchFields = watch(['dniCountry', 'residence', 'university', 'photo', 'birthday', 'dniCaducity', 'visaCaducity', 'temporalVisa']);
  const watchNationalities = watch('nationalitiesList') || [];

  const initialFormState = profile && false;
  const [formActive, setFormActive] = useState(initialFormState);
  const [nationalityOpen, setNationalityOpen] = useState(false);
  const [showTemporalResidence, setShowTemporalResidence] = useState(false);

  const { data: residences } = useListPublicEntity('residence');
  const { data: universities } = useListPublicEntity('university');

  const initialTemporalResidenceValue = _get(profile, 'residenceHasExpiration', false);
  const birthday = _get(profile, 'birthday', '');
  const dniCaducity = _get(profile, 'passport.expiration', '');
  const visaCaducity = _get(profile, 'residenceExpiration', '');

  useEffect(() => {
    setShowTemporalResidence(initialTemporalResidenceValue);
  }, [initialTemporalResidenceValue, profile]);

  const genderSelect = Object.values(genderDictionary);

  useEffect(() => {
    const fetchData = async () => {
      if (!reset || !profile) return;
      const nationalitiesList = _get(profile, 'nationalitiesList', []);
      const gender = _get(profile, 'gender', '');

      reset({
        photo: _get(profile, 'photo', ''),
        dniCountry: _get(profile, 'passport.country.nameLang.es', ''),
        residence: _get(profile, 'residence.nameLang.es', ''),
        university: _get(profile, 'university.name', ''),
        gender: genderDictionary[gender] || 'Otro',
        temporalVisa: _get(profile, 'residenceHasExpiration', false),
        nationalitiesList,
        birthday: _get(profile, 'birthday', ''),
        dniCaducity: _get(profile, 'passport.expiration', ''),
        visaCaducity: _get(profile, 'residenceExpiration', ''),
      });
    };

    fetchData();
  }, [profile]);

  // Remove nationality
  const onRemove = (name) => {
    const newNationalities = [];
    watchNationalities.forEach((nat) => {
      if (name !== nat) newNationalities.push(nat);
    });
    setValue('nationalitiesList', newNationalities, { shouldValidate: true });
  };

  const onAddImage = (path) => {
    setValue('photo', path);
  };

  /* edit / save data button */
  const onSubmit = (data, e) => {
    if (e) e.preventDefault();
    if (watchNationalities.length === 0) return;

    let genderSelected = '';
    if (data.gender === 'Hombre') genderSelected = 'male';
    else if (data.gender === 'Mujer') genderSelected = 'female';
    else genderSelected = 'other';

    // const newNationalities = data.nationalitiesList.map((nat) => {
    //   const completeNat = getEntity(residences, nat);
    //   return completeNat;
    // });

    const newPersonal = {
      passport: {
        passportCountryId: getId(residences, data.dniCountry),
        number: data.dniNumber,
        birthPlace: data.birthPlace,
        expiration: data.dniCaducity,
      },
      gender: genderSelected,
      birthday: data.birthday,
      residence: getEntity(residences, data.residence),
      residenceHasExpiration: data.temporalVisa,
      residenceExpiration: data.visaCaducity,
      university: getEntity(universities, data.university),
      name: data.name,
      lastname: data.lastname,
      nationalitiesList: data.nationalitiesList,
      photo: data.photo,
    };

    if (newPersonal) dispatch(studentActions.save({ ...newPersonal }));
    setFormActive(!formActive);
  };

  const scrollToTop = () => (
    window.scrollTo({ top: 0, behavior: 'smooth' })
  );

  return (
    <MobilePadding flex>
      <Column width={60} br mobileFull>

        <Box container width="100%">
          <FormTitle title="DATOS PERSONALES:" onClick={() => !isDirty && setFormActive(!formActive)} />
          {
            formActive && screen !== 'phone' && (
              <div style={{ minWidth: '14rem', marginBottom: '2rem' }}>
                <ButtonPrimary
                  iconColor="#105285"
                  type={2}
                  text="Guardar datos"
                  // htmlType="submit"
                  margin="0 auto"
                  fullWidth
                  padding="0.5rem 1rem"
                  fontSize="1rem"
                  onClick={handleSubmit((data) => onSubmit(data))}
                />
              </div>
            )
          }
        </Box>

        <FormProvider {...methods}>
          <PersonalForm key="personalForm">
            <Label>Nombre*:</Label>
            <Input
              disabled={!formActive}
              name="name"
              placeholder="nombre"
              ref={register({ required: true })}
              defaultValue={_get(profile, 'name', '')}
              mobileHeight="inherit"
            />
            {errors.name && <Error>El nombre es un campo obligatorio</Error>}

            <Label>Apellidos*:</Label>
            <Input
              disabled={!formActive}
              name="lastname"
              placeholder="apellido"
              ref={register({ required: true })}
              defaultValue={_get(profile, 'lastname', '')}
              mobileHeight="inherit"
            />
            {errors.lastname && <Error>Debes indicar tus apellidos</Error>}

            <DobleInputDiv>
              <InputColumn width="40%">
                <Label>Fecha de nacimiento*:</Label>
                {
                  formActive ? (
                    <Controller
                      name="birthday"
                      control={control}
                      rules={{ required: true }}
                      render={() => (
                        <DatePicker
                          value={watchFields.birthday}
                          onValueChange={(value) => setValue('birthday', value)}
                          setValue={setValue}
                          name="birthday"
                        />
                      )}
                    />
                  ) : <span>{dates.getStringDate(birthday) || 'dd/mm/yyyy'}</span>
                }
                {errors.birthday && !watchFields.birthday && <Error>Debes indicar tu fecha de nacimiento</Error>}
              </InputColumn>

              <InputColumn width="40%">
                <Label>Género*:</Label>

                <Select
                  name="gender"
                  disabled={!formActive}
                  ref={register({ required: true })}
                  defaultValue={genderDictionary[_get(profile, 'gender', '')]}
                >
                  {
                    genderSelect.map((option) => (
                      <option key={option} value={option}>{option}</option>
                    ))
                  }
                </Select>
                {errors.gender && <Error>Por favor, indica tu género</Error>}
              </InputColumn>
            </DobleInputDiv>

            <Label>Nacionalidad/es*:</Label>

            <FullFlexCont center={formActive}>
              {
                watchNationalities?.length > 0 && watchNationalities.map((nation) => (
                  <NationalityTag
                    key={nation}
                    title={nation}
                    onRemove={onRemove}
                    active={formActive}
                  />
                ))
              }
            </FullFlexCont>

            {
              (formActive && (!watchNationalities || watchNationalities?.length === 0))
              && <Error>Añade tu nacionalidad</Error>
            }

            {watchNationalities.length < 2 && (
              <>
                <WhiteSpace height="2rem" />

                <ButtonSecondary
                  text="Añadir nacionalidad"
                  onClick={formActive ? () => setNationalityOpen(true) : () => { }}
                  margin="auto"
                  fullWidth
                  disabled={!formActive}
                />

                <WhiteSpace height="4rem" />
              </>
            )}

            <div>Pasaporte/DNI*:</div>
            <DobleInputDiv>
              <InputColumn width="40%">
                <SubLabel>País:</SubLabel>
                <Select
                  disabled={!formActive}
                  name="dniCountry"
                  value={watchFields.dniCountry}
                  onChange={(e) => setValue(
                    'dniCountry', e.target.value, { shouldValidate: true },
                  )}
                >
                  {showOptions(residences, '', 'país del documento')}
                </Select>
                {errors.dniCountry && <Error>Debes indicar el país de tu documento</Error>}
              </InputColumn>

              <InputColumn width="40%">
                <SubLabel>Lugar de nacimiento:</SubLabel>
                <Input
                  disabled={!formActive}
                  name="birthPlace"
                  placeholder="lugar de nacimiento"
                  ref={register({ required: true })}
                  defaultValue={_get(profile, 'passport.birthPlace', '')}
                />
                {errors.birthPlace && <Error>Indica tu lugar de nacimiento</Error>}
              </InputColumn>
            </DobleInputDiv>
            <DobleInputDiv>
              <InputColumn width="40%">
                <SubLabel>Número:</SubLabel>
                <Input
                  disabled={!formActive}
                  name="dniNumber"
                  placeholder="número pasaporte/dni"
                  ref={register({ required: true })}
                  defaultValue={_get(profile, 'passport.number', '')}
                />
                {errors.dniNumber && <Error>El número de DNI o Pasaporte es obligatorio</Error>}
              </InputColumn>

              <InputColumn width="40%">
                <SubLabel>Fecha de caducidad:</SubLabel>
                {
                  formActive ? (
                    <Controller
                      name="dniCaducity"
                      control={control}
                      rules={{ required: true }}
                      render={() => (
                        <DatePicker
                          value={watchFields.dniCaducity}
                          onValueChange={(value) => setValue('dniCaducity', value)}
                          setValue={setValue}
                          name="dniCaducity"
                        />
                      )}
                    />
                  ) : <span>{dates.getStringDate(dniCaducity) || 'dd/mm/yyyy'}</span>
                }
                {
                  errors.dniCaducity && !watchFields.dniCaducity
                  && <Error>Debes indicar la fecha de caducidad de tu documento</Error>
                }
              </InputColumn>
            </DobleInputDiv>

            <Label>País de residencia actual*:</Label>
            <Select
              disabled={!formActive}
              name="residence"
              value={watchFields.residence}
              onChange={(e) => {
                setValue('residence', e.target.value, { shouldValidate: true });
              }}
            >
              {showOptions(residences, '', 'país de residencia')}
            </Select>
            {errors.residence && <Error>Indica tu país de residencia actual</Error>}

            <CheckboxDiv>
              <InputCheckbox
                disabled={!formActive}
                type="checkbox"
                name="temporalVisa"
                onClick={() => setShowTemporalResidence(!showTemporalResidence)}
                ref={register({ required: false })}
              />
              <CBLabel>Mi permiso de residencia es temporal</CBLabel>
            </CheckboxDiv>

            {showTemporalResidence
              && (
                <>
                  <P size={1.5}>*Si tu residencia es temporal, tu permiso de residencia caduca:
                    {
                      formActive ? (
                        <Controller
                          name="visaCaducity"
                          control={control}
                          rules={{ required: !!watchFields.temporalVisa }}
                          render={() => (
                            <DatePicker
                              value={watchFields.visaCaducity}
                              onValueChange={(value) => setValue('visaCaducity', value)}
                              setValue={setValue}
                              name="visaCaducity"
                            />
                          )}
                        />
                      ) : <span> {dates.getStringDate(visaCaducity) || 'dd/mm/yyyy'}</span>
                    }
                    {errors.visaCaducity && !watchFields.visaCaducity
                    && <Error>Debes indicar la fecha de caducidad</Error>}
                  </P>
                </>
              )}

            <Label>Universidad*:</Label>
            <Select
              disabled={!formActive}
              name="university"
              value={watchFields.university}
              onChange={(e) => {
                setValue('university', e.target.value, { shouldValidate: true });
              }}
              mBottom={2}
            >
              {showOptions(universities, '', 'universidad')}
            </Select>
            {errors.university && <Error>Debes indicar tu universidad</Error>}

            {!formActive
              && (
              <ButtonPrimary
                iconComponent={<EditIcon height="3rem" />}
                iconColor="#105285"
                type={2}
                htmlType="button"
                text="Editar datos"
                onClick={() => setFormActive(true)}
                margin="0 0 0 auto"
                mobileFullWidth
              />
              )}

            {formActive
              && (
              <ButtonPrimary
                iconComponent={<EditIcon height="3rem" />}
                iconColor="#105285"
                type={2}
                text="Guardar datos"
                htmlType="button"
                margin="0 0 0 auto"
                mobileFullWidth
                onClick={handleSubmit((data) => {
                  if (Object.keys(errors).length === 0) {
                    scrollToTop();
                  }
                  return onSubmit(data);
                })}
              />
              )}

            {nationalityOpen && (
              <Modal onClose={() => setNationalityOpen(false)}>
                <NewNationality
                  setValue={setValue}
                  watch={watch}
                  residences={residences}
                  onClose={() => setNationalityOpen(false)}
                />
              </Modal>
            )}
          </PersonalForm>
        </FormProvider>

      </Column>
      <Column width={40} center>
        <FormImageUploadField
          label="Seleccionar imagen"
          entity="profile"
          userId={userId}
          onSave={onAddImage}
          photo={watchFields.photo}
          disabled={!formActive}
        />
      </Column>
    </MobilePadding>
  );
};

PersonalDataForm.propTypes = {
  profile: PropTypes.shape({}).isRequired,
};

export default PersonalDataForm;
