import React, { useState } from 'react';
import SingleRow from '../../../common/SingleRow';
import FieldsToEditList from './FieldsToEditList';
import CurrentStudyModal from './CurrentStudyModal';
import PrevStudiesList from './PrevStudiesList';
import ExperienceList from './ExperienceList';
import LanguagesList from './LanguagesList';
import DigitalsList from './DigitalsList';
import Skills from '../Skills';
import CoursesList from './CoursesList';

import {
  CardContentWrapper,
  WhiteSpace,
  ModalsLogo,
  NoIconTitle,
} from '../../formStyledComponents';

const EditCvModal = (props) => {
  const {
    littleDevice, onClose, profile, name,
    city, address, cp, region, campus,
    iniDate, endDate, gradDate, institutionName,
    tutor, lastname, email, phone,
    currentSkills,
  } = props;

  const [selectedCvEdit, setSelectedCvEdit] = useState(null);

  return (
    <CardContentWrapper mobileCenter alignCenter>
      <ModalsLogo src="/images/logo.jpg" alt="Nexus logo" />

      {!selectedCvEdit && (
        <>
          <NoIconTitle margin="0 0 2rem 0">¿QUÉ QUIERES EDITAR DE TU C.V.?</NoIconTitle>

          <WhiteSpace mobileHeight="5rem" />

          <FieldsToEditList setSelectedCvEdit={setSelectedCvEdit} />

          <SingleRow mobileMargin="0" margin="0" />

        </>
      )}

      {selectedCvEdit === 'Última formación académica' &&
        (
          <CurrentStudyModal
            setSelectedCvEdit={setSelectedCvEdit}
            onClose={onClose}
            name={name}
            city={city}
            address={address}
            cp={cp}
            region={region}
            campus={campus}
            iniDate={iniDate}
            endDate={endDate}
            gradDate={gradDate}
            institutionName={institutionName}
            tutor={tutor}
            lastname={lastname}
            email={email}
            phone={phone}
            country={profile?.currentStudy?.country?.nameLang?.es || ''}
            currentStudyEnded={profile?.currentStudy?.ended}
          />
        )}

      {selectedCvEdit === 'Estudios superiores' &&
        (
          <PrevStudiesList
            profile={profile}
            setSelectedCvEdit={setSelectedCvEdit}
            littleDevice={littleDevice}
          />
        )}

      {selectedCvEdit === 'Experiencia laboral' &&
        (
          <ExperienceList
            profile={profile}
            littleDevice={littleDevice}
            setSelectedCvEdit={setSelectedCvEdit}
          />
        )}

      {selectedCvEdit === 'Idiomas' &&
        (
          <LanguagesList
            profile={profile}
            littleDevice={littleDevice}
            setSelectedCvEdit={setSelectedCvEdit}
          />
        )}

      {selectedCvEdit === 'Competencias digitales' &&
        (
          <DigitalsList
            profile={profile}
            littleDevice={littleDevice}
            setSelectedCvEdit={setSelectedCvEdit}
          />
        )}

      {selectedCvEdit === 'Aptitudes y habilidades' &&
        (
          <Skills
            profile={profile}
            littleDevice={littleDevice}
            currentSkills={currentSkills}
            isModal
            setSelectedCvEdit={setSelectedCvEdit}
          />
        )}

      {selectedCvEdit === 'Otros cursos' &&
        (
          <CoursesList
            profile={profile}
            littleDevice={littleDevice}
            currentSkills={currentSkills}
            isModal
            setSelectedCvEdit={setSelectedCvEdit}
          />
        )}

    </CardContentWrapper>
  );
};

export default EditCvModal;
