import React from 'react';
import styled from 'styled-components/macro';
import theme from '../CustomTheme/Theme';


const MobileFooterWrapper = styled.div`
  display:none;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    box-shadow: ${(props) => props.theme.shadows.shadow1};
    position: fixed;
    bottom: 0;
    background-color: #fff;
    width: 100%;
    padding: 1.2rem 0;
    display: block;
    margin-left: -2rem;
  }
`;

const ButtonsWrapper = styled.div`
  display:flex;
  justify-content: space-evenly;
  margin-top:1rem;
`;

const ButtonSeguro = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:${theme.colors.themeTertiary};
  width: 14rem;
  border-color: transparent;
  border-radius: 22.7rem;
  object-fit: contain;
  height: 4.5rem;
  color:${theme.colors.themeSecondary};
  font-weight: bold;
  font-size: ${theme.fontSizes.large};
  cursor:pointer;
  margin-right:1rem;
`;

const ButtonNexus = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:${theme.colors.themePrimary};
  width: 14rem;
  border-color: transparent;
  border-radius: 22.7rem;
  object-fit: contain;
  height: 4.5rem;
  color:${theme.colors.white};
  font-weight: bold;
  font-size: ${theme.fontSizes.large};
  cursor:pointer;
`;

const MobileFooter = () => {
  const mobile = 638392124;
  return (
    <MobileFooterWrapper>
      <ButtonsWrapper>
        <ButtonSeguro href="tel:+34902109393">Contacta Seguro</ButtonSeguro>
        <ButtonNexus target="noopener noreferrer" href={`https://api.whatsapp.com/send?phone=34${mobile}`}>
          Contacta Nexus
        </ButtonNexus>
      </ButtonsWrapper>
    </MobileFooterWrapper>
  );
};

export default MobileFooter;
