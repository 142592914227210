import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import EditDeleteIcons from '../../EditDeleteIcons';
import SeeMoreCard from '../../SeeMoreCard';
import { studentActions } from '../../../../redux/actions';
import HSpacer from '../../../layout/HSpacer';

import {
  Column,
  Input,
  Label,
  InputColumn,
  FullFlexCont,
  WhiteSpace,
  ContactForm,
  BlueInput,
  DisabledBlueInput,
  EditDeleteIconsCont,
  Error,
  Select,
  MobilePadding,
} from '../../formStyledComponents';
import { ButtonPrimary } from '../../../common';

const PrevStudy = ({
  profile,
  name,
  deletePrevStudy,
  institution,
  init,
  grad,
  finish,
  finishSelect,
  prevStudies,
  scrollToTop,
}) => {
  const { register, handleSubmit, errors } = useForm();

  const dispatch = useDispatch();

  /* set the form to active/inactive to be edited */
  const initialFormState = prevStudies !== [] && false;
  const [prevStudiesFormActive, setPrevStudiesFormActive] = useState(initialFormState);

  // Asign a ref to the card, to open it with the "edit" button
  const cardRefStudies = useRef(`study-${name + institution}`);

  const [selectedCardStudies, setSelectedCardStudies] = useState(null);

  const edit = (e) => {
    e.preventDefault();
    setPrevStudiesFormActive(!prevStudiesFormActive);
    setSelectedCardStudies(cardRefStudies.current);
  };

  const openByRef = selectedCardStudies === `study-${name + institution}`;

  /* handle errors  */
  const nameError = errors.name && <Error>Por favor, indica el título de los estudios previos</Error>;
  const institutionError = errors.institutionName && <Error>Por favor, indica el nombre de la institución</Error>;
  const gradDateError = errors.gradDate && <Error>Debes indicar la fecha de titulación</Error>;

  const onSubmit = (data, e) => {
    e.preventDefault();
    const obj = { ...data };
    data.ended === 'Sí' ? obj.ended = true : obj.ended = false;
    const studies = [];

    prevStudies.forEach((study) => {
      studies.push({
        ended: study.ended,
        gradDate: study.gradDate,
        iniDate: study.iniDate,
        institutionName: study.institutionName,
        name: study.name,
      });
    });

    studies.forEach((study, index) => {
      if (obj.name === study.name) {
        studies.splice(index, 1, obj);
        dispatch(studentActions.save({ prevStudies: studies }));
      }
    });

    setPrevStudiesFormActive(!prevStudiesFormActive);
  };

  const finishValue = finish === true ? 'Sí' : 'No';


  return (
    <>
      <ContactForm key={name + institution} onSubmit={handleSubmit(onSubmit)}>

        <WhiteSpace height="1rem" />

        <MobilePadding>
          <FullFlexCont mobilePadding="0">
            <InputColumn width="fit-content">

              <DisabledBlueInput
                bold
                width="300px"
                mBottom="0"
                disabled={!prevStudiesFormActive}
                name="name"
                placeholder="Nombre de tu carrera"
                ref={register({ required: true })}
                value={name}
              />
              {nameError}

              <BlueInput
                width="300px"
                disabled={!prevStudiesFormActive}
                name="institutionName"
                placeholder="Nombre del centro"
                ref={register({ required: true })}
                defaultValue={institution}
              />
              {institutionError}
            </InputColumn>

            <EditDeleteIconsCont>
              <EditDeleteIcons
                edit={edit}
                deleteField={deletePrevStudy}
                isActive={prevStudiesFormActive}
                onSubmit={onSubmit}
                confirmText="¿Estás seguro de que quieres eliminar esta ficha?"
                value1={name}
                value2={institution}
              />
            </EditDeleteIconsCont>

          </FullFlexCont>

        </MobilePadding>

        <SeeMoreCard
          ref={cardRefStudies}
          openByRef={openByRef}
          selectedCard={selectedCardStudies}
          profile={profile}
          setSelectedCard={setSelectedCardStudies}
        >

          <WhiteSpace height="4rem" />

          <Column width={30} center mobileFull mobileMargin="0">
            <InputColumn>
              <Label>Fecha inicio:</Label>
              <Input
                disabled={!prevStudiesFormActive}
                type="date"
                name="iniDate"
                ref={register({ required: false })}
                defaultValue={init}
                mobileMargin="0"
                key={`${init}iniDate`}
              />
            </InputColumn>
          </Column>
          <Column width={30} center mobileFull mobileMargin="0">
            <InputColumn>
              <Label>Fecha titulación:</Label>
              <Input
                disabled={!prevStudiesFormActive}
                type="date"
                name="gradDate"
                ref={register({ required: true })}
                defaultValue={grad}
                mobileMargin="0"
                key={`${grad}gradDate`}
              />
              {gradDateError}
            </InputColumn>
          </Column>
          <Column width={30} center mobileFull mobileMargin="0">
            <InputColumn>
              <Label>Estudios finalizados:</Label>
              <Select
                name="ended"
                disabled={!prevStudiesFormActive}
                ref={register({ required: true })}
                defaultValue={finishValue}
                mobileMargin="0"
                key={`${finishValue}ended`}
              >
                {finishSelect.map((option) => <option key={option}>{option}</option>)}
              </Select>
            </InputColumn>
          </Column>

          {prevStudiesFormActive
            && (
              <>
                <HSpacer height="1rem" />
                <ButtonPrimary
                  type={3}
                  text="Guardar"
                  htmlType="submit"
                  onClick={Object.keys(errors).length === 0 ? scrollToTop : null}
                  margin="0 0 0 auto"
                />
              </>
            )}

        </SeeMoreCard>

      </ContactForm>
    </>
  );
};

export default PrevStudy;
