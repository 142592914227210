import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import Icons from '../../Icons';


const Container = styled.div`
  color: ${({ theme }) => theme.colors.textInverse};
  padding: ${({ active }) => (active ? '0.8rem 2.5rem 0.8rem 0.5rem' : '0.8rem 2.5rem')} ;
  max-height: 3.5rem;
  margin: 0.5rem;
  border-radius: 2rem;
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: 1.8rem;
  font-weight: 700;
  text-align: center;
  background-color: ${({ active, theme }) => (active ? theme.colors.themePrimary : theme.colors.grey)};
  // display: ${({ active }) => (active ? 'flex' : 'block')};
  display: flex;
  align-items: center;
  transition: all 0.1s ease-in-out;

  &:hover {
    cursor: ${({ active }) => (active ? 'initial' : 'pointer')};
    background-color: ${({ theme }) => theme.colors.themePrimary};
  }
`;

const RemoveIcon = styled.div`
  background-color: ${({ theme }) => theme.colors.themeTertiary};
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  margin-right: 1rem;

  &:hover {
    cursor: pointer;
  }
`;


const FilterTag = ({ title, active, onAdd, onRemove }) => {
  return (
    <Container
      onClick={!active ? () => onAdd(title) : () => {}}
      active={active}
    >
      {
        active && (
          <RemoveIcon onClick={() => onRemove(title)}>
            <Icons
              icon="close"
              width="1.5"
              iconColor="#105285"
              onClick={() => onRemove(title)}
            />
          </RemoveIcon>
        )
      }
      {title}
    </Container>
  );
};

FilterTag.propTypes = {
  title: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default FilterTag;
