import { SET_PROGRESS } from '../actions/progressBarActions';

const defaultState = { count: 0 };

export default function progressBarReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_PROGRESS:
      return { ...state, count: action.payload };
    default:
      return state;
  }
}
