import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import _get from 'lodash/get';
import SingleRow from '../../../common/SingleRow';
import ExperienceToEdit from './ExperienceToEdit';
import ButtonPrimary from '../../../common/Buttons/ButtonPrimary';
import ButtonSecondary from '../../../common/Buttons/ButtonSecondary';
import NewExperience from '../workExperience/NewExperience';
import { studentActions } from '../../../../redux/actions';
import { getId } from '../../helpers';
import useListPublicEntity from '../../../../hooks/useListPublicEntity';

import {
  FullFlexCont,
  ModalMenuP,
  NoIconTitle,
  WhiteSpace,
  Column
} from '../../formStyledComponents';


const ExperienceList = ({
  profile,
  setSelectedCvEdit,
  littleDevice
}) => {

  const dispatch = useDispatch();

  const { data: residences } = useListPublicEntity('residence');

  const [selectedExperience, setSelectedExperience] = useState(null);

  const onSelectField = (field) => {
    setSelectedExperience(field);
  };

  /* add-experiences button */
  const addExperience = (newExperience) => {
    const experiences = [];
    const workExperiences = _get(profile, 'workExperience', []);

    workExperiences.forEach(exp => {
      experiences.push({
        jobName: exp.jobName,
        iniDate: exp.iniDate,
        endDate: exp.endDate,
        workExperienceCountryId: getId(residences, exp.country.nameLang.es),
        city: exp.city,
        desc: exp.desc,
        company: exp.company,
      })
    });

    experiences.push({
      jobName: newExperience.jobName,
      iniDate: newExperience.iniDate,
      endDate: newExperience.endDate,
      workExperienceCountryId: getId(residences, newExperience.workExperienceCountryId),
      city: newExperience.city,
      desc: newExperience.desc,
      company: newExperience.company,
    });

    dispatch(studentActions.save({ workExperience: experiences }));
    setSelectedExperience(null);
  };

  const workExperience = _get(profile, 'workExperience', []);

  return (
    <FullFlexCont padding="0" between alignEnd mobilePadding="0">

      <NoIconTitle width="100%" margin=" 2rem 0" center>EXPERIENCIA LABORAL</NoIconTitle>

      <WhiteSpace mobileHeight="5rem" />

      {
        workExperience.map((experience, i) => {
          const iniDate = _get(experience, 'iniDate', '');
          const endDate = _get(experience, 'endDate', '');
          const country = _get(experience, 'country.nameLang.es', '');
          const city = _get(experience, 'city', '');
          const desc = _get(experience, 'desc', '');
          const company = _get(experience, 'company', '');
          const jobName = _get(experience, 'jobName', '');

          return (
            selectedExperience === company && (
              <ExperienceToEdit
                i={i}
                key={company + jobName}
                iniDate={iniDate}
                endDate={endDate}
                country={country}
                city={city}
                desc={desc}
                company={company}
                jobName={jobName}
                setSelectedExperience={setSelectedExperience}
                profile={profile}
                workExperience={workExperience}
              />
            )
          );
        })
      }

      {!selectedExperience && (
        <>
          {workExperience.map(field => {
            return <ModalMenuP key={field.jobName} onClick={() => onSelectField(field.company)}>{field.jobName} en {field.company}</ModalMenuP>;
          })}

          <SingleRow mobileMargin="0" margin="0" />
        </>
      )}

      {!selectedExperience &&
        (
          <FullFlexCont justify="space-between">
            <Column width="46" padding="0">
              <ButtonSecondary
                text="Atrás"
                onClick={() => setSelectedCvEdit(null)}
                margin="auto"
                fullWidth
              />
            </Column>

            <Column width="46" padding="0">
              <ButtonPrimary
                type={2}
                text="Añadir"
                margin="auto"
                fullWidth
                onClick={() => setSelectedExperience('addExperience')}
              />
            </Column>
          </FullFlexCont>
        )}

      {selectedExperience === 'addExperience' && (
        <NewExperience
          addExperience={addExperience}
          profile={profile}
          littleDevice={littleDevice}
          setSelectedExperience={setSelectedExperience}
          isEditModal
        />
      )}

    </FullFlexCont>
  );
};

export default ExperienceList;

