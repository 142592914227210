import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import CurrentStudy from './CurrentStudy';
import PrevStudies from './prevStudies';
import DigitalExperience from './digitalExperience';
import { SingleRow, DoubleRow, Modal, ProgressBar, Box } from '../../common';
import Experience from './workExperience';
import LangExperience from './languages';
import Skills from './Skills';
import OtherCourses from './otherCourses';
import OtherDocuments from './otherDocuments';
import ButtonSecondary from '../../common/Buttons/ButtonSecondary';
import ButtonPrimary from '../../common/Buttons/ButtonPrimary';
import { EditIcon } from '../../common/Icons/IconsList';
import EditCvModal from './EditCvModal';
import AddCvModal from './AddCvModal';

import {
  WhiteSpace,
  Column,
  BottomButtons,
  MobilePadding,
} from '../formStyledComponents';
import HSpacer from '../../layout/HSpacer/HSpacer';
import CvPreview from './CvPreview/CvPreview';

const Text = styled.p`
  font-weight: bold;
`;

const CvDataForm = ({ completeCount, littleDevice, profile }) => {
  /* get data from profileData for the initial input values */
  const name = _get(profile, 'currentStudy.name', '');
  // const country = _get(profile, 'currentStudy.country.id', '');
  const city = _get(profile, 'currentStudy.city', '');
  const address = _get(profile, 'currentStudy.address', '');
  const cp = _get(profile, 'currentStudy.cp', '');
  const region = _get(profile, 'currentStudy.region', '');
  const campus = _get(profile, 'currentStudy.campus', '');
  const iniDate = _get(profile, 'currentStudy.iniDate', '');
  const endDate = _get(profile, 'currentStudy.endDate', '');
  const gradDate = _get(profile, 'currentStudy.gradDate', '');
  // const ended = _get(profile, 'currentStudy.ended', false);
  const institutionName = _get(profile, 'currentStudy.institutionName', '');
  const universityName = _get(profile, 'university.name', '');

  const tutor = _get(profile, 'tutor.name', '');
  const lastname = _get(profile, 'tutor.lastname', '');
  const email = _get(profile, 'tutor.email', '');
  const phone = _get(profile, 'tutor.phone', '');

  const history = useHistory();

  const [addToCvModalActive, setAddToCvModalActive] = useState(false);
  const [editCvModalActive, setEditCvModalActive] = useState(false);
  const [showCvPreview, setShowCvPreview] = useState(false);

  const onCloseAddModal = () => {
    setAddToCvModalActive(false);
  };

  const onCloseEditModal = () => {
    setEditCvModalActive(false);
  };

  const currentSkills = _get(profile, 'skills', '');

  const scrollToTop = () => (
    window.scrollTo({ top: 0, behavior: 'smooth' })
  );

  if (showCvPreview) {
    return (
      <CvPreview profile={profile} setShowCvPreview={setShowCvPreview} />
    );
  }

  const profileCompleted = completeCount === 100;

  return (
    <>
      <MobilePadding>
        <SingleRow />
      </MobilePadding>

      <HSpacer height="1rem" />
      <MobilePadding>
        <ButtonSecondary text="Ver CV" fullWidth onClick={() => setShowCvPreview(true)} />
      </MobilePadding>
      <HSpacer height="2rem" />

      <CurrentStudy
        profile={profile}
        littleDevice={littleDevice}
        name={name}
        city={city}
        address={address}
        cp={cp}
        region={region}
        campus={campus}
        iniDate={iniDate}
        endDate={endDate}
        gradDate={gradDate}
        institutionName={institutionName}
        tutor={tutor}
        lastname={lastname}
        email={email}
        phone={phone}
        scrollToTop={scrollToTop}
        universityName={universityName}
      />

      <MobilePadding>
        <DoubleRow margin="3rem 0" />
      </MobilePadding>

      <PrevStudies profile={profile} littleDevice={littleDevice} scrollToTop={scrollToTop} />

      <MobilePadding>
        <DoubleRow margin="3rem 0" />
      </MobilePadding>

      <Experience profile={profile} littleDevice={littleDevice} scrollToTop={scrollToTop} />

      <MobilePadding>
        <DoubleRow margin="3rem 0" />
      </MobilePadding>

      <LangExperience profile={profile} littleDevice={littleDevice} />

      <MobilePadding>
        <DoubleRow margin="3rem 0" />
      </MobilePadding>

      <DigitalExperience profile={profile} littleDevice={littleDevice} />

      <MobilePadding>
        <DoubleRow margin="3rem 0" />
      </MobilePadding>

      <MobilePadding>

        <OtherCourses profile={profile} littleDevice={littleDevice} />

        <DoubleRow margin="3rem 0" />
      </MobilePadding>

      <MobilePadding>
        <Skills profile={profile} currentSkills={currentSkills} littleDevice={littleDevice} />
        <DoubleRow margin="3rem 0" />
      </MobilePadding>


      <MobilePadding>
        <OtherDocuments profile={profile} littleDevice={littleDevice} />
      </MobilePadding>

      {profileCompleted && (
        <Box container direction="column" width="100%" justify="center" align="center">
          <ProgressBar progress={completeCount} mobileMargin="1rem 0" />
          <Text>¡Has completado tu perfil!</Text>
          <HSpacer height="1rem" />
          <ButtonPrimary
            iconColor="#105285"
            type={2}
            text="Ir a vacantes"
            fontSize="medium"
            onClick={() => history.push('/vacancy-finder')}
          />
        </Box>
      )}


      {littleDevice
        && (
          <>
            <WhiteSpace mobileHeight="10rem" />

            <BottomButtons justify="space-between">
              <Column width="46">
                <ButtonSecondary
                  text="Añadir +"
                  onClick={() => setAddToCvModalActive(true)}
                  margin="auto"
                  fullWidth
                />
              </Column>

              <Column width="46">
                <ButtonPrimary
                  iconComponent={<EditIcon height="3rem" />}
                  type={2}
                  text="Editar"
                  onClick={() => setEditCvModalActive(true)}
                  margin="auto"
                  fullWidth
                  mobilePadding="0.5rem"
                />
              </Column>
            </BottomButtons>
          </>
        )}

      {addToCvModalActive && (
        <Modal onClose={onCloseAddModal}>
          <AddCvModal
            littleDevice={littleDevice}
            onClose={onCloseAddModal}
            profile={profile}
          />
        </Modal>
      )}

      {editCvModalActive && (
        <Modal onClose={onCloseEditModal}>
          <EditCvModal
            littleDevice={littleDevice}
            onClose={onCloseEditModal}
            profile={profile}
            name={name}
            city={city}
            address={address}
            cp={cp}
            region={region}
            campus={campus}
            iniDate={iniDate}
            endDate={endDate}
            gradDate={gradDate}
            institutionName={institutionName}
            tutor={tutor}
            lastname={lastname}
            email={email}
            phone={phone}
            currentSkills={currentSkills}
          />
        </Modal>
      )}
    </>
  );
};

CvDataForm.propTypes = {
  profile: PropTypes.shape({}).isRequired,
  littleDevice: PropTypes.bool,
};

CvDataForm.defaultProps = {
  littleDevice: false,
};

export default CvDataForm;
