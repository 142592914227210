import styled, { css } from 'styled-components/macro';

export const PersonalForm = styled.form`
  margin-left: 2rem;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    margin: 0 0 2rem 0;
  }
`;

export const WhiteSpace = styled.div`
  width: 100%;
  height: ${({ height }) => height};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    display: ${({ mobileHeight }) => !mobileHeight && 'none'};
    height: ${({ mobileHeight }) => mobileHeight};
  }
`;

export const NoIconTitle = styled.h3`
  font-size: ${({ theme, size }) => theme.fontSizes[size]};
  margin: ${({ margin }) => margin || '0 0 0 2rem'};
  width: ${({ width }) => width};
  text-align: ${({ center }) => center && 'center'}; 

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: ${({ mobileMargin }) => mobileMargin || '0'};
  }

`;

export const SubTitle = styled.p`
  margin: ${({ margin }) => margin || '1rem 0 0 2rem'};
  font-size: 13px;
  line-height: 1.38;
  width: 100%;
`;

export const FullFlexCont = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: ${({ baseline }) => baseline && 'baseline'};
  justify-content: ${({ justify }) => (justify || 'flex-start')};
  padding: ${({ padding }) => padding || '2rem 0'};
  margin: ${({ margin }) => margin || '0'};
  flex-direction: ${({ column }) => column && 'column'};
  text-align: ${({ alignCenter }) => alignCenter && 'center'};

  ${(props) => props.center && css`
    justify-content: center;
  `};

  ${(props) => props.between && css`
    justify-content: space-between;
  `};

  ${(props) => props.alignCenter && css`
    align-items: center;
  `};

  ${(props) => props.alignEnd && css`
    align-items: flex-end;
  `};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    padding: ${({ mobilePadding }) => mobilePadding || '2rem 0'};
    align-items: ${({ mobileAlignCenter }) => mobileAlignCenter && 'center'};
    justify-content: ${({ mobileJustifyCenter }) => mobileJustifyCenter && 'center'};
  }
`;

export const FlexForm = styled.form`
  display: flex;
  width: 100%;
  flex-direction: ${({ column }) => column && 'column'};
  align-items: ${({ center }) => center && 'center'};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    flex-wrap: wrap;
  }
`;

export const ContactForm = styled(FlexForm)`
  flex-wrap: wrap;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ center }) => (center ? 'center' : 'flex-start')};
  flex-wrap: wrap;
  width: ${({ width }) => width}%;
  border-right: ${({ br }) => (br ? '2px solid' : 'none')};
  border-right-color: ${({ theme }) => theme.colors.themeTertiary};
  padding: ${({ padding }) => padding || '1rem 2rem 2rem 1rem'};
  margin: ${({ margin }) => margin || '2rem 0'};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    border-right: none;
    border-top: ${({ br }) => (br ? '2px solid' : 'none')};
    border-bottom: ${({ br }) => (br ? '2px solid' : 'none')};
    width: ${({ mobileFull }) => (mobileFull ? '100%' : '')};
    border-color: ${({ theme }) => theme.colors.themeTertiary};
    padding: 0;
    margin: ${({ mobileMargin }) => mobileMargin || '2rem 0'};
  }
`;

export const P = styled.p`
  margin-bottom: 1rem;
  font-size: ${({ size }) => size}rem;
`;

export const Input = styled.input`
  border: transparent;
  width: ${({ width }) => width || '100%'};
  padding: 0.625rem 1.375rem;
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: ${(props) => props.theme.fontSizes.medium};
  line-height:1.5;
  margin-bottom: ${({ mBottom }) => mBottom || '2rem'};
  border: solid 1px ${(props) => props.theme.colors.textSecondary};
  border-radius: 1.5rem;
  outline: none;
  background: none;
  text-align: ${({ center }) => center && 'center'};

  &:disabled {
    border: none;
    padding-left: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {  
    width: ${({ mobileWidth }) => mobileWidth || 'fit-content'};
    height: ${({ mobileHeight }) => mobileHeight || 'fit-content'};
    margin: ${({ mobileMargin }) => mobileMargin || ''};
    text-align: ${({ mobileCenter }) => mobileCenter && 'center'};
    padding: ${({ mobilePadding }) => mobilePadding};
  }
`;

export const Label = styled.label`
  font-size: 1.8rem;
  font-weight: bold;
  display: inline-block;
  margin: ${({ margin }) => margin || '0.5rem 0'};
  width: ${({ width }) => width};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-right: ${({ mobileMargin }) => mobileMargin || '1rem'};
    width: ${({ mobileWidth }) => mobileWidth};
  }
`;

export const SubLabel = styled.label`
  color: ${(props) => props.theme.colors.themeSecondary};
  display: inline-block;
  margin: 0.5rem 0;
  font-size: 1.7rem;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-right: ${({ mobileMargin }) => mobileMargin || '1rem'};
    width: ${({ mobileWidth }) => mobileWidth};
  }
`;

export const DobleInputDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: ${({ column }) => column && 'column'};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-wrap: wrap;
  }
`;

export const InputColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || '100%'};
  margin: ${({ margin }) => (margin || '0 2rem 0 0')};
  align-items: ${({ alignCenter }) => alignCenter && 'center'};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: ${({ mobileWidth }) => mobileWidth || '100%'};
    margin: ${({ mobileMargin }) => mobileMargin};
  }

  ${(props) => props.mobileRow && css`
    @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
      flex-direction: row;
    }
  `};
  ${(props) => props.mobileAlignCenter && css`
    @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
      align-items: center;
    }
  `};

`;

export const CheckboxDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

export const InputCheckbox = styled.input`
  width: auto;
  margin: 1rem;
`;

export const CBLabel = styled.label`
  margin: 1rem 0;
`;

export const Error = styled.span`
  color: red;
  display: flex;
  padding: 0.5rem;
`;

export const Select = styled.select`
  border: transparent;
  width: ${({ width }) => width || '100%'};
  padding: 0.625rem 1.375rem;
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: ${(props) => props.theme.fontSizes.medium};
  line-height:1.5;
  margin-bottom:${(props) => props.mBottom}rem;
  border: solid 1px ${(props) => props.theme.colors.textSecondary};
  border-radius: 1.5rem;
  outline: none;
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  background: none;

  &:disabled {
    border: none;
    padding-left: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: ${({ mobileWidth }) => mobileWidth};
  }

`;

export const CardContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ padding }) => padding || '2rem 4rem'};
  flex-wrap: wrap;
  text-align: ${({ alignCenter }) => alignCenter && 'center'};
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    text-align: ${({ mobileCenter }) => mobileCenter && 'center'};
    padding: ${({ mobilePadding }) => mobilePadding || '2rem'};
  }
`;

export const BlueInput = styled(Input)`
  color: ${(props) => props.theme.colors.themeSecondary};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  margin-left: 2rem;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-left: 0;
    margin-bottom: 0;
    width: ${({ mobileWidth }) => (mobileWidth || '100%')};
  }
`;

export const DisabledBlueInput = styled(BlueInput)`
  margin: ${({ margin }) => margin || '0 0 1rem 0'};
  padding: 0;
  border: none;
`;

export const EditDeleteIconsCont = styled(FullFlexCont)`
  width: fit-content;
  margin-left: auto;
  margin-right: 2rem;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 0;
    margin-right: 0;
  }

`;

export const InputName = styled.input`
  border: transparent;
  width: ${({ width }) => width || '100%'};
  padding: 0.625rem 1.375rem;
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: ${(props) => props.theme.fontSizes.medium};
  line-height:1.5;
  margin-bottom: ${({ mBottom }) => mBottom || '2rem'};
  margin-left: 2rem;
  border: solid 1px ${(props) => props.theme.colors.textSecondary};
  border-radius: 1.5rem;
  outline: none;
  background: none;
  text-align: ${({ center }) => center && 'center'};

  &:disabled {
    border: none;
    padding-left: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {  
    width: ${({ mobileWidth }) => mobileWidth || 'fit-content'};
    height: ${({ mobileHeight }) => mobileHeight || 'auto'};
    margin: ${({ mobileMargin }) => mobileMargin || ''};
    text-align: ${({ mobileCenter }) => mobileCenter && 'center'};
    padding: ${({ mobilePadding }) => mobilePadding};
  }
`;

export const BeforeTitle = styled.p`
  position: absolute;
  top: 34%;
  left: -45px;
  font-weight: bold;
`;

export const TextArea = styled.textarea`
  border: transparent;
  width: ${({ width }) => width || '100%'};
  padding: 0.625rem 1.375rem;
  color: ${(props) => props.theme.colors.textPrimary};
  font-family: ${(props) => props.theme.fonts[1]};
  font-size: ${(props) => props.theme.fontSizes.medium};
  line-height:1.5;
  margin-bottom: ${({ mBottom }) => mBottom || '2rem'};
  border: solid 1px ${(props) => props.theme.colors.textSecondary};
  border-radius: 1.5rem;
  outline: none;
  background: none;
  resize: none;

  &:disabled {
    border: none;
    padding-left: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {  
    padding: 1rem;
    width: ${({ mobileWidth }) => mobileWidth || '100%'};
  }
`;

export const InputSubmit = styled.input`
  color: ${(props) => props.theme.colors.themeSecondary};
  background: none;
  border: none;
  outline: none;
  margin-left: auto;
  margin-top: 2rem;
  margin-right: 2rem;
  cursor: pointer;
  font-size: 1.5rem;
  text-decoration: underline;
`;

export const ModalsLogo = styled.img`
  object-fit: cover;
  object-position: 50% 50%;
  width: 85px;
  position: absolute;
  top: 2rem;
  left: 2rem;
`;

export const BottomButtons = styled(FullFlexCont)`
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;
  padding: 0 2rem;
  border-top: 2px solid lightgrey;
`;

export const ModalMenuP = styled.p`
  font-size: 1.8rem;
  padding: 2rem 0;
  width: 100%;
  border-top: 2px solid ${({ theme }) => theme.colors.themeTertiary};
  cursor: pointer;
`;

export const MobilePadding = styled.div`
  display: ${({ flex }) => flex && 'flex'};
  padding: 0;
  margin: 0;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {  
    height: ${({ mdheight }) => mdheight};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {  
    padding: 0 2rem;
    flex-direction: column;
  }
`;
