import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import _get from 'lodash/get';
import SingleRow from '../../../common/SingleRow';
import LangToEdit from './LangToEdit';
import ButtonPrimary from '../../../common/Buttons/ButtonPrimary';
import ButtonSecondary from '../../../common/Buttons/ButtonSecondary';
import NewLang from '../languages/NewLang';
import { studentActions } from '../../../../redux/actions';
import { levels } from '../../helpers';

import {
  FullFlexCont,
  ModalMenuP,
  NoIconTitle,
  WhiteSpace,
  Column,
} from '../../formStyledComponents';
import { translateLevel } from '../../helpers/constants';


const LanguageList = ({
  profile,
  setSelectedCvEdit,
  littleDevice,
}) => {
  const dispatch = useDispatch();

  const [selectedLang, setSelectedLang] = useState(null);

  const onSelectField = (field) => {
    setSelectedLang(field);
  };

  /* add-prev-studies button */
  const addLang = (data) => {
    const languages = [];
    const languageExperience = _get(profile, 'languageExperience', []);

    languageExperience.forEach((lang) => {
      languages.push(lang);
    });

    languages.push(data);
    dispatch(studentActions.save({ languageExperience: languages }));
    setSelectedLang(null);
  };

  const languageExperience = _get(profile, 'languageExperience', []);

  return (
    <FullFlexCont padding="0" between alignEnd mobilePadding="0">

      <NoIconTitle width="100%" margin=" 2rem 0" center>IDIOMAS</NoIconTitle>

      <WhiteSpace mobileHeight="5rem" />

      {
        languageExperience.map((lang, i) => {
          const languageName = _get(lang, 'languageName', '');
          const speak = _get(lang, 'speak', '');
          const write = _get(lang, 'write', '');
          const understand = _get(lang, 'understand', '');

          return (
            selectedLang === languageName && (
              <LangToEdit
                key={languageName}
                languageName={languageName}
                speak={translateLevel(speak)}
                write={translateLevel(write)}
                understand={translateLevel(understand)}
                setSelectedLang={setSelectedLang}
                profile={profile}
                levels={levels}
              />
            )
          );
        })
      }

      {!selectedLang && (
        <>
          {languageExperience.map((field) => <ModalMenuP key={field.languageName} onClick={() => onSelectField(field.languageName)}>{field.languageName}</ModalMenuP>)}

          <SingleRow mobileMargin="0" margin="0" />
        </>
      )}

      {!selectedLang
        && (
          <FullFlexCont justify="space-between">
            <Column width="46" padding="0">
              <ButtonSecondary
                text="Atrás"
                onClick={() => setSelectedCvEdit(null)}
                margin="auto"
                fullWidth
              />
            </Column>

            <Column width="46" padding="0">
              <ButtonPrimary
                type={2}
                text="Añadir"
                margin="auto"
                fullWidth
                onClick={() => setSelectedLang('addLang')}
              />
            </Column>
          </FullFlexCont>
        )}

      {selectedLang === 'addLang' && (
        <NewLang
          addLang={addLang}
          profile={profile}
          littleDevice={littleDevice}
          setSelectedLang={setSelectedLang}
          levels={levels}
          langExperience={_get(profile, 'languageExperience', [])}
          isEditModal
        />
      )}

    </FullFlexCont>
  );
};

export default LanguageList;

