import React from 'react';
import PropTypes from 'prop-types';

import { dates } from '../../../utils';


const DatesOpen = ({ datesOpen }) => {
  if (!datesOpen || datesOpen === null || datesOpen && datesOpen.length <= 0) return <span>Abierto todo el año</span>;

  return (
    <>
      {
        datesOpen.map((el, key) => {
          const ini = dates.getDateText(el.dateIni); // mm-dd
          const end = dates.getDateText(el.dateEnd); // mm-dd
          return (
            <React.Fragment key={key}>
              <span>
                Del
                {' '}
                {ini}
                {' '}
                al
                {' '}
                {end}
              </span>
              <br />
            </React.Fragment>
          );
        })
      }
    </>
  );
};

DatesOpen.propTypes = {
  datesOpen: PropTypes.array.isRequired,
};

export default DatesOpen;
