import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ButtonIcon } from '../common';
import Icons from '../common/Icons';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const UserImg = styled.img`
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 100%;
  width: 4rem;
  height: 4rem;
  margin-right: 0.3125rem;
`;

const UserName = styled.p`
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: ${(props) => props.theme.fontSizes.medium};
  margin-left: 1rem;
`;

const UserContainer = ({ userImg = '', userName = '', onClick }) => (
  userName
    ? (
      <FlexWrapper>

        {
          userImg && userImg !== null ?
            (
              <UserImg src={userImg} alt="user Img" />
            ) : (
              <Icons
                icon='avatar'
                width="4"
                height="4"
              />
            )
        }

        <UserName>{userName}</UserName>
        <ButtonIcon width={2} icon="logout" onClick={onClick} />
      </FlexWrapper>
    )
    : (
      <Link to="/login">
        <FlexWrapper>
          <ButtonIcon width={2} icon="user" iconColor="themeSecondary" bgColor="grey" />
          <UserName>Iniciar sesión</UserName>
        </FlexWrapper>
      </Link>
    )
);

export default UserContainer;