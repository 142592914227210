import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Icons from '../../Icons';
import TagDepartamento from '../../Tags/TagDepartamento';


const Container = styled.div`
  margin: 2.3rem 0;
  box-shadow: ${(props) => props.theme.shadows.shadow1};
  border-radius: ${(props) => props.theme.borderRadius.card};
  display:flex;
  justify-content: space-between;
  flex-wrap:wrap;
`;

const Part = styled.div`
  display: flex;
`;

const PartIcon = styled.div`
  padding: 3rem;
  display:flex;
  justify-content:center;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 2rem;
  }
`;

const PartFluid = styled.div`
  width: calc(100% - 12rem);
  padding: 2rem 2.7rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: calc(100% - 10rem);
    padding: 2rem;
  }
`;

const LineBetweenMobile = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      display: block;
  }
`;

const VerticalSeparator = styled.div`
  width: 2px;
  background-color: ${(props) => props.theme.colors.themeTertiary};
`;

const Title = styled.p`
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: ${(props) => props.theme.fontSizes.medium};
  font-weight: 700;
  line-height: 1.5;
`;

const Money = styled.p`
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: ${(props) => props.theme.fontSizes.titleSmall};
  line-height: 1.5;
`;

const Text = styled.span`
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: ${(props) => props.theme.fontSizes.medium};
  line-height: 1.5;
`;

const TagContainer = styled.div`
  width: 15rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
    margin-top: 1rem;
  }
`;

const IconCard = styled.div`
  cursor: pointer;
  display:flex;
  justify-content:center;
  align-items:center;
  height: 5.3rem;
  width: 5.3rem;
  background-color: ${({ theme, active }) => (active ? theme.colors.themePrimary : theme.colors.themeInverse)};
  border-radius: 100%;
  border: 2px solid ${(props) => props.theme.colors.themePrimary};
  transition: all 0.1s ease-in-out;
  
  svg {
    padding-left: 0.5rem;
    margin-left: 6px;
    fill: ${({ active, theme }) => (active ? theme.colors.themeInverse : theme.colors.themePrimary)};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.themePrimary};
    svg {
      fill: ${(props) => props.theme.colors.themeInverse};
    }
  }
`;

const IconBlock = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  height: 5.3rem;
  width: 5.3rem;
  background-color: ${(props) => props.theme.colors.grey};
  border-radius: 100%;
`;

const statusDictionary = {
  pending: { tagText: 'Pendiente', tagColor: 'alertError' },
  inReview: { tagText: 'En revisión', tagColor: 'textPrimary' },
  error: { tagText: 'Error', tagColor: 'alertNotification' },
  done: { tagText: 'Pagado', tagColor: 'alertAccepted' },
};

const CardPagos = (props) => {
  const {
    title, money, date, onClick, status = 'pending', active, setIsOpen, currencySymbol,
  } = props;

  const { tagText, tagColor } = statusDictionary[status];

  return (
    <Container>
      <PartFluid>
        <LineBetweenMobile>
          <div>
            <Title>{title}</Title>
            <Money>{money}{currencySymbol}   </Money>
            {title !== 'Seguro' && (
              <div>
                <Text>Necesario antes de:</Text>
                &nbsp;
                <Text>{date}</Text>
              </div>
            )}
          </div>
          <TagContainer>
            <TagDepartamento title={tagText} bgColor={tagColor} full small />
          </TagContainer>
        </LineBetweenMobile>
      </PartFluid>

      <Part><VerticalSeparator /></Part>

      <PartIcon>
        {
          status === 'done' ? (
            <IconBlock>
              <Icons icon="bloqueo" width={3} iconColor="white" />
            </IconBlock>
          ) : (
            <IconCard
              onClick={() => {
                setIsOpen(true);
                onClick();
              }}
              active={active}
            >
              <Icons icon="check-blue" large iconColor="themePrimary" />
            </IconCard>
          )
        }
      </PartIcon>
    </Container>
  );
};

CardPagos.propTypes = {
  active: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  money: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

export default CardPagos;
