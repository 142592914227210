import React from 'react';
import { useForm } from 'react-hook-form';
import ButtonPrimary from '../../../common/Buttons/ButtonPrimary';
import ButtonSecondary from '../../../common/Buttons/ButtonSecondary';

import {
  Input,
  Label,
  InputColumn,
  ContactForm,
  CardContentWrapper,
  Select,
  WhiteSpace,
  FullFlexCont,
  Column,
} from '../../formStyledComponents';
import { languages } from '../../helpers/constants';

const NewLang = ({
  addLang,
  levels,
  littleDevice,
  setSelectedLang,
  isAddModal,
  setSelectedCvAdd,
  langExperience,
  isEditModal,
}) => {
  const { register, handleSubmit } = useForm();

  const onSubmit = (data, e) => {
    e.preventDefault();
    if (langExperience.length < 5) {
      addLang(data);
    } else {
      window.alert('Puedes añadir un máximo de 5 experiencias a esta sección');
    }
  };

  return (
    <CardContentWrapper mobilePadding="0" padding={isAddModal || isEditModal && '0'}>
      <ContactForm onSubmit={handleSubmit(onSubmit)}>

        <FullFlexCont padding="0" between mobilePadding="0">
          <InputColumn
            width={isAddModal || isEditModal ? '100%' : '27%'}
            margin="0 3rem 0 0"
            mobileMargin="0"
            alignCenter={littleDevice}
          >
            <Label>Idioma:</Label>
            <Select
              name="languageName"
              ref={register({ required: true })}
              mobileWidth="80%"
              mobileMargin="0"
            >
              <option key="default">Idioma</option>
              {languages.map((op) => <option key={op} value={op}>{op}</option>)}
            </Select>
          </InputColumn>
        </FullFlexCont>

        <WhiteSpace height="2rem" />

        <FullFlexCont padding="0" between mobilePadding="0">
          <InputColumn
            width={isAddModal || isEditModal ? '100%' : '27%'}
            alignCenter={littleDevice}
            mobileMargin="0"
          >
            <Label>Hablado:</Label>
            <Select
              width="100%"
              mBottom="0"
              name="speak"
              ref={register({ required: true })}
              mobileWidth="80%"
              mobileMargin="0"
            >
              <option key="default">nivel hablado</option>
              {levels.map((op) => <option key={op.value} value={op.value}>{op.label}</option>)}
            </Select>
          </InputColumn>

          <InputColumn
            width={isAddModal || isEditModal ? '100%' : '27%'}
            alignCenter={littleDevice}
            mobileMargin="0"
          >
            <Label>Escrito:</Label>
            <Select
              width="100%"
              mBottom="0"
              name="write"
              ref={register({ required: true })}
              mobileWidth="80%"
              mobileMargin="0"
            >
              <option key="default">nivel escrito</option>
              {levels.map((op) => <option key={op.value} value={op.value}>{op.label}</option>)}
            </Select>
          </InputColumn>

          <InputColumn
            width={isAddModal || isEditModal ? '100%' : '27%'}
            alignCenter={littleDevice}
            mobileMargin="0"
          >
            <Label>Comprensión:</Label>
            <Select
              width="100%"
              mBottom="0"
              name="understand"
              ref={register({ required: true })}
              mobileWidth="80%"
              mobileMargin="0"
            >
              <option key="default">comprensión</option>
              {levels.map((op) => <option key={op.value} value={op.value}>{op.label}</option>)}
            </Select>
          </InputColumn>
        </FullFlexCont>

        {!littleDevice && (
          <>
            <WhiteSpace height="4rem" />

            <ButtonPrimary
              iconColor="#105285"
              type={2}
              text="Guardar datos"
              htmlType="submit"
              margin="0 0 0 auto"
            />

            <WhiteSpace height="4rem" />
          </>
        )}

        {littleDevice && (
          <FullFlexCont justify="space-between">

            <WhiteSpace height="1rem" />

            <Column width="46" padding="0">
              <ButtonSecondary
                text="Atrás"
                onClick={!isAddModal ? () => setSelectedLang(null) : () => setSelectedCvAdd(null)}
                margin="auto"
                fullWidth
              />
            </Column>

            <Column width="46" padding="0">
              <ButtonPrimary
                type={2}
                text="Guardar"
                htmlType="submit"
                margin="auto"
                fullWidth
              />
            </Column>

          </FullFlexCont>
        )}

      </ContactForm>
    </CardContentWrapper>
  );
};

export default NewLang;
