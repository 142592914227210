import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MenuItems from '../MenuItems';


const MobileAsideMenuContainer = styled.div`
  transition: all ease-in-out 500ms;
  opacity: 1;
  background-color: #fff;
  transform: ${({ show }) => (show === 'true' ? 'translateX(-0%)' : 'translateX(100%)')};
  z-index: 20;
  position: fixed;
  bottom: 0%;
  left: 0;
  right: 0;
  padding: 1rem 4rem;
  height: 91%;
  overflow: scroll;
`;

const MenuContainer = styled.div`
  text-align: center;
  padding: 4rem;
`;

const Logo = styled.img`
  object-fit: cover;
  object-position: 50% 50%;
  width: 85px;
`;

const BlueLine = styled.div`
  width: 100%;
  height: 2px;
  margin-top: 2.5rem;
  background-color: ${({ theme }) => theme.colors.themeTertiary};
`;

const MobileAsideMenu = ({ isLogged, notificationsNumber }) => {
  const show = useSelector((state) => state.showBurgerMenu.value);

  return (
    <MobileAsideMenuContainer show={show.toString()}>

      <MenuContainer>
        <Link to="/menu">
          <Logo src="/images/logo.jpg" alt="Nexus logo" />
        </Link>
        <BlueLine />
        <MenuItems isLogged={isLogged} notificationsNumber={notificationsNumber} />
      </MenuContainer>

    </MobileAsideMenuContainer>
  );
};

export default MobileAsideMenu;
