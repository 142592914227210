import React, { useContext } from 'react';
import styled from 'styled-components/macro';

import { ThemeContext } from 'styled-components';
import Icons from '../../Icons';


const Container = styled.button`
  outline: none;
  cursor: ${({ disabled }) => (!disabled && 'pointer')};
  border-radius: 3rem;
  border: transparent;
  border: solid 2px ${({ bgColor }) => bgColor};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  background-color: ${({ theme }) => theme.colors.textInverse};
  color: ${({ TextColor }) => TextColor};
  font-size: ${({ theme, fontSize }) => theme.fontSizes[fontSize]};
  font-weight: 700;
  padding: 1rem  1.5rem;
  transition: background-color 0.1s ease-in-out, border-color  0.1s ease-in-out;

  svg {
    stroke: ${({ TextColor, stroke }) => (stroke ? TextColor : 'none')};
    fill: ${({ TextColor, fill }) => (fill ? TextColor : 'none')};
  }

  &:hover{
      background-color: ${({ disabled, bgColor }) => (!disabled && bgColor)};
      border: solid 2px ${({ disabled, bgColor }) => (!disabled && bgColor)};
      color: ${({ disabled, theme }) => (!disabled && theme.colors.textInverse)};

      svg {
        stroke: ${({ theme, stroke }) => (stroke ? theme.colors.textInverse : 'none')};
        fill: ${({ theme, fill }) => (fill ? theme.colors.textInverse : 'none')};
      }
  }
  
  &.favourite{
    background-color: ${({ disabled, bgColor }) => (!disabled && bgColor)};
      border: solid 2px ${({ disabled, bgColor }) => (!disabled && bgColor)};
      color: ${({ disabled, theme }) => (!disabled && theme.colors.textInverse)};

      svg {
        stroke: ${({ theme, stroke }) => (stroke ? theme.colors.textInverse : 'none')};
        fill: ${({ theme, fill }) => (fill ? theme.colors.textInverse : 'none')};
      }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {   
    padding: ${({ mobilePadding }) => (mobilePadding)};
    width: ${({ mobileWidth }) => (mobileWidth)};
  }
`;

const ButtonSecondary = ({
  text,
  type = 0,
  fullWidth = false,
  onClick,
  fontSize = 'large',
  icon,
  stroke,
  fill,
  htmlType = 'button',
  disabled = false,
  mobilePadding,
  mobileWidth,
  insideFavourites,
}) => {
  const themeContext = useContext(ThemeContext);

  let TextColor;
  let bgColor;
  switch (type) {
    case 1:
      TextColor = (props) => props.theme.colors.greyDark;
      bgColor = (props) => props.theme.colors.greyDark;
      break;

    case 2:
      TextColor = (props) => props.theme.colors.themeSecondary;
      bgColor = (props) => props.theme.colors.themeSecondary;
      break;

    case 3:
      TextColor = (props) => props.theme.colors.textInverse;
      bgColor = (props) => props.theme.colors.textInverse;
      break;

    default:
      TextColor = (props) => props.theme.colors.themePrimary;
      bgColor = (props) => props.theme.colors.themePrimary;
      break;
  }

  return (
    <Container
      TextColor={disabled ? themeContext.colors.grey : TextColor}
      bgColor={disabled ? themeContext.colors.grey : bgColor}
      fullWidth={fullWidth}
      onClick={onClick}
      fontSize={fontSize}
      stroke={stroke}
      fill={fill}
      type={htmlType}
      disabled={disabled}
      mobilePadding={mobilePadding}
      mobileWidth={mobileWidth}
      insideFavourites={insideFavourites}
      className={insideFavourites ? 'favourite' : null}
    >
      {icon && <Icons icon={icon} width={3} />}
      {text && text}
    </Container>
  );
};

export default ButtonSecondary;
