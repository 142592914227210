/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import IconComponent from './IconComponent';


const AddIcon = (props) => (
  <IconComponent
    iconCode={(
      <g>
        <path d="M37.194 18.684L37.194 21.234 2.725 21.234 2.725 18.684z" />
        <path d="M21.234 2.725L21.234 37.194 18.684 37.194 18.684 2.725z" />
      </g>
      )}
    viewBox="0 0 40 40"
    width="4"
    height="4"
    {...props}
  />
);

const ArrowBackIcon = (props) => (
  <IconComponent
    iconCode={(
      <path d="M39.5 6.5L39.5 32 36.5 32 36.5 9.5 14 9.5 14 6.5z" transform="rotate(-135 26.75 19.25)" />
    )}
    viewBox="0 0 40 40"
    width="4"
    height="4"
    {...props}
  />
);

const AvatarIcon = (props) => (
  <IconComponent
    iconCode={(
      <g fill="none" fillRule="evenodd">
        <circle cx="20" cy="20" r="17" fill="#A4B3B7" />
        <circle cx="20" cy="14.632" r="4.474" fill="#FFF" />
        <path fill="#FFF" d="M20 22.326c1.24 0 2.357-.545 3.128-1.414 1.78.164 6.714 1.505 6.714 2.846v4.295H10.158v-4.295c1.875-1.909 3.75-2.863 5.624-2.863h1.075c.772.879 1.894 1.431 3.143 1.431z" />
      </g>
    )}
    viewBox="0 0 40 40"
    width="4"
    height="4"
    {...props}
  />
);

const UserIcon = (props) => (
  <IconComponent
    iconCode={(
      <g fillRule="evenodd">
        <circle cx="20.377" cy="12.643" r="7.643" />
        <path d="M26 24c5.944 0 11 2 11 4.45v5.548c0 .552-.448 1-1 1h-.002l-31-.078c-.552 0-.998-.448-.998-1v-5.553C4 25.734 10.32 24 15 24h.146c1.285 1.34 3.153 2.184 5.231 2.184s3.946-.844 5.23-2.183L26 24z" />
      </g>
    )}
    viewBox="0 0 40 40"
    width="4"
    height="4"
    {...props}
  />
);

const BloqueoIcon = (props) => (
  <IconComponent
    iconCode={(
      <g fillRule="evenodd" stroke="none">
        <path fillRule="nonzero" d="M33 15.526V37H7V15.526h26zm-2 2H9V35h22V17.526z" />
        <path fillRule="nonzero" d="M19.579 3c5.523 0 10 4.477 10 10v4h-20v-4c0-5.523 4.477-10 10-10zm0 2c-4.335 0-7.865 3.448-7.996 7.75l-.004.25-.001 2h16v-2c0-4.335-3.447-7.865-7.75-7.996L19.578 5z" />
        <circle cx="20.316" cy="24.474" r="3.579" />
        <path d="M18.526 26.263H22.105V31.631H18.526z" />
      </g>
    )}
    viewBox="0 0 40 40"
    width="4"
    height="4"
    {...props}
  />
);

const CheckIcon = (props) => (
  <IconComponent
    iconCode={(
      <path d="M36.036 8.033L37.964 10.331 13.739 30.661 2.949 20.071 5.051 17.929 13.896 26.611z" />
    )}
    viewBox="0 0 40 40"
    width="4"
    height="4"
    {...props}
  />
);

const CloseIcon = (props) => (
  <IconComponent
    iconCode={(
      <g>
        <path d="M35.834 2.197L37.638 4 4 37.638 2.197 35.834z" />
        <path d="M2.197 4.014L4 2.211 37.638 35.849 35.834 37.652z" />
      </g>
    )}
    viewBox="0 0 40 40"
    width="4"
    height="4"
    {...props}
  />
);

const DocumentsIcon = (props) => (
  <IconComponent
    iconCode={(
      <g fill="none" fillRule="evenodd" strokeWidth="2.06">
        <path d="M17.59 25.152c0 1.422 1.137 2.575 2.54 2.575 1.404 0 2.541-1.153 2.541-2.575M27.753 8.152C27.753 5.306 25.478 3 22.67 3c-2.806 0-5.081 2.306-5.081 5.152M12.508 28.758c0 4.267 3.413 7.727 7.622 7.727 4.21 0 7.623-3.46 7.623-7.727M22.671 9.182L22.671 25.667M27.753 8.152L27.753 28.758M17.59 8.152L17.59 25.667M12.508 16.394L12.508 28.758" />
      </g>
    )}
    viewBox="0 0 40 40"
    width="4"
    height="4"
    {...props}
  />
);

const EditIcon = (props) => (
  <IconComponent
    iconCode={(
      <g fillRule="evenodd" stroke="none">
        <path fillRule="nonzero" d="M27.448 13.09V36.54H4V13.09h23.448zm-1.6 1.6H5.6v20.248h20.248V14.69z" />
        <path d="M34.223 3.461L15.785 21.9l-1.459 4.775 4.775-1.459L37.54 6.777l-3.316-3.316z" />
      </g>
    )}
    viewBox="0 0 40 40"
    width="4"
    height="4"
    {...props}
  />
);

const FindVacancyIcon = (props) => (
  <IconComponent
    iconCode={(
      <g stroke="none">
        <path d="M44.718 17.292c1.092-1.397 1.741-3.153 1.741-5.064C46.46 7.683 42.776 4 38.23 4 33.685 4 30 7.683 30 12.228s3.684 8.228 8.23 8.228c1.91 0 3.667-.648 5.064-1.74 3.463 3.755 5.317 5.509 5.563 5.261l1.12-1.12c.247-.247-1.505-2.103-5.259-5.565zm-6.488 0c-2.798 0-5.065-2.263-5.065-5.064 0-2.797 2.263-5.063 5.065-5.063 2.797 0 5.064 2.262 5.064 5.063 0 2.797-2.263 5.064-5.064 5.064zM27 4H3v32h24V4zm-2 2v28H5V6h20z" />
        <path d="M21.5 11.5L21.5 12.5 8.5 12.5 8.5 11.5zM21.5 18.5L21.5 19.5 8.5 19.5 8.5 18.5zM21.5 26.5L21.5 27.5 8.5 27.5 8.5 26.5z" />
      </g>
    )}
    viewBox="0 0 54 40"
    width="4"
    height="4"
    {...props}
  />
);

const ProcessesIcon = (props) => (
  <IconComponent
    iconCode={(
      <path stroke="none" d="M41.318 10.493l-3.422-3.347C37.14 6.412 36.116 6 35.05 6H18.749c-1.064 0-2.092.415-2.846 1.146l-3.422 3.347H4v17.979h4.6c1.265 0 2.286-.999 2.293-2.229h.654l6.08 5.372c2.221 1.765 5.305 1.807 7.59.267.899.76 1.87 1.118 2.955 1.118 1.308 0 2.537-.52 3.507-1.688 1.588.612 3.464.183 4.6-1.18l1.883-2.272c.403-.485.654-1.04.784-1.617h4.161c.007 1.23 1.035 2.229 2.293 2.229H50V10.493h-8.682zm-6.444 15.465l-1.898 2.346c-.204.247-.568.291-.822.087l-1.738-1.413-2.182 2.659c-.437.531-1.091.35-1.31.175l-2.676-2.295-1.135 1.399c-1.01 1.245-2.85 1.435-4.022.48l-7.076-6.41H10v-9.331h3.047l4.488-4.487c.145-.059.269-.11.415-.168h4.123l-2.814 2.586c-2.139 1.96-2.262 5.267-.32 7.38 1.076 1.18 4.45 3 7.382.32l.596-.547 7.87 6.396c.247.204.283.575.087.823zM43 22h-4.91c-.164-.19-.348-.366-.547-.522l-7.287-5.65.887-.772c.461-.406.496-1.09.07-1.53l-.773-.793c-.426-.44-1.142-.468-1.603-.068l-3.917 3.428c-.675.59-1.824.637-2.456 0-.66-.67-.603-1.7.086-2.297l4.654-4.071c.526-.46 1.207-.711 1.916-.711L35.06 9c.149 0 .29.054.39.156l4.378 4.173H43V22zm4 4c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1c0 .556-.45 1-1 1z" />
    )}
    viewBox="0 0 54 40"
    width="4"
    height="4"
    {...props}
  />
);

const LocationIcon = (props) => (
  <IconComponent
    iconCode={(
      <g fillRule="evenodd">
        <path d="M20 37c10-7.852 15-14.413 15-19.684C35 9.409 28.284 3 20 3 11.716 3 5 9.41 5 17.316 5 22.586 10 29.148 20 37z" />
        <circle cx="20" cy="16.947" r="5" fill="#FFF" />
      </g>
    )}
    viewBox="0 0 54 40"
    width="4"
    height="4"
    {...props}
  />
);

const CardPaymentIcon = (props) => (
  <IconComponent
    iconCode={(
      <g fillRule="evenodd" stroke="none">
        <path fillRule="nonzero" d="M47 7c1.105 0 2 .895 2 2v23c0 1.105-.895 2-2 2H8c-1.105 0-2-.895-2-2V9c0-1.105.895-2 2-2h39zm0 1.6H8c-.193 0-.355.137-.392.32L7.6 9v23c0 .193.137.355.32.392L8 32.4h39c.193 0 .355-.137.392-.32L47.4 32V9c0-.193-.137-.355-.32-.392L47 8.6z" />
        <path d="M6 12H49V18H6zM11 21H28V24H11z" />
      </g>
    )}
    viewBox="0 0 54 40"
    width="4"
    height="4"
    {...props}
  />
);

const TransferPaymentIcon = (props) => (
  <IconComponent
    iconCode={(
      <g fillRule="evenodd">
        <path fillRule="nonzero" stroke="none" d="M50 6c.552 0 1 .448 1 1v26c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1V7c0-.552.448-1 1-1h46zm-.6 1.599H4.6v24.8h44.8v-24.8z" />
        <path stroke="none" d="M9 12H46V15H9zM9 17H31V20H9zM9 22H25V25H9zM34 26H46V29H34z" />
      </g>
    )}
    viewBox="0 0 54 40"
    width="4"
    height="4"
    {...props}
  />
);

const BankPaymentIcon = (props) => (
  <IconComponent
    iconCode={(
      <g fillRule="evenodd" stroke="none">
        <path fillRule="nonzero" d="M18.95 2.276l-14.28 13.6c-.653.622-.213 1.724.69 1.724h28.56c.903 0 1.343-1.102.69-1.724l-14.28-13.6c-.387-.368-.993-.368-1.38 0zm.69 2.105L31.42 15.6H7.859L19.64 4.381z" />
        <rect width="31.28" height="2.72" x="4" y="34.28" rx="1" />
        <rect width="2.72" height="16.32" x="8.08" y="19.32" rx="1" />
        <rect width="2.72" height="16.32" x="14.88" y="19.32" rx="1" />
        <rect width="2.72" height="16.32" x="21.68" y="19.32" rx="1" />
        <rect width="2.72" height="16.32" x="28.48" y="19.32" rx="1" />
      </g>
    )}
    viewBox="0 0 40 40"
    width="4"
    height="4"
    {...props}
  />
);

const PracticesIcon = (props) => (
  <IconComponent
    iconCode={(
      <g fillRule="evenodd">
        <circle cx="17.331" cy="20.474" r="3.405" strokeWidth="1.66" />
        <path strokeLinejoin="round" strokeWidth="1.66" d="M17.33 27.77c.92 0 1.748-.37 2.32-.96 1.32.11 4.977 1.022 4.977 1.933v2.918h0-14.593v-2.918c1.39-1.297 2.78-1.946 4.17-1.946H15c.572.597 1.405.973 2.33.973z" />
        <path fillRule="nonzero" stroke="none" d="M51.069 9v28.138H3V9h48.069zm-2.417 2.416H5.416v23.305h43.236V11.416z" />
        <path fillRule="nonzero" stroke="none" d="M30.62 3c.419 0 .76.34.76.76v5.514c0 .42-.341.76-.76.76h-7.86c-.42 0-.76-.34-.76-.76V3.76c0-.42.34-.76.76-.76h7.86zm-.898 1.656h-6.066v3.721h6.066V4.656zM43.644 15.654c.457 0 .828.371.828.829 0 .422-.316.77-.724.822l-.104.006H30.356c-.457 0-.828-.37-.828-.828 0-.423.316-.771.724-.822l.104-.007h13.288zM43.644 20.344c.457 0 .828.37.828.828 0 .423-.316.771-.724.822l-.104.007H30.356c-.457 0-.828-.371-.828-.829 0-.422.316-.77.724-.822l.104-.006h13.288zM43.644 25.034c.457 0 .828.37.828.828 0 .422-.316.77-.724.822l-.104.006H30.356c-.457 0-.828-.37-.828-.828 0-.422.316-.77.724-.822l.104-.006h13.288z" />
      </g>
    )}
    viewBox="0 0 54 40"
    width="4"
    height="4"
    {...props}
  />
);

const ReviewIcon = (props) => (
  <IconComponent
    iconCode={(
      <g fillRule="evenodd">
        <path fillRule="nonzero" d="M17.044 4.178c7.659 0 13.867 6.208 13.867 13.866 0 7.659-6.208 13.867-13.867 13.867-7.658 0-13.866-6.208-13.866-13.867 0-7.658 6.208-13.866 13.866-13.866zm0 2.5c-6.277 0-11.366 5.089-11.366 11.366 0 6.278 5.089 11.367 11.366 11.367 6.278 0 11.367-5.089 11.367-11.367 0-6.277-5.089-11.366-11.367-11.366z" />
        <circle cx="17.044" cy="18.044" r="7.467" />
        <path fillRule="nonzero" d="M24.172 28L27 25.172 36.762 34.933 33.933 37.762z" />
      </g>
    )}
    viewBox="0 0 40 40"
    width="4"
    height="4"
    {...props}
  />
);

const SearchIcon = (props) => (
  <IconComponent
    iconCode={(
      <g>
        <path d="M16.867 4c7.658 0 13.866 6.208 13.866 13.867 0 7.658-6.208 13.866-13.866 13.866C9.208 31.733 3 25.525 3 17.867 3 10.208 9.208 4 16.867 4zm0 2.5C10.589 6.5 5.5 11.589 5.5 17.867c0 6.277 5.089 11.366 11.367 11.366 6.277 0 11.366-5.089 11.366-11.366 0-6.278-5.089-11.367-11.366-11.367z" />
        <path d="M23.994 27.822L26.822 24.994 36.584 34.756 33.756 37.584z" />
      </g>
    )}
    viewBox="0 0 40 40"
    width="4"
    height="4"
    {...props}
  />
);

const TrashIcon = (props) => (
  <IconComponent
    iconCode={(
      <path fillRule="evenodd" stroke="none" d="M28.4 13.737V33.42H11.6V13.737h16.8zM25 3H15l-2 1.79H6v3.578h28V4.79h-7L25 3zm7.133 7.158H7.867v23.007c0 2.11 1.82 3.835 4.044 3.835H28.09c2.224 0 4.044-1.726 4.044-3.835V10.158z" />
    )}
    viewBox="0 0 40 40"
    width="4"
    height="4"
    {...props}
  />
);

const HeartShapeIcon = (props) => (
  <IconComponent
    iconCode={(
      <path fill="none" fillRule="evenodd" strokeWidth="1.6" d="M3.5 1.95C1.885 3.06.873 4.934.804 6.91c-.176 4.875 4.278 8.72 10.98 14.826.342.311.778.465 1.225.447.462-.018.93-.216 1.332-.587 6.548-5.955 11.017-9.81 10.854-14.7-.07-1.963-1.081-3.836-2.695-4.946C21.047.956 19.427.655 17.911.873c-1.677.242-3.229 1.11-4.302 2.37-.223.263-.427.543-.608.837-.183-.294-.386-.574-.61-.836C11.317 1.982 9.763 1.109 8.085.867 6.57.65 4.953.951 3.499 1.95z" />
    )}
    viewBox="0 0 26 23"
    width="4"
    height="4"
    {...props}
  />
);

const DownIcon = (props) => (
  <IconComponent
    iconCode={(
      <g strokeWidth="1.6" fill="#105285" fillRule="nonzero">
        <polygon id="Rectangle-Copy-9" transform="translate(20.500000, 18.555556) rotate(-225.000000) translate(-20.500000, -18.555556) " points="32.3333333 6.72222222 32.3333333 30.3888889 30.3333333 30.3888889 30.333 8.722 8.66666667 8.72222222 8.66666667 6.72222222" />
        <polygon id="Line-2-Copy-3" points="19.0287273 2.94351986 21.0287264 2.94536989 20.9990746 35.0009246 18.9990754 34.9990746" />
      </g>
    )}
    viewBox="0 0 40 40"
    width="4"
    height="4"
    {...props}
  />
);


export {
  AddIcon,
  ArrowBackIcon,
  AvatarIcon,
  BankPaymentIcon,
  BloqueoIcon,
  CardPaymentIcon,
  CheckIcon,
  CloseIcon,
  DocumentsIcon,
  DownIcon,
  EditIcon,
  FindVacancyIcon,
  HeartShapeIcon,
  LocationIcon,
  PracticesIcon,
  ProcessesIcon,
  ReviewIcon,
  SearchIcon,
  TransferPaymentIcon,
  TrashIcon,
  UserIcon,
};
