import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import {
  FormContainer, Label, Error,
} from './styledComponents';
import { InputText, ButtonPrimary } from '../common';
import HSpacer from '../layout/HSpacer';
import { EditIcon } from '../common/Icons/IconsList';


const DataForm = (props) => {
  const {
    onSubmit, isFormActive, setIsFormActive, student,
  } = props;
  const { register, handleSubmit, errors } = useForm();

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>

      <Label>Usuario:</Label>
      <InputText
        name="email"
        defaultValue={student.email}
        type="email"
        active={false}
      />

      <HSpacer height="2rem" />

      <Label>Nombre*:</Label>
      <InputText
        name="name"
        defaultValue={student.name}
        placeholder="Introduce tu nombre"
        active={isFormActive}
        inputRef={register({
          required: 'Campo obligatorio',
        })}
      />
      {errors.name && <Error>{errors.name.message}</Error>}

      <HSpacer height="1.2rem" />

      <Label>Apellidos*:</Label>
      <InputText
        name="lastname"
        defaultValue={student.lastname}
        placeholder="Introduce tu apellido"
        active={isFormActive}
        inputRef={register({
          required: 'Campo obligatorio',
        })}
      />
      {errors.lastname && <Error>{errors.lastname.message}</Error>}

      <HSpacer height="1.2rem" />

      {
        !isFormActive && (
          <ButtonPrimary
            iconComponent={<EditIcon height="3rem" />}
            iconColor="#105285"
            type={2}
            text="Editar datos"
            onClick={() => setIsFormActive(true)}
            fullWidth
          />
        )
      }
      {
        isFormActive && (
          <>
            <ButtonPrimary
              iconColor="#105285"
              type={2}
              htmlType="submit"
              text="Guardar"
              fontSize="medium"
              fullWidth
            />

            <HSpacer height="1.2rem" />

            <ButtonPrimary
              iconColor="#105285"
              type={2}
              text="Cancelar"
              fontSize="medium"
              onClick={() => setIsFormActive(false)}
              onSubmit={() => { }}
              fullWidth
            />
          </>
        )
      }
    </FormContainer>
  );
};

DataForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isFormActive: PropTypes.bool.isRequired,
  setIsFormActive: PropTypes.func.isRequired,
  student: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    lastname: PropTypes.string,
  }).isRequired,
};

export default DataForm;
