import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';


const BlueRow = styled.hr`
  height: 2px;
  border-width: 0;
  background-color: ${({ theme }) => theme.colors.themeTertiary};
  margin: ${({ margin }) => margin};
  width: ${(props) => (props.width ? `${props.width}%` : `${100}%`)};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: ${(props) => (props.mobileMargin || '1rem 0')};
  }
`;

const SingleRow = (props) => {
  const { margin, width, mobileMargin } = props;

  return <BlueRow margin={margin} width={width} mobileMargin={mobileMargin} />;
};

SingleRow.propTypes = {
  margin: PropTypes.string,
  width: PropTypes.number,
};

SingleRow.defaultProps = {
  margin: '2rem 0',
  width: 100,
};

export default SingleRow;
