import React from 'react';
import _get from 'lodash/get';
import { useForm, Controller } from 'react-hook-form';
import ButtonPrimary from '../../../common/Buttons/ButtonPrimary';
import ButtonSecondary from '../../../common/Buttons/ButtonSecondary';

import { DatePicker } from '../../../common';
import {
  Input,
  Label,
  InputColumn,
  ContactForm,
  Error,
  CardContentWrapper,
  Select,
  WhiteSpace,
  FullFlexCont,
  Column,
} from '../../formStyledComponents';
import HSpacer from '../../../layout/HSpacer/HSpacer';

const NewStudy = ({
  addPrevStudy,
  finishSelect,
  littleDevice,
  setSelectedStudy,
  isAddModal,
  setSelectedCvAdd,
  profile,
  isEditModal,
}) => {
  const { register, handleSubmit, errors, watch, setValue, control } = useForm();
  const watchFields = watch(['iniDate', 'gradDate']);

  const onSubmit = (data, e) => {
    e.preventDefault();
    const prevStudies = _get(profile, 'prevStudies', []);

    if (prevStudies.length < 2) {
      const obj = { ...data };
      data.ended === 'Sí' ? obj.ended = true : obj.ended = false;
      addPrevStudy(obj);
    } else {
      window.alert('Puedes añadir un máximo de 2 experiencias a esta sección');
    }
  };

  /* handle errors  */
  const nameError = errors.name && <Error>Por favor, indica el título de los estudios previos</Error>;
  const institutionError = errors.institutionName && <Error>Por favor, indica el nombre de la institución</Error>;
  const gradDateError = errors.gradDate && <Error>Debes indicar la fecha de titulación</Error>;

  return (
    <CardContentWrapper mobilePadding="0" padding={isAddModal || isEditModal && '0'}>
      <ContactForm onSubmit={handleSubmit(onSubmit)}>

        <InputColumn margin="0" alignCenter={littleDevice}>
          <Label>Nombre:</Label>
          <Input
            width="50%"
            name="name"
            placeholder="Nombre de tu carrera"
            ref={register({ required: true })}
            mobileWidth="80%"
            mobileMargin="0"
          />
          {nameError}
        </InputColumn>

        <FullFlexCont padding="0" between mobilePadding="0">
          <InputColumn margin="0" width="50%" alignCenter={littleDevice}>
            <Label>Nombre del centro:</Label>
            <Input
              name="institutionName"
              placeholder="Nombre del centro"
              ref={register({ required: true })}
              mobileWidth="80%"
              mobileMargin="0"
            />
            {institutionError}
          </InputColumn>

          <InputColumn margin="0" width="40%" alignCenter={littleDevice}>
            <Label>Estudios finalizados:</Label>
            <Select
              name="ended"
              ref={register({ required: true })}
              mobileWidth="80%"
            >
              {finishSelect.map((option) => <option key={option}>{option}</option>)}
            </Select>
          </InputColumn>
        </FullFlexCont>

        <FullFlexCont padding="0" between mobilePadding="0">
          <InputColumn margin="0" width="40%" alignCenter={littleDevice}>
            <Label>Fecha de inicio:</Label>
            <Controller
              name="iniDate"
              control={control}
              rules={{ required: false }}
              render={() => (
                <DatePicker
                  name="iniDate"
                  setValue={setValue}
                  value={watchFields.iniDate}
                  onValueChange={(value) => setValue('iniDate', value)}
                />
              )}
            />
          </InputColumn>

          <InputColumn margin="0" width="40%" alignCenter={littleDevice}>
            <Label>Fecha titulación:</Label>
            <Controller
              name="gradDate"
              control={control}
              rules={{ required: true }}
              render={() => (
                <DatePicker
                  name="gradDate"
                  setValue={setValue}
                  value={watchFields.gradDate}
                  onValueChange={(value) => setValue('gradDate', value)}
                />
              )}
            />
            {gradDateError}
          </InputColumn>
        </FullFlexCont>

        {!littleDevice && (
          <>
            <HSpacer height="2rem" />
            <ButtonPrimary
              iconColor="#105285"
              type={2}
              text="Guardar datos"
              htmlType="submit"
              margin="0 0 0 auto"
            />
          </>
        )}

        {littleDevice && (
          <FullFlexCont justify="space-between">

            <Column width="46" padding="0">
              <ButtonSecondary
                text="Atrás"
                onClick={!isAddModal ? () => setSelectedStudy(null) : () => setSelectedCvAdd(null)}
                margin="auto"
                fullWidth
              />
            </Column>

            <Column width="46" padding="0">
              <ButtonPrimary
                type={2}
                text="Guardar"
                htmlType="submit"
                margin="auto"
                fullWidth
              />
            </Column>

          </FullFlexCont>
        )}

        <WhiteSpace height="4rem" />

      </ContactForm>
    </CardContentWrapper>
  );
};

export default NewStudy;
