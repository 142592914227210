import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ButtonPrimary from '../../common/Buttons/ButtonPrimary';
import Icons from '../../common/Icons';

import {
  CardWrapper,
  BlueCard,
  SeeLessDiv,
  CardContentWrapper,
} from './styledComponents';


const SeeMoreCard = ({
  children,
  openByRef,
  selectedCard,
  profile,
  setSelectedCard,
}) => {
  const [closed, setClosed] = useState(true);

  useEffect(() => {
    setClosed(selectedCard !== 'currentStudy' && !openByRef);
    setSelectedCard(openByRef ? null : selectedCard);
  }, [selectedCard, openByRef, profile]);

  return (
    <CardWrapper>
      <ButtonPrimary
        withActive
        text={closed ? 'Ver más' : 'Ver menos'}
        type={4}
        position="absolute"
        top="-2rem"
        left="0"
        right="0"
        margin="auto"
        onClick={() => setClosed(!closed)}
      />
      <BlueCard closed={closed}>
        <CardContentWrapper>
          {children}
        </CardContentWrapper>

        {
          !closed && (
            <SeeLessDiv onClick={() => setClosed(true)}>
              <Icons icon="chevronUp" iconColor="#4e5152" width="3" />
            </SeeLessDiv>
          )
        }

      </BlueCard>
    </CardWrapper>
  );
};

SeeMoreCard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SeeMoreCard;
