import React, {
  useCallback,
  useContext, useEffect, useMemo, useState,
} from 'react';
import { getNotificationsList } from '../services/api/notifications';


export const NotificationsContext = React.createContext({});

export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const unreadNotifications = notifications?.filter(
    (notification) => (notification.type === 'web' || notification.type === 'web_correo') && !notification.read
  );
  
  const unseenNotifications = notifications?.filter(
    (notification) => (notification.type === 'web' || notification.type === 'web_correo') && !notification.seen
  );

  const getList = useCallback(async () => {
    const { success, data } = await getNotificationsList();

    if (success) setNotifications(data);
  }, []);


  useEffect(() => {
    getList();
  }, []);

  const value = useMemo(() => ({
    getList, unseenNotifications, unreadNotifications, notifications, setNotifications }),
  [getList, unseenNotifications, unreadNotifications, notifications, setNotifications]);

  return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
};

export const NotificationsConsumer = ({ children }) => <NotificationsContext.Consumer>{children}</NotificationsContext.Consumer>;

export const useNotifications = () => useContext(NotificationsContext);
