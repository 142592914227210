import React from 'react';
import _get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import ButtonPrimary from '../../../common/Buttons/ButtonPrimary';
import ButtonSecondary from '../../../common/Buttons/ButtonSecondary';
import { studentActions } from '../../../../redux/actions';

import {
  Column,
  Input,
  Label,
  InputColumn,
  FullFlexCont,
  WhiteSpace,
  ContactForm,
  Error,
  Select,
  DisabledBlueInput,
} from '../../formStyledComponents';

const StudyToEdit = ({
  profile,
  name,
  institution,
  init,
  grad,
  finish,
  setSelectedStudy,
}) => {
  const { register, handleSubmit, errors } = useForm();

  const dispatch = useDispatch();

  /* handle errors  */
  const nameError = errors.name && <Error>Por favor, indica el título de los estudios previos</Error>;
  const institutionError = errors.institutionName && <Error>Por favor, indica el nombre de la institución</Error>;
  const gradDateError = errors.gradDate && <Error>Debes indicar la fecha de titulación</Error>;

  const onSubmit = (data, e) => {
    e.preventDefault();
    const obj = { ...data };
    data.ended === 'Sí' ? obj.ended = true : obj.ended = false;
    const studies = [];
    const prevStudies = _get(profile, 'prevStudies', []);

    prevStudies.forEach((study) => {
      studies.push({
        ended: study.ended,
        gradDate: study.gradDate,
        iniDate: study.iniDate,
        institutionName: study.institutionName,
        name: study.name,
      });
    });

    studies.forEach((study, index) => {
      if (obj.name === study.name) {
        studies.splice(index, 1, obj);

        dispatch(studentActions.save({ prevStudies: studies }));
      }
    });

    setSelectedStudy(null);
  };

  const finishValue = finish === true ? 'Sí' : 'No';

  /* Select options */
  const finishSelect = ['Sí', 'No'];

  return (
    <>
      <ContactForm onSubmit={handleSubmit(onSubmit)}>

        <WhiteSpace height="1rem" />

        <FullFlexCont mobilePadding="0" justify="center">
          <InputColumn width="100%" alignCenter margin="0">

            <DisabledBlueInput
              bold
              mBottom="0.5rem"
              name="name"
              placeholder="Nombre de tu carrera"
              ref={register({ required: true })}
              mobileWidth="80%"
              value={name}
            />
            {nameError}

            <Input
              name="institutionName"
              placeholder="Nombre del centro"
              ref={register({ required: true })}
              defaultValue={institution}
              mobileWidth="80%"
            />
            {institutionError}
          </InputColumn>
        </FullFlexCont>

        <Column width={100} center mobileFull mobileMargin="0" padding="0" margin="0">
          <InputColumn alignCenter margin="0">
            <Label>Fecha inicio:</Label>
            <Input
              type="date"
              name="iniDate"
              ref={register({ required: false })}
              defaultValue={init}
              mobileMargin="0"
              mobileWidth="80%"
            />
          </InputColumn>
        </Column>
        <Column width={100} center mobileFull mobileMargin="0" padding="0" margin="0">
          <InputColumn alignCenter margin="0">
            <Label>Fecha titulación:</Label>
            <Input
              type="date"
              name="gradDate"
              ref={register({ required: true })}
              defaultValue={grad}
              mobileMargin="0"
              mobileWidth="80%"
            />
            {gradDateError}
          </InputColumn>
        </Column>
        <Column width={100} center mobileFull mobileMargin="0" padding="0" margin="0">
          <InputColumn alignCenter margin="0">
            <Label>Estudios finalizados:</Label>
            <Select
              name="ended"
              ref={register({ required: true })}
              defaultValue={finishValue}
              mobileMargin="0"
              width="80%"
            >
              {finishSelect.map((option) => <option key={option}>{option}</option>)}
            </Select>
          </InputColumn>
        </Column>

        <FullFlexCont justify="space-between">

          <WhiteSpace height="1rem" />

          <Column width="46" padding="0" margin="0">
            <ButtonSecondary
              text="Atrás"
              onClick={() => setSelectedStudy(null)}
              margin="auto"
              fullWidth
            />
          </Column>

          <Column width="46" padding="0" margin="0">
            <ButtonPrimary
              type={2}
              text="Guardar"
              htmlType="submit"
              margin="auto"
              fullWidth
            />
          </Column>

        </FullFlexCont>

      </ContactForm>
    </>
  );
};

export default StudyToEdit;
