import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import LoggedLayout from '../components/layout/LoggedLayout';
import { PageContainer } from '../components/common';


const Text = styled.p`
  margin: 2rem 0;
`;

const GoBack = styled.button`
  border: none;
  background: none;
  text-decoration: underline;
  cursor: pointer;
`;


const Page404 = () => {

  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <LoggedLayout>
      <PageContainer>
        <h1>404 - Página no encontrada</h1>
        <Text>El contenido que está buscando no se encuentra en la URL introducida</Text>
        <GoBack onClick={goBack}>Volver a la página anterior</GoBack>
      </PageContainer>
    </LoggedLayout>
  );
};

export default Page404;