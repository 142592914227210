/* eslint-disable import/prefer-default-export */
import { API } from 'aws-amplify';
import capitalize from '@bit/iamtechnologies.iamtech-js.capitalize';

import { payment } from '../../utils';
import { listServices } from '../../graphql/queries';
import { createPayment, payPayment } from '../../graphql/mutations';
import { response } from './response';


const res = ({ success, msg, data }) => ({
  success,
  message: !success && !msg ? 'UnexpectedError' : msg,
  data,
});

const paymentRelatedEntities = [
  'service',
  'student',
];

const getSavingPaymentInfo = (payment) => {
  const savingPaymentInfo = { ...payment };
  Object.keys(payment).forEach((paymentProperty) => {
    if (paymentProperty === 'createdAt'
      || paymentProperty === 'updatedAt'
      || payment[paymentProperty] === undefined
      // || studentDiscardedField.includes(studentProperty)
    ) {
      delete savingPaymentInfo[paymentProperty];
    }

    if (paymentRelatedEntities.includes(paymentProperty)) {
      const relatedFieldName = `payment${capitalize(paymentProperty)}Id`;
      savingPaymentInfo[relatedFieldName] = payment[paymentProperty] ? payment[paymentProperty].id : null;
      delete savingPaymentInfo[paymentProperty];
    }

    // if (studentRelatedEntitiesArray.includes(studentProperty)) {
    //   const relatedFieldName = `student${capitalize(studentProperty)}Id`;
    //   savingPaymentInfo[relatedFieldName] = values[studentProperty] && values[studentProperty].length
    //     ? values[studentProperty].map((realtedEntity) => realtedEntity.id)
    //     : null;
    //   delete savingPaymentInfo[studentProperty];
    // }
  });
  return savingPaymentInfo;
};

async function createUserPayment(payment) {
  try {
    const result = await API.graphql({
      query: createPayment,
      variables: { input: payment },
    });
    const { data } = result;
    return res({ success: true, msg: 'success', data: data.createPayment });
  } catch (error) {
    console.log('🚀 ~ file: getPayments.js ~ line 26 ~ createUserPayment ~ error', error);
    const { data, errors } = error;
    return res({ success: false, msg: error.code, data });
  }
}

async function payStudentPayment(payment) {
  const savingPaymentInfo = getSavingPaymentInfo(payment);
  try {
    const result = await API.graphql({
      query: payPayment,
      variables: { input: savingPaymentInfo },
    });
    const { data } = result;
    return res({ success: true, msg: 'success', data: data.createPayment });
  } catch (error) {
    console.log('🚀 ~ file: payments.js ~ line 43 ~ payStudentPayment ~ error', error);
    const { data, errors } = error;
    return res({ success: false, msg: error.code, data });
  }
}

const getPayments = async ({ formData, paymentStudentId, studentCode, iva }) => {
  if (!formData) return [];
  const { priceSelected } = formData;

  const payments = [];

  const {
    firstPayment: selectedFirstPayment,
    secondPayment: selectedSecondPayment,
    currency: selectedCurrency,
  } = priceSelected?.otherCurrencyPrices
    ? {
      firstPayment: priceSelected.otherCurrencyPrices.firstPayment,
      secondPayment: priceSelected.otherCurrencyPrices.secondPayment,
      currency: priceSelected.otherCurrencyPrices.currency,
    }
    : {
      firstPayment: priceSelected.firstPayment,
      secondPayment: priceSelected.secondPayment,
      currency: 'EUR', // Default currency
    };

  const { firstPayment, secondPayment } = payment.getPrice(selectedFirstPayment, selectedSecondPayment, iva);

  const currency = priceSelected?.otherCurrencyPrices ? selectedCurrency : 'EUR';


  if (priceSelected.firstPayment > 0) {
    const paymentOne = {
      amount: firstPayment,
      code: `${studentCode}/firstPayment/1`,
      currency,
      paymentServiceId: priceSelected.service.id,
      quotaName: 'firstPayment',
      status: 'pending',
      paymentStudentId,
    };
    const result = await createUserPayment(paymentOne);
    const { success, message, data } = result;
    if (success) payments.push(data);
  }

  if (priceSelected.secondPayment > 0) {
    const paymentTwo = {
      amount: secondPayment,
      code: `${studentCode}/secondPayment/1`,
      currency,
      paymentServiceId: priceSelected.service.id,
      quotaName: 'secondPayment',
      status: 'pending',
      paymentStudentId,
    };
    const result = await createUserPayment(paymentTwo);
    const { success, message, data } = result;
    if (success) payments.push(data);
  }

  return payments;
};


const setupIntent = async (amount, paymentId, currency) => {
  try {
    const { body } = await API.get('payments', 'payment-intent', { queryStringParameters: { amount, paymentId, currency } });
    return response(true, null, body);
  } catch (error) {
    return response(false, error);
  }
};


async function getInsurancePayment() {
  try {
    const result = await API.graphql({
      query: listServices,
      authMode: 'API_KEY',
      variables: { filter: { key: { eq: 'seguro' } } } });
    const { data } = result;
    return res({ success: true, msg: 'success', data: data.listServices });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('getInsurancePayment error -> ', error);
    return res({ success: false, msg: error.code });
  }
}

export { getPayments, getInsurancePayment, payStudentPayment, setupIntent };
