import React from 'react';

import { Notification } from '../Modal';

const NotificationProvider = (props) => {
  const { onReadNotification, unreadNotifications, unseenNotifications, showNotification, setShowNotification } = props;

  return (
    <>
      <Notification
        unreadNotifications={unreadNotifications}
        unseenNotifications={unseenNotifications}
        onReadNotification={onReadNotification}
        showNotification={showNotification}
        setShowNotification={setShowNotification}
      />
    </>
  );
};

export default NotificationProvider;
