import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import IconButton from '../../common/Buttons/IconButton';
import { DownIcon, TrashIcon } from '../../common/Icons/IconsList';

const IconsContainer = styled.div`
  display: flex;
  width: 8.5rem;
  height: fit-content;
  margin-right: 2rem;
`;

const VerticalSpace = styled.div`
  min-width: 1rem;
`;

const DownDeleteIcons = (props) => {
  const {
    download, deleteField, confirmText,
  } = props;

  const onDelete = (e) => {
    e.preventDefault();
    if (window.confirm(confirmText)) deleteField();
  };

  return (
    <IconsContainer>
      <IconButton
        icon={<TrashIcon />}
        type={2}
        width={2.4}
        padding="1rem"
        onClick={onDelete}
      />

      <VerticalSpace />

      <IconButton
        icon={<DownIcon />}
        type={2}
        width={2.4}
        padding="1rem"
        onClick={download}
      />

    </IconsContainer>
  );
};

DownDeleteIcons.propTypes = {
  deleteField: PropTypes.func.isRequired,
  download: PropTypes.func.isRequired,
  confirmText: PropTypes.string.isRequired,
};

export default DownDeleteIcons;
