import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';

import { studentActions } from '../../../../redux/actions';
import useListPublicEntity from '../../../../hooks/useListPublicEntity';
import { showOptions, getId } from '../../helpers';

import ButtonPrimary from '../../../common/Buttons/ButtonPrimary';
import ButtonSecondary from '../../../common/Buttons/ButtonSecondary';
import { DatePicker } from '../../../common';
import {
  WhiteSpace,
  FullFlexCont,
  Column,
  InputColumn,
  Input,
  Label,
  ContactForm,
  Error,
  NoIconTitle,
  SubLabel,
  Select,
} from '../../formStyledComponents';

const CurrentStudyModal = ({
  setSelectedCvEdit,
  name,
  city,
  address,
  cp,
  region,
  campus,
  iniDate,
  endDate,
  gradDate,
  institutionName,
  tutor,
  lastname,
  email,
  phone,
  country,
  currentStudyEnded,
}) => {
  const { register, handleSubmit, errors, watch, setValue, reset, control } = useForm();
  const watchFields = watch(['iniDate', 'endDate', 'gradDate']);

  const dispatch = useDispatch();

  const { data: residences } = useListPublicEntity('residence');

  useEffect(() => {
    reset({
      iniDate,
      endDate,
      gradDate,
    });
  }, [reset, iniDate, endDate, gradDate]);

  const onSubmit = (data, e) => {
    e.preventDefault();
    dispatch(studentActions.save({
      currentStudy: {
        studyCountryId: getId(residences, data.country),
        city: data.city,
        address: data.address,
        cp: data.cp,
        region: data.region,
        campus: data.campus,
        iniDate: data.iniDate,
        endDate: data.endDate,
        gradDate: data.gradDate,
        ended: currentStudyEnded,
        name: data.name,
        institutionName: data.institutionName,
      },
      tutor: {
        name: data.tutor,
        lastname: data.lastname,
        email: data.email,
        phone: data.telf,
      },
    }));
    setSelectedCvEdit(null);
  };

  /* handle errors  */
  const nameError = errors.name && <Error>Por favor, indica el título de los estudios en curso</Error>;
  const institutionError = errors.institutionName && <Error>Por favor, indica el nombre de la institución</Error>;
  const countryError = errors.country && <Error>Por favor, indica el país</Error>;
  const cityError = errors.city && <Error>Por favor, indica la ciudad</Error>;
  const endDateError = errors.endDate && !watchFields.endDate && <Error>Debes indicar la fecha de finalización</Error>;
  const gradDateError = errors.gradDate && !watchFields.gradDate && <Error>Debes indicar la fecha esperada de titulación</Error>;
  const tutorNameError = errors.tutor && <Error>Por favor, indica el nombre del tutor</Error>;
  const tutorLastNameError = errors.lastname && <Error>Por favor, indica los apellidos del tutor</Error>;
  const tutorEmailError = errors.email?.type === 'required' && <Error>Por favor, indica el e-mail del tutor</Error>;
  const tutorEmailError2 = errors.email?.type === 'pattern' && <Error>Formato de email incorrecto</Error>;
  const tutorTelfError = errors.telf?.type === 'required' && <Error>Por favor, indica el teléfono del tutor</Error>;
  const tutorTelfError2 = errors.telf?.type === 'pattern' && <Error>Número de teléfono no válido</Error>;


  return (

    <>
      <NoIconTitle width="100%" margin=" 2rem 0" center>ÚLTIMA FORMACIÓN ACADÉMICA</NoIconTitle>

      <WhiteSpace mobileHeight="1rem" />

      <ContactForm key="currentStudyModal" onSubmit={handleSubmit(onSubmit)} column center>
        <InputColumn width="fit-content" alignCenter margin="0">

          <Input
            mobileWidth="80%"
            mBottom="0.5rem"
            name="name"
            placeholder="Nombre de tu carrera"
            ref={register({ required: true })}
            defaultValue={name}
          />
          {nameError}

          <Input
            mobileWidth="80%"
            name="institutionName"
            placeholder="Nombre del centro"
            ref={register({ required: true })}
            defaultValue={institutionName}
          />
          {institutionError}
        </InputColumn>

        <FullFlexCont mobilePadding="0" center>
          <Label mobileMargin="">Dirección institución académica*</Label>
        </FullFlexCont>

        <Column width={100} padding="1rem 2rem 0 2rem" margin="0" center mobileFull mobileMargin="1rem 0">
          <InputColumn alignCenter margin="0">
            <SubLabel mobileWidth="80%" mobileMargin="1rem 0">País*:</SubLabel>
            <Select
              name="country"
              ref={register({ required: true })}
              mobileMargin="0"
              mobileWidth="50%"
            >
              {showOptions(residences, country, 'nombre país')}
            </Select>
            {countryError}
          </InputColumn>
        </Column>
        <Column width={100} padding="1rem 2rem 0 2rem" margin="0" center mobileFull mobileMargin="1rem 0">
          <InputColumn alignCenter margin="0">
            <SubLabel mobileWidth="80%" mobileMargin="1rem 0">Ciudad*:</SubLabel>
            <Input
              name="city"
              placeholder="ciudad"
              ref={register({ required: true })}
              defaultValue={city}
              mobileMargin="0"
              mobileWidth="80%"
            />
            {cityError}
          </InputColumn>
        </Column>
        <Column width={100} padding="1rem 2rem 0 2rem" margin="0" center mobileFull mobileMargin="1rem 0">
          <InputColumn alignCenter margin="0">
            <SubLabel mobileWidth="80%" mobileMargin="1rem 0">Calle y nº:</SubLabel>
            <Input
              name="address"
              placeholder="dirección de la institución"
              ref={register({ required: false })}
              defaultValue={address}
              mobileMargin="0"
              mobileWidth="80%"
            />
          </InputColumn>
        </Column>

        <Column width={100} padding="0 2rem" margin="0" center mobileFull mobileMargin="1rem 0">
          <InputColumn alignCenter margin="0">
            <SubLabel mobileWidth="80%" mobileMargin="1rem 0">Código Postal:</SubLabel>
            <Input
              name="cp"
              placeholder="código postal"
              ref={register({ required: false })}
              defaultValue={cp}
              mobileMargin="0"
              mobileWidth="80%"
            />
          </InputColumn>
        </Column>
        <Column width={100} padding="0 2rem" margin="0" center mobileFull mobileMargin="1rem 0">
          <InputColumn alignCenter margin="0">
            <SubLabel mobileWidth="80%" mobileMargin="1rem 0">Estado/región:</SubLabel>
            <Input
              name="region"
              placeholder="estado / región"
              ref={register({ required: false })}
              defaultValue={region}
              mobileMargin="0"
              mobileWidth="80%"
            />
          </InputColumn>
        </Column>
        <Column width={100} padding="0 2rem" margin="0" center mobileFull mobileMargin="1rem 0">
          <InputColumn alignCenter margin="0">
            <SubLabel mobileWidth="80%" mobileMargin="1rem 0">Campus:</SubLabel>
            <Input
              name="campus"
              placeholder="campus"
              ref={register({ required: false })}
              defaultValue={campus}
              mobileMargin="0"
              mobileWidth="80%"
            />
          </InputColumn>
        </Column>

        <Column width={100} padding="0 2rem" margin="0" center mobileFull mobileMargin="1rem 0">
          <InputColumn alignCenter>
            <Label mobileMargin="">Fecha inicio:</Label>
            <Controller
              name="iniDate"
              control={control}
              render={() => (
                <DatePicker
                  name="iniDate"
                  setValue={setValue}
                  value={watchFields.iniDate}
                  onValueChange={(value) => setValue('iniDate', value)}
                />
              )}
            />
          </InputColumn>
        </Column>
        <Column width={100} padding="0 2rem" margin="0" center mobileFull mobileMargin="1rem 0">
          <InputColumn alignCenter>
            <Label mobileMargin="">Fecha prevista finalización estudios*:</Label>
            <Controller
              name="endDate"
              control={control}
              rules={{ required: true }}
              render={() => (
                <DatePicker
                  name="endDate"
                  setValue={setValue}
                  value={watchFields.endDate}
                  onValueChange={(value) => setValue('endDate', value)}
                />
              )}
            />
            {endDateError}
          </InputColumn>
        </Column>
        <Column width={100} padding="0 2rem" margin="0" center mobileFull mobileMargin="1rem 0">
          <InputColumn alignCenter>
            <Label mobileMargin="0.5rem 0">Año previsto titulación*:</Label>
            <Controller
              name="gradDate"
              control={control}
              rules={{ required: true }}
              render={() => (
                <DatePicker
                  name="gradDate"
                  setValue={setValue}
                  value={watchFields.gradDate}
                  onValueChange={(value) => setValue('gradDate', value)}
                />
              )}
            />
            {gradDateError}
          </InputColumn>
        </Column>

        <FullFlexCont mobilePadding="0" center>
          <Label mobileMargin="">Tutor de prácticas*:</Label>
        </FullFlexCont>

        <Column width={100} padding="1rem 2rem 0 2rem" margin="0" center mobileFull mobileMargin="1rem 0">
          <InputColumn alignCenter>
            <SubLabel>Nombre:</SubLabel>
            <Input
              name="tutor"
              placeholder="nombre del tutor"
              ref={register({ required: !currentStudyEnded })}
              defaultValue={tutor}
              mobileMargin="0 0 0.5rem 0"
              mobileWidth="80%"
            />
            {tutorNameError}
            <Input
              name="lastname"
              placeholder="apellidos del tutor"
              ref={register({ required: !currentStudyEnded })}
              defaultValue={lastname}
              mobileMargin="0"
              mobileWidth="80%"
            />
            {tutorLastNameError}
          </InputColumn>
        </Column>
        <Column width={100} padding="1rem 2rem 0 2rem" margin="0" center mobileFull mobileMargin="1rem 0">
          <InputColumn alignCenter>
            <SubLabel>e-mail:</SubLabel>
            <Input
              name="email"
              placeholder="email del tutor"
              ref={register({
                required: !currentStudyEnded,
                pattern: {
                  value: /[\w\-\._]+@[\w\-\._]+\.\w{2,10}/,
                },
              })}
              defaultValue={email}
              mobileMargin="0"
              mobileWidth="80%"
            />
            {tutorEmailError}
            {tutorEmailError2}
          </InputColumn>
        </Column>
        <Column width={100} padding="1rem 2rem 0 2rem" margin="0" center mobileFull mobileMargin="1rem 0">
          <InputColumn alignCenter>
            <SubLabel>Telf:</SubLabel>
            <Input
              name="telf"
              placeholder="teléfono del tutor"
              ref={register({
                required: !currentStudyEnded,
                pattern: {
                  value: /^\+[0-9][0-9]{6,20}$/,
                },
              })}
              defaultValue={phone}
              mobileMargin="0"
              mobileWidth="80%"
            />
            {tutorTelfError}
            {tutorTelfError2}
          </InputColumn>
        </Column>

        <FullFlexCont justify="space-between">

          <WhiteSpace height="1rem" />

          <Column width="46" padding="0">
            <ButtonSecondary
              text="Atrás"
              onClick={() => setSelectedCvEdit(null)}
              margin="auto"
              fullWidth
            />
          </Column>

          <Column width="46" padding="0">
            <ButtonPrimary
              type={2}
              text="Guardar"
              htmlType="submit"
              margin="auto"
              fullWidth
            />
          </Column>

        </FullFlexCont>

      </ContactForm>
    </>
  );
};

export default CurrentStudyModal;
