import styled from 'styled-components/macro';


export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const LeftArrow = styled.div`
  margin-right: 0.8rem;
  cursor: pointer;
`;

export const Title = styled.h1`
  display: inline-block;
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: ${({ theme }) => theme.fontSizes.titleMedium};
`;
