import React from 'react';
import styled from 'styled-components/macro';

const FlexBox = styled.div`
  display: ${(props) => (props.container ? 'flex' : 'block')};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  flex-wrap: ${(props) => props.wrap};
  flex: ${(props) => (props.flexWidth ? `0 0 ${props.flexWidth}%` : '')};
  flex-direction: ${(props) => props.direction};
  margin: ${({ margin }) => margin || '0'};
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding};
  gap: ${({ gap }) => gap};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    flex-direction: ${(props) => (props.signUpMobile ? 'column' : props.direction)};
  }
`;


const Box = ({
  container,
  children,
  gap,
  justify = 'space-between',
  align = 'center',
  w,
  wrap = 'no-wrap',
  spacing = 0,
  direction = 'row',
  signUpMobile,
  width,
  padding,
  margin,
  ...rest
}) => {
  const flexWidth = w ? w * 100 - spacing : null;
  return (
    <FlexBox
      width={width}
      padding={padding}
      margin={margin}
      container={container}
      gap={gap}
      justify={justify}
      align={align}
      flexWidth={flexWidth}
      wrap={wrap}
      spacing={spacing}
      direction={direction}
      signUpMobile={signUpMobile}
      {...rest}
    >
      {children}
    </FlexBox>
  );
};

export default Box;
