/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const notificationSent = /* GraphQL */ `
  subscription NotificationSent($studentId: String!) {
    notificationSent(studentId: $studentId) {
      studentId
      timestamp
      title
      content
      read
      seen
      type
      onCopyEmail
    }
  }
`;
export const notificationUpdate = /* GraphQL */ `
  subscription NotificationUpdate($studentId: String!) {
    notificationUpdate(studentId: $studentId) {
      studentId
      timestamp
      title
      content
      read
      seen
      type
      onCopyEmail
    }
  }
`;
