import React from 'react';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import FadeIn from '../../hooks/fadeIn';

import { Box, CardVacante, CardButton } from '../common';

import { images } from '../../utils';


const Vacancies = (props) => {
  const screen = useSelector((state) => state.windowResize.screen);
  let w = 1 / 3;
  if (screen === 'phone') w = 1;
  if (screen === 'tab-land') w = 1 / 2;

  const {
    items,
    hasFullProfile,
    goToProfile,
    onChangePage,
    setVacancyId,
    lastPage,
    loading,
    total,
    setFullProfileModalVisible,
    vacancyType,
  } = props;

  if (loading) {
    const loaders = [1, 2, 3];
    return (
      <Box container wrap="wrap">
        {
          loaders.map((item) => (
            <Box w={w} spacing={3} key={item}>
              <CardButton loader />
            </Box>
          ))
        }
      </Box>
    );
  }

  return (
    <Box container wrap="wrap">
      {
        items.filter((item) => item.active).map((item) => {
          const id = _get(item, 'id', '');
          const tagName = _get(item, 'department', '');
          const workplaceName = _get(item, 'company', '');
          const economicHelp = _get(item, 'benefitEconomy', '');
          const logoImage = _get(item, 'logoImage', '');
          const file = images.getUrl('vacancy/logoImage', logoImage);
          const city = _get(item, 'city', '');
          const duration = '400ms';
          const warned = _get(item, 'isWarned', false);
          const benefitHouse = _get(item, 'benefitHouse', false);

          return (
            <Box w={w} spacing={3} key={id}>
              <FadeIn duration={duration} fade>
                <CardVacante
                  benefitHouse={benefitHouse}
                  file={file}
                  tagName={tagName}
                  workplaceName={workplaceName}
                  economicHelp={economicHelp}
                  ubication={city}
                  id={id}
                  setVacancyId={setVacancyId}
                  hasFullProfile={hasFullProfile}
                  setFullProfileModalVisible={setFullProfileModalVisible}
                  warned={warned}
                />
              </FadeIn>
            </Box>
          );
        })
      }
      {
        !hasFullProfile && items.length > 0 && (
          <Box w={w} spacing={3}>
            <CardButton
              text="Para ver más prácticas completa tu sección de perfil"
              onClick={goToProfile}
            />
          </Box>
        )
      }
      {
        hasFullProfile && items.length > 0 && !lastPage && vacancyType === 'Búsqueda' && (
          <Box w={w} spacing={3}>
            <CardButton
              text={(
                <span>
                  Quedan por ver:
                  {' '}
                  {total}
                  {' '}
                  <br />
                  Ver más prácticas
                </span>
              )}
              onClick={onChangePage}
            />
          </Box>
        )
      }
      {
        items.length === 0 && (
          <Box w={w} spacing={3}>
            <CardButton
              text="¡Vaya! No hemos encontrado vacantes que coincidan con tu búsqueda. Explora otras opciones en el buscador."
            />
          </Box>
        )
      }
    </Box>
  );
};

Vacancies.propTypes = {
  goToProfile: PropTypes.func.isRequired,
  hasFullProfile: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChangePage: PropTypes.func.isRequired,
  setVacancyId: PropTypes.func.isRequired,
  lastPage: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
};

export default Vacancies;
