import React from 'react';
import styled from 'styled-components';
import CustomTheme from '../components/CustomTheme';

import ButtonPrimary from '../components/common/Buttons/ButtonPrimary';
import ButtonSecondary from '../components/common/Buttons/ButtonSecondary';
import ButtonIcon from '../components/common/Buttons/ButtonIcon';

import Header from '../components/Header';

import CardNotificacion from '../components/common/Cards/CardNotificacion';
import CardVacante from '../components/common/Cards/CardVacante';
import CardPrioridad from '../components/common/Cards/CardPrioridad';
import CardRecomendado from '../components/common/Cards/CardRecomendado';
import CardDocumentos from '../components/common/Cards/CardDocumentos';
import CardPagos from '../components/common/Cards/CardPagos';

import InputText from '../components/common/formFields/Inputs/InputText';
import InputCheck from '../components/common/formFields/Inputs/InputCheck';

import Select from '../components/common/formFields/Select';

import { SingleRow, DoubleRow, PageSubMenuPill, ProgressBar, ButtonBig, Icons, Box, IconButton } from '../components/common';

import {
  AddIcon, ArrowBackIcon, AvatarIcon, BloqueoIcon,
  CheckIcon, DocumentsIcon, EditIcon, FindVacancyIcon,
  LocationIcon, CardPaymentIcon, BankPaymentIcon, TransferPaymentIcon,
  PracticesIcon, ReviewIcon, SearchIcon, TrashIcon, HeartShapeIcon,
} from '../components/common/Icons/IconsList';


const Typo = styled.span`
  font-size: ${({ theme, size }) => theme.fontSizes[size]};
`;


function App() {
  return (
    <CustomTheme>
      <Header />
      <ButtonPrimary text="Click Here" />
      <ButtonPrimary text="Click Here" type={1} />
      <ButtonPrimary text="Click Here" type={2} />


      <ButtonSecondary text="Click Here" />
      <ButtonSecondary text="Click Here" type={1} />
      <ButtonSecondary text="Click Here" type={2} />
      <ButtonSecondary text="Click Here" type={3} />

      <ButtonIcon title="Tu perfil" icon="user" iconColor="themeSecondary" />
      <ButtonIcon title="Procesos" icon="deal" iconColor="themeSecondary" large />

      <Typo size="giant2">giant2</Typo>
      <br />
      <Typo size="giant1">giant1</Typo>
      <br />
      <Typo size="titleLarge">titleLarge</Typo>
      <br />
      <Typo size="titleMedium">titleMedium</Typo>
      <br />
      <Typo size="titleSmall">titleSmall</Typo>
      <br />
      <Typo size="titleXSmall">titleXSmall</Typo>
      <br />
      <Typo size="large">large</Typo>
      <br />
      <Typo size="medium">medium</Typo>
      <br />
      <Typo size="small">small</Typo>
      <br />
      <Typo size="xsmall">xsmall</Typo>
      <br />


      <InputText />

      <InputCheck text="No" />

      <InputCheck text="Si" checked />

      <Select />


      <CardNotificacion />

      <CardVacante />

      <CardPrioridad
        position={1}
        first
        tagName="Food & Beverage"
        workplaceName="MAJESTIC HOTEL & SPA"
        economicHelp="300€/mes brutos"
        openDates="Abierto todo el año"
        ubication="Barcelona, Catalunya, España"
      />

      <CardPrioridad
        position={2}
        tagName="Food & Beverage"
        workplaceName="MAJESTIC HOTEL & SPA BARCELONA 5* GL"
        economicHelp="300€/mes brutos"
        openDates="Abierto todo el año"
        ubication="Barcelona, Catalunya, España"
      />

      <CardPrioridad
        position={3}
        last
        tagName="Food & Beverage"
        workplaceName="MAJESTIC HOTEL & SPA"
        economicHelp="300€/mes brutos"
        openDates="Abierto todo el año"
        ubication="Barcelona, Catalunya, España"
      />

      <CardRecomendado />

      <CardDocumentos />

      <CardPagos />

      <SingleRow />

      <DoubleRow />

      <PageSubMenuPill title1="Title1" title2="Title2" title3="Title3" active="Title1" />

      <ProgressBar />

      <ButtonBig onClick={() => console.log('ButtonBig')}>
        <Box container justify="center">
          <p>
            Añadir práctica
            <br />
            <span>(3 de 4 posibles)</span>
          </p>
          <Icons icon="chevronLeft" iconColor="blue" width={2.5} />
        </Box>
      </ButtonBig>

      <Box container justify="center" wrap="wrap" style={{maxWidth: '800px', margin: '10rem auto'}}>
        <Box container direction="column" style={{margin: '1rem'}}>
          <AddIcon iconColor='#4798B6' />
          AddIcon
        </Box>
        <Box container direction="column" style={{margin: '1rem'}}>
          <ArrowBackIcon />
          ArrowBackIcon
        </Box>
        <Box container direction="column" style={{margin: '1rem'}}>
          <AvatarIcon />
          AvatarIcon
        </Box>
        <Box container direction="column" style={{margin: '1rem'}}>
          <BloqueoIcon iconColor="red" />
          BloqueoIcon
        </Box>
        <Box container direction="column" style={{margin: '1rem'}}>
          <BloqueoIcon iconColor="red" />
          BloqueoIcon
        </Box>
        <Box container direction="column" style={{margin: '1rem'}}>
          <div style={{ backgroundColor: 'blue' }}>
            <BloqueoIcon iconColor="white" />
          </div>
          BloqueoIcon
        </Box>
        <Box container direction="column" style={{margin: '1rem'}}>
          <CheckIcon iconColor="green" />
          CheckIcon
        </Box>
        <Box container direction="column" style={{margin: '1rem'}}>
          <DocumentsIcon iconColor="orange" />
          DocumentsIcon
        </Box>
        <Box container direction="column" style={{margin: '1rem'}}>
          <EditIcon iconColor="pink" />
          EditIcon
        </Box>
        <Box container direction="column" style={{margin: '1rem'}}>
          <FindVacancyIcon iconColor="violet" />
          FindVacancyIcon
        </Box>
        <Box container direction="column" style={{margin: '1rem'}}>
          <LocationIcon iconColor="#F07F5C" />
          LocationIcon
        </Box>
        <Box container direction="column" style={{margin: '1rem'}}>
          <CardPaymentIcon iconColor="blue" />
          CardPaymentIcon
        </Box>
        <Box container direction="column" style={{margin: '1rem'}}>
          <BankPaymentIcon iconColor="blue" />
          BankPaymentIcon
        </Box>
        <Box container direction="column" style={{margin: '1rem'}}>
          <TransferPaymentIcon iconColor="violet" />
          TransferPaymentIcon
        </Box>
        <Box container direction="column" style={{ margin: '1rem' }}>
          <PracticesIcon iconColor="violet" />
          PracticesIcon
        </Box>
        <Box container direction="column" style={{ margin: '1rem' }}>
          <ReviewIcon iconColor="violet" />
          ReviewIcon
        </Box>
        <Box container direction="column" style={{ margin: '1rem' }}>
          <SearchIcon iconColor="violet" />
          SearchIcon
        </Box>
        <Box container direction="column" style={{ margin: '1rem' }}>
          <TrashIcon iconColor="violet" />
          TrashIcon
        </Box>
        <Box container direction="column" style={{margin: '1rem'}}>
          <HeartShapeIcon iconColor="violet" />
          HeartShapeIcon
        </Box>

        <Box container direction="column" style={{ margin: '1rem' }}>
          <IconButton
            icon={<EditIcon />}
            type={2}
            width={2.4}
            padding="1rem"
          />
          EditIcon
        </Box>

        <Box container direction="column" style={{ margin: '1rem' }}>
          <IconButton
            icon={<TrashIcon />}
            type={2}
            width={2.4}
            padding="1rem"
          />
        </Box>

        <Box container direction="column" style={{ margin: '1rem' }}>
          <IconButton
            icon={<TransferPaymentIcon />}
            text="Transferencia"
            type={5}
            width={6.2}
            padding="0rem 3rem"
            square
          />
        </Box>

        <Box container direction="column" style={{ margin: '1rem' }}>
          <ButtonPrimary
            type={2}
            text="Editar datos"
            iconComponent={<EditIcon />}
            fontSize="medium"
          />
        </Box>

        <Box container direction="column" style={{ margin: '1rem' }}>
          <ButtonPrimary
            type={2}
            text="Editar imagen"
            iconComponent={<EditIcon />}
            fontSize="medium"
          />
        </Box>

      </Box>

    </CustomTheme>
  );
}

export default App;
