import React from 'react';

import { ButtonSecondary } from '../../../common';

const PrintToPdf = ({ profile }) => {
  const printCV = (user) => {
    const { name, lastname } = user;
    const title = `${name}_${lastname}_CV`;
    const cv = document.getElementById('print-cv');
    const html = `
    <html>
      <head>
        <title>${title}</title>
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;700&display=swap" rel="stylesheet">
      </head>
      <style>
        * {
          margin: 0;
          padding: 0;
        }
        html {
          font-size: 62.5%;
        }
        .cv-wrapper {
          font-family: 'Source Sans 3', sans-serif;
          font-weight: 400;
          font-size: 1.4rem;
          color: #7f7f7f;
          width: 100%;
          margin: 4rem auto;
          background-color: #fff;
        }
        .cv-wrapper li {
          list-style: inside;
        }
        .header {
          color: #595959;
          background-color: #edeeef;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          padding: 1rem 6.6rem 3rem;
        }
        .header-logo {
          padding: 3rem 6.6rem;
        }
        .nexus-logo {
          width: 100px;
        }
        .nexus-logo img {
          object-fit: cover;
          object-position: 50% 50%;
          width: 85px;
        }
        .title {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          font-size: 5.6rem;
          font-weight: 700;
          padding-bottom: 1rem;
          border-bottom: 3pt solid #a6a6a6;
        }
        .photo {
          padding-bottom: 1rem;
          border-bottom: 3pt solid #a6a6a6;
        }
        .photo img {
          width: auto;
          height: auto;
          max-width: 100pt;
          max-height: 150pt;
        }
        .content {
          display: flex;
          min-height: 78%;
        }
        .left {
          width: 35%;
          background-color: #edeeef;
          padding: 4rem 2rem 6.6rem 6.6rem;
        }
        .right {
          width: 75%;
          padding: 4rem 2rem 5rem 5rem;
        }
        .section-title {
          color: #595959;
          font-size: 1.8rem;
          font-weight: 700;
          margin-bottom: 1.3rem;
        }
        .featured {
          display: flex;
          align-items: center;
          margin-bottom: 0.6rem;
          font-size: 1.5rem;
        }
        .icon {
          display: inline-block;
          width: 50pt;
        }
        .lang {
          min-width: 50%;
        }
        .section {
          margin-bottom: 5rem;
        }
        .section:last-child {
          margin-bottom: 8rem;
        }
        .section-columns {
          display: flex;
          justify-content: space-between;
          margin-bottom: 2.6rem;
        }
        .section-columns-title {
          margin-bottom: 0.3rem;
          color: #595959;
          font-size: 1.2rem;
          font-weight: 700;
          text-transform: uppercase;
        }
        .section-columns-subtitle {
          font-size: 1.4rem;
          margin-bottom: 0.6rem;
        }
        .task-list {
          margin: 1rem 0 0.6rem;
          font-weight: bold;
        }
        .section-columns-left {
          width: 20%;
          font-size: 1.2rem;
          line-height: 1.6rem;
        }
        .section-columns-right {
          width: 80%;
          padding-left: 5rem;
        }
      </style>
      <body>
        ${cv.outerHTML}
      </body>
    </html>
    `;

    const printWindow = window.open('', title);
    printWindow.document.write(html);

    printWindow.document.close(); // necessary for IE >= 10
    printWindow.focus(); // necessary for IE >= 10*/

    printWindow.print();
  };


  return <ButtonSecondary text="Imprimir" onClick={() => printCV(profile)} />;
};

export default PrintToPdf;
