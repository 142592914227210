import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import SingleRow from '../common/SingleRow';
import theme from '../CustomTheme/Theme';

const Question = styled.div`
  font-size: ${theme.fontSizes.large};
  font-weight: bold;
  line-height: 1.18;
  text-align: center;
  color: ${theme.colors.themeSecondary};
  margin-top: 1rem;
`;

const Answer = styled.div`
  display: flex;
  width:100%;
  flex-direction:column;
  align-items: center;
  margin: 3rem 0;
`;

const AnswerText = styled.div`
  overflow: hidden;
  max-height: ${(props) => (props.extended ? '100rem' : '0')};
  height: ${(props) => (props.extended ? '100%' : '0%')};
  margin-bottom: ${(props) => (props.extended ? '2rem' : '0')};
  transition: all .5s;
  font-size: ${theme.fontSizes.medium};
  line-height: 1.47;
`;

const ButtonVer = styled.button`
  background-color:${theme.colors.themeTertiary};
  width:${(props) => (props.buttonWidth ? `${props.buttonWidth}rem` : '14rem')};;
  border-color: transparent;
  border-radius: 22.7rem;
  object-fit: contain;
  height: 4.5rem;
  color:${theme.colors.themeSecondary};
  font-weight: bold;
  font-size: ${theme.fontSizes.large};
  cursor:pointer;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width:14rem;
  }
`;

const ButtonContainer = styled.div`
  width:100%;
  position:absolute;
  display:flex;
  justify-content:center;
`;

const SeparatorWrapper = styled.div`
  width: 100%;
  height: 4.5rem;
  display:flex;
  align-items:center;
  position:relative;
`;

const AnswersAndQuestions = (props) => {
  const {
    faq = {},
    textInButton = { dropped: 'Ver menos', undropped: 'Ver más' },
    buttonWidth,
  } = props;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [buttonDropped, setbuttonDropped] = useState(false);
  const extended = buttonDropped;
  let buttonText = buttonDropped ? textInButton.dropped : textInButton.undropped;
  if (windowWidth <= 600 && textInButton.droppedMobile) {
    buttonText = buttonDropped ? textInButton.droppedMobile : textInButton.undroppedMobile;
  }

  useEffect(() => {
    window.addEventListener('resize', () => { setWindowWidth(window.innerWidth); });
    return () => {
      window.addEventListener('resize', () => { setWindowWidth(window.innerWidth); });
    };
  }, []);

  return (
    <>
      <Question>{faq.questionLang?.es || ''}</Question>
      <Answer>
        <AnswerText extended={extended} dangerouslySetInnerHTML={{ __html: faq.answerLang?.es }} />
        <SeparatorWrapper extended={extended}>
          <SingleRow width={100} />
          <ButtonContainer>
            <ButtonVer buttonWidth={buttonWidth} onClick={() => setbuttonDropped(!buttonDropped)}>
              {buttonText}
            </ButtonVer>
          </ButtonContainer>
        </SeparatorWrapper>
      </Answer>
    </>
  );
};

export default AnswersAndQuestions;
