import React from 'react';
import _get from 'lodash/get';
import ContactToEdit from './ContactToEdit';

import {
  ModalMenuP,
} from '../../formStyledComponents';

const EmergencyContactList = ({ 
  emergencyContactData, 
  setContactShown, 
  contactShown,
  profile,
}) => {

  return (
    <>
      {
        emergencyContactData.map(contact => {
          const name = _get(contact, 'name', '');
          const email = _get(contact, 'email', '');
          const phone = _get(contact, 'phone', '');
          const relationship = _get(contact, 'relationship', '');
          const contactKey = name + email;

          return (
            contactShown === contactKey && (
              <ContactToEdit
                name={name}
                email={email}
                phone={phone}
                relationship={relationship}
                key={contactKey + name}
                contactShown={contactShown}
                setContactShown={setContactShown}
                profile={profile}
              />
            )

          );
        })
      }
      {!contactShown && emergencyContactData.map(contact => {
        const name = _get(contact, 'name', '');
        const email = _get(contact, 'email', '');
        const contactKey = name + email;

        return (
          <ModalMenuP
            key={contactKey}
            onClick={() => setContactShown(contactKey)}
          >
            {name}
          </ModalMenuP>
        );
      })}
    </>
  );
};

export default EmergencyContactList;
