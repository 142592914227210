import React from 'react';
import styled from 'styled-components/macro';

const InputContainer = styled.label`
  flex: 0 0 35px;
  margin-right: 10px;
  display: ${(props) => (props.signUpMobile ? 'flex' : 'block')};
  position: relative;
  color: ${(props) => props.theme.colors.textPrimary};
  font-size:${(props) => (props.signUpMobile ? props.theme.fontSizes.small : props.theme.fontSizes.medium)};
  padding-left: 0rem;
  cursor: pointer;
  `;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:checked ~ span {
    background-color: ${(props) => props.theme.colors.themePrimary};
  }
  &:checked ~ span:after {
      display: block;
  }
`;

const Checkmark = styled.span`
  border-radius: 100%;
  position: absolute;
  top: 1px;
  right: ${(props) => (!props.positioned ? 0 : null)};
  left:${(props) => (props.positioned === 'left' ? 0 : null)};
  height: 1.25rem;
  width: 1.25rem;
  background-color: ${(props) => props.theme.colors.textSecondary};
`;

const TextWrapper = styled.div`
  margin-left:${({ marginLeft }) => (marginLeft && marginLeft)};
`;


const InputCheck = ({
  mBottom, 
  text = 'Click here', 
  checked = false, 
  value, 
  onChecked, 
  signUpMobile, 
  positioned, 
  marginLeft,
  onClick,
}) => (
    <InputContainer mBottom={mBottom} signUpMobile>
      <Input
        type="checkbox"
        onChange={() => onChecked(value)}
        checked={checked}
      />
      <Checkmark positioned={positioned} />
      <TextWrapper marginLeft={marginLeft} onClick={onClick}>{text}</TextWrapper>
    </InputContainer>
  );


export default InputCheck;
