import React from 'react';
import _get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import ButtonPrimary from '../../../common/Buttons/ButtonPrimary';
import ButtonSecondary from '../../../common/Buttons/ButtonSecondary';
import { studentActions } from '../../../../redux/actions';

import {
  FullFlexCont,
  Column,
  InputColumn,
  Input,
  Label,
  ContactForm,
  Error,
  DisabledBlueInput,
} from '../../formStyledComponents';

const ContactToEdit = ({
  name,
  email,
  phone,
  relationship,
  setContactShown,
  profile,
}) => {
  const { register, handleSubmit, errors } = useForm();

  const dispatch = useDispatch();

  const onSubmit = (data, e) => {
    e.preventDefault();
    const contacts = _get(profile, 'emergencyContacts', []);
    contacts.forEach((contact, index) => {
      if (data.name === contact.name) {
        contacts.splice(index, 1, data);

        dispatch(studentActions.save({ emergencyContacts: contacts }));
      }
    });
    setContactShown(null);
  };

  /* handle errors  */
  const emailError = errors.email?.type === 'required' && <Error>Por favor, indica tu email de contacto</Error>;
  const emailError2 = errors.email?.type === 'pattern' && <Error>Formato de email incorrecto</Error>;
  const nameError = errors.name && <Error>El nombre del contacto de emergencia es obligatorio</Error>;
  const phoneError = errors.phone?.type === 'required' && <Error>Debes indicar un teléfono móvil de contacto</Error>;
  const phoneError2 = errors.phone?.type === 'pattern' && <Error>Número de teléfono no válido</Error>;


  return (
    <ContactForm onSubmit={handleSubmit(onSubmit)}>

      <InputColumn margin="0">
        <Label>Nombre*:</Label>
        <DisabledBlueInput
          width="50%"
          name="name"
          placeholder="nombre"
          ref={register({ required: true })}
          mobileWidth="100%"
          mobileCenter
          mobileMargin="0 0 1rem 0"
          value={name}
          width="100%"
          center
          margin="1rem 0"
        />
        {nameError}
      </InputColumn>

      <InputColumn margin="0">
        <Label>Relación o parentesco:</Label>
        <Input
          width="50%"
          name="relationship"
          placeholder="relationship"
          ref={register({ required: false })}
          mobileCenter
          mobileWidth="100%"
          mobileMargin="0 0 1rem 0"
          defaultValue={relationship}
          width="100%"
          center
        />
      </InputColumn>

      <FullFlexCont padding="0" between alignEnd column>
        <InputColumn margin="0" width="100%">
          <Label>Email de contacto:</Label>
          <Input
            name="email"
            placeholder="email"
            ref={register({
              pattern: {
                value: /[\w\-\._]+@[\w\-\._]+\.\w{2,10}/,
              },
            })}
            mobileCenter
            mobileWidth="100%"
            mobileMargin="0 0 1rem 0"
            defaultValue={email}
            width="100%"
            center
          />
          {emailError}
          {emailError2}
        </InputColumn>

        <InputColumn margin="0" width="100%">
          <Label>Teléfono de contacto*:</Label>
          <Input
            name="phone"
            placeholder="phone"
            ref={register({
              required: true,
              pattern: {
                value: /^\+[0-9][0-9]{6,20}$/,
              },
            })}
            mobileCenter
            mobileWidth="100%"
            mobileMargin="0 0 1rem 0"
            defaultValue={phone}
            width="100%"
            center
          />
          {phoneError}
          {phoneError2}
        </InputColumn>
      </FullFlexCont>

      <FullFlexCont justify="space-between" padding="0" mobilePadding="0">
        <Column width="46" padding="0">
          <ButtonSecondary
            text="Atrás"
            onClick={() => setContactShown(null)}
            margin="auto"
            fullWidth
          />
        </Column>

        <Column width="46" padding="0">
          <ButtonPrimary
            type={2}
            text="Guardar"
            margin="auto"
            fullWidth
            htmlType="submit"
          />
        </Column>
      </FullFlexCont>

    </ContactForm>
  );
};

export default ContactToEdit;
