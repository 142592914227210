import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import {
  FormContainer, Label, Error, ButtonContainer,
} from './styledComponents';
import { InputText, ButtonPrimary } from '../common';
import HSpacer from '../layout/HSpacer';


const PasswordForm = (props) => {
  const { onChangePassword, setIsChangingPassword, isChangingPassword } = props;
  const {
    register, handleSubmit, errors, getValues,
  } = useForm();

  return (
    <FormContainer onSubmit={handleSubmit(onChangePassword)}>
      <Label>Nueva contraseña:</Label>
      <InputText
        name="password"
        type="password"
        placeholder={isChangingPassword ? 'Introduce tu nueva contraseña' : ''}
        inputRef={register({ required: 'Campo obligatorio' })}
      />
      {errors.password && <Error>{errors.password.message}</Error>}

      <HSpacer height="1.2rem" />

      <Label>Confirma contraseña:</Label>
      <InputText
        name="passwordConfirmation"
        type="password"
        placeholder={isChangingPassword ? 'Confirma tu nueva contraseña' : ''}
        inputRef={register({
          required: 'Por favor, confirma tu contraseña',
          validate: {
            matchesPreviousPassword: (value) => {
              const { password } = getValues();
              return password === value || 'Las contraseñas escritas no coinciden';
            },
          },
        })}
      />
      {errors.passwordConfirmation && <Error>{errors.passwordConfirmation.message}</Error>}

      <ButtonContainer>
        <ButtonPrimary
          iconColor="#105285"
          type={2}
          htmlType="submit"
          text="Guardar"
          fontSize="medium"
          fullWidth
        />
      </ButtonContainer>

      <ButtonContainer>
        <ButtonPrimary
          iconColor="#105285"
          type={2}
          text="Cancelar"
          fontSize="medium"
          onClick={() => setIsChangingPassword(false)}
          onSubmit={() => { }}
          fullWidth
        />
      </ButtonContainer>
    </FormContainer>
  );
};

PasswordForm.propTypes = {
  onChangePassword: PropTypes.func.isRequired,
  setIsChangingPassword: PropTypes.func.isRequired,
  isChangingPassword: PropTypes.func.isRequired,
};

export default PasswordForm;
