import { API, graphqlOperation } from 'aws-amplify';

import { getPartner } from '../../graphql/queries';
import { response } from './response';

// eslint-disable-next-line import/prefer-default-export
export async function getPartnerIva(id) {
  try {
    // const result = await API.graphql(graphqlOperation(getPartner, { id }));
    const result = await API.graphql({
      query: getPartner,
      authMode: 'API_KEY',
      variables: { id },
    });
    const { data } = result;
    const iva = data.getPartner?.iva;
    return response(true, null, iva);
  } catch (error) {
    console.log("🚀 ~ file: iva.js ~ line 15 ~ getPartnerIva ~ error", error)
    const { data, errors } = error;
    return response(false, '');
  }
}
