import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { studentActions } from '../../redux/actions';
import { getStudent } from '../../services/api/student';
import { getPayments } from '../../services/api/payments';

import Box from '../common/Flex/Box';
import InputCheck from '../common/formFields/Inputs/InputCheck';
import InputText from '../common/formFields/Inputs/InputText';
import { signUp, login } from '../../services/api/auth';
import { Error } from '../profile/formStyledComponents';
import { Modal } from '../common';
import HSpacer from '../layout/HSpacer';
import ButtonPrimary from '../common/Buttons/ButtonPrimary';
import ButtonSecondary from '../common/Buttons/ButtonSecondary';
import Loader from '../common/Loader';
import LegalContent from '../common/Legal/LegalContent';

import {
  StepTitle,
  CentralCardLeft,
  CentralCard,
} from './Styles';


const CenterCentralCard = styled(CentralCard)`
  margin: auto;
  justify-content: center;
  width: fit-content;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) { 
    width: 90%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) { 
    top: -16rem;
  }
`;

const CardLeftContainer = styled(CentralCardLeft)`
  padding: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {   
    padding:2rem;
    overflow-y:scroll;
    overflow-x: hidden;
    height: calc(100vh - 60px);
    top: 18rem;
  }
`;

const InputLabel = styled.label`
  display: block;
  text-align: center;
  color: ${(props) => props.theme.colors.greyDark};
  font-size: ${(props) => props.theme.fontSizes.large};
  margin-bottom: 3px;
`;

const Step5 = ({
  onStepBack,
  formData,
  onFormDataChange,
  screen,
  iva,
  device,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const passwordRef = useRef();
  const phoneRef = useRef();
  const query = new URLSearchParams(useLocation().search);
  const code = query.get('code');

  const [clicked, setClicked] = useState(false);
  const [errors, setErrors] = useState({});
  const [areErrors, setAreErrors] = useState(false);
  const [legalSelected, setLegalSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const errorValues = Object.values(errors);
    const isTrue = (value) => value === true;
    const someError = errorValues.some(isTrue);

    if (!someError) {
      setAreErrors(false);
    } else {
      setAreErrors(true);
      setLoading(false);
    }
  }, [formData, errors]);

  // Accept conditions to sign up
  const initialConditions = {
    terms: false,
    cancel: false,
    data: false,
  };
  const [conditions, setConditions] = useState(initialConditions);

  const handleConditionsChange = (name, value) => {
    setConditions({
      ...conditions,
      [name]: value,
    });
  };

  const checkAcceptConditions = () => {
    const conditionValues = Object.values(conditions);
    const isTrue = (value) => value === true;
    const result = conditionValues.every(isTrue);
    return result;
  };

  // Set errors
  const onSetErrors = (password, values) => {
    const acceptedConditions = checkAcceptConditions(conditions);
    const phoneFormat = new RegExp(/^\+[0-9][0-9]{6,20}$/);
    const correctPhone = phoneFormat.test(values.phone);
    const emailFormat = new RegExp(/[\w\-._]+@[\w\-._]+\.\w{2,10}/);
    const correctEmail = emailFormat.test(values.email);
    const passwordFormat = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S{6,99}$/);
    const correctPassword = passwordFormat.test(password);

    setErrors({
      name: !values.name,
      lastname: !values.lastname,
      email: !values.email,
      emailFormat: !correctEmail,
      phone: !values.phone,
      phoneFormat: !correctPhone,
      password: !password,
      passwordFormat: !correctPassword,
      conditions: !acceptedConditions,
    });
  };

  const [signUpErrorMessage, setSignUpErrorMessage] = useState('');

  // Sign up submit
  const handleSignUp = async (password, values) => {
    setClicked(true);
    setLoading(true);
    setSignUpErrorMessage('');
    onSetErrors(password, values);
    const acceptedConditions = checkAcceptConditions(conditions);

    const finishedStudy = values.finishedStudy ? '1' : '0';
    const euNationality = values.eu ? '1' : '0';
    // const code = '';

    // We send departmentsLooking as an array join because custom fields can only be string or number.
    const internship = {
      ...(values?.requiredHours && { 'custom:internshipHours': values.requiredHours }),
      ...(values?.selectedDepartments && { 'custom:departmentsLooking': values.selectedDepartments.join('-') }),
      ...(values?.initDate && { 'custom:initDate': values.initDate }),
      ...(values?.endDate && { 'custom:endDate': values.endDate }),
      ...(values?.priceSelected?.service?.id && { 'custom:internshipServiceId': values.priceSelected.service.id }),
    };

    const allValues = {
      password,
      phone_number: values.phone,
      name: values.name,
      email: values.email,
      'custom:lastname': values.lastname,
      'custom:studentCountryId': values.country,
      'custom:studentResidenceId': values.residence,
      'custom:studentUniversityId': values.university,
      'custom:euNationality': euNationality,
      'custom:finishedStudy': finishedStudy,
      'custom:studentPartnerId': code || 'NEXUS',
      'custom:device': device,
      ...internship,
    };

    if (!areErrors && acceptedConditions) {
      const response = await signUp(allValues);

      if (response.success) {
        const loginResponse = await login(allValues.email, allValues.password);
        if (loginResponse.success) {
          const { data: student } = await getStudent();
          const newPayments = await getPayments({
            formData,
            paymentStudentId: student.id,
            studentCode: `${student.code}/${student.number}`,
            iva,
          });

          student.payments = student?.payments
            ? [...student.payments, ...newPayments] : [...newPayments];
          student.currentStudy = {
            studyCountryId: formData.residence,
            ended: formData.finishedStudy,
          };
          student.mobilePhone = formData.phone;

          dispatch(studentActions.save(student));
          history.push('/payment');
          return;
        }
      }
      let message = 'Ha habido un error en el registro';
      if (response.message === 'UsernameExistsException') message = 'Ya existe un usuario con ese mail registrado';
      setSignUpErrorMessage(message);
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    onSetErrors(passwordRef.current.value, formData);
  }, [formData, conditions]);

  /* handle errors  */
  const nameError = clicked && errors.name && <Error>Debes indicar tu nombre</Error>;
  const lastnameError = clicked && errors.lastname && <Error>Debes indicar tus apellidos</Error>;
  const emailError = clicked && errors.email && <Error>Debes indicar tu email</Error>;
  const emailFormatError = clicked && errors.emailFormat && !errors.email && <Error>El formato del email no es válido</Error>;
  const phoneError = clicked && errors.phone && <Error>Debes indicar tu teléfono</Error>;
  const phoneFormatError = clicked && errors.phoneFormat && !errors.phone && <Error>El formato del móvil no es válido. (Ej.: +34999999999)</Error>;
  const passwordError = clicked && errors.password && <Error>Contraseña obligatoria</Error>;
  const passwordFormatError = clicked && errors.passwordFormat && !errors.password && <Error>La contraseña debe tener al menos 6 carácteres, sin espacios y contener por lo menos un número, una mayúscula, una minúscula y un carácter especial</Error>;
  const conditionsError = clicked && errors.conditions && <Error>Debes aceptar los términos y las políticas de cancelación y de protección de datos</Error>;
  const signUpError = signUpErrorMessage !== '' && <Error>{signUpErrorMessage}</Error>;


  return (
    <CenterCentralCard>

      {legalSelected && (
        <Modal onClose={() => setLegalSelected(null)} isLegal>
          <LegalContent contentKey={legalSelected} />
        </Modal>
      )}

      <CardLeftContainer>
        <StepTitle>Datos estudiante</StepTitle>
        {signUpError}
        <HSpacer height="40px" />
        <InputText
          name="name"
          label="Nombre"
          value={formData.name}
          placeholder="Introduce tu nombre"
          onChange={(value) => onFormDataChange('name', value)}
          controlled
        />
        {nameError}
        <HSpacer />
        <InputText
          name="lastname"
          label="Apellidos"
          value={formData.lastname}
          placeholder="Introduce tus apellidos"
          onChange={(value) => onFormDataChange('lastname', value)}
          controlled
        />
        {lastnameError}
        <HSpacer />
        <InputText
          name="email"
          label="E-mail"
          value={formData.email}
          type="email"
          placeholder="Introduce tu email"
          onChange={(value) => onFormDataChange('email', value)}
          controlled
        />
        {emailError}
        {emailFormatError}
        <HSpacer />
        <InputText
          name="phone"
          label="Teléfono"
          value={formData.phone}
          placeholder="Introduce tu teléfono (Ej.: +34999999999)"
          onChange={(value) => onFormDataChange('phone', value)}
          controlled
          ref={phoneRef}
        />
        {phoneError}
        {phoneFormatError}
        <HSpacer />
        <InputText
          name="password"
          label="Password"
          type="password"
          placeholder="Introduce tu password"
          inputRef={passwordRef}
        />
        {passwordError}
        {passwordFormatError}
        <HSpacer />
        <InputLabel>He leído y acepto</InputLabel>
        <HSpacer height="2rem" />
        <Box container signUpMobile align="flex-start">
          <Box w={1 / 3} spacing={3}>
            <InputCheck
              text="Términos y condiciones"
              signUpMobile
              positioned="left"
              marginLeft="2rem"
              checked={conditions.terms}
              value={conditions.terms}
              onChecked={(checked) => handleConditionsChange('terms', !checked)}
              onClick={() => setLegalSelected('terms')}
            />
          </Box>
          <Box w={1 / 3} spacing={3}>
            <InputCheck
              text="Políticas de cancelación"
              signUpMobile
              positioned="left"
              marginLeft="2rem"
              checked={conditions.cancel}
              value={conditions.cancel}
              onChecked={(checked) => handleConditionsChange('cancel', !checked)}
              onClick={() => setLegalSelected('cancel')}
            />
          </Box>
          <Box w={1 / 3} spacing={3}>
            <InputCheck
              text="Protección de datos"
              signUpMobile
              positioned="left"
              marginLeft="2rem"
              checked={conditions.data}
              value={conditions.data}
              onChecked={(checked) => handleConditionsChange('data', !checked)}
              onClick={() => setLegalSelected('privacy')}
            />
          </Box>
        </Box>
        {conditionsError}

        {screen === 'phone' ? (
          <>
            <HSpacer height="2rem" />
            <Box container width="100%">
              <Box w={1 / 2} spacing={1} margin="0 1rem 0 0">
                <ButtonSecondary
                  text="Atrás"
                  onClick={onStepBack}
                  margin="auto"
                  fullWidth
                  mobilePadding="1rem 0.5rem"
                />
              </Box>
              <Box w={1 / 2} spacing={1}>
                <ButtonPrimary
                  htmlType="button"
                  text={loading ? <Loader /> : 'Registrarse'}
                  onClick={() => handleSignUp(passwordRef.current.value, formData)}
                  margin="auto"
                  fullWidth
                  mobilePadding="1rem 0.5rem"
                />
              </Box>
            </Box>
          </>
        ) : (
          <>
            <HSpacer height="2rem" />
            <ButtonPrimary
              htmlType="button"
              text={loading ? <Loader /> : 'Registrarse'}
              onClick={() => handleSignUp(passwordRef.current.value, formData)}
              fullWidth
            />
          </>
        )}

      </CardLeftContainer>
    </CenterCentralCard>
  );
};

export default Step5;
