import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import _get from 'lodash/get';
import SingleRow from '../../../common/SingleRow';
import DigitalToEdit from './DigitalToEdit';
import ButtonPrimary from '../../../common/Buttons/ButtonPrimary';
import ButtonSecondary from '../../../common/Buttons/ButtonSecondary';
import NewDigital from '../digitalExperience/NewDigital';
import { studentActions } from '../../../../redux/actions';

import {
  FullFlexCont,
  ModalMenuP,
  NoIconTitle,
  WhiteSpace,
  Column
} from '../../formStyledComponents';


const DigitalsList = ({
  profile,
  setSelectedCvEdit,
  littleDevice,
}) => {

  const dispatch = useDispatch();

  const [selectedDigital, setSelectedDigital] = useState(null);

  const onSelectField = (field) => {
    setSelectedDigital(field);
  };

  /* add-prev-studies button */
  const addDigital = (data) => {
    const digitals = [];
    const digitalExperience = _get(profile, 'digitalExperience', []);
    digitalExperience.forEach(exp => {
      digitals.push(exp);
    });
    digitals.push(data);
    dispatch(studentActions.save({ digitalExperience: digitals }));
    setSelectedDigital(null);
  };

  const levels = ['none', 'user', 'advanced', 'professional', 'expert'];
  const digitalExperience = _get(profile, 'digitalExperience', []);

  return (
    <FullFlexCont padding="0" between alignEnd mobilePadding="0">

      <NoIconTitle width="100%" margin=" 2rem 0"  center>COMPETENCIAS DIGITALES</NoIconTitle>

      <WhiteSpace mobileHeight="5rem" />

      {
        digitalExperience.map((digit, i) => {
          const name = _get(digit, 'name', '');
          const level = _get(digit, 'level', '');
          const certificate = _get(digit, 'certificate', '');

          return (
            selectedDigital === name && (
              <DigitalToEdit
                key={name}
                name={name}
                level={level}
                certificate={certificate}
                setSelectedDigital={setSelectedDigital}
                profile={profile}
                levels={levels}
              />
            )
          );
        })
      }

      {!selectedDigital && (
        <>
          {digitalExperience.map(field => {
            return <ModalMenuP key={field.name} onClick={() => onSelectField(field.name)}>{field.name}</ModalMenuP>;
          })}

          <SingleRow mobileMargin="0" margin="0" />
        </>
      )}

      {!selectedDigital &&
        (
          <FullFlexCont justify="space-between">
            <Column width="46" padding="0">
              <ButtonSecondary
                text="Atrás"
                onClick={() => setSelectedCvEdit(null)}
                margin="auto"
                fullWidth
              />
            </Column>

            <Column width="46" padding="0">
              <ButtonPrimary
                type={2}
                text="Añadir"
                margin="auto"
                fullWidth
                onClick={() => setSelectedDigital('addDigital')}
              />
            </Column>
          </FullFlexCont>
        )}

      {selectedDigital === 'addDigital' && (
        <NewDigital
          addDigital={addDigital}
          profile={profile}
          littleDevice={littleDevice}
          setSelectedDigital={setSelectedDigital}
          levels={levels}
          isEditModal
        />
      )}

    </FullFlexCont>
  );
};

export default DigitalsList;

