import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import _sortBy from 'lodash/sortBy';

import InputCheck from '../common/formFields/Inputs/InputCheck';
import ButtonPrimary from '../common/Buttons/ButtonPrimary';
import Select from '../common/formFields/Select';
import {
  StepTitle,
  CentralCard,
  CentralCardLeft,
  OnBoardingIllustration,
  CentralRight,
  CentralCardLine,
  SlidesDots,
  SlidesDotsItem,
} from './Styles';
import HSpacer from '../layout/HSpacer';
import useListPublicEntity from '../../hooks/useListPublicEntity';
import { Error } from '../profile/formStyledComponents';

const ChecksQuestion = styled.p`
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.colors.greyDark};
`;

const ChecksContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ChecksContainerItem = styled.div`
  flex: 0 0 45px;
  margin: 0 10px;
`;

const sortCountries = (countries) => {
  const sortedCountries = _sortBy(countries, 'nameLang.es');
  const findIndex = sortedCountries.findIndex((el) => el.nameLang.es === 'Otros Europa');
  if (findIndex >= 0) {
    sortedCountries.splice(sortedCountries.length - 1, 0, sortedCountries.splice(findIndex, 1)[0]);
  }
  // Hidden 'France' & 'Portugal' options
  return sortedCountries.filter(({ nameLang }) => nameLang.es === 'España');
};


const Step1 = (props) => {
  const {
    onStepCompleted, onDotClick, formData, onFormDataChange,
  } = props;

  const { data: residences } = useListPublicEntity('residence');
  const { data: countries } = useListPublicEntity('country');
  const sortedCountries = sortCountries(countries);

  const [clicked, setClicked] = useState(false);
  const [errors, setErrors] = useState({});
  const [areErrors, setAreErrors] = useState(false);

  useEffect(() => {
    const errorValues = Object.values(errors);
    const isTrue = (value) => value === true;
    const someError = errorValues.some(isTrue);

    if (!someError) {
      setAreErrors(false);
    } else {
      setAreErrors(true);
    }
  }, [formData, errors]);

  // Set errors
  const onSetErrors = (values) => {
    setErrors({
      country: !values.country,
      residence: !values.residence,
    });
  };

  // Check errors and continue
  const onContinue = (values) => {
    setClicked(true);
    onSetErrors(values);
    if (areErrors) return;
    onStepCompleted();
  };

  /* handle errors  */
  const countryError = clicked && errors.country && <Error>Debes indicar el país de destino</Error>;
  const residenceError = clicked && errors.residence && <Error>Debes indicar tu país de residencia</Error>;

  useEffect(() => {
    onSetErrors(formData);
  }, [formData]);

  return (
    <CentralCard>
      <CentralCardLeft>
        <OnBoardingIllustration src="/images/on-boarding/onboarding-step-1.png" />
      </CentralCardLeft>
      <CentralCardLine />
      <CentralRight>
        <StepTitle>¿Dónde deseas realizar tus prácticas?</StepTitle>
        <HSpacer height="40px" />
        <Select
          placeholder="País de destino"
          options={sortedCountries}
          onSelectChange={(value) => onFormDataChange('country', value)}
          value={formData.country}
        />
        {countryError}
        <HSpacer />
        <Select
          placeholder="País de residencia"
          options={residences}
          onSelectChange={(value) => onFormDataChange('residence', value)}
          value={formData.residence}
        />
        {residenceError}
        <HSpacer />
        <ChecksQuestion>¿Tienes pasaporte europeo?</ChecksQuestion>
        <HSpacer height="10px" />
        <ChecksContainer>
          <ChecksContainerItem>
            <InputCheck text="No" checked={!formData.eu} value={false} onChecked={(checked) => onFormDataChange('eu', checked)} />
          </ChecksContainerItem>
          <ChecksContainerItem>
            <InputCheck text="Sí" checked={formData.eu} value onChecked={(checked) => onFormDataChange('eu', checked)} />
          </ChecksContainerItem>
        </ChecksContainer>
        <HSpacer height="40px" />
        <ButtonPrimary text="Siguiente" onClick={() => onContinue(formData)} fullWidth />
        <SlidesDots>
          <SlidesDotsItem onClick={() => onDotClick(0)} active />
          <SlidesDotsItem onClick={() => onDotClick(1)} />
          <SlidesDotsItem onClick={() => onDotClick(2)} />
          <SlidesDotsItem onClick={() => onDotClick(3)} />
        </SlidesDots>
      </CentralRight>
    </CentralCard>
  );
};

export default Step1;
