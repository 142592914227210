import React from 'react';
import PropTypes from 'prop-types';

import { BarContainer, BarContent } from './styledComponents';

const ProgressBar = ({ progress, margin, mobileMargin }) => {
  return (
    <BarContainer margin={margin} mobileMargin={mobileMargin}>
      <BarContent progress={progress}>
        {progress}%
      </BarContent>
    </BarContainer>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  margin: PropTypes.string,
  mobileMargin: PropTypes.string,
};

ProgressBar.defaultProps = {
  margin: '2rem 0',
  mobileMargin: '1rem',
}

export default ProgressBar;