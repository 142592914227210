import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import UserContainer from './UserContainer';
import { ButtonIcon } from '../common';
import { showBurgerMenu, studentActions } from '../../redux/actions';
import Hamburger from './Hamburger';
import { signout } from '../../services/api/auth';


const Container = styled.div`
  box-shadow: ${(props) => props.theme.shadows.header};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  left: 0%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  z-index: 10;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {   
    box-shadow: none;
  }
`;

const Logo = styled.img`
  object-fit: cover;
  object-position: 50% 50%;
  width: 85px;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const IconWrapper = styled(Link)`
  display: flex;
  align-items: center;
`;


const Header = ({ userImg = '', userName = '', isLogin, noMenuLink, notificationsNumber }) => {
  const screen = useSelector((state) => state.windowResize.screen);
  const showMenu = useSelector((state) => state.showBurgerMenu.value);
  const isHamburguerMenu = screen === 'phone' || screen === 'tab-port';
  const dispatch = useDispatch();

  const activateMenu = () => {
    if (showMenu) dispatch(showBurgerMenu.hidde());
    else dispatch(showBurgerMenu.show());
  };

  // log out
  const history = useHistory();

  const handleLogout = async () => {
    const { success } = await signout();

    if (success) {
      history.push('/login');
      dispatch(studentActions.empty());
    }
  };

  const onSignOut = (e) => {
    e.preventDefault();
    handleLogout();
  };

  if (isHamburguerMenu) {
    return (
      <Container>
        <Link to={!noMenuLink ? '/menu' : '/on-boarding'}>
          <Logo src={!showMenu && '/images/logo.jpg'} alt={!showMenu && 'Nexus logo'} />
        </Link>
        <FlexWrapper>
          {!noMenuLink && (
            <IconWrapper to="/notifications">
              <ButtonIcon width={2} icon="bell" iconColor="#4e5152" bgColor="grey" />
              {notificationsNumber}
            </IconWrapper>
          )}
          {!noMenuLink
            ? (
              <Hamburger
                showMenu={showMenu}
                activateMenu={activateMenu}
              />
            )
            : !isLogin && <UserContainer userImg={userImg} userName={userName} onClick={onSignOut} />}
        </FlexWrapper>
      </Container>
    );
  }

  return (
    <Container>
      <Link to={!noMenuLink ? '/menu' : '/on-boarding'}>
        <Logo src="/images/logo.jpg" alt="Nexus logo" />
      </Link>
      {!isLogin && <UserContainer userImg={userImg} userName={userName} onClick={onSignOut} />}
    </Container>
  );
};

export default Header;
